import { math, rgba } from 'polished';
import styled from 'styled-components';

import { Tag, TagsContainer } from '@littleotter/legacy-components';

export const WorryDomainTag = styled(Tag)`
  background-color: ${({ theme }) => rgba(theme.deprecated_.colors.red, 0.2)};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
`;

export const WorryDomainTagsContainer = styled(TagsContainer)`
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
`;
