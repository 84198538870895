import { useEffect, useRef } from 'react';

const STORAGE_KEY = 'checkup-refresh-detector';
const makeToken = () => new Date().getTime().toString();

/**
 * useRefreshDetector will detect when a refresh occurs during the time the calling component is loaded.
 * It operates by leveraging the difference in lifecycle between localStorage and MutableRefObject.
 * When a page is reloaded, all objects on the page and all data in memory is lost; however, data in localStorage is not.
 * This means that we can tell when a refresh happens if our localStorage data contains a marker that the current
 * data in memory does not, in this case being a `currentSessionToken`.
 * @param onRefresh The function to be called when a refresh happens.
 * The argument is a function that returns the current token.
 */
export const useRefreshDetector = (onRefresh: (getToken: () => string) => void) => {
  const currentSessionToken = useRef<string>('');

  useEffect(() => {
    const foundSessionToken = localStorage.getItem(STORAGE_KEY);
    const previousSessionWasInterrupted =
      foundSessionToken !== null && foundSessionToken !== currentSessionToken.current;
    if (previousSessionWasInterrupted) {
      localStorage.removeItem(STORAGE_KEY);
      onRefresh(() => currentSessionToken.current);
      return;
    }
    currentSessionToken.current = makeToken();
    localStorage.setItem(STORAGE_KEY, currentSessionToken.current);
  }, [onRefresh]);
};
