import { type Message, type Participant } from '@twilio/conversations';
import { useEffect, useMemo, useState, type FC, type PropsWithChildren } from 'react';

import { type ConversationPreview } from '$shared/contexts/Conversations/conversationPreview';

import { type ConversationInfoFragment } from '../../../../../graphql/__generated__/ConversationInfoFragment';
import { type ViewerQuery_viewer } from '../../../../../graphql/__generated__/ViewerQuery';
import { ConversationMeta } from './components';

export type ConversationListItemProps = {
  conversation: ConversationPreview;
  conversationInfo: ConversationInfoFragment;
  lastActiveAt: Date;
  onMessageAdded: () => void;
  viewer: ViewerQuery_viewer;
};

export const ConversationListItem: FC<PropsWithChildren<ConversationListItemProps>> = ({
  conversation,
  conversationInfo,
  lastActiveAt,
  onMessageAdded,
  viewer,
}) => {
  const [unreadMessageCount, setUnreadMessageCount] = useState<number | null>(null);
  const [lastMessage, setLastMessage] = useState<Message | null>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);

  const isNotDeleted = (message: Message) => {
    // @ts-expect-error: TODO: find a way to type this method
    return !message.attributes?.is_deleted;
  };

  const isLastMessageOutgoing = useMemo(() => lastMessage?.author === viewer.id, [viewer.id, lastMessage]);
  const isLastMessageRead = useMemo(() => {
    if (!isLastMessageOutgoing) {
      return null;
    }

    const lastMessageRecipients = participants.filter((participant) => participant.identity !== lastMessage?.author);
    return lastMessageRecipients.every((participant) => participant.lastReadMessageIndex === lastMessage?.index);
  }, [isLastMessageOutgoing, lastMessage, participants]);

  useEffect(() => {
    let active = true;
    const addNewMessage = (newMessage: Message) => {
      if (active) {
        setLastMessage(newMessage);
        setUnreadMessageCount((count) => (count ?? 0) + 1);
        onMessageAdded();
      }
    };

    conversation.on('messageAdded', addNewMessage);

    return () => {
      active = false;
      conversation.off('messageAdded', addNewMessage);
    };
  });

  useEffect(() => {
    let active = true;
    const getParticipants = async () => {
      const _participants = await conversation.getParticipants();
      if (active) {
        setParticipants(_participants);
      }
    };
    getParticipants();

    conversation.on('participantUpdated', getParticipants);

    return () => {
      active = false;
      conversation.off('participantUpdated', getParticipants);
    };
  }, [conversation]);

  useEffect(() => {
    let active = true;

    const getConversationInformation = async () => {
      const [unreadMessageCountFromTwilio, totalMessageCountFromTwilio, lastMessageFromTwilio] = await Promise.all([
        conversation.getUnreadMessagesCount(),
        conversation.getMessagesCount(),
        conversation.getMessages(1),
      ]);
      if (active) {
        setUnreadMessageCount(unreadMessageCountFromTwilio ?? totalMessageCountFromTwilio);
        setLastMessage(lastMessageFromTwilio.items.filter(isNotDeleted)[0] ?? null);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    getConversationInformation();

    return () => {
      active = false;
    };
  }, [conversation, lastActiveAt]);

  return (
    <ConversationMeta
      sid={conversation.sid}
      participants={conversationInfo.participants}
      viewer={viewer}
      unreadMessageCount={unreadMessageCount ?? 0}
      lastActiveAt={lastActiveAt}
      lastMessageBody={lastMessage?.body || (conversation.closed ? '(archived)' : '(media message)')}
      isLastMessageOutgoing={isLastMessageOutgoing}
      isLastMessageRead={!!isLastMessageRead}
    />
  );
};
