import { hideVisually, math } from 'polished';
import styled from 'styled-components';

export const StyledContainer = styled.label`
  background: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  padding: ${({ theme }) => `${theme.deprecated_.sizeBasis} ${math(`${theme.deprecated_.sizeBasis} * 1.25`)}`};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  display: flex;
  user-select: none;
`;

export const StyledContent = styled.div`
  margin-left: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const StyledTitle = styled.header`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 3`)};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
`;

export const StyledDescription = styled.small`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
`;

export const StyledCircleContainer = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-color: ${({ theme }) => theme.deprecated_.border.color};
  border-width: ${({ theme }) => theme.deprecated_.border.size};
  border-style: ${({ theme }) => theme.deprecated_.border.style};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: ${({ theme }) => theme.deprecated_.colors.white};
  cursor: pointer;
`;

export const StyledCircle = styled.div<{ checked: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%};
  background: ${(props) => (props.checked ? props.theme.deprecated_.colors.brandPurple : 'transparent')};
`;

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  ${hideVisually()}
`;
