import { endRoute, parentRoute } from '$shared/utils/routes';

import { type FmhcUrlParams } from './reports';

export const paymentDetailsRoutes = {
  paymentDetails: parentRoute('/payment-details')({
    welcomeCall: parentRoute('/payment-details/welcome-call')({
      welcomeCall: endRoute('/payment-details/welcome-call'),
      confirmation: endRoute('/payment-details/welcome-call/confirmation'),
    }),
    fmhc: parentRoute('/payment-details/fmhc')({
      confirmation: endRoute<FmhcUrlParams>('/payment-details/fmhc/confirmation/:fmhcId'),
    }),
  }),
};
