import { type FC } from 'react';

import { CountWrapper, CurrentCount } from './styled';

export type CounterProps = {
  current: number;
  total: number;
};

export const Counter: FC<CounterProps> = ({ current, total }) => (
  <CountWrapper>
    <CurrentCount>{current}</CurrentCount> of {total}
  </CountWrapper>
);
