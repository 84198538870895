import { type FmhcReport as GqlFmhcReport } from '../../../../../../graphql/lo1/generated';
import { type ChildSubject } from '../../../../../FmhcCheckup/types';
import { type SubjectReportCollection } from '../../../types';
import { type ChildIdToChildSubjectMap, type ChildIdToWorryDomainsMap } from '../types';
import { gqlChildReportToAppReportForSubject } from './childReports';
import { separateReportsBySubject } from './separateReportsBySubject';
import { gqlCaregiverReportToAppReportForSubject, gqlFamilyReportToAppReportForSubject } from './subjectReports';

export const handleGqlChildReports = (
  gqlChildReports: GqlFmhcReport[],
  childrenWorryDomainsByChildId: ChildIdToWorryDomainsMap,
  childSubjectsByChildId: ChildIdToChildSubjectMap,
  isFreeAccount: boolean
): SubjectReportCollection[] => {
  return gqlChildReports
    .map(gqlChildReportToAppReportForSubject(childrenWorryDomainsByChildId, childSubjectsByChildId, isFreeAccount))
    .filter(
      (maybeSubjectReportCollection): maybeSubjectReportCollection is SubjectReportCollection =>
        !!maybeSubjectReportCollection
    )
    .sort((a, b) => ((a.subject as ChildSubject).firstName < (b.subject as ChildSubject).firstName ? -1 : 0));
};

export const handleCaregiverReports = (
  gqlCaregiverReports: GqlFmhcReport[],
  caregiverWorryDomains: string[],
  isFreeAccount: boolean
): SubjectReportCollection[] => {
  return gqlCaregiverReports.map(gqlCaregiverReportToAppReportForSubject(caregiverWorryDomains, isFreeAccount));
};

export const handleFamilyReports = (
  gqlFamilyReports: GqlFmhcReport[],
  familyWorryDomains: string[],
  isFreeAccount: boolean
): SubjectReportCollection[] => {
  return gqlFamilyReports.map(gqlFamilyReportToAppReportForSubject(familyWorryDomains, isFreeAccount));
};

export const handleGqlSubjectReports = (
  reports: GqlFmhcReport[],
  childrenWorryDomainsByChildId: ChildIdToWorryDomainsMap,
  childSubjectsByChildId: ChildIdToChildSubjectMap,
  caregiverWorryDomains: string[],
  familyWorryDomains: string[],
  isFreeAccount: boolean
): SubjectReportCollection[] => {
  const { childGqlReports, familyGqlReports, caregiverGqlReports } = separateReportsBySubject(reports);

  return [
    ...handleGqlChildReports(childGqlReports, childrenWorryDomainsByChildId, childSubjectsByChildId, isFreeAccount),
    ...handleCaregiverReports(caregiverGqlReports, caregiverWorryDomains, isFreeAccount),
    ...handleFamilyReports(familyGqlReports, familyWorryDomains, isFreeAccount),
  ];
};
