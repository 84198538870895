import { type FC, type ReactNode } from 'react';
import { type FieldErrors } from 'react-hook-form6';
import ReactSelect, { components, type ControlProps } from 'react-select';

import {
  StyledControl,
  StyledDropdownIndicator,
  StyledError,
  StyledIndicatorSeparator,
  StyledLabel,
  StyledLabelDiv,
} from './styled';

export type Option<Value extends string | number> = {
  label: string | number;
  value: Value;
};

export type SelectProps<Value extends string | number = string | number> = {
  name: string;
  options: Option<Value>[];
  label?: ReactNode;
  placeholder?: string;
  value?: Value;
  onChange?: (value: string) => void;
  errors?: FieldErrors;
  noEmptyOption?: boolean;

  disabled?: boolean;
  defaultValue?: Option<Value>;
};

export type CustomControlProps = ControlProps<Option<string | number>, false>;

export const Control: FC<CustomControlProps> = ({ selectProps, ...props }) => (
  <StyledControl hasError={selectProps.hasError} hasLabel={selectProps.hasLabel}>
    <components.Control {...props} selectProps={selectProps} />
  </StyledControl>
);

export const DropdownIndicator: FC = () => <StyledDropdownIndicator />;

export const IndicatorSeparator: FC = () => <StyledIndicatorSeparator />;

export const Select = <Value extends string | number>({
  label,
  name,
  onChange,
  options,
  placeholder,
  errors,
  noEmptyOption,
  value,
  defaultValue,
}: SelectProps<Value>) => {
  const updatedOptions = noEmptyOption ? options : [{ label: placeholder ?? '', value: '' }, ...options];
  const getOptionByValue = (_value: string | number | undefined) =>
    updatedOptions.find((option) => option.value === _value);

  return (
    <StyledLabel>
      {label && <StyledLabelDiv>{label}</StyledLabelDiv>}

      <ReactSelect
        isSearchable={false}
        isMulti={false}
        options={updatedOptions}
        components={{ DropdownIndicator, IndicatorSeparator, Control }}
        hasLabel={!!label}
        hasError={errors && errors[name]}
        onChange={(event) => onChange && onChange((event?.value as string) || '')}
        value={getOptionByValue(value)}
        defaultValue={defaultValue}
        placeholder={placeholder}
      />
      <StyledError>{errors?.[name]?.message}</StyledError>
    </StyledLabel>
  );
};
