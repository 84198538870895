import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { SurveyResponseDetails } from '../../../components/SurveyResponseDetails';
import { type ClientSurveyResponseDetailsQuery } from '../../../graphql/__generated__/ClientSurveyResponseDetailsQuery';
import { MissingQueryDataError } from '../../../graphql/errors';
import { CLIENT_SURVEY_RESPONSE_DETAILS_QUERY } from '../../../graphql/provider-client';

export const ClientSurveyResponseDetails: FC<PropsWithChildren> = () => {
  const { id } = useParams<{ id: string }>();

  const { data, error, loading } = useQuery<ClientSurveyResponseDetailsQuery>(CLIENT_SURVEY_RESPONSE_DETAILS_QUERY, {
    variables: { id: Number(id) },
  });
  useGraphQLErrorHandling(error);

  if (loading) return <PageWideLoading />;

  const surveyResponse = data?.surveyResponse;

  if (!surveyResponse?.survey) throw new MissingQueryDataError('ClientSurveyResponseDetailsQuery');

  return <SurveyResponseDetails surveyResponse={surveyResponse} showScores />;
};
