import { type FC, type PropsWithChildren } from 'react';

import { StyledUl } from './styled';

export type ListProps = {
  variant?: 'disc' | 'connected-disc' | 'connected-circle';
  className?: string;
};

export const List: FC<PropsWithChildren<ListProps>> = ({ className, variant = 'disc', children }) => (
  <StyledUl variant={variant} className={className}>
    {children}
  </StyledUl>
);
