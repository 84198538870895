import { type CustomLayerProps } from '@nivo/line';
import { area } from 'd3-shape';

export const thresholdLayerFactory = ({
  maxScore,
  threshold,
  thresholdXValues,
  thresholdColor,
}: thresholdLayerFactoryProps) => {
  const thresholdLayer = ({ lineGenerator, xScale, yScale }: CustomLayerProps) => {
    const areaGenerator = area()
      .x(([x]) => xScale(x))
      .y1(([, y]) => yScale(y))
      .y0(() => yScale(maxScore * 2));

    const thresholdArea: [number, number][] = [
      [thresholdXValues[0], threshold],
      [thresholdXValues[3], threshold],
    ];
    // thresholdLines is not actually being used right now. It could be used to implement
    // the Figma design's CONCERNING threshold line IF nivo/annotations supported Line charts,
    // but it is not supported now :cry:
    const thresholdLines: [number, number][] = [
      [thresholdXValues[0], thresholdXValues[1]],
      [thresholdXValues[2], thresholdXValues[3]],
    ];

    return (
      <>
        <path d={areaGenerator(thresholdArea) ?? undefined} fillOpacity={0.3} fill={thresholdColor} />
        {thresholdLines.map(([x0, x1]) => {
          const lineStyle = {
            stroke: thresholdColor,
            strokeWidth: 2,
            strokeDasharray: 5,
          };
          const d = lineGenerator([
            { x: xScale(x0), y: yScale(threshold) },
            { x: xScale(x1), y: yScale(threshold) },
          ]);
          return <path key={x0} d={d ?? undefined} fill="none" stroke={thresholdColor} style={lineStyle} />;
        })}
      </>
    );
  };
  return thresholdLayer;
};

export interface thresholdLayerFactoryProps {
  maxScore: number;
  threshold: number;
  thresholdXValues: number[];
  thresholdColor: string;
}
