import { useRef, type DetailedHTMLProps, type FC, type PropsWithChildren, type TextareaHTMLAttributes } from 'react';

import { useWindowDimensions } from '$shared/hooks/useWindowDimensions';

import { InputBoxContainer, StyledInputBox } from './styled';

export const InputBox: FC<
  PropsWithChildren<Omit<DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, 'ref'>>
> = ({ onChange, ...props }) => {
  // TODO: figure out why react dev tools log a noisy message about using forwardRef even though this seems to work
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { windowHeight } = useWindowDimensions();

  let height = 'auto';
  if (textAreaRef?.current?.scrollHeight) {
    textAreaRef.current.style.height = '0'; // This is necessary to obtain the true scrollHeight (otherwise, it will never decrease)
    height = `${Math.min(Math.floor(windowHeight / 3), textAreaRef.current.scrollHeight)}px`;
    textAreaRef.current.style.height = '';
  }

  return (
    <InputBoxContainer>
      <StyledInputBox ref={textAreaRef} rows={1} textAreaHeight={height} onChange={onChange} {...props} />
    </InputBoxContainer>
  );
};
