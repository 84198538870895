import { capitalize } from 'lodash-es';
import { type FC, type PropsWithChildren } from 'react';

import { InsuranceAuthCard, StyledLabelPrimary, StyledLabelSecondary } from './styled';

export type InsuranceAuthorizationCardProps = {
  insurancePartner: string;
  codeLabel: string;
  authorizationCode: string;
  typeOfService: string;
  expiration?: string;
};

export const InsuranceAuthorizationCard: FC<PropsWithChildren<InsuranceAuthorizationCardProps>> = ({
  insurancePartner,
  codeLabel,
  authorizationCode,
  typeOfService,
  expiration,
}) => (
  <InsuranceAuthCard>
    <StyledLabelPrimary>
      {insurancePartner} • {capitalize(typeOfService)}
    </StyledLabelPrimary>
    <StyledLabelSecondary>
      {codeLabel}: {authorizationCode}
    </StyledLabelSecondary>
    {expiration && <StyledLabelSecondary>Authorization Expires: {expiration}</StyledLabelSecondary>}
  </InsuranceAuthCard>
);
