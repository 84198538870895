import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { type ProfileFamilyQuery } from '../../../../../graphql/__generated__/ProfileFamilyQuery';
import { PROFILE_FAMILY_QUERY } from '../../../../../graphql/profile';

export const useGetProfilePictures = () => {
  const { data } = useQuery<ProfileFamilyQuery>(PROFILE_FAMILY_QUERY);
  const [caregiverProfilePictures, setCaregiverProfilePictures] = useState<{ [key: string]: string }>({});
  useEffect(() => {
    if (!data?.viewer) {
      return;
    }
    const viewerProfilePicture = { [data.viewer.id]: data.viewer.profilePicture };
    const profilePictures =
      data.viewer.family?.caregivers.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr.profilePicture }),
        viewerProfilePicture
      ) ?? {};
    setCaregiverProfilePictures(profilePictures);
  }, [data]);
  return caregiverProfilePictures;
};
