import styled from 'styled-components';

import familyOtter from './assets/Family_hug.png';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: ${({ theme }) => theme.spacing['06']} ${({ theme }) => theme.spacing['06']} 0;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    flex-direction: row;
    margin: ${({ theme }) => theme.spacing['09']} auto;
  }
`;

export const StyledImageContainer = styled.div`
  text-align: center;
`;

export const StyledImage = styled.img.attrs({
  src: familyOtter,
})`
  width: 100%;
  max-width: 460px;
`;

export const StyledTextWrapper = styled.div`
  text-align: left;
  max-width: 650px;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    padding: 0 ${({ theme }) => theme.spacing['12']} 0 ${({ theme }) => theme.spacing['04']};
  }
`;

export const StyledPrimaryText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.larger};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.spacing['05']};
  line-height: 38px;
`;

export const StyledSecondaryText = styled.div`
  letter-spacing: ${({ theme }) => theme.deprecated_.letterSpacing};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  text-align: left;
`;

export const StyledActionsWrapper = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing['07']} ${({ theme }) => theme.spacing['01']}
    ${({ theme }) => theme.spacing['08']};

  a {
    display: block;
  }

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    max-width: 200px;
    position: relative;
    padding: ${({ theme }) => theme.spacing['06']} ${({ theme }) => theme.spacing['04']};
  }
`;
export const StyledSubtitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.regularPlus};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: ${({ theme }) => theme.spacing['05']} 0 ${({ theme }) => theme.spacing['03']};
`;

export const StyledList = styled.ul`
  list-style: disc;
  padding-left: ${({ theme }) => theme.spacing['06']};
  margin: ${({ theme }) => theme.spacing['04']} 0;
  b {
    color: ${({ theme }) => theme.deprecated_.colors.textBlue};
  }
  i {
    font-style: italic;
  }
`;
