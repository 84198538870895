import { math, rgba } from 'polished';
import styled from 'styled-components';

export const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Container = styled.div`
  position: relative;
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 21`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 14`)};
`;

export const ConcernZoneLayer = styled.rect`
  fill: ${({ theme }) => rgba(theme.deprecated_.colors.lightRed, 0.1)};
`;
