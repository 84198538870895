import { format } from 'date-fns';
import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Header, HTMLContainer } from '@littleotter/legacy-components';

const Content = styled.article`
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  max-height: 50vh;
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    min-width: ${({ theme }) =>
      `calc(${math(`${theme.deprecated_.breakpoints.md} - ${theme.deprecated_.sizeBasis} * 4`)} - ${
        theme.deprecated_.border.size
      } * 2)`};
    max-width: 50vw;
  }
`;

type TermsAndConditionsModalContentProps = {
  name: string;
  dateActive: string;
  info: string;
  text: string;
};

export const TermsAndConditionsModalContent: FC<PropsWithChildren<TermsAndConditionsModalContentProps>> = ({
  name,
  dateActive,
  info,
  text,
}) => (
  <Content>
    <Header as="h3">{name}</Header>
    {dateActive && <Header as="h4">Effective Date: {format(new Date(dateActive), 'MMMM d, yyyy')}</Header>}
    {info && <HTMLContainer content={info} />}
    {text && <HTMLContainer content={text} />}
  </Content>
);
