import {
  type ResourcesFragment_edges_node_tags_edges_node,
  type ResourcesFragment_edges_node_worryDomains_edges_node,
} from '../../../graphql/__generated__/ResourcesFragment';

type TagListElement =
  | ResourcesFragment_edges_node_worryDomains_edges_node
  | ResourcesFragment_edges_node_tags_edges_node;

export const getTagList = (
  worryDomainNodes: ResourcesFragment_edges_node_worryDomains_edges_node[],
  tagNodes: ResourcesFragment_edges_node_tags_edges_node[]
): TagListElement[] => [...worryDomainNodes, ...tagNodes];

export const getTagNames = (tagList: TagListElement[]): string[] => tagList.map((tag) => tag.name);

export const isTagListElementArray = (
  listElement: TagListElement | TagListElement[]
): listElement is TagListElement[] => Array.isArray(listElement);

export const getTooltipText = (list: TagListElement[]): string =>
  list.reduce((acc, value) => (acc ? `${acc},<br />${value.name}` : value.name), '');
