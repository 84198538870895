import { type FC, type PropsWithChildren } from 'react';

import { ProfilePicture, Tooltip } from '@littleotter/legacy-components';

import { ProfileGroupContainer, ProfilePicturesContainer, TooltipLabel } from './styled';

export type Profile = {
  id: string;
  profilePictureSrc?: string;
  fullName: string;
};

export type ProfileGroupProps = {
  tooltipId: string;
  label: string;
  profiles: Profile[];
};

export const ProfileGroup: FC<PropsWithChildren<ProfileGroupProps>> = ({ tooltipId, label, profiles }) => (
  <ProfileGroupContainer>
    <ProfilePicturesContainer>
      {profiles.map((profile) => (
        <ProfilePicture key={profile.id} src={profile.profilePictureSrc} size="smallest" />
      ))}
    </ProfilePicturesContainer>
    <TooltipLabel data-tip={profiles.map((profile) => profile.fullName).join('<br />')} data-for={tooltipId}>
      {label} ({profiles.length})
    </TooltipLabel>
    <Tooltip id={tooltipId} effect="solid" multiline />
  </ProfileGroupContainer>
);
