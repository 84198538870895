import { type Point } from './types';

type Interval = [Point, Point] | null;

type GetInterval = (x: number, points: readonly Point[]) => Interval;

/**
 * Given an array of `points`, returns the two points with the closest x coordinates surrounding the passed `x` value.
 * If `x` is not within the x coordinates of any two points, meaning `x` is higher than the highest x coordinate of
 * any point, or `x` is smaller than the smallest x coordinate of any point, returns null.
 */
export const getInterval: GetInterval = (x, points) =>
  [...points]
    .sort((p0, p1) => p0[0] - p1[0])
    .reduce<Interval>((interval, point, index, sortedPoints) => {
      const nextPoint = sortedPoints[index + 1];

      if (point[0] <= x && nextPoint?.[0] >= x) {
        return [point, nextPoint];
      }

      return interval;
    }, null);
