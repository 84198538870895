import { type DefaultTheme } from 'styled-components';

import { type ChartData } from '../types';

type ThemeColors = DefaultTheme['deprecated_']['colors'];

type GetBarColor = (data: ChartData, threshold: number | undefined, colors: ThemeColors) => string;

export const getBarColor: GetBarColor = ({ value, color }, threshold, colors) =>
  !threshold || (value ?? 0) <= threshold ? (color ?? colors.green) : colors.lightRed;
