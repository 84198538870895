import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing['06']};
`;

export const FormContainer = styled.div`
  padding-bottom: 1rem;
`;

export const Description = styled.p`
  color: ${({ theme }) => `${theme.deprecated_.colors.text}CC`}; /* 80% opacity */
  padding-bottom: ${({ theme }) => theme.spacing['06']};
`;
