import { math } from 'polished';
import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  background-color: ${({ theme }) => theme.deprecated_.colors.lightBackground};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
`;

type ProgressBarAmountProps = {
  percentComplete: number;
};

export const ProgressBarAmount = styled.div<ProgressBarAmountProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ percentComplete }) => `${percentComplete}%`};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  background-color: ${({ theme }) => theme.deprecated_.colors.green};
  transition: width 500ms linear;
`;

export const CountWrapper = styled.div`
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 0.875`)};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
`;

export const CurrentCount = styled.span`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;
