import { math, rgba } from 'polished';
import styled, { keyframes } from 'styled-components';

const starsSize = '39px';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Stars = styled.img`
  position: relative;
  width: ${starsSize};
  height: auto;
  left: ${starsSize};
  top: -24px;

  animation-name: ${fadeInAnimation};
  animation-duration: 0.3s;
  animation-delay: 1.7s;
  animation-fill-mode: both;
`;

export const TopHeaderContainer = styled.div`
  text-align: center;

  h3 {
    display: inline-block;
    margin-right: ${math(`${starsSize} * -1`)};
  }
`;

export const Details = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-items: start;
  align-items: baseline;
  row-gap: ${({ theme }) => theme.deprecated_.sizeBasis};
  column-gap: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  margin: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)} 0;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    row-gap: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
    column-gap: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.5`)};
    margin: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)} 0;
  }
`;

export const MoreInfo = styled.div`
  color: ${({ theme }) => rgba(theme.deprecated_.colors.text, 0.6)};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  letter-spacing: ${({ theme }) => theme.deprecated_.letterSpacing};
  text-align: center;
`;
