import { type FC, type PropsWithChildren } from 'react';
import { AiOutlineBulb, AiOutlineHeart } from 'react-icons/ai';
import { RiMessage2Line, RiSunLine } from 'react-icons/ri';
import styled, { type DefaultTheme } from 'styled-components';

type IconWithBackgroundVariant = 'toolkit' | 'onboarding';

const Container = styled.div<{
  backgroundColor: keyof DefaultTheme['deprecated_']['colors'];
  variant: IconWithBackgroundVariant;
}>`
  display: inline-block;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: ${({ theme, backgroundColor }) => theme.deprecated_.colors[backgroundColor]};
  ${({ variant, theme }) => (variant === 'onboarding' ? `color: ${theme.deprecated_.colors.white};` : '')}
`;

export type IconWithBackgroundName = 'Bulb' | 'Heart' | 'Message' | 'Sun';

const CustomIcon: FC<PropsWithChildren<{ name: IconWithBackgroundName }>> = ({ name }) => {
  const Component = {
    Bulb: AiOutlineBulb,
    Heart: AiOutlineHeart,
    Message: RiMessage2Line,
    Sun: RiSunLine,
  }[name];

  return <Component />;
};

const toolkitColors = {
  Bulb: 'green',
  Heart: 'red',
  Message: 'brandPurple',
  Sun: 'brandPurple',
} as const;

const onboardingColors = {
  Bulb: 'blueJeans',
  Heart: 'blueJeans',
  Message: 'brightUbe',
  Sun: 'brightUbe',
} as const;

const getColor = (
  name: IconWithBackgroundName,
  variant: IconWithBackgroundVariant
): keyof DefaultTheme['deprecated_']['colors'] => {
  const colors = (
    {
      toolkit: toolkitColors,
      onboarding: onboardingColors,
    } as const
  )[variant];

  return colors[name];
};

export type IconWithBackgroundProps = {
  name: IconWithBackgroundName;
  variant?: IconWithBackgroundVariant;
};

export const IconWithBackground: FC<PropsWithChildren<IconWithBackgroundProps>> = ({ name, variant = 'toolkit' }) => (
  <Container backgroundColor={getColor(name, variant)} variant={variant}>
    <CustomIcon name={name} />
  </Container>
);
