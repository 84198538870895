import { type WatchQueryFetchPolicy } from '@apollo/client';
import { subDays } from 'date-fns';
import { useMemo } from 'react';

import { type Fmhc } from '../../graphql/lo1/generated';
import { useFmhcsQuery } from './useFmhcsQuery';

const ALL_FMHC_SCENARIOS = ['latestSubmitted', 'latestFreshSubmitted', 'latestFreshUnsubmitted'] as const;
export type FmhcScenario = (typeof ALL_FMHC_SCENARIOS)[number];
const FMHC_FRESHNESS_THRESHOLD_DAYS = 7;
const FMHC_STALENESS_THRESHOLD_DAYS = 90;

/**
 * useGetFmhcs gets the latest submitted FMHC, the latest submitted FMHC within the staleness threshold, and the latest unsubmitted FMHC within the freshness threshold.
 *
 * If no varargs are present, then all three FMHCs are queried for. Otherwise, only the ones specified are returned.
 *
 * example: get all FMHCs
 * ```ts
 * { latestSubmitted, latestFreshSubmitted, latestFreshUnsubmitted } = useGetFmhcs();
 * ```
 *
 * example: get just specified FMHCs
 * ```ts
 * { latestFreshSubmitted } = useGetFmhcs("latestFreshSubmitted");
 * ```
 */
export const useGetFmhcs = ({ noCache = false, scenarios = [] }: useGetFmhcsProps = {}): {
  [S in FmhcScenario]?: Fmhc;
} & { loading: boolean; refetch: () => void } => {
  const { skiplatestSubmitted, skiplatestFreshSubmitted, skiplatestFreshUnsubmitted } = parseFmhcScenarioArgs(
    ...scenarios
  );
  const fetchPolicy: WatchQueryFetchPolicy | undefined = noCache ? 'no-cache' : undefined;
  const [latestSubmitted, loading1, refetch1] = useGetLatestSubmittedFmhc({
    skipQuery: skiplatestSubmitted,
    fetchPolicy,
  });
  const [latestFreshSubmitted, loading2, refetch2] = useGetLatestFreshSubmittedFmhc({
    skipQuery: skiplatestFreshSubmitted,
    fetchPolicy,
  });
  const [latestFreshUnsubmitted, loading3, refetch3] = useGetLatestFreshUnsubmittedFmhc({
    skipQuery: skiplatestFreshUnsubmitted,
    fetchPolicy,
  });
  const refetch = () => {
    refetch1();
    refetch2();
    refetch3();
  };
  return {
    latestSubmitted,
    latestFreshSubmitted,
    latestFreshUnsubmitted,
    loading: loading1 || loading2 || loading3,
    refetch,
  };
};

type useGetFmhcsProps = {
  noCache?: boolean;
  scenarios?: FmhcScenario[];
};

type SkippedScenarios = {
  [skipScenario in `skip${FmhcScenario}`]?: boolean;
};

/**
 * Returns an object containing `skipQuery` data for each `FmhcScenario` based on which scenarios
 * are specified in `args`. If `args` is empty, then no scenarios are skipped.
 */
export const parseFmhcScenarioArgs = (...args: FmhcScenario[]): SkippedScenarios => {
  const fmhcScenarios = args.length ? args : ALL_FMHC_SCENARIOS;
  return ALL_FMHC_SCENARIOS.reduce<SkippedScenarios>(
    (skipped, scenario) => ({
      ...skipped,
      [`skip${scenario}`]: !fmhcScenarios.includes(scenario),
    }),
    {}
  );
};

const useGetLatestSubmittedFmhc = ({
  fetchPolicy = undefined,
  skipQuery = false,
}: UseGetFmhcProps): [Fmhc | undefined, boolean, () => void] => {
  const [fmhcs, loading, refetch] = useFmhcsQuery({ isSubmitted: true, skipQuery, fetchPolicy });
  return [fmhcs?.[0], loading, refetch];
};

const useGetLatestFreshSubmittedFmhc = ({
  fetchPolicy = undefined,
  skipQuery = false,
}: UseGetFmhcProps): [Fmhc | undefined, boolean, () => void] => {
  const stalenessThreshold = useStalenessThreshold();
  const [fmhcs, loading, refetch] = useFmhcsQuery({
    isSubmitted: true,
    dateThreshold: stalenessThreshold,
    skipQuery,
    fetchPolicy,
  });
  return [fmhcs?.[0], loading, refetch];
};

const useStalenessThreshold = () => {
  const stalenessThreshold = useMemo(
    () => subDays(new Date(), FMHC_STALENESS_THRESHOLD_DAYS),
    [FMHC_STALENESS_THRESHOLD_DAYS]
  );
  return stalenessThreshold;
};

const useFreshnessThreshold = () => {
  const freshnessThreshold = useMemo(
    () => subDays(new Date(), FMHC_FRESHNESS_THRESHOLD_DAYS),
    [FMHC_FRESHNESS_THRESHOLD_DAYS]
  );
  return freshnessThreshold;
};

const useGetLatestFreshUnsubmittedFmhc = ({
  fetchPolicy = undefined,
  skipQuery = false,
}: UseGetFmhcProps): [Fmhc | undefined, boolean, () => void] => {
  const freshnessThreshold = useFreshnessThreshold();
  const [fmhcs, loading, refetch] = useFmhcsQuery({
    isSubmitted: false,
    dateThreshold: freshnessThreshold,
    skipQuery,
    fetchPolicy,
  });
  return [fmhcs?.[0], loading, refetch];
};

type UseGetFmhcProps = {
  skipQuery?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};
