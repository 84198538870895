import { math } from 'polished';
import styled from 'styled-components';

import { LogoLoading } from '@littleotter/legacy-components';

const Container = styled.div`
  margin: 0 auto;
  width: 75%;
  max-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 12`)};
  height: ${({ theme }) => `calc(100vh - ${theme.deprecated_.sizeBasis} * 10)`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageWideLoading = () => (
  <Container>
    <LogoLoading />
  </Container>
);
