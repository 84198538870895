import { endOfDay, isBefore, isValid, isWithinInterval } from 'date-fns';
import { type ConversationsInfo_conversations_participants_outOfOfficeDates as OutOfOfficeDatesType } from 'src/graphql/__generated__/ConversationsInfo';

import { logger } from '$services/logging';

/**
 * Maybe get a current out of office date interval from a list of out of office dates
 *
 * @returns the out of office date interval that overlaps with today's date, or undefined if none
 */
export const maybeGetCurrentOutOfOfficeInterval = (
  outOfOfficeDates: OutOfOfficeDatesType[]
): OutOfOfficeDatesType | undefined => {
  const currentDate = new Date();

  // We want to safely handle each iteration of the array so that if one iteration fails, it doesn't affect the rest
  const currentOutOfOfficeInterval = outOfOfficeDates.find((outOfOfficeDate) => {
    try {
      // append default timezone so that dates are timezone agnostic
      const startDate = new Date(`${outOfOfficeDate.startDate}T00:00:00.000`);
      const endDate = new Date(`${outOfOfficeDate.endDate}T00:00:00.000`);

      const oooDateInterval = getValidOutOfOfficeInterval(startDate, endDate);

      return isWithinInterval(currentDate, oooDateInterval);
    } catch (error) {
      logger.error(error);
      return false;
    }
  });

  return currentOutOfOfficeInterval;
};

/**
 * Validates and returns a date interval based on provided OOO start and end dates
 * Throws an error if dates or interval are invalid
 *
 * Intervals always span the entire day:
 * - startDate is always the start of the day
 * - endDate is always the end of the day
 */
const getValidOutOfOfficeInterval = (startDate: Date, endDate: Date): { start: Date; end: Date } => {
  if (!isValid(startDate)) {
    throw new Error(`[getValidOutOfOfficeInterval] Invalid out of office START date: ${startDate}`);
  }

  if (!isValid(endDate)) {
    throw new Error(`[getValidOutOfOfficeInterval] Invalid out of office END date: ${endDate}`);
  }

  if (isBefore(endDate, startDate)) {
    throw new Error('[getValidOutOfOfficeInterval] END date is before START date');
  }

  return {
    start: startDate,
    end: endOfDay(endDate),
  };
};
