import { type FC, type PropsWithChildren } from 'react';

import { Loading } from '@littleotter/legacy-components';

import { HappyOtterImage } from './HappyOtterImage';
import { Message, Modal } from './styled';

export type PaymentConfirmedModalProps = {
  isOpen: boolean;
};

export const PaymentConfirmedModal: FC<PropsWithChildren<PaymentConfirmedModalProps>> = ({ isOpen }) => (
  <Modal isOpen={isOpen} raised centered>
    <HappyOtterImage />
    <Message>
      Hang tight!
      <br />
      Matching you with an expert
    </Message>
    <Loading />
  </Modal>
);
