import { type FC, type PropsWithChildren } from 'react';
import ReactWordcloud from 'react-wordcloud';
import styled, { useTheme } from 'styled-components';

import { type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_wordCloud } from '../../../../../graphql/__generated__/BackToSchoolReportQuery';

const Container = styled.div<{ wordAmount: number }>`
  height: ${({ wordAmount }) => `${100 + 10 * (wordAmount - 2)}px`};
  width: 360px;
  margin: auto;
`;

export type WordCloudProps = {
  words: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_wordCloud[];
};

export const WordCloud: FC<PropsWithChildren<WordCloudProps>> = ({ words }) => {
  const {
    deprecated_: { fontFamilies, fontWeights, colors },
  } = useTheme();

  return (
    <Container wordAmount={words.length}>
      <ReactWordcloud
        words={words.map((word) => ({ text: word.text, value: word.size }))}
        minSize={[300, 100]}
        maxWords={19}
        options={{
          deterministic: true,
          fontFamily: fontFamilies.body,
          fontSizes: [12, 24],
          fontWeight: String(fontWeights.bold),
          enableTooltip: false,
          rotations: 1,
          rotationAngles: [0, 0],
          scale: 'sqrt',
          spiral: 'archimedean',
          padding: 8,
        }}
        callbacks={{
          getWordColor: (word) => {
            return [colors.lightRed, colors.backgroundBlue, colors.green][word.value - 1];
          },
        }}
      />
    </Container>
  );
};
