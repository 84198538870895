import { capitalize } from 'lodash-es';
import { type FC, type PropsWithChildren } from 'react';

import { FlexContainer, Illustration, LeftSection, RightSection, RiskText, Title } from './styled';
import { type Risk } from './types';

export type CardContentProps = {
  outerTitle?: string;
  innerTitle?: string;
  risk: Risk;
  illustrationSrc: string;
};

export const CardContent: FC<PropsWithChildren<CardContentProps>> = ({
  outerTitle,
  innerTitle,
  risk,
  illustrationSrc,
}) => (
  <>
    {outerTitle && <Title>{outerTitle}</Title>}
    <FlexContainer>
      <LeftSection>
        {innerTitle && <Title>{innerTitle}</Title>}
        <RiskText>{risk ? capitalize(risk) : 'N/A'}</RiskText>
      </LeftSection>
      <RightSection>
        <Illustration
          src={illustrationSrc}
          alt={`${[outerTitle, innerTitle].filter((t) => t !== undefined).join(' ')} Illustration`}
          isGrayscale={!risk}
        />
      </RightSection>
    </FlexContainer>
  </>
);
