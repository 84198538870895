import { math } from 'polished';
import styled from 'styled-components';

import { RoundedImage } from '../RoundedImage/RoundedImage';

export const ChildIntroImage = styled(RoundedImage)`
  width: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`${sizeBasis} * 9.68`)};
  height: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`${sizeBasis} * 9.68`)};
  display: block;
  margin: 0 auto
    ${({
      theme: {
        deprecated_: { sizeBasis },
      },
    }) => math(`${sizeBasis} * 4.31`)};
`;
