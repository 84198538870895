import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/legacy-components';

import { type HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_caregiverMentalHealth } from '../../../../graphql/__generated__/HolidayReportQuery';
import { SummaryCard } from '../components';
import { CollapseSection } from './CollapseSection';
import { Section, SummaryCardContainer } from './styled';

type CaregiverMentalHealthProps = {
  caregiverMentalHealthResult: HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_caregiverMentalHealth;
};

export const CaregiverMentalHealth: FC<PropsWithChildren<CaregiverMentalHealthProps>> = ({
  caregiverMentalHealthResult,
}) => {
  return (
    <Section>
      <Header as="h4" id="caregiver-anxiety-and-depression">
        Caregiver Mental Health
      </Header>
      <p>
        {caregiverMentalHealthResult.anxiety &&
          caregiverMentalHealthResult.anxiety.risk !== 'missing' &&
          `Your own anxiety is ${caregiverMentalHealthResult.anxiety.risk}. `}
        {caregiverMentalHealthResult.depression &&
          caregiverMentalHealthResult.depression.risk !== 'missing' &&
          `Your depression risk is ${caregiverMentalHealthResult.depression.risk}.`}
      </p>
      <SummaryCardContainer>
        <SummaryCard risk={caregiverMentalHealthResult.anxiety?.risk ?? null} category="Anxiety" />
        <SummaryCard risk={caregiverMentalHealthResult.depression?.risk ?? null} category="Depression" />
      </SummaryCardContainer>
      <CollapseSection
        meaningContent={caregiverMentalHealthResult.content.meaning}
        whatICanDoToSupportContent={caregiverMentalHealthResult.content.whatICanDoToSupport}
        activityToDoContent={caregiverMentalHealthResult.content.activityToDo}
      />
    </Section>
  );
};
