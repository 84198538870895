import { hideVisually } from 'polished';
import { type FC } from 'react';
import styled from 'styled-components';

import { StyledLabel } from '../styled';

const StyledRadio = styled.input.attrs({ type: 'radio' })`
  ${hideVisually()}
`;

type Option = {
  label: string;
  value: string | number;
};

export type SingleSelectionOptionsProps = {
  name: string;
  options: Option[];
  value: string | number | null;
  onChange: (value: string | number) => void;
  className?: string;
};

export const SingleSelectionOptions: FC<SingleSelectionOptionsProps> = ({
  name,
  options,
  value,
  className,
  onChange,
}) => (
  <div className={className}>
    {options.map(({ label, value: _value }) => (
      <StyledLabel key={_value} isSelected={_value === value}>
        <StyledRadio name={name} value={_value} onChange={() => onChange(_value)} />
        <span style={{ verticalAlign: 'top' }}>{label}</span>
      </StyledLabel>
    ))}
  </div>
);
