import { createRef, useEffect, useState, type RefObject } from 'react';

/**
 * Returns a list of refs of the specified size. Analogous to `useRef` but meant to be used with a list of elements.
 *
 * @example
 * ```tsx
 * const refs = useRefList<HTMLInputElement>(items.length);
 *
 * const onButtonClick = (index: number) => () => {
 *   refs[index].current?.focus();
 * };
 *
 * return (
 *   <>
 *     {items.map((item, index) => (
 *       <div key={item.xxx}>
 *         <input ref={refs[index]} type="text" />
 *         <button type="button" onClick={onButtonClick(index)}>
 *           Focus the input {item.xxx}
 *         </button>
 *       </div>
 *     ))}
 *   </>
 * );
 * ```
 */
export const useRefList = <Element extends HTMLElement = HTMLElement>(length: number) => {
  const [refs, setRefs] = useState<RefObject<Element>[]>([]);

  // If the list size changes, create a new list of refs with the new size
  // copying the existing refs and creating new ones if necessary.
  useEffect(() => {
    setRefs((curr) => Array.from({ length }, (_, i) => curr[i] ?? createRef<Element>()));
  }, [length]);

  return refs;
};
