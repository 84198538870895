import { useLocation } from 'react-router-dom';

import { useSingletonEffect, useUpdateEffect } from '@littleotter/kit/react';

import { useEventTracking } from '$services/event-tracking';

export const usePageViewTracking = () => {
  const { pathname } = useLocation();

  const { viewedPage } = useEventTracking();

  const { referrer } = document;

  // handles the initial page view track on mount and ensures it only runs once
  useSingletonEffect(() => {
    viewedPage();
  });

  // handles subsequent page view tracks after the initial mount (will not track first mount, that is handled above)
  useUpdateEffect(() => {
    viewedPage();
  }, [viewedPage, pathname, referrer]);
};
