/**
 * Should be thrown when GraphQL data returned from a mutation is required but
 * not defined
 */
export class MissingMutationDataError extends Error {
  constructor(mutationName: string) {
    super(`Missing data in ${mutationName} response`);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MissingMutationDataError);
    }

    this.name = 'MissingMutationDataError';
  }
}
