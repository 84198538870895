/* eslint-disable @typescript-eslint/no-explicit-any */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AssessmentDefinitionId: string;
  DateTime: string;
  FmhcId: string;
  UInt: number;
};

export type Answer = {
  __typename?: 'Answer';
  choice: Choice;
  questionVariableName: Scalars['String'];
};

export type AnswerInput = {
  choice: ChoiceInput;
  questionVariableName: Scalars['String'];
};

export type Appointment = {
  __typename?: 'Appointment';
  clientEmail: Scalars['String'];
  end: Scalars['DateTime'];
  providerEmail: Scalars['String'];
  serviceName: Scalars['String'];
  start: Scalars['DateTime'];
  status: AppointmentStatus;
};

export enum AppointmentStatus {
  Cancelled = 'Cancelled',
  Confirmed = 'Confirmed'
}

export type AssessmentCollection = {
  __typename?: 'AssessmentCollection';
  assessmentSubmissions: Array<AssessmentSubmission>;
  createdAt: Scalars['DateTime'];
  familyId: Scalars['ID'];
  id: Scalars['ID'];
  state: AssessmentCollectionStateEnum;
  type: AssessmentCollectionTypeEnum;
  updatedAt: Scalars['DateTime'];
  version: Scalars['String'];
};

export enum AssessmentCollectionStateEnum {
  Started = 'STARTED',
  Submitted = 'SUBMITTED'
}

export enum AssessmentCollectionTypeEnum {
  Fmhc = 'FMHC'
}

export type AssessmentCollectionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type AssessmentDefinition = {
  __typename?: 'AssessmentDefinition';
  assessmentDefinitionId: Scalars['AssessmentDefinitionId'];
  questions: Array<AssessmentQuestion>;
  version: Scalars['String'];
};

export type AssessmentQuestion = {
  __typename?: 'AssessmentQuestion';
  question: Question;
  questionOptions?: Maybe<QuestionOptions>;
};

export type AssessmentSubmission = {
  __typename?: 'AssessmentSubmission';
  answers: Array<Answer>;
  assessmentDefinitionId: Scalars['AssessmentDefinitionId'];
  domainQuestionSets: Array<DomainQuestionSet>;
  subject: Subject;
  submissionTime: Scalars['DateTime'];
  submitterId: Scalars['String'];
  submitterName: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type ChildSubject = {
  __typename?: 'ChildSubject';
  ageInMonths: Scalars['Int'];
  childId: Scalars['String'];
  sex: Sex;
};

export type ChildSubjectInput = {
  ageInMonths: Scalars['Int'];
  childId: Scalars['String'];
  sex: Sex;
};

export type Choice = {
  __typename?: 'Choice';
  choice: Scalars['String'];
  id: Scalars['String'];
  value: Scalars['Int'];
};

export type ChoiceInput = {
  choice: Scalars['String'];
  id: Scalars['String'];
  value: Scalars['Int'];
};

export type Client = {
  __typename?: 'Client';
  email: Scalars['String'];
  name: Scalars['String'];
  totalCancelled: Scalars['UInt'];
  totalCancelledInLast30Days: Scalars['UInt'];
  totalConfirmed: Scalars['UInt'];
};

export type ClosedConversation = {
  __typename?: 'ClosedConversation';
  lastActiveAt: Scalars['DateTime'];
  sid: Scalars['String'];
};

/** See: https://stripe.com/docs/api/coupons/object */
export type Coupon = {
  __typename?: 'Coupon';
  amountOff: Scalars['Int'];
  duration: CouponDuration;
  durationInMonths: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  percentOff: Scalars['Int'];
  valid: Scalars['Boolean'];
};

/** See: https://stripe.com/docs/api/coupons/object#coupon_object-duration */
export enum CouponDuration {
  Forever = 'FOREVER',
  Once = 'ONCE',
  Repeating = 'REPEATING'
}

export type CreateSubscriptionResponse = {
  __typename?: 'CreateSubscriptionResponse';
  /**
   * The client secret used to confirm payment.
   *
   * See: https://stripe.com/docs/api/payment_intents/object#payment_intent_object-client_secret
   */
  clientSecret: Scalars['String'];
  /** The subscription that was created (or retrieved; this method is idempotent) */
  subscription: Subscription_;
};

export type DomainQuestionSet = {
  __typename?: 'DomainQuestionSet';
  domainName: Scalars['String'];
  questionAnswers: Array<QuestionAnswer>;
};

export type DomainReport = {
  __typename?: 'DomainReport';
  context: Array<DomainReportContext>;
  domainName: Scalars['String'];
  domainScore?: Maybe<DomainScore>;
  orderIndex: Scalars['Int'];
  subDomainScores: Array<DomainScore>;
};

export type DomainReportContext = {
  __typename?: 'DomainReportContext';
  content: Scalars['String'];
  contextType: DomainReportContextType;
};

export enum DomainReportContextType {
  CallToAction = 'CallToAction',
  Explanation = 'Explanation'
}

export type DomainScore = {
  __typename?: 'DomainScore';
  label: Scalars['String'];
  maxScore: Scalars['Int'];
  rawScore?: Maybe<Scalars['Int']>;
  rawScoresOverTime: Array<IntegerTimePoint>;
  scoreName: Scalars['String'];
  shownInReport: Scalars['Boolean'];
  threshold: Scalars['Int'];
};

export type Fmhc = {
  __typename?: 'Fmhc';
  familyId: Scalars['String'];
  id: Scalars['FmhcId'];
  isSubmitted: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
  submissionTime: Scalars['DateTime'];
  submitterId: Scalars['String'];
  submitterName: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type FmhcOverTimeResponse = {
  __typename?: 'FmhcOverTimeResponse';
  reports: Array<FmhcReport>;
  submissionTime: Scalars['DateTime'];
  submitterName: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type FmhcReport = {
  __typename?: 'FmhcReport';
  domainReports: Array<DomainReport>;
  numQuestionsAnswered: Scalars['Int'];
  subject: Subject;
};

export type FmhcsFilter = {
  isSubmitted?: InputMaybe<Scalars['Boolean']>;
  startTimeIsAfter?: InputMaybe<Scalars['DateTime']>;
  submissionTimeIsAfter?: InputMaybe<Scalars['DateTime']>;
};

export type FmhcsInput = {
  filter?: InputMaybe<FmhcsFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<FmhcsSortBy>;
};

export enum FmhcsSortBy {
  StartTime = 'startTime',
  SubmissionTime = 'submissionTime'
}

export type Foo = {
  __typename?: 'Foo';
  value: Scalars['Int'];
};

export type GetAssessmentDefinitionRequest = {
  assessmentDefinitionId: Scalars['String'];
};

export type GetAssessmentDefinitionResponse = {
  __typename?: 'GetAssessmentDefinitionResponse';
  assessment: AssessmentDefinition;
};

export type GetAssessmentSubmissionsRequest = {
  fmhcId: Scalars['String'];
};

export type GetAssessmentSubmissionsResponse = {
  __typename?: 'GetAssessmentSubmissionsResponse';
  submissions: Array<AssessmentSubmission>;
};

export type GetFirstFmhcPaymentUrlInput = {
  couponId?: InputMaybe<Scalars['String']>;
  promoCodeId?: InputMaybe<Scalars['String']>;
};

export type GetFirstFmhcPaymentUrlResponse = {
  __typename?: 'GetFirstFmhcPaymentUrlResponse';
  paymentUrl: Scalars['String'];
};

export type GetFmhcReportRequest = {
  fmhcId: Scalars['FmhcId'];
};

export type GetFmhcReportResponse = {
  __typename?: 'GetFmhcReportResponse';
  reports: Array<FmhcReport>;
  submissionTime: Scalars['DateTime'];
  submitterName: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type GetFmhcRequest = {
  fmhcId: Scalars['String'];
};

export type GetFmhcResponse = {
  __typename?: 'GetFmhcResponse';
  fmhc: Fmhc;
};

export type GetLatestSubmittedFmhcRequest = {
  familyId: Scalars['String'];
};

export type GetLatestSubmittedFmhcResponse = {
  __typename?: 'GetLatestSubmittedFmhcResponse';
  fmhc?: Maybe<Fmhc>;
};

export type HasPaidFirstFmhcResponse = {
  __typename?: 'HasPaidFirstFmhcResponse';
  firstFmhcId?: Maybe<Scalars['String']>;
  hasPaid: Scalars['Boolean'];
};

export type IntegerTimePoint = {
  __typename?: 'IntegerTimePoint';
  datetime: Scalars['DateTime'];
  value: Scalars['Int'];
};

export type IsAssessmentCompleteRequest = {
  assessmentDefinitionId: Scalars['AssessmentDefinitionId'];
  fmhcId: Scalars['FmhcId'];
  subject: SubjectInput;
};

export type IsAssessmentCompleteResponse = {
  __typename?: 'IsAssessmentCompleteResponse';
  isComplete: Scalars['Boolean'];
};

export type JoinSubscriptionWaitlistResponse = {
  __typename?: 'JoinSubscriptionWaitlistResponse';
  success: Scalars['Boolean'];
};

export type License = {
  __typename?: 'License';
  practice: Practice;
  state?: Maybe<UsState>;
  type?: Maybe<Scalars['String']>;
};

export type MarkFmhcAsSubmittedRequest = {
  fmhcId: Scalars['String'];
  submitterId: Scalars['String'];
  submitterName: Scalars['String'];
};

export type MarkFmhcAsSubmittedResponse = {
  __typename?: 'MarkFmhcAsSubmittedResponse';
  success: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  MarkFmhcAsSubmitted: MarkFmhcAsSubmittedResponse;
  MutationNoop?: Maybe<Scalars['String']>;
  StartFmhc: StartFmhcResponse;
  /**
   * Starts a new Assessment Collection if it has been long enough since the last one for the
   * authenticated user. Otherwise, return the active Assessment Collection.
   */
  StartOrContinueAssessmentCollection: StartOrContinueAssessmentCollectionResponse;
  SubmitAssessment: SubmitAssessmentResponse;
  /** Create a subscription for the given user's family. */
  createSubscription: CreateSubscriptionResponse;
  /** Add the given user's family to the subscription waitlist. */
  joinSubscriptionWaitlist: JoinSubscriptionWaitlistResponse;
};


export type MutationMarkFmhcAsSubmittedArgs = {
  request: MarkFmhcAsSubmittedRequest;
};


export type MutationStartFmhcArgs = {
  request: StartFmhcRequest;
};


export type MutationStartOrContinueAssessmentCollectionArgs = {
  input: StartOrContinueAssessmentCollectionInput;
};


export type MutationSubmitAssessmentArgs = {
  request: SubmitAssessmentRequest;
};


export type MutationCreateSubscriptionArgs = {
  couponId?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
};


export type MutationJoinSubscriptionWaitlistArgs = {
  email: Scalars['String'];
};

export enum Practice {
  ParentingSpecialist = 'ParentingSpecialist',
  Psychiatrist = 'Psychiatrist',
  Therapist = 'Therapist'
}

export type PromptOptions = {
  __typename?: 'PromptOptions';
  approximateTime?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  prompt: Scalars['String'];
  subPrompt: Scalars['String'];
};

export type Provider = {
  __typename?: 'Provider';
  ages: Array<Scalars['UInt']>;
  email: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  licenses: Array<License>;
  name: Scalars['String'];
  profilePictureUrl: Scalars['String'];
  specialConditions: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  therapySpecialties: Array<TherapySpecialty>;
  timezone: Scalars['String'];
  trainings: Array<Training>;
  weeklySessionAvailability: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * Assessment Collections, sorted by latest submission time.
   * This query supports pagination.
   */
  AssessmentCollections: Array<AssessmentCollection>;
  FmhcOverTime?: Maybe<FmhcOverTimeResponse>;
  Fmhcs: Array<Fmhc>;
  Foo: Foo;
  GetAssessmentDefinition: GetAssessmentDefinitionResponse;
  GetAssessmentSubmissions: GetAssessmentSubmissionsResponse;
  GetFirstFmhcPaymentUrl: GetFirstFmhcPaymentUrlResponse;
  GetFmhc: GetFmhcResponse;
  GetFmhcReport: GetFmhcReportResponse;
  GetLatestSubmittedFmhc: GetLatestSubmittedFmhcResponse;
  HasPaidFirstFmhc: HasPaidFirstFmhcResponse;
  IsAssessmentComplete: IsAssessmentCompleteResponse;
  QueryNoop?: Maybe<Scalars['String']>;
  StaffFmhcs: Array<Fmhc>;
  clients: Array<Client>;
  /** Return closed (archived) Twilio conversations for the authenticated user. */
  closedConversations: Array<ClosedConversation>;
  /** Query Stripe for the coupon with the given promo code */
  coupon: Coupon;
  futureAppointments: Array<Appointment>;
  providers: Array<Provider>;
};


export type QueryAssessmentCollectionsArgs = {
  input: AssessmentCollectionsInput;
};


export type QueryFmhcsArgs = {
  input?: InputMaybe<FmhcsInput>;
};


export type QueryGetAssessmentDefinitionArgs = {
  request: GetAssessmentDefinitionRequest;
};


export type QueryGetAssessmentSubmissionsArgs = {
  request: GetAssessmentSubmissionsRequest;
};


export type QueryGetFirstFmhcPaymentUrlArgs = {
  input?: InputMaybe<GetFirstFmhcPaymentUrlInput>;
};


export type QueryGetFmhcArgs = {
  request: GetFmhcRequest;
};


export type QueryGetFmhcReportArgs = {
  request: GetFmhcReportRequest;
};


export type QueryGetLatestSubmittedFmhcArgs = {
  request: GetLatestSubmittedFmhcRequest;
};


export type QueryIsAssessmentCompleteArgs = {
  request: IsAssessmentCompleteRequest;
};


export type QueryStaffFmhcsArgs = {
  request?: InputMaybe<StaffFmhcsInput>;
};


export type QueryCouponArgs = {
  promotionCode: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  questionConfig: QuestionConfig;
  variableName: Scalars['String'];
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  answer?: Maybe<Answer>;
  question: Question;
};

export type QuestionConfig = {
  __typename?: 'QuestionConfig';
  promptOptions?: Maybe<PromptOptions>;
  questionType: QuestionType;
  singleSelectOptions?: Maybe<SingleSelectOptions>;
};

export type QuestionOptions = {
  __typename?: 'QuestionOptions';
  required?: Maybe<Scalars['Boolean']>;
};

export enum QuestionType {
  Prompt = 'Prompt',
  SingleSelect = 'SingleSelect'
}

export enum Sex {
  Female = 'Female',
  Male = 'Male',
  Unknown = 'Unknown'
}

export type SingleSelectOptions = {
  __typename?: 'SingleSelectOptions';
  choices: Array<Maybe<Choice>>;
  prompt: Scalars['String'];
  questionText: Scalars['String'];
};

export type StaffFmhcsInput = {
  familyId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<FmhcsInput>;
  submitterId?: InputMaybe<Scalars['String']>;
};

export type StartFmhcRequest = {
  familyId: Scalars['String'];
  submitterId: Scalars['String'];
  submitterName: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};

export type StartFmhcResponse = {
  __typename?: 'StartFmhcResponse';
  fmhcId: Scalars['FmhcId'];
};

export type StartOrContinueAssessmentCollectionInput = {
  type: AssessmentCollectionTypeEnum;
};

export type StartOrContinueAssessmentCollectionResponse = {
  __typename?: 'StartOrContinueAssessmentCollectionResponse';
  assessmentCollection: AssessmentCollection;
};

export type Subject = {
  __typename?: 'Subject';
  childSubject?: Maybe<ChildSubject>;
  subjectType: SubjectType;
};

export type SubjectInput = {
  childSubject?: InputMaybe<ChildSubjectInput>;
  subjectType: SubjectType;
};

export enum SubjectType {
  Caregiver = 'Caregiver',
  Child = 'Child',
  Family = 'Family'
}

export type SubmitAssessmentRequest = {
  answers: Array<AnswerInput>;
  assessmentCollectionId?: InputMaybe<Scalars['ID']>;
  assessmentDefinitionId: Scalars['AssessmentDefinitionId'];
  /** @deprecated fmhc is deprecated. Use assessmentCollectionId instead. */
  fmhcId?: InputMaybe<Scalars['FmhcId']>;
  subject: SubjectInput;
  submitterId: Scalars['String'];
  submitterName: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};

export type SubmitAssessmentResponse = {
  __typename?: 'SubmitAssessmentResponse';
  success: Scalars['Boolean'];
};

/** See: https://stripe.com/docs/api/subscriptions/object#subscription_object-status */
export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}

/**
 * See: https://stripe.com/docs/api/subscriptions/object
 *
 * Note: The type name ends with an underscore because Subscription is a reserved name in GraphQL (see: https://dgraph.io/docs/graphql/schema/reserved/)
 */
export type Subscription_ = {
  __typename?: 'Subscription_';
  billingCycleAnchor: Scalars['DateTime'];
  cancelAtPeriodEnd: Scalars['Boolean'];
  canceledAt: Scalars['DateTime'];
  coupon?: Maybe<Coupon>;
  currentPeriodEnd: Scalars['DateTime'];
  currentPeriodStart: Scalars['DateTime'];
  id: Scalars['String'];
  status: SubscriptionStatus;
};

export enum TherapySpecialty {
  Adult = 'Adult',
  Couples = 'Couples',
  Family = 'Family',
  Trauma = 'Trauma'
}

export enum Training {
  Safety = 'Safety'
}

export enum UsState {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Cz = 'CZ',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export type Waitlist = {
  __typename?: 'Waitlist';
  /** size tells us how many families are currently on the waitlist */
  size: Scalars['Int'];
  /** status tells us if the waitlist is active yet */
  status: WaitlistStatus;
};

export enum WaitlistStatus {
  NoWaitRequired = 'NO_WAIT_REQUIRED',
  WaitRequired = 'WAIT_REQUIRED'
}

export type MarkFmhcAsSubmittedMutationVariables = Exact<{
  request: MarkFmhcAsSubmittedRequest;
}>;


export type MarkFmhcAsSubmittedMutation = { __typename?: 'Mutation', MarkFmhcAsSubmitted: { __typename?: 'MarkFmhcAsSubmittedResponse', success: boolean } };

export type MutationNoopMutationVariables = Exact<{ [key: string]: never; }>;


export type MutationNoopMutation = { __typename?: 'Mutation', MutationNoop?: string | null };

export type StartFmhcMutationVariables = Exact<{
  request: StartFmhcRequest;
}>;


export type StartFmhcMutation = { __typename?: 'Mutation', StartFmhc: { __typename?: 'StartFmhcResponse', fmhcId: string } };

export type StartOrContinueAssessmentCollectionMutationVariables = Exact<{
  input: StartOrContinueAssessmentCollectionInput;
}>;


export type StartOrContinueAssessmentCollectionMutation = { __typename?: 'Mutation', StartOrContinueAssessmentCollection: { __typename?: 'StartOrContinueAssessmentCollectionResponse', assessmentCollection: { __typename?: 'AssessmentCollection', createdAt: string, familyId: string, id: string, state: AssessmentCollectionStateEnum, type: AssessmentCollectionTypeEnum, updatedAt: string, version: string, assessmentSubmissions: Array<{ __typename?: 'AssessmentSubmission', assessmentDefinitionId: string, submissionTime: string, submitterId: string, submitterName: string, version?: string | null, answers: Array<{ __typename?: 'Answer', questionVariableName: string, choice: { __typename?: 'Choice', choice: string, id: string, value: number } }>, domainQuestionSets: Array<{ __typename?: 'DomainQuestionSet', domainName: string, questionAnswers: Array<{ __typename?: 'QuestionAnswer', answer?: { __typename?: 'Answer', questionVariableName: string, choice: { __typename?: 'Choice', choice: string, id: string, value: number } } | null, question: { __typename?: 'Question', variableName: string, questionConfig: { __typename?: 'QuestionConfig', questionType: QuestionType, promptOptions?: { __typename?: 'PromptOptions', approximateTime?: string | null, imageUrl?: string | null, prompt: string, subPrompt: string } | null, singleSelectOptions?: { __typename?: 'SingleSelectOptions', prompt: string, questionText: string, choices: Array<{ __typename?: 'Choice', choice: string, id: string, value: number } | null> } | null } } }> }>, subject: { __typename?: 'Subject', subjectType: SubjectType, childSubject?: { __typename?: 'ChildSubject', ageInMonths: number, childId: string, sex: Sex } | null } }> } } };

export type SubmitAssessmentMutationVariables = Exact<{
  request: SubmitAssessmentRequest;
}>;


export type SubmitAssessmentMutation = { __typename?: 'Mutation', SubmitAssessment: { __typename?: 'SubmitAssessmentResponse', success: boolean } };

export type CreateSubscriptionMutationVariables = Exact<{
  couponId?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'CreateSubscriptionResponse', clientSecret: string, subscription: { __typename?: 'Subscription_', billingCycleAnchor: string, cancelAtPeriodEnd: boolean, canceledAt: string, currentPeriodEnd: string, currentPeriodStart: string, id: string, status: SubscriptionStatus, coupon?: { __typename?: 'Coupon', amountOff: number, duration: CouponDuration, durationInMonths: number, id: string, name: string, percentOff: number, valid: boolean } | null } } };

export type JoinSubscriptionWaitlistMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type JoinSubscriptionWaitlistMutation = { __typename?: 'Mutation', joinSubscriptionWaitlist: { __typename?: 'JoinSubscriptionWaitlistResponse', success: boolean } };

export type AssessmentCollectionsQueryVariables = Exact<{
  input: AssessmentCollectionsInput;
}>;


export type AssessmentCollectionsQuery = { __typename?: 'Query', AssessmentCollections: Array<{ __typename?: 'AssessmentCollection', createdAt: string, familyId: string, id: string, state: AssessmentCollectionStateEnum, type: AssessmentCollectionTypeEnum, updatedAt: string, version: string, assessmentSubmissions: Array<{ __typename?: 'AssessmentSubmission', assessmentDefinitionId: string, submissionTime: string, submitterId: string, submitterName: string, version?: string | null, answers: Array<{ __typename?: 'Answer', questionVariableName: string, choice: { __typename?: 'Choice', choice: string, id: string, value: number } }>, domainQuestionSets: Array<{ __typename?: 'DomainQuestionSet', domainName: string, questionAnswers: Array<{ __typename?: 'QuestionAnswer', answer?: { __typename?: 'Answer', questionVariableName: string, choice: { __typename?: 'Choice', choice: string, id: string, value: number } } | null, question: { __typename?: 'Question', variableName: string, questionConfig: { __typename?: 'QuestionConfig', questionType: QuestionType, promptOptions?: { __typename?: 'PromptOptions', approximateTime?: string | null, imageUrl?: string | null, prompt: string, subPrompt: string } | null, singleSelectOptions?: { __typename?: 'SingleSelectOptions', prompt: string, questionText: string, choices: Array<{ __typename?: 'Choice', choice: string, id: string, value: number } | null> } | null } } }> }>, subject: { __typename?: 'Subject', subjectType: SubjectType, childSubject?: { __typename?: 'ChildSubject', ageInMonths: number, childId: string, sex: Sex } | null } }> }> };

export type FmhcOverTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type FmhcOverTimeQuery = { __typename?: 'Query', FmhcOverTime?: { __typename?: 'FmhcOverTimeResponse', submissionTime: string, submitterName: string, version?: string | null, reports: Array<{ __typename?: 'FmhcReport', numQuestionsAnswered: number, domainReports: Array<{ __typename?: 'DomainReport', domainName: string, orderIndex: number, context: Array<{ __typename?: 'DomainReportContext', content: string, contextType: DomainReportContextType }>, domainScore?: { __typename?: 'DomainScore', label: string, maxScore: number, rawScore?: number | null, scoreName: string, shownInReport: boolean, threshold: number, rawScoresOverTime: Array<{ __typename?: 'IntegerTimePoint', datetime: string, value: number }> } | null, subDomainScores: Array<{ __typename?: 'DomainScore', label: string, maxScore: number, rawScore?: number | null, scoreName: string, shownInReport: boolean, threshold: number, rawScoresOverTime: Array<{ __typename?: 'IntegerTimePoint', datetime: string, value: number }> }> }>, subject: { __typename?: 'Subject', subjectType: SubjectType, childSubject?: { __typename?: 'ChildSubject', ageInMonths: number, childId: string, sex: Sex } | null } }> } | null };

export type FmhcsQueryVariables = Exact<{
  input?: InputMaybe<FmhcsInput>;
}>;


export type FmhcsQuery = { __typename?: 'Query', Fmhcs: Array<{ __typename?: 'Fmhc', familyId: string, id: string, isSubmitted: boolean, startTime: string, submissionTime: string, submitterId: string, submitterName: string, version?: string | null }> };

export type FooQueryVariables = Exact<{ [key: string]: never; }>;


export type FooQuery = { __typename?: 'Query', Foo: { __typename?: 'Foo', value: number } };

export type GetAssessmentDefinitionQueryVariables = Exact<{
  request: GetAssessmentDefinitionRequest;
}>;


export type GetAssessmentDefinitionQuery = { __typename?: 'Query', GetAssessmentDefinition: { __typename?: 'GetAssessmentDefinitionResponse', assessment: { __typename?: 'AssessmentDefinition', assessmentDefinitionId: string, version: string, questions: Array<{ __typename?: 'AssessmentQuestion', question: { __typename?: 'Question', variableName: string, questionConfig: { __typename?: 'QuestionConfig', questionType: QuestionType, promptOptions?: { __typename?: 'PromptOptions', approximateTime?: string | null, imageUrl?: string | null, prompt: string, subPrompt: string } | null, singleSelectOptions?: { __typename?: 'SingleSelectOptions', prompt: string, questionText: string, choices: Array<{ __typename?: 'Choice', choice: string, id: string, value: number } | null> } | null } }, questionOptions?: { __typename?: 'QuestionOptions', required?: boolean | null } | null }> } } };

export type GetAssessmentSubmissionsQueryVariables = Exact<{
  request: GetAssessmentSubmissionsRequest;
}>;


export type GetAssessmentSubmissionsQuery = { __typename?: 'Query', GetAssessmentSubmissions: { __typename?: 'GetAssessmentSubmissionsResponse', submissions: Array<{ __typename?: 'AssessmentSubmission', assessmentDefinitionId: string, submissionTime: string, submitterId: string, submitterName: string, version?: string | null, answers: Array<{ __typename?: 'Answer', questionVariableName: string, choice: { __typename?: 'Choice', choice: string, id: string, value: number } }>, domainQuestionSets: Array<{ __typename?: 'DomainQuestionSet', domainName: string, questionAnswers: Array<{ __typename?: 'QuestionAnswer', answer?: { __typename?: 'Answer', questionVariableName: string, choice: { __typename?: 'Choice', choice: string, id: string, value: number } } | null, question: { __typename?: 'Question', variableName: string, questionConfig: { __typename?: 'QuestionConfig', questionType: QuestionType, promptOptions?: { __typename?: 'PromptOptions', approximateTime?: string | null, imageUrl?: string | null, prompt: string, subPrompt: string } | null, singleSelectOptions?: { __typename?: 'SingleSelectOptions', prompt: string, questionText: string, choices: Array<{ __typename?: 'Choice', choice: string, id: string, value: number } | null> } | null } } }> }>, subject: { __typename?: 'Subject', subjectType: SubjectType, childSubject?: { __typename?: 'ChildSubject', ageInMonths: number, childId: string, sex: Sex } | null } }> } };

export type GetFirstFmhcPaymentUrlQueryVariables = Exact<{
  input?: InputMaybe<GetFirstFmhcPaymentUrlInput>;
}>;


export type GetFirstFmhcPaymentUrlQuery = { __typename?: 'Query', GetFirstFmhcPaymentUrl: { __typename?: 'GetFirstFmhcPaymentUrlResponse', paymentUrl: string } };

export type GetFmhcQueryVariables = Exact<{
  request: GetFmhcRequest;
}>;


export type GetFmhcQuery = { __typename?: 'Query', GetFmhc: { __typename?: 'GetFmhcResponse', fmhc: { __typename?: 'Fmhc', familyId: string, id: string, isSubmitted: boolean, startTime: string, submissionTime: string, submitterId: string, submitterName: string, version?: string | null } } };

export type GetFmhcReportQueryVariables = Exact<{
  request: GetFmhcReportRequest;
}>;


export type GetFmhcReportQuery = { __typename?: 'Query', GetFmhcReport: { __typename?: 'GetFmhcReportResponse', submissionTime: string, submitterName: string, version?: string | null, reports: Array<{ __typename?: 'FmhcReport', numQuestionsAnswered: number, domainReports: Array<{ __typename?: 'DomainReport', domainName: string, orderIndex: number, context: Array<{ __typename?: 'DomainReportContext', content: string, contextType: DomainReportContextType }>, domainScore?: { __typename?: 'DomainScore', label: string, maxScore: number, rawScore?: number | null, scoreName: string, shownInReport: boolean, threshold: number, rawScoresOverTime: Array<{ __typename?: 'IntegerTimePoint', datetime: string, value: number }> } | null, subDomainScores: Array<{ __typename?: 'DomainScore', label: string, maxScore: number, rawScore?: number | null, scoreName: string, shownInReport: boolean, threshold: number, rawScoresOverTime: Array<{ __typename?: 'IntegerTimePoint', datetime: string, value: number }> }> }>, subject: { __typename?: 'Subject', subjectType: SubjectType, childSubject?: { __typename?: 'ChildSubject', ageInMonths: number, childId: string, sex: Sex } | null } }> } };

export type GetLatestSubmittedFmhcQueryVariables = Exact<{
  request: GetLatestSubmittedFmhcRequest;
}>;


export type GetLatestSubmittedFmhcQuery = { __typename?: 'Query', GetLatestSubmittedFmhc: { __typename?: 'GetLatestSubmittedFmhcResponse', fmhc?: { __typename?: 'Fmhc', familyId: string, id: string, isSubmitted: boolean, startTime: string, submissionTime: string, submitterId: string, submitterName: string, version?: string | null } | null } };

export type HasPaidFirstFmhcQueryVariables = Exact<{ [key: string]: never; }>;


export type HasPaidFirstFmhcQuery = { __typename?: 'Query', HasPaidFirstFmhc: { __typename?: 'HasPaidFirstFmhcResponse', firstFmhcId?: string | null, hasPaid: boolean } };

export type IsAssessmentCompleteQueryVariables = Exact<{
  request: IsAssessmentCompleteRequest;
}>;


export type IsAssessmentCompleteQuery = { __typename?: 'Query', IsAssessmentComplete: { __typename?: 'IsAssessmentCompleteResponse', isComplete: boolean } };

export type QueryNoopQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryNoopQuery = { __typename?: 'Query', QueryNoop?: string | null };

export type StaffFmhcsQueryVariables = Exact<{
  request?: InputMaybe<StaffFmhcsInput>;
}>;


export type StaffFmhcsQuery = { __typename?: 'Query', StaffFmhcs: Array<{ __typename?: 'Fmhc', familyId: string, id: string, isSubmitted: boolean, startTime: string, submissionTime: string, submitterId: string, submitterName: string, version?: string | null }> };

export type ClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientsQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'Client', email: string, name: string, totalCancelled: number, totalCancelledInLast30Days: number, totalConfirmed: number }> };

export type ClosedConversationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClosedConversationsQuery = { __typename?: 'Query', closedConversations: Array<{ __typename?: 'ClosedConversation', lastActiveAt: string, sid: string }> };

export type CouponQueryVariables = Exact<{
  promotionCode: Scalars['String'];
}>;


export type CouponQuery = { __typename?: 'Query', coupon: { __typename?: 'Coupon', amountOff: number, duration: CouponDuration, durationInMonths: number, id: string, name: string, percentOff: number, valid: boolean } };

export type FutureAppointmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type FutureAppointmentsQuery = { __typename?: 'Query', futureAppointments: Array<{ __typename?: 'Appointment', clientEmail: string, end: string, providerEmail: string, serviceName: string, start: string, status: AppointmentStatus }> };

export type ProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type ProvidersQuery = { __typename?: 'Query', providers: Array<{ __typename?: 'Provider', ages: Array<number>, email: string, endDate?: string | null, name: string, profilePictureUrl: string, specialConditions: string, startDate?: string | null, therapySpecialties: Array<TherapySpecialty>, timezone: string, trainings: Array<Training>, weeklySessionAvailability: number, licenses: Array<{ __typename?: 'License', practice: Practice, state?: UsState | null, type?: string | null }> }> };


export const MarkFmhcAsSubmittedDocument = gql`
    mutation MarkFmhcAsSubmitted($request: MarkFmhcAsSubmittedRequest!) {
  MarkFmhcAsSubmitted(request: $request) {
    success
  }
}
    `;
export type MarkFmhcAsSubmittedMutationFn = Apollo.MutationFunction<MarkFmhcAsSubmittedMutation, MarkFmhcAsSubmittedMutationVariables>;

/**
 * __useMarkFmhcAsSubmittedMutation__
 *
 * To run a mutation, you first call `useMarkFmhcAsSubmittedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFmhcAsSubmittedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFmhcAsSubmittedMutation, { data, loading, error }] = useMarkFmhcAsSubmittedMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useMarkFmhcAsSubmittedMutation(baseOptions?: Apollo.MutationHookOptions<MarkFmhcAsSubmittedMutation, MarkFmhcAsSubmittedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkFmhcAsSubmittedMutation, MarkFmhcAsSubmittedMutationVariables>(MarkFmhcAsSubmittedDocument, options);
      }
export type MarkFmhcAsSubmittedMutationHookResult = ReturnType<typeof useMarkFmhcAsSubmittedMutation>;
export type MarkFmhcAsSubmittedMutationResult = Apollo.MutationResult<MarkFmhcAsSubmittedMutation>;
export type MarkFmhcAsSubmittedMutationOptions = Apollo.BaseMutationOptions<MarkFmhcAsSubmittedMutation, MarkFmhcAsSubmittedMutationVariables>;
export const MutationNoopDocument = gql`
    mutation MutationNoop {
  MutationNoop
}
    `;
export type MutationNoopMutationFn = Apollo.MutationFunction<MutationNoopMutation, MutationNoopMutationVariables>;

/**
 * __useMutationNoopMutation__
 *
 * To run a mutation, you first call `useMutationNoopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationNoopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationNoopMutation, { data, loading, error }] = useMutationNoopMutation({
 *   variables: {
 *   },
 * });
 */
export function useMutationNoopMutation(baseOptions?: Apollo.MutationHookOptions<MutationNoopMutation, MutationNoopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutationNoopMutation, MutationNoopMutationVariables>(MutationNoopDocument, options);
      }
export type MutationNoopMutationHookResult = ReturnType<typeof useMutationNoopMutation>;
export type MutationNoopMutationResult = Apollo.MutationResult<MutationNoopMutation>;
export type MutationNoopMutationOptions = Apollo.BaseMutationOptions<MutationNoopMutation, MutationNoopMutationVariables>;
export const StartFmhcDocument = gql`
    mutation StartFmhc($request: StartFmhcRequest!) {
  StartFmhc(request: $request) {
    fmhcId
  }
}
    `;
export type StartFmhcMutationFn = Apollo.MutationFunction<StartFmhcMutation, StartFmhcMutationVariables>;

/**
 * __useStartFmhcMutation__
 *
 * To run a mutation, you first call `useStartFmhcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartFmhcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startFmhcMutation, { data, loading, error }] = useStartFmhcMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useStartFmhcMutation(baseOptions?: Apollo.MutationHookOptions<StartFmhcMutation, StartFmhcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartFmhcMutation, StartFmhcMutationVariables>(StartFmhcDocument, options);
      }
export type StartFmhcMutationHookResult = ReturnType<typeof useStartFmhcMutation>;
export type StartFmhcMutationResult = Apollo.MutationResult<StartFmhcMutation>;
export type StartFmhcMutationOptions = Apollo.BaseMutationOptions<StartFmhcMutation, StartFmhcMutationVariables>;
export const StartOrContinueAssessmentCollectionDocument = gql`
    mutation StartOrContinueAssessmentCollection($input: StartOrContinueAssessmentCollectionInput!) {
  StartOrContinueAssessmentCollection(input: $input) {
    assessmentCollection {
      assessmentSubmissions {
        answers {
          choice {
            choice
            id
            value
          }
          questionVariableName
        }
        assessmentDefinitionId
        domainQuestionSets {
          domainName
          questionAnswers {
            answer {
              choice {
                choice
                id
                value
              }
              questionVariableName
            }
            question {
              questionConfig {
                promptOptions {
                  approximateTime
                  imageUrl
                  prompt
                  subPrompt
                }
                questionType
                singleSelectOptions {
                  choices {
                    choice
                    id
                    value
                  }
                  prompt
                  questionText
                }
              }
              variableName
            }
          }
        }
        subject {
          childSubject {
            ageInMonths
            childId
            sex
          }
          subjectType
        }
        submissionTime
        submitterId
        submitterName
        version
      }
      createdAt
      familyId
      id
      state
      type
      updatedAt
      version
    }
  }
}
    `;
export type StartOrContinueAssessmentCollectionMutationFn = Apollo.MutationFunction<StartOrContinueAssessmentCollectionMutation, StartOrContinueAssessmentCollectionMutationVariables>;

/**
 * __useStartOrContinueAssessmentCollectionMutation__
 *
 * To run a mutation, you first call `useStartOrContinueAssessmentCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartOrContinueAssessmentCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startOrContinueAssessmentCollectionMutation, { data, loading, error }] = useStartOrContinueAssessmentCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartOrContinueAssessmentCollectionMutation(baseOptions?: Apollo.MutationHookOptions<StartOrContinueAssessmentCollectionMutation, StartOrContinueAssessmentCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartOrContinueAssessmentCollectionMutation, StartOrContinueAssessmentCollectionMutationVariables>(StartOrContinueAssessmentCollectionDocument, options);
      }
export type StartOrContinueAssessmentCollectionMutationHookResult = ReturnType<typeof useStartOrContinueAssessmentCollectionMutation>;
export type StartOrContinueAssessmentCollectionMutationResult = Apollo.MutationResult<StartOrContinueAssessmentCollectionMutation>;
export type StartOrContinueAssessmentCollectionMutationOptions = Apollo.BaseMutationOptions<StartOrContinueAssessmentCollectionMutation, StartOrContinueAssessmentCollectionMutationVariables>;
export const SubmitAssessmentDocument = gql`
    mutation SubmitAssessment($request: SubmitAssessmentRequest!) {
  SubmitAssessment(request: $request) {
    success
  }
}
    `;
export type SubmitAssessmentMutationFn = Apollo.MutationFunction<SubmitAssessmentMutation, SubmitAssessmentMutationVariables>;

/**
 * __useSubmitAssessmentMutation__
 *
 * To run a mutation, you first call `useSubmitAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAssessmentMutation, { data, loading, error }] = useSubmitAssessmentMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSubmitAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAssessmentMutation, SubmitAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAssessmentMutation, SubmitAssessmentMutationVariables>(SubmitAssessmentDocument, options);
      }
export type SubmitAssessmentMutationHookResult = ReturnType<typeof useSubmitAssessmentMutation>;
export type SubmitAssessmentMutationResult = Apollo.MutationResult<SubmitAssessmentMutation>;
export type SubmitAssessmentMutationOptions = Apollo.BaseMutationOptions<SubmitAssessmentMutation, SubmitAssessmentMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($couponId: String, $userId: Int!) {
  createSubscription(couponId: $couponId, userId: $userId) {
    clientSecret
    subscription {
      billingCycleAnchor
      cancelAtPeriodEnd
      canceledAt
      coupon {
        amountOff
        duration
        durationInMonths
        id
        name
        percentOff
        valid
      }
      currentPeriodEnd
      currentPeriodStart
      id
      status
    }
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      couponId: // value for 'couponId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const JoinSubscriptionWaitlistDocument = gql`
    mutation joinSubscriptionWaitlist($email: String!) {
  joinSubscriptionWaitlist(email: $email) {
    success
  }
}
    `;
export type JoinSubscriptionWaitlistMutationFn = Apollo.MutationFunction<JoinSubscriptionWaitlistMutation, JoinSubscriptionWaitlistMutationVariables>;

/**
 * __useJoinSubscriptionWaitlistMutation__
 *
 * To run a mutation, you first call `useJoinSubscriptionWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinSubscriptionWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinSubscriptionWaitlistMutation, { data, loading, error }] = useJoinSubscriptionWaitlistMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useJoinSubscriptionWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<JoinSubscriptionWaitlistMutation, JoinSubscriptionWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinSubscriptionWaitlistMutation, JoinSubscriptionWaitlistMutationVariables>(JoinSubscriptionWaitlistDocument, options);
      }
export type JoinSubscriptionWaitlistMutationHookResult = ReturnType<typeof useJoinSubscriptionWaitlistMutation>;
export type JoinSubscriptionWaitlistMutationResult = Apollo.MutationResult<JoinSubscriptionWaitlistMutation>;
export type JoinSubscriptionWaitlistMutationOptions = Apollo.BaseMutationOptions<JoinSubscriptionWaitlistMutation, JoinSubscriptionWaitlistMutationVariables>;
export const AssessmentCollectionsDocument = gql`
    query AssessmentCollections($input: AssessmentCollectionsInput!) {
  AssessmentCollections(input: $input) {
    assessmentSubmissions {
      answers {
        choice {
          choice
          id
          value
        }
        questionVariableName
      }
      assessmentDefinitionId
      domainQuestionSets {
        domainName
        questionAnswers {
          answer {
            choice {
              choice
              id
              value
            }
            questionVariableName
          }
          question {
            questionConfig {
              promptOptions {
                approximateTime
                imageUrl
                prompt
                subPrompt
              }
              questionType
              singleSelectOptions {
                choices {
                  choice
                  id
                  value
                }
                prompt
                questionText
              }
            }
            variableName
          }
        }
      }
      subject {
        childSubject {
          ageInMonths
          childId
          sex
        }
        subjectType
      }
      submissionTime
      submitterId
      submitterName
      version
    }
    createdAt
    familyId
    id
    state
    type
    updatedAt
    version
  }
}
    `;

/**
 * __useAssessmentCollectionsQuery__
 *
 * To run a query within a React component, call `useAssessmentCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCollectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssessmentCollectionsQuery(baseOptions: Apollo.QueryHookOptions<AssessmentCollectionsQuery, AssessmentCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssessmentCollectionsQuery, AssessmentCollectionsQueryVariables>(AssessmentCollectionsDocument, options);
      }
export function useAssessmentCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCollectionsQuery, AssessmentCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssessmentCollectionsQuery, AssessmentCollectionsQueryVariables>(AssessmentCollectionsDocument, options);
        }
export type AssessmentCollectionsQueryHookResult = ReturnType<typeof useAssessmentCollectionsQuery>;
export type AssessmentCollectionsLazyQueryHookResult = ReturnType<typeof useAssessmentCollectionsLazyQuery>;
export type AssessmentCollectionsQueryResult = Apollo.QueryResult<AssessmentCollectionsQuery, AssessmentCollectionsQueryVariables>;
export const FmhcOverTimeDocument = gql`
    query FmhcOverTime {
  FmhcOverTime {
    reports {
      domainReports {
        context {
          content
          contextType
        }
        domainName
        domainScore {
          label
          maxScore
          rawScore
          rawScoresOverTime {
            datetime
            value
          }
          scoreName
          shownInReport
          threshold
        }
        orderIndex
        subDomainScores {
          label
          maxScore
          rawScore
          rawScoresOverTime {
            datetime
            value
          }
          scoreName
          shownInReport
          threshold
        }
      }
      numQuestionsAnswered
      subject {
        childSubject {
          ageInMonths
          childId
          sex
        }
        subjectType
      }
    }
    submissionTime
    submitterName
    version
  }
}
    `;

/**
 * __useFmhcOverTimeQuery__
 *
 * To run a query within a React component, call `useFmhcOverTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFmhcOverTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFmhcOverTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useFmhcOverTimeQuery(baseOptions?: Apollo.QueryHookOptions<FmhcOverTimeQuery, FmhcOverTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FmhcOverTimeQuery, FmhcOverTimeQueryVariables>(FmhcOverTimeDocument, options);
      }
export function useFmhcOverTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FmhcOverTimeQuery, FmhcOverTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FmhcOverTimeQuery, FmhcOverTimeQueryVariables>(FmhcOverTimeDocument, options);
        }
export type FmhcOverTimeQueryHookResult = ReturnType<typeof useFmhcOverTimeQuery>;
export type FmhcOverTimeLazyQueryHookResult = ReturnType<typeof useFmhcOverTimeLazyQuery>;
export type FmhcOverTimeQueryResult = Apollo.QueryResult<FmhcOverTimeQuery, FmhcOverTimeQueryVariables>;
export const FmhcsDocument = gql`
    query Fmhcs($input: FmhcsInput) {
  Fmhcs(input: $input) {
    familyId
    id
    isSubmitted
    startTime
    submissionTime
    submitterId
    submitterName
    version
  }
}
    `;

/**
 * __useFmhcsQuery__
 *
 * To run a query within a React component, call `useFmhcsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFmhcsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFmhcsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFmhcsQuery(baseOptions?: Apollo.QueryHookOptions<FmhcsQuery, FmhcsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FmhcsQuery, FmhcsQueryVariables>(FmhcsDocument, options);
      }
export function useFmhcsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FmhcsQuery, FmhcsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FmhcsQuery, FmhcsQueryVariables>(FmhcsDocument, options);
        }
export type FmhcsQueryHookResult = ReturnType<typeof useFmhcsQuery>;
export type FmhcsLazyQueryHookResult = ReturnType<typeof useFmhcsLazyQuery>;
export type FmhcsQueryResult = Apollo.QueryResult<FmhcsQuery, FmhcsQueryVariables>;
export const FooDocument = gql`
    query Foo {
  Foo {
    value
  }
}
    `;

/**
 * __useFooQuery__
 *
 * To run a query within a React component, call `useFooQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooQuery({
 *   variables: {
 *   },
 * });
 */
export function useFooQuery(baseOptions?: Apollo.QueryHookOptions<FooQuery, FooQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FooQuery, FooQueryVariables>(FooDocument, options);
      }
export function useFooLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FooQuery, FooQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FooQuery, FooQueryVariables>(FooDocument, options);
        }
export type FooQueryHookResult = ReturnType<typeof useFooQuery>;
export type FooLazyQueryHookResult = ReturnType<typeof useFooLazyQuery>;
export type FooQueryResult = Apollo.QueryResult<FooQuery, FooQueryVariables>;
export const GetAssessmentDefinitionDocument = gql`
    query GetAssessmentDefinition($request: GetAssessmentDefinitionRequest!) {
  GetAssessmentDefinition(request: $request) {
    assessment {
      assessmentDefinitionId
      questions {
        question {
          questionConfig {
            promptOptions {
              approximateTime
              imageUrl
              prompt
              subPrompt
            }
            questionType
            singleSelectOptions {
              choices {
                choice
                id
                value
              }
              prompt
              questionText
            }
          }
          variableName
        }
        questionOptions {
          required
        }
      }
      version
    }
  }
}
    `;

/**
 * __useGetAssessmentDefinitionQuery__
 *
 * To run a query within a React component, call `useGetAssessmentDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentDefinitionQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetAssessmentDefinitionQuery(baseOptions: Apollo.QueryHookOptions<GetAssessmentDefinitionQuery, GetAssessmentDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssessmentDefinitionQuery, GetAssessmentDefinitionQueryVariables>(GetAssessmentDefinitionDocument, options);
      }
export function useGetAssessmentDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentDefinitionQuery, GetAssessmentDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssessmentDefinitionQuery, GetAssessmentDefinitionQueryVariables>(GetAssessmentDefinitionDocument, options);
        }
export type GetAssessmentDefinitionQueryHookResult = ReturnType<typeof useGetAssessmentDefinitionQuery>;
export type GetAssessmentDefinitionLazyQueryHookResult = ReturnType<typeof useGetAssessmentDefinitionLazyQuery>;
export type GetAssessmentDefinitionQueryResult = Apollo.QueryResult<GetAssessmentDefinitionQuery, GetAssessmentDefinitionQueryVariables>;
export const GetAssessmentSubmissionsDocument = gql`
    query GetAssessmentSubmissions($request: GetAssessmentSubmissionsRequest!) {
  GetAssessmentSubmissions(request: $request) {
    submissions {
      answers {
        choice {
          choice
          id
          value
        }
        questionVariableName
      }
      assessmentDefinitionId
      domainQuestionSets {
        domainName
        questionAnswers {
          answer {
            choice {
              choice
              id
              value
            }
            questionVariableName
          }
          question {
            questionConfig {
              promptOptions {
                approximateTime
                imageUrl
                prompt
                subPrompt
              }
              questionType
              singleSelectOptions {
                choices {
                  choice
                  id
                  value
                }
                prompt
                questionText
              }
            }
            variableName
          }
        }
      }
      subject {
        childSubject {
          ageInMonths
          childId
          sex
        }
        subjectType
      }
      submissionTime
      submitterId
      submitterName
      version
    }
  }
}
    `;

/**
 * __useGetAssessmentSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentSubmissionsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetAssessmentSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<GetAssessmentSubmissionsQuery, GetAssessmentSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssessmentSubmissionsQuery, GetAssessmentSubmissionsQueryVariables>(GetAssessmentSubmissionsDocument, options);
      }
export function useGetAssessmentSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentSubmissionsQuery, GetAssessmentSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssessmentSubmissionsQuery, GetAssessmentSubmissionsQueryVariables>(GetAssessmentSubmissionsDocument, options);
        }
export type GetAssessmentSubmissionsQueryHookResult = ReturnType<typeof useGetAssessmentSubmissionsQuery>;
export type GetAssessmentSubmissionsLazyQueryHookResult = ReturnType<typeof useGetAssessmentSubmissionsLazyQuery>;
export type GetAssessmentSubmissionsQueryResult = Apollo.QueryResult<GetAssessmentSubmissionsQuery, GetAssessmentSubmissionsQueryVariables>;
export const GetFirstFmhcPaymentUrlDocument = gql`
    query GetFirstFmhcPaymentUrl($input: GetFirstFmhcPaymentUrlInput) {
  GetFirstFmhcPaymentUrl(input: $input) {
    paymentUrl
  }
}
    `;

/**
 * __useGetFirstFmhcPaymentUrlQuery__
 *
 * To run a query within a React component, call `useGetFirstFmhcPaymentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstFmhcPaymentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstFmhcPaymentUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFirstFmhcPaymentUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetFirstFmhcPaymentUrlQuery, GetFirstFmhcPaymentUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirstFmhcPaymentUrlQuery, GetFirstFmhcPaymentUrlQueryVariables>(GetFirstFmhcPaymentUrlDocument, options);
      }
export function useGetFirstFmhcPaymentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirstFmhcPaymentUrlQuery, GetFirstFmhcPaymentUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirstFmhcPaymentUrlQuery, GetFirstFmhcPaymentUrlQueryVariables>(GetFirstFmhcPaymentUrlDocument, options);
        }
export type GetFirstFmhcPaymentUrlQueryHookResult = ReturnType<typeof useGetFirstFmhcPaymentUrlQuery>;
export type GetFirstFmhcPaymentUrlLazyQueryHookResult = ReturnType<typeof useGetFirstFmhcPaymentUrlLazyQuery>;
export type GetFirstFmhcPaymentUrlQueryResult = Apollo.QueryResult<GetFirstFmhcPaymentUrlQuery, GetFirstFmhcPaymentUrlQueryVariables>;
export const GetFmhcDocument = gql`
    query GetFmhc($request: GetFmhcRequest!) {
  GetFmhc(request: $request) {
    fmhc {
      familyId
      id
      isSubmitted
      startTime
      submissionTime
      submitterId
      submitterName
      version
    }
  }
}
    `;

/**
 * __useGetFmhcQuery__
 *
 * To run a query within a React component, call `useGetFmhcQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFmhcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFmhcQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetFmhcQuery(baseOptions: Apollo.QueryHookOptions<GetFmhcQuery, GetFmhcQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFmhcQuery, GetFmhcQueryVariables>(GetFmhcDocument, options);
      }
export function useGetFmhcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFmhcQuery, GetFmhcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFmhcQuery, GetFmhcQueryVariables>(GetFmhcDocument, options);
        }
export type GetFmhcQueryHookResult = ReturnType<typeof useGetFmhcQuery>;
export type GetFmhcLazyQueryHookResult = ReturnType<typeof useGetFmhcLazyQuery>;
export type GetFmhcQueryResult = Apollo.QueryResult<GetFmhcQuery, GetFmhcQueryVariables>;
export const GetFmhcReportDocument = gql`
    query GetFmhcReport($request: GetFmhcReportRequest!) {
  GetFmhcReport(request: $request) {
    reports {
      domainReports {
        context {
          content
          contextType
        }
        domainName
        domainScore {
          label
          maxScore
          rawScore
          rawScoresOverTime {
            datetime
            value
          }
          scoreName
          shownInReport
          threshold
        }
        orderIndex
        subDomainScores {
          label
          maxScore
          rawScore
          rawScoresOverTime {
            datetime
            value
          }
          scoreName
          shownInReport
          threshold
        }
      }
      numQuestionsAnswered
      subject {
        childSubject {
          ageInMonths
          childId
          sex
        }
        subjectType
      }
    }
    submissionTime
    submitterName
    version
  }
}
    `;

/**
 * __useGetFmhcReportQuery__
 *
 * To run a query within a React component, call `useGetFmhcReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFmhcReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFmhcReportQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetFmhcReportQuery(baseOptions: Apollo.QueryHookOptions<GetFmhcReportQuery, GetFmhcReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFmhcReportQuery, GetFmhcReportQueryVariables>(GetFmhcReportDocument, options);
      }
export function useGetFmhcReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFmhcReportQuery, GetFmhcReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFmhcReportQuery, GetFmhcReportQueryVariables>(GetFmhcReportDocument, options);
        }
export type GetFmhcReportQueryHookResult = ReturnType<typeof useGetFmhcReportQuery>;
export type GetFmhcReportLazyQueryHookResult = ReturnType<typeof useGetFmhcReportLazyQuery>;
export type GetFmhcReportQueryResult = Apollo.QueryResult<GetFmhcReportQuery, GetFmhcReportQueryVariables>;
export const GetLatestSubmittedFmhcDocument = gql`
    query GetLatestSubmittedFmhc($request: GetLatestSubmittedFmhcRequest!) {
  GetLatestSubmittedFmhc(request: $request) {
    fmhc {
      familyId
      id
      isSubmitted
      startTime
      submissionTime
      submitterId
      submitterName
      version
    }
  }
}
    `;

/**
 * __useGetLatestSubmittedFmhcQuery__
 *
 * To run a query within a React component, call `useGetLatestSubmittedFmhcQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestSubmittedFmhcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestSubmittedFmhcQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetLatestSubmittedFmhcQuery(baseOptions: Apollo.QueryHookOptions<GetLatestSubmittedFmhcQuery, GetLatestSubmittedFmhcQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestSubmittedFmhcQuery, GetLatestSubmittedFmhcQueryVariables>(GetLatestSubmittedFmhcDocument, options);
      }
export function useGetLatestSubmittedFmhcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestSubmittedFmhcQuery, GetLatestSubmittedFmhcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestSubmittedFmhcQuery, GetLatestSubmittedFmhcQueryVariables>(GetLatestSubmittedFmhcDocument, options);
        }
export type GetLatestSubmittedFmhcQueryHookResult = ReturnType<typeof useGetLatestSubmittedFmhcQuery>;
export type GetLatestSubmittedFmhcLazyQueryHookResult = ReturnType<typeof useGetLatestSubmittedFmhcLazyQuery>;
export type GetLatestSubmittedFmhcQueryResult = Apollo.QueryResult<GetLatestSubmittedFmhcQuery, GetLatestSubmittedFmhcQueryVariables>;
export const HasPaidFirstFmhcDocument = gql`
    query HasPaidFirstFmhc {
  HasPaidFirstFmhc {
    firstFmhcId
    hasPaid
  }
}
    `;

/**
 * __useHasPaidFirstFmhcQuery__
 *
 * To run a query within a React component, call `useHasPaidFirstFmhcQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasPaidFirstFmhcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasPaidFirstFmhcQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasPaidFirstFmhcQuery(baseOptions?: Apollo.QueryHookOptions<HasPaidFirstFmhcQuery, HasPaidFirstFmhcQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasPaidFirstFmhcQuery, HasPaidFirstFmhcQueryVariables>(HasPaidFirstFmhcDocument, options);
      }
export function useHasPaidFirstFmhcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasPaidFirstFmhcQuery, HasPaidFirstFmhcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasPaidFirstFmhcQuery, HasPaidFirstFmhcQueryVariables>(HasPaidFirstFmhcDocument, options);
        }
export type HasPaidFirstFmhcQueryHookResult = ReturnType<typeof useHasPaidFirstFmhcQuery>;
export type HasPaidFirstFmhcLazyQueryHookResult = ReturnType<typeof useHasPaidFirstFmhcLazyQuery>;
export type HasPaidFirstFmhcQueryResult = Apollo.QueryResult<HasPaidFirstFmhcQuery, HasPaidFirstFmhcQueryVariables>;
export const IsAssessmentCompleteDocument = gql`
    query IsAssessmentComplete($request: IsAssessmentCompleteRequest!) {
  IsAssessmentComplete(request: $request) {
    isComplete
  }
}
    `;

/**
 * __useIsAssessmentCompleteQuery__
 *
 * To run a query within a React component, call `useIsAssessmentCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAssessmentCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAssessmentCompleteQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useIsAssessmentCompleteQuery(baseOptions: Apollo.QueryHookOptions<IsAssessmentCompleteQuery, IsAssessmentCompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsAssessmentCompleteQuery, IsAssessmentCompleteQueryVariables>(IsAssessmentCompleteDocument, options);
      }
export function useIsAssessmentCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsAssessmentCompleteQuery, IsAssessmentCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsAssessmentCompleteQuery, IsAssessmentCompleteQueryVariables>(IsAssessmentCompleteDocument, options);
        }
export type IsAssessmentCompleteQueryHookResult = ReturnType<typeof useIsAssessmentCompleteQuery>;
export type IsAssessmentCompleteLazyQueryHookResult = ReturnType<typeof useIsAssessmentCompleteLazyQuery>;
export type IsAssessmentCompleteQueryResult = Apollo.QueryResult<IsAssessmentCompleteQuery, IsAssessmentCompleteQueryVariables>;
export const QueryNoopDocument = gql`
    query QueryNoop {
  QueryNoop
}
    `;

/**
 * __useQueryNoopQuery__
 *
 * To run a query within a React component, call `useQueryNoopQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryNoopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryNoopQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryNoopQuery(baseOptions?: Apollo.QueryHookOptions<QueryNoopQuery, QueryNoopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryNoopQuery, QueryNoopQueryVariables>(QueryNoopDocument, options);
      }
export function useQueryNoopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryNoopQuery, QueryNoopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryNoopQuery, QueryNoopQueryVariables>(QueryNoopDocument, options);
        }
export type QueryNoopQueryHookResult = ReturnType<typeof useQueryNoopQuery>;
export type QueryNoopLazyQueryHookResult = ReturnType<typeof useQueryNoopLazyQuery>;
export type QueryNoopQueryResult = Apollo.QueryResult<QueryNoopQuery, QueryNoopQueryVariables>;
export const StaffFmhcsDocument = gql`
    query StaffFmhcs($request: StaffFmhcsInput) {
  StaffFmhcs(request: $request) {
    familyId
    id
    isSubmitted
    startTime
    submissionTime
    submitterId
    submitterName
    version
  }
}
    `;

/**
 * __useStaffFmhcsQuery__
 *
 * To run a query within a React component, call `useStaffFmhcsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffFmhcsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffFmhcsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useStaffFmhcsQuery(baseOptions?: Apollo.QueryHookOptions<StaffFmhcsQuery, StaffFmhcsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffFmhcsQuery, StaffFmhcsQueryVariables>(StaffFmhcsDocument, options);
      }
export function useStaffFmhcsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffFmhcsQuery, StaffFmhcsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffFmhcsQuery, StaffFmhcsQueryVariables>(StaffFmhcsDocument, options);
        }
export type StaffFmhcsQueryHookResult = ReturnType<typeof useStaffFmhcsQuery>;
export type StaffFmhcsLazyQueryHookResult = ReturnType<typeof useStaffFmhcsLazyQuery>;
export type StaffFmhcsQueryResult = Apollo.QueryResult<StaffFmhcsQuery, StaffFmhcsQueryVariables>;
export const ClientsDocument = gql`
    query clients {
  clients {
    email
    name
    totalCancelled
    totalCancelledInLast30Days
    totalConfirmed
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const ClosedConversationsDocument = gql`
    query closedConversations {
  closedConversations {
    lastActiveAt
    sid
  }
}
    `;

/**
 * __useClosedConversationsQuery__
 *
 * To run a query within a React component, call `useClosedConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosedConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosedConversationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClosedConversationsQuery(baseOptions?: Apollo.QueryHookOptions<ClosedConversationsQuery, ClosedConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClosedConversationsQuery, ClosedConversationsQueryVariables>(ClosedConversationsDocument, options);
      }
export function useClosedConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClosedConversationsQuery, ClosedConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClosedConversationsQuery, ClosedConversationsQueryVariables>(ClosedConversationsDocument, options);
        }
export type ClosedConversationsQueryHookResult = ReturnType<typeof useClosedConversationsQuery>;
export type ClosedConversationsLazyQueryHookResult = ReturnType<typeof useClosedConversationsLazyQuery>;
export type ClosedConversationsQueryResult = Apollo.QueryResult<ClosedConversationsQuery, ClosedConversationsQueryVariables>;
export const CouponDocument = gql`
    query coupon($promotionCode: String!) {
  coupon(promotionCode: $promotionCode) {
    amountOff
    duration
    durationInMonths
    id
    name
    percentOff
    valid
  }
}
    `;

/**
 * __useCouponQuery__
 *
 * To run a query within a React component, call `useCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponQuery({
 *   variables: {
 *      promotionCode: // value for 'promotionCode'
 *   },
 * });
 */
export function useCouponQuery(baseOptions: Apollo.QueryHookOptions<CouponQuery, CouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
      }
export function useCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponQuery, CouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
        }
export type CouponQueryHookResult = ReturnType<typeof useCouponQuery>;
export type CouponLazyQueryHookResult = ReturnType<typeof useCouponLazyQuery>;
export type CouponQueryResult = Apollo.QueryResult<CouponQuery, CouponQueryVariables>;
export const FutureAppointmentsDocument = gql`
    query futureAppointments {
  futureAppointments {
    clientEmail
    end
    providerEmail
    serviceName
    start
    status
  }
}
    `;

/**
 * __useFutureAppointmentsQuery__
 *
 * To run a query within a React component, call `useFutureAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFutureAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFutureAppointmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFutureAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<FutureAppointmentsQuery, FutureAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FutureAppointmentsQuery, FutureAppointmentsQueryVariables>(FutureAppointmentsDocument, options);
      }
export function useFutureAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FutureAppointmentsQuery, FutureAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FutureAppointmentsQuery, FutureAppointmentsQueryVariables>(FutureAppointmentsDocument, options);
        }
export type FutureAppointmentsQueryHookResult = ReturnType<typeof useFutureAppointmentsQuery>;
export type FutureAppointmentsLazyQueryHookResult = ReturnType<typeof useFutureAppointmentsLazyQuery>;
export type FutureAppointmentsQueryResult = Apollo.QueryResult<FutureAppointmentsQuery, FutureAppointmentsQueryVariables>;
export const ProvidersDocument = gql`
    query providers {
  providers {
    ages
    email
    endDate
    licenses {
      practice
      state
      type
    }
    name
    profilePictureUrl
    specialConditions
    startDate
    therapySpecialties
    timezone
    trainings
    weeklySessionAvailability
  }
}
    `;

/**
 * __useProvidersQuery__
 *
 * To run a query within a React component, call `useProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProvidersQuery(baseOptions?: Apollo.QueryHookOptions<ProvidersQuery, ProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProvidersQuery, ProvidersQueryVariables>(ProvidersDocument, options);
      }
export function useProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProvidersQuery, ProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProvidersQuery, ProvidersQueryVariables>(ProvidersDocument, options);
        }
export type ProvidersQueryHookResult = ReturnType<typeof useProvidersQuery>;
export type ProvidersLazyQueryHookResult = ReturnType<typeof useProvidersLazyQuery>;
export type ProvidersQueryResult = Apollo.QueryResult<ProvidersQuery, ProvidersQueryVariables>;