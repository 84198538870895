import { gql } from '@apollo/client';

export const PROVIDER_FAMILY_DETAILS_QUERY = gql`
  query ProviderFamilyDetailsQuery($familyId: Int!) {
    family(familyId: $familyId) {
      id
      name
      caregivers {
        id
        firstName
        lastName
        profilePicture
      }
      providers {
        id
        firstName
        lastName
        profilePicture
      }
      nextAppointment {
        id
        startDatetime
        service {
          id
          name
        }
        provider {
          id
          firstName
          lastName
        }
        zoomLink
      }
      conversationWithProvider {
        id
        conversationSid
      }
      shortTermGoals
      longTermGoals
      providerNote
    }
  }
`;

export const PROVIDER_ASSESSMENTS_AND_DOCUMENTATION_QUERY = gql`
  query ProviderAssessmentsAndDocumentationQuery($familyId: Int!) {
    family(familyId: $familyId) {
      id
      surveyResponses {
        edges {
          node {
            id
            survey {
              id
              name
            }
            externalId
            createdAt
            user {
              id
              firstName
              lastName
            }
            child {
              id
              firstName
            }
          }
        }
      }
    }
  }
`;

export const PROVIDER_FAMILY_APPOINTMENTS_QUERY = gql`
  query ProviderFamilyAppointmentsQuery($familyId: Int!) {
    family(familyId: $familyId) {
      id
      upcomingAppointments {
        id
        startDatetime
        service {
          id
          name
        }
        provider {
          id
          firstName
          lastName
        }
        zoomLink
      }
    }
  }
`;
