import { type FC, type PropsWithChildren } from 'react';

import { SelectableTag, type SelectableTagProps } from '../SelectableTag';
import { Section, TagsContainer, Text } from '../styled';
import { getFilterItems, type FilterItem } from '../utils';

export type TagFilterProps = {
  data: FilterItem[] | undefined;
  selected: FilterItem[];
  onSelect: (item: FilterItem) => SelectableTagProps['onSelect'];
};

export const TagFilter: FC<PropsWithChildren<TagFilterProps>> = ({ data, selected, onSelect }) => {
  const items = getFilterItems(data);

  const isSelected = (item: FilterItem) => selected.some((selectedTag) => selectedTag.pk === item.pk);

  return (
    <Section>
      <Text>Filter content by tags</Text>
      <TagsContainer>
        {items.map((tag) => (
          <SelectableTag key={tag.pk} selected={isSelected(tag)} onSelect={onSelect(tag)} variant="secondary">
            {tag.name}
          </SelectableTag>
        ))}
      </TagsContainer>
    </Section>
  );
};
