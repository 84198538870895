import { useMemo, type FC, type PropsWithChildren } from 'react';

import { type Score } from '../../../types';
import { SummaryContent } from '../SummaryContent/SummaryContent';
import {
  SummaryContentContainer,
  SummaryContentSection,
  SummaryContentSectionList,
  SummaryContentSectionListItem,
  SummaryContentSectionTitle,
} from './styled';

export type SummaryCardProps = {
  scores: Score[];
  title: string;
  subtitle?: string;
  className?: string;
  onClick?: () => void;
};

export const SummaryCard: FC<PropsWithChildren<SummaryCardProps>> = ({
  scores,
  subtitle,
  title,
  className,
  onClick,
}) => {
  const concerning = useMemo(
    () =>
      scores
        .filter((score) => score.rawScore !== undefined && score.rawScore >= score.threshold)
        .map((score) => score.label),
    [scores]
  );

  const typical = useMemo(
    () =>
      scores
        .filter((score) => score.rawScore !== undefined && score.rawScore < score.threshold)
        .map((score) => score.label),
    [scores]
  );

  return (
    <SummaryContent subtitle={subtitle} title={title} className={className} onClick={onClick}>
      <SummaryContentContainer>
        <SummaryContentSection>
          <SummaryContentSectionTitle color="red">Concerning</SummaryContentSectionTitle>
          <SummaryContentSectionList>
            {concerning.map((domain) => (
              <SummaryContentSectionListItem key={domain}>{domain}</SummaryContentSectionListItem>
            ))}
          </SummaryContentSectionList>
        </SummaryContentSection>
        <SummaryContentSection>
          <SummaryContentSectionTitle color="green">Typical</SummaryContentSectionTitle>
          <SummaryContentSectionList>
            {typical.map((domain) => (
              <SummaryContentSectionListItem key={domain}>{domain}</SummaryContentSectionListItem>
            ))}
          </SummaryContentSectionList>
        </SummaryContentSection>
      </SummaryContentContainer>
    </SummaryContent>
  );
};
