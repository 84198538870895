import { gql } from '@apollo/client';

export const CONVERSATION_PARTICIPANTS_QUERY = gql`
  query ConversationParticipantsQuery($conversationSid: String!) {
    conversation(sid: $conversationSid) {
      id
      participants {
        id
        firstName
        lastName
      }
    }
  }
`;
