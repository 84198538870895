import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useGraphQLErrorHandling } from '$shared/hooks';

import {
  type ProviderAssessmentsAndDocumentationQuery,
  type ProviderAssessmentsAndDocumentationQuery_family_surveyResponses_edges_node,
  type ProviderAssessmentsAndDocumentationQueryVariables,
} from '../../../../graphql/__generated__/ProviderAssessmentsAndDocumentationQuery';
import { PROVIDER_ASSESSMENTS_AND_DOCUMENTATION_QUERY } from '../../../../graphql/provider-family-details';
import { type AssessmentsAndDocumentationTableDataItem } from '../components';

export const useAssessmentsAndDocumentationTableData = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<
    ProviderAssessmentsAndDocumentationQuery,
    ProviderAssessmentsAndDocumentationQueryVariables
  >(PROVIDER_ASSESSMENTS_AND_DOCUMENTATION_QUERY, { variables: { familyId: Number(id) } });
  useGraphQLErrorHandling(error);

  const tableData: AssessmentsAndDocumentationTableDataItem[] = useMemo(
    () =>
      data?.family.surveyResponses?.edges
        .map((edge) => edge?.node)
        .filter((node): node is ProviderAssessmentsAndDocumentationQuery_family_surveyResponses_edges_node => !!node)
        .map<AssessmentsAndDocumentationTableDataItem>((surveyResponse) => ({
          surveyResponse: {
            name: surveyResponse.survey?.name ?? '-',
            externalId: surveyResponse.externalId ?? undefined,
          },
          createdAt: new Date(surveyResponse.createdAt),
          informant: surveyResponse.user ? `${surveyResponse.user.firstName} ${surveyResponse.user.lastName}` : '-',
          client: surveyResponse.child?.firstName ?? '-',
        })) ?? [],
    [data?.family.surveyResponses?.edges]
  );

  return { tableData, data, loading, error };
};
