import { type ILoggerService } from '../logging/types';
import {
  DEPERECATED_FACEBOOK_LO_CLICK_ID_COOKIE_NAME,
  DEPRECATED_FACEBOOK_LO_BROWSER_ID_COOKIE_NAME,
  FACEBOOK_BROWSER_ID_COOKIE_NAME,
  FACEBOOK_CLICK_ID_COOKIE_NAME,
  UTM_PARAMETER_COOKIE_PREFIX,
} from './constants';
import type { EventPropsShape, IEventTrackingProvider, IEventTrackingService } from './types';

/**
 * The consumer-facing Event Tracking service. This service provides a common interface for tracking events and user
 * actions across the platform. It is a wrapper around the underlying event tracking provider and handles translating
 * common event tracking methods and properties into the appropriate provider calls.
 */
export class PlatformEventTrackingService implements IEventTrackingService {
  private logger: ILoggerService;

  private provider: IEventTrackingProvider;

  private isInitialized = false;

  private facebookEventProperties: EventPropsShape = {};

  private utmEventProperties: EventPropsShape = {};

  constructor(logger: ILoggerService, provider: IEventTrackingProvider) {
    this.logger = logger;
    this.provider = provider;
    this.facebookEventProperties = this.getFacebookCookiesAsPropertiesMap();
    this.utmEventProperties = this.getUtmCookiesAsPropertiesMap();
  }

  /**
   * Initialize the underlying event tracking provider. This method should be called before any other event tracking
   * methods are called.
   *
   * ! Because Event Tracking is client-only, it's recommend to call this method inside of a useSingletonEffect hook
   * ! as part of the application bootstrap process. We need `window` to exist before we attempt to initialize the provider.
   *
   * ! Event Tracking SDK from CDPs usually automatically call a `page` method internally on initialization. This means
   * ! we don't need to call `viewedPage` manually on first page load.
   */
  init() {
    // Only allow the provider to initialize once
    if (!this.isInitialized) {
      this.provider.init();
      this.isInitialized = true;
    }
  }

  /**
   * The `onServiceReady` method lets you register a callback that will be invoked when the underlying event tracking
   * provider is ready to receive events. This is useful for providers that need to load an external script before
   * they can be used.
   *
   * @param callback A callback to be invoked after the underlying provider is ready
   */
  onServiceReady(callback: () => void) {
    this.provider.ready?.(callback);
  }

  /**
   * The `identify` method lets you identify a user and associate them to their actions. It also lets you record any traits
   * about them like their name, email, etc.
   *
   * Identify events have reserved traits to standardize the data we send to our 3rd party providers. These reserved
   * traits types are already defined and don't need to be passed in as a generic type argument.
   *
   * @example identify('user123', { email: '', name: '' })
   *
   * We can also send custom traits that are specific to our platform. These traits can be strongly typed by
   * passing a generic type argument to the identify method.
   *
   * @example identify<CustomTraits>('user123', { email: '', name: '', customTrait: '' })
   *
   * @param userId The user's unique identifier
   * @param traits The user's traits (name, email, etc.)
   * @param callback A callback to be invoked after the identify call is complete
   */
  identify<T extends EventPropsShape = Record<string, never>>(userId: string, traits?: T, callback?: () => void) {
    if (!this.canCallMethods('identify', userId)) {
      return;
    }

    // ensure consistent casing for userId regardless of the user input
    const normalizedUserId = userId.toLowerCase();

    // Safely copy the traits object so we don't mutate the original
    const _traits = { ...traits };

    // If the user has an email trait, ensure consistent casing for email regardless of the user input
    if (_traits?.email && typeof _traits.email === 'string') {
      _traits.email = _traits.email.toLowerCase();
    }

    this.provider.identify(normalizedUserId, _traits, () => {
      this.logger.debug(`[platform.event-tracking.${this.provider.name}] Identified user: ${normalizedUserId}`, {
        traits,
      });
      callback?.();
    });
  }

  /**
   * The `clearIdentity` method lets you clear data about the currently identified user from the user's device. This has
   * no effect on the user's existing identity in analytics destinations and does not affect historical data.
   *
   * Keep in mind, that any action taken post logout will be attributed to a new user. Once the user logs back in using
   * the same `userId`, downstream destinations will know how to merge the all the identities.
   */
  clearIdentity() {
    if (!this.canCallMethods('clearIdentity')) {
      return;
    }
    this.provider.clearIdentity();
    this.logger.debug(`[platform.event-tracking.${this.provider.name}] Clearing user identity`);
  }

  /**
   * The `linkUserWithGroup` method lets you link the currently identified user with a company/organization/account/subscription
   * ! Do not use this method in place of `identify` for tracking individual users.
   * ! Groups ids must be consistent across all platforms
   *
   * This method can be useful when you want to track a user's actions within the context of a group and you want to
   * gather more robust information for that group across many users, as opposed to just adding traits to an `identity` call.
   *
   * `linkUserWithGroup` events have reserved traits to standardize the data we send to our 3rd party providers. These reserved
   * traits types are already defined and don't need to be passed in as a generic type argument.
   *
   * @example linkUserWithGroup('group123', { name: '' })
   *
   * We can also send custom traits that are specific to our platform. These traits can be strongly typed by
   * passing a generic type argument to the group method.
   *
   * @example linkUserWithGroup<CustomTraits>('group123', { name: '', customTrait: '' })
   *
   * @param groupId The unique identifier for the group (This should be consistent across all platforms and included in our Tracking Plan)
   * @param traits The group traits (name, email, etc.)
   * @param callback A callback to be invoked after the group call is complete
   */
  linkUserWithGroup<T extends EventPropsShape = Record<string, never>>(
    groupId: string,
    traits?: T,
    callback?: () => void
  ) {
    if (!this.canCallMethods('linkUserWithGroup', groupId)) {
      return;
    }
    this.provider.group(groupId, traits, () => {
      this.logger.debug(`[platform.event-tracking.${this.provider.name}] Linked user with group: ${groupId}`, {
        groupTraits: traits,
      });
      callback?.();
    });
  }

  /**
   * The `alias` method lets you merge different identities of a known user.
   * ! USE WITH CAUTION. This method is likely not reversible in downstream providers.
   *
   * @param newUserId The new user ID to alias to
   * @param previousUserId The previous user ID to alias from
   * @param callback A callback to be invoked after the alias call is complete
   */
  alias(newUserId: string, previousUserId?: string, callback?: () => void) {
    if (!this.canCallMethods('alias', newUserId)) {
      return;
    }
    this.provider.alias(newUserId, previousUserId, () => {
      this.logger.debug(
        `[platform.event-tracking.${this.provider.name}] Aliased user: ${previousUserId} -> ${newUserId}`
      );
      callback?.();
    });
  }

  /**
   * The track method lets you capture events along a user's journey along with custom properties that describe the event.
   *  ! DO NOT USE THIS METHOD TO TRACK PAGE VIEWS. Use the `page` method instead.
   *
   * Track events have reserved properties to standardize the data we send to our 3rd party providers. These reserved
   * traits types are already defined and don't need to be passed in as a generic type argument.
   *
   * @example track('CLICKED_BUTTON', { reservedProperty: '' })
   *
   * We can also send custom properties that are specific to our platform. These properties can be strongly typed by passing a generic
   * type argument to the track method.
   *
   * @example track<CustomProperties>('CLICKED_BUTTON', { reservedProperty: '', customProperty: '' })
   *
   * In addition to custom properties, `track` event properties can also contain traits about the user that performed
   * the action. Allowed traits include the reserved traits (by default), but custom traits can also be defined by including
   * them in the generic type argument.
   *
   * @example track('CLICKED_BUTTON', { reservedProperty: '', traits: { reservedTrait: '' } })
   * @example track<CustomPropertiesWithTraits>('CLICKED_BUTTON', { reservedProperty: '', customProperty: '', traits: { reservedTrait: '' customTrait: '' } })
   *
   * @param event The event name (this should always mirror the name defined in our Tracking Plan)
   * @param properties The event properties to attach to the event (these should always mirror the properties defined in our Tracking Plan)
   * @param callback A callback to be invoked after the track call is complete
   */
  track<T extends EventPropsShape = Record<string, never>>(event: string, properties?: T, callback?: () => void) {
    if (!this.canCallMethods('track', event)) {
      return;
    }
    const _properties = { ...properties, ...this.facebookEventProperties, ...this.utmEventProperties };

    this.provider.track(event, _properties, () => {
      this.logger.debug(`[platform.event-tracking.${this.provider.name}] Tracked event: ${event}`, {
        properties: _properties,
      });
      callback?.();
    });
  }

  /**
   * The `viewedPage` method lets you record page views on a website, along with optional extra properties about the page being viewed.
   *
   * `viewedPage` events have reserved properties to standardize the data we send to our 3rd party providers. However, we can also
   * send custom properties that are specific to our platform. These properties can be strongly typed by passing a generic
   * type argument to the page method.
   *
   * @example viewedPage<CustomProperties>('HOME', { reservedProperty: '', customProperty: '' })
   *
   * In addition to custom properties, `viewedPage` event properties can also contain traits about the user. However,
   * we recommend using the `identify` or `track` method to send user traits instead of the `viewedPage` method. We are supporting
   * this because most CDPs support it. However, it is not recommended. If we find a good use-case for it and you MUST
   * do it, then you can strongly type the traits by including them in the generic type argument.
   *
   * @example viewedPage<CustomPropertiesWithTraits>('HOME', { reservedProperty: '', customProperty: '', traits: { someCustomTrait: '' } })
   *
   * @param pageName The name of the page being viewed
   * @param properties The page properties to attach to the event
   * @param callback A callback to be invoked after the page call is complete
   */
  viewedPage<T extends EventPropsShape = Record<string, never>>(properties?: T, callback?: () => void) {
    if (!this.canCallMethods('viewedPage')) {
      return;
    }

    const _properties = { ...properties, ...this.facebookEventProperties, ...this.utmEventProperties };

    this.provider.page(_properties, () => {
      this.logger.debug(`[platform.event-tracking.${this.provider.name}] Viewed page`, { properties: _properties });
      callback?.();
    });
  }

  /**
   * Checks to make sure that the `init` method has been called before any other event tracking methods are called.
   * If it hasn't, it logs an error and returns false.
   */
  private canCallMethods(name: string, meta?: string) {
    if (!this.isInitialized) {
      this.logger.error(
        new Error(`[platform.event-tracking] Event Tracking method called before initialization: ${name}:${meta}`)
      );
      return false;
    }
    return true;
  }

  /**
   * Get a cookie value from the document.
   * TODO: Move to a centralized cookie utility in `kit`
   */
  private static getCookieValue(cookieName: string) {
    return document.cookie
      .split('; ')
      .find((cookie) => cookie.startsWith(`${cookieName}=`))
      ?.split('=')[1];
  }

  /**
   * Strips empty values from an object
   */
  private stripEmptyValueEntriesFromObject(obj: EventPropsShape) {
    return Object.fromEntries(Object.entries(obj).filter(([, value]) => !!value));
  }

  /**
   * Reads all available UTM cookies and extracts the values into an object that can be spread onto the event properties object
   */
  private getUtmCookiesAsPropertiesMap(): EventPropsShape {
    try {
      const allCookies = document.cookie.split('; ');
      const utmPropertyMap = allCookies.reduce((acc, cookie) => {
        if (!cookie.startsWith(UTM_PARAMETER_COOKIE_PREFIX)) {
          return acc;
        }

        const [key, value] = cookie.split('=');

        if (!key || !value) {
          return acc;
        }

        const utmKey = key.replace('lo_', '');
        return { ...acc, [utmKey]: value };
      }, {} as EventPropsShape);

      return utmPropertyMap;
    } catch (error) {
      this.logger.error(new Error(`[platform.event-tracking] Error reading UTM cookies`, { cause: error }));
      return {};
    }
  }

  /**
   * Reads Facebook Pixel related cookies and extract the values into an object that can be spread onto the event properties object.
   *
   * NOTE: These cookies are set in our Squarespace app and Unbounce App.
   * NOTE:  Note that we will have to manually map fbc and fbp to event.context for the meta destinations in Rudderstack. it doesn't infer them from properties
   */
  private getFacebookCookiesAsPropertiesMap(): EventPropsShape {
    const facebookPropertyMap = {
      fbc:
        PlatformEventTrackingService.getCookieValue(FACEBOOK_CLICK_ID_COOKIE_NAME) ||
        PlatformEventTrackingService.getCookieValue(DEPERECATED_FACEBOOK_LO_CLICK_ID_COOKIE_NAME),
      fbp:
        PlatformEventTrackingService.getCookieValue(FACEBOOK_BROWSER_ID_COOKIE_NAME) ||
        PlatformEventTrackingService.getCookieValue(DEPRECATED_FACEBOOK_LO_BROWSER_ID_COOKIE_NAME),
    };

    return this.stripEmptyValueEntriesFromObject(facebookPropertyMap);
  }
}
