import styled from 'styled-components';

import { FamilyDetailsCard } from '../FamilyDetailsCard';

export const StyledFamilyDetailsCard = styled(FamilyDetailsCard)`
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const TextBlue = styled.span`
  color: ${({ theme }) => theme.deprecated_.colors.textBlue};
`;

export const NextCallDate = styled.div`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.large};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin: -0.5rem;
  flex-wrap: wrap;

  & > * {
    margin: 0.5rem;
  }
`;
