import { gql } from '@apollo/client';

import UPCOMING_APPOINTMENT_FRAGMENT from './upcoming-appointment-fragment';

export const CARE_SCHEDULE = gql`
  query CareSchedule {
    viewer {
      id
      firstName
      lastName
      email
      isDemoAccount
      phoneNumber
      hasConfirmedAppointments
      hasCompletedAppointments
      upcomingAppointments {
        ...UpcomingAppointment
      }
      bundleStatus {
        bundleType
        totalSessions
        sessionsBooked
      }
      family {
        children {
          id
          providers {
            id
            firstName
            lastName
            externalProviderId
            providerTitle
            profilePicture
            intakeqServiceId
            intakeqBundles {
              bundleName
              bundleId
            }
          }
        }
        nextAppointment {
          ...UpcomingAppointment
        }
        welcomeCall {
          id
          status
        }
      }
    }
  }
  ${UPCOMING_APPOINTMENT_FRAGMENT}
`;
