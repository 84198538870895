import { type FC } from 'react';
import styled from 'styled-components';

type Size = 'small' | 'regular';

type ProgressBarContainerProps = {
  componentSize: Size;
};

export const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
  --progress-bar-border-radius: 4px;
  --progress-bar-color: ${({ theme }) => theme.deprecated_.colors.green};

  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  height: ${({ theme, componentSize }) =>
    `calc(${theme.deprecated_.sizeBasis} / ${{ small: 2, regular: 1.5 }[componentSize]})`};
  width: 100%;
  position: relative;
  margin-bottom: calc(2 * ${({ theme }) => theme.deprecated_.sizeBasis});
  border-radius: var(--progress-bar-border-radius);
  border: ${({ theme }) =>
    `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.border.color}`};
  overflow: hidden;
`;

type ProgressBarAmountProps = {
  percentComplete: number;
};

export const ProgressBarAmount = styled.div<ProgressBarAmountProps>`
  background-color: var(--progress-bar-color);
  /* subtract the border size of the parent from the border radius of the element, so it matches the inner curvature */
  border-radius: ${({ theme }) => `calc(var(--progress-bar-border-radius) - ${theme.deprecated_.border.size})`};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ percentComplete }) => `${percentComplete}%`};
  transition: width 500ms linear;
`;

export type ProgressBarProps = {
  progress: number;
  size?: Size;
  className?: string;
};

export const ProgressBar: FC<ProgressBarProps> = ({ progress, size = 'regular', className }) => (
  <ProgressBarContainer className={className} componentSize={size}>
    <ProgressBarAmount percentComplete={progress} />
  </ProgressBarContainer>
);
