import { gql } from '@apollo/client';

import { RESOURCE_FRAGMENT } from './resource-fragment';

export const RESOURCES_FRAGMENT = gql`
  fragment ResourcesFragment on ResourceTypeConnection {
    edges {
      node {
        ...ResourceFragment
      }
    }
  }
  ${RESOURCE_FRAGMENT}
`;
