import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useMemo, type FC, type ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useTable, type Column, type Row } from 'react-table';

import { Header, Link } from '@littleotter/legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type ProviderClientQuery,
  type ProviderClientQuery_user_surveyResponses_edges,
} from '../../../graphql/__generated__/ProviderClientQuery';
import { MissingQueryDataError } from '../../../graphql/errors';
import { PROVIDER_CLIENT_QUERY } from '../../../graphql/provider-client';
import { routes } from '../../../routes';
import { BaseTable } from '../components';
import { ChildDetailsCard } from './ChildDetailsCard';
import { ClientDetailsCard } from './ClientDetailsCard';
import { MarginChildren, StyledSection } from './components';

export const ProviderClient: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<ProviderClientQuery>(PROVIDER_CLIENT_QUERY, {
    variables: { id: Number(id) },
  });
  useGraphQLErrorHandling(error);

  const surveyResponses = data?.user.surveyResponses;

  const tableData = useMemo(
    () =>
      surveyResponses?.edges.filter((edge): edge is ProviderClientQuery_user_surveyResponses_edges => edge !== null) ??
      [],
    [surveyResponses]
  );

  const columns = useMemo(
    (): Column<ProviderClientQuery_user_surveyResponses_edges>[] => [
      {
        Header: 'Date',
        accessor: ({ node }) => format(new Date(node?.createdAt), 'MMMM d, yyyy'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - fix this by making ProviderClientQuery_user_surveyResponses_edges.node non-nullable in GQL
        Cell({
          value,
          row,
        }: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: ReactElement<any, any>;
          row: Row<ProviderClientQuery_user_surveyResponses_edges>;
        }) {
          return row.original.node ? (
            <Link href={routes.provider.client.surveyResponse.url({ id: row.original.node.pk })}>{value}</Link>
          ) : (
            value
          );
        },
      },
      {
        Header: 'Worry Domain',
        accessor: ({ node }) => node?.survey?.worryDomain?.name,
      },
      {
        Header: 'Score',
        accessor: ({ node }) => node?.score,
      },
      {
        Header: 'Risk Categorization',
        accessor: ({ node }) => node?.riskCategorization,
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: tableData });

  if (loading) return <PageWideLoading />;
  if (!data) throw new MissingQueryDataError('ProviderClientQuery');

  const { user } = data;

  return (
    <MarginChildren>
      <Header as="h1">Client Details</Header>
      <StyledSection>
        <ClientDetailsCard user={user} />
      </StyledSection>
      <StyledSection>
        <Header as="h2">{user.family?.children.length === 1 ? 'Child' : 'Children'}</Header>
        <MarginChildren small>
          {user.family?.children.map((child) => <ChildDetailsCard key={child.id} child={child} />)}
        </MarginChildren>
      </StyledSection>

      <StyledSection>
        <Header as="h2">Survey Responses</Header>
        {tableData.length ? (
          <BaseTable tableInstance={tableInstance} />
        ) : (
          <p>This user hasn't responded any surveys yet.</p>
        )}
      </StyledSection>
    </MarginChildren>
  );
};
