import { math } from 'polished';
import styled from 'styled-components';

import { Modal as BaseModal, ModalContent } from '@littleotter/legacy-components';

export const Modal = styled(BaseModal)`
  --modal-padding: ${({ theme }) => theme.deprecated_.sizeBasis};

  position: relative;
  overflow: hidden;

  ${ModalContent} {
    overflow: visible;
    text-align: center;
  }
`;

export const Message = styled.p`
  margin-top: ${({ theme }) => theme.deprecated_.sizeBasis};
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
`;

export const StyledImg = styled.img`
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * -1`)};
  margin-left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * -1`)};
  margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * -1`)};
  margin-bottom: 0;
`;
