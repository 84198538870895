import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type Column, type Row, type TableOptions } from 'react-table';

import { Link } from '@littleotter/legacy-components';

import { logger } from '$services/logging';

import { routes } from '../../../../../routes';
import { CellText, GrayCellText, StyledList } from '../styled';
import {
  type AppointmentData,
  type ClientData,
  type FamilyData,
  type GlobalFilterValue,
  type MyClientsTableDataItem,
} from '../types';

export const useTableOptions = (tableData: MyClientsTableDataItem[]): TableOptions<MyClientsTableDataItem> => {
  const columns = useMemo(
    (): Column<MyClientsTableDataItem>[] => [
      {
        id: 'familyName',
        Header: 'Family Name',
        accessor: (data) => data.family,
        Cell({ value }: { value: FamilyData }) {
          return <Link href={routes.provider.family.details.url({ id: Number(value.id) })}>{value.name}</Link>;
        },
      },
      {
        id: 'familyCode',
        Header: 'Family Code',
        accessor: (data) => data.family.code,
      },
      {
        id: 'caregivers',
        Header: 'Caregivers',
        accessor: (data) => data.caregivers,
        Cell({ value }: { value: ClientData[] }) {
          return (
            <StyledList>
              {value.map((caregiver) => (
                <li key={caregiver.id}>
                  {caregiver.profileLink ? (
                    <Link isAnchor href={caregiver.profileLink}>
                      {caregiver.name}
                    </Link>
                  ) : (
                    caregiver.name
                  )}
                </li>
              ))}
            </StyledList>
          );
        },
      },
      {
        id: 'children',
        Header: 'Children',
        accessor: (data) => data.children,
        Cell({ value }: { value: ClientData[] }) {
          return (
            <StyledList>
              {value.map((child) => (
                <li key={child.id}>
                  {child.profileLink ? (
                    <Link isAnchor href={child.profileLink}>
                      {child.name}
                    </Link>
                  ) : (
                    child.name
                  )}
                </li>
              ))}
            </StyledList>
          );
        },
      },
      {
        id: 'nextAppointment',
        Header: 'Next Appointment',
        accessor: (data) => data.nextAppointment,
        Cell({ value }: { value: AppointmentData | undefined }) {
          return value ? (
            <>
              <CellText>{format(value.datetime, "EEEE, MMMM do 'at' h:mma")}</CellText>
              <CellText>{value.providerName}</CellText>
            </>
          ) : (
            <GrayCellText>No upcoming appointments</GrayCellText>
          );
        },
      },
      {
        id: 'conversation',
        Header: 'Conversation',
        accessor: (data) => data.conversationLink,
        Cell({ value }: { value: string | undefined }) {
          if (!value) {
            return <GrayCellText>Not Available</GrayCellText>;
          }

          return <Link href={value}>Link</Link>;
        },
      },
      {
        id: 'latestFmhcReport',
        Header: 'Latest FMHC Report',
        accessor: (data) => ({ familyId: data.family.id, fmhcIdsPromises: data.latestFmhcIds }),
        Cell({
          value,
        }: {
          value: { familyId: string; fmhcIdsPromises: { [caregiverId: string]: Promise<string | undefined> } };
        }) {
          const [message, setMessage] = useState<string>('Loading');
          const [fmhcIds, setFmhcIds] = useState<{ [caregiverId: string]: string }>({});

          useEffect(() => {
            (async () => {
              try {
                const fmhcIdEntries = Object.entries(value.fmhcIdsPromises);
                const resolved = await Promise.allSettled(fmhcIdEntries.map((p) => p[1]));
                const fmhcIdsResolved = resolved.reduce(
                  (prev, curr, index) => {
                    if (curr.status === 'rejected' || curr.value === undefined) {
                      return prev;
                    }
                    return { ...prev, [fmhcIdEntries[index][0]]: curr.value };
                  },
                  {} as { [caregiverId: string]: string }
                );
                if (Object.keys(fmhcIdsResolved).length === 0) {
                  setMessage('No Reports');
                } else {
                  setFmhcIds(fmhcIdsResolved);
                }
              } catch (e) {
                logger.error(new Error('Error in client table', { cause: e }));
                if (typeof e === 'string') {
                  setMessage(`Error: ${e}`);
                } else if (e instanceof Error) {
                  setMessage(`Error: ${e.message}`);
                } else {
                  setMessage(`Error: ${JSON.stringify(e)}`);
                }
              }
            })();
          }, [value.fmhcIdsPromises]);

          if (Object.keys(fmhcIds).length === 0) {
            return <GrayCellText>{message}</GrayCellText>;
          }
          return (
            <ol>
              {Object.entries(fmhcIds).map(([caregiverId, fmhcId]) => (
                <li key={caregiverId}>
                  <Link href={routes.provider.family.reports.url({ familyId: value.familyId, fmhcId })}>
                    Latest report for {} ({caregiverId})
                  </Link>
                </li>
              ))}
            </ol>
          );
          // <Link href={routes.provider.family.reports.url({ familyId: value.familyId, fmhcId })}>Reports</Link>;
        },
      },
    ],
    []
  );

  const sortBy = useMemo(() => [{ id: 'familyName' }], []);

  const globalFilter = useCallback(
    (rows: Array<Row<MyClientsTableDataItem>>, _columnIds: string[], globalFilterValue: GlobalFilterValue) =>
      rows.filter(
        (row) => !globalFilterValue.providerId || row.original.providerIds.includes(globalFilterValue.providerId)
      ),
    []
  );

  return {
    columns,
    data: tableData,
    initialState: {
      sortBy,
    },
    globalFilter,
    autoResetGlobalFilter: false,
  };
};
