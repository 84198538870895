import { type FC, type PropsWithChildren } from 'react';

import { Icon } from '@littleotter/legacy-components';

import * as styled from './styled';

export const ReportListItem: FC<PropsWithChildren<ReportListItemProps>> = ({
  key,
  href,
  profilePicture,
  title,
  content,
}) => {
  return (
    <li key={key}>
      <styled.StyledLinkListLink href={href}>
        <styled.CardLeftSection>
          <styled.StyledProfilePicture size="smaller" src={profilePicture} />
        </styled.CardLeftSection>
        <styled.CardCenterSection>
          <styled.CardLabel>{title}</styled.CardLabel>
          <styled.CardContent>{content}</styled.CardContent>
        </styled.CardCenterSection>
        <styled.StyledCardRightSection>
          <Icon name="ChevronRight" />
        </styled.StyledCardRightSection>
      </styled.StyledLinkListLink>
    </li>
  );
};

export interface ReportListItemProps {
  key: string;
  profilePicture?: string;
  title: string;
  href: string;
  content: string;
}
