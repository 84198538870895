import { type FC } from 'react';

import { HTMLContainer } from '../../../HTMLContainer';
import { type CustomWidgetProps } from '../types';

export type ContentWidgetOptions = {
  content: string;
};

type ContentWidgetProps = CustomWidgetProps<ContentWidgetOptions>;

export const ContentWidget: FC<ContentWidgetProps> = ({ options }) => {
  const { content } = options as ContentWidgetOptions;

  return <HTMLContainer content={content} />;
};
