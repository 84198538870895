import { math } from 'polished';
import styled, { createGlobalStyle } from 'styled-components';

export const Background = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.deprecated_.colors.cream};
  }
`;

export const Container = styled.section`
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.08);

  button[type='submit'] {
    margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
    width: 100%;
  }
`;
