import { math } from 'polished';
import styled, { css } from 'styled-components';

import { Header as HeaderComponent, Link } from '@littleotter/legacy-components';

export type StyledContainerProps = {
  removePaddingTop?: boolean;
};

export const StyledContainer = styled.header<StyledContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme, removePaddingTop }) =>
    !removePaddingTop &&
    css`
      padding-top: ${math(`${theme.deprecated_.sizeBasis} * 3`)};
    `}
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.deprecated_.colors.textBlue};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 0.875`)};
  width: max-content;
  white-space: nowrap;
`;

export const StyledHeader = styled(HeaderComponent)`
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.25`)};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;
