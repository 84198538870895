import { gql } from '@apollo/client';

import { RESOURCES_FRAGMENT } from './resources-fragment';

export const SAVED_RESOURCES_QUERY = gql`
  query SavedResources {
    viewer {
      id
      favorites {
        ...ResourcesFragment
      }
    }
  }
  ${RESOURCES_FRAGMENT}
`;
