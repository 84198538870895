import { type Client, type Message, type Participant } from '@twilio/conversations';
import { useEffect, useState } from 'react';

import { type ConversationPreview } from '$shared/contexts/Conversations/conversationPreview';

import {
  getCountByConversation,
  getTotalCount,
  getUnreadMessagesCount,
  type CountByConversation,
} from '../utils/messagesCount';

type OnParticipantUpdatedData = {
  participant: Participant;
  updateReasons: string[];
};

type OnParticipantUpdated = (data: OnParticipantUpdatedData) => void;

type OnMessageAdded = (message: Message) => void;

type UseUnreadMessagesCount = (client: Client | null, conversations: ConversationPreview[]) => number;

export const useUnreadMessagesCount: UseUnreadMessagesCount = (client, conversations) => {
  const [countByConversation, setCountByConversation] = useState<CountByConversation>({});

  // Set initial count values
  useEffect(() => {
    const asyncSetCountByConversation = async () => {
      const counts = await getCountByConversation(conversations);
      setCountByConversation(counts);
    };

    asyncSetCountByConversation();
  }, [conversations]);

  // When the viewer updates their lastReadMessageIndex, update the count for the related conversation
  useEffect(() => {
    const onParticipantUpdated: OnParticipantUpdated = async ({ participant, updateReasons }) => {
      const isViewerParticipant = client?.user.identity === participant.identity;
      const isMessageReadUpdate = updateReasons.includes('lastReadMessageIndex');
      if (!isViewerParticipant || !isMessageReadUpdate) {
        return;
      }

      const { sid } = participant.conversation;
      const updatedCount = await getUnreadMessagesCount(participant.conversation);
      setCountByConversation((currentCountByConversation) => ({
        ...currentCountByConversation,
        [sid]: updatedCount,
      }));
    };

    client?.on('participantUpdated', onParticipantUpdated);

    return () => {
      client?.off('participantUpdated', onParticipantUpdated);
    };
  }, [client]);

  // When a message is added to a conversation, increment the number of unread messages for it
  useEffect(() => {
    const onMessageAdded: OnMessageAdded = (message) => {
      const { sid } = message.conversation;
      setCountByConversation((currentCountByConversation) => ({
        ...currentCountByConversation,
        [sid]: currentCountByConversation[sid] + 1,
      }));
    };

    client?.on('messageAdded', onMessageAdded);

    return () => {
      client?.off('messageAdded', onMessageAdded);
    };
  }, [client]);

  return getTotalCount(countByConversation);
};
