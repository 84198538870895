import styled from 'styled-components';

export const ProfileGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProfilePicturesContainer = styled.div`
  display: flex;
  margin: 0.25rem;
  flex-wrap: wrap;

  & > * {
    margin: -0.25rem;
  }
`;

export const TooltipLabel = styled.div`
  padding: 0.75rem 1rem;
  border: 1px solid ${({ theme }) => theme.deprecated_.colors.lightGray};
  border-radius: 4px;
  flex-shrink: 0;
  margin-left: 0.5rem;
`;
