import { math } from 'polished';
import styled from 'styled-components';

export const HorizontalScrollingContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-left: ${({ theme }) => math(`-${theme.deprecated_.sizeBasis} * 2.5`)};
  margin-right: ${({ theme }) => math(`-${theme.deprecated_.sizeBasis}`)};

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex-shrink: 0;
  }

  &:before,
  &:after {
    content: '';
    min-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  }

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
  }

  @media (min-width: ${(props) => props.theme.deprecated_.breakpoints.md}) {
    margin-left: 0;
    margin-right: 0;

    scrollbar-width: auto;
    &::-webkit-scrollbar {
      display: auto;
    }

    &:before,
    &:after {
      min-width: 1px;
    }
  }
`;
