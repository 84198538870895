import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form6';
import { useHistory } from 'react-router-dom';

import { logger } from '$services/logging';
import { type SetBannerMessage } from '$shared/hooks';
import setServerErrors from '$shared/utils/setServerErrors';

import {
  type CreateConversationMutation,
  type CreateConversationMutationVariables,
} from '../../../../../../graphql/__generated__/CreateConversationMutation';
import { MissingMutationDataError } from '../../../../../../graphql/errors';
import { CREATE_CONVERSATION_MUTATION } from '../../../../../../graphql/new-conversation-form';
import { routes } from '../../../../../../routes';
import { type NewConversationFormData } from '../types';

export const useNewConversationForm = (setBannerMessage: SetBannerMessage) => {
  const history = useHistory();

  const [createConversation, { loading: mutationLoading }] = useMutation<
    CreateConversationMutation,
    CreateConversationMutationVariables
  >(CREATE_CONVERSATION_MUTATION);

  const { control, handleSubmit, formState, setError, errors } = useForm<NewConversationFormData>({
    defaultValues: { participants: [] },
    mode: 'all',
  });

  const onSubmit = useCallback<SubmitHandler<NewConversationFormData>>(
    async (formData) => {
      try {
        const result = await createConversation({
          variables: {
            participantsPks: formData.participants.map((pk) => Number(pk.value)),
          },
        });

        const resultData = result.data?.createConversation;

        if (!resultData) {
          throw new MissingMutationDataError('CreateConversationMutation');
        }

        switch (resultData.__typename) {
          case 'ConversationType':
            history.push(routes.messages.conversation.url({ id: resultData.conversationSid }));
            return;
          case 'ConversationFormError':
            setBannerMessage({ type: 'error', message: resultData.message });
            setServerErrors<{ participants: string[] }>(resultData.fieldErrors, setError);
            return;
          default:
            throw new Error('Unknown result typename');
        }
      } catch (e) {
        logger.error(new Error('Error creating conversation', { cause: e }));
        setBannerMessage({ type: 'error', message: 'Something went wrong. Try again later.' });
      }
    },
    [createConversation, history, setBannerMessage, setError]
  );

  return { control, handleSubmit, formState, errors, mutationLoading, onSubmit };
};
