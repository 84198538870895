import { useCallback, useEffect, useState } from 'react';

import { type BannerVariant } from '@littleotter/legacy-components';

export type BannerMessage = {
  type?: BannerVariant;
  message?: string;
};

export type SetBannerMessage = (bannerMessage: BannerMessage | null) => void;

export const useBannerMessage = () => {
  const [bannerMessage, _setBannerMessage] = useState<BannerMessage>({});
  const [bannerIsShowing, setBannerIsShowing] = useState(false);

  useEffect(() => {
    if (bannerMessage.message) {
      setBannerIsShowing(true);
    }
  }, [bannerMessage]);

  const setBannerMessage = useCallback<SetBannerMessage>(
    (message) => (message !== null ? _setBannerMessage(message) : setBannerIsShowing(false)),
    []
  );

  return { bannerMessage, bannerIsShowing, setBannerMessage };
};
