import { math } from 'polished';
import styled from 'styled-components';

export const SampleBox = styled.div`
  min-width: 211px;
  height: 192px;
  background: ${({ theme }) => theme.deprecated_.colors.blueJeans};
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
`;

type HorizontalScrollContainerProps = {
  gap: number;
};

export const HorizontalScrollContainer = styled.div<HorizontalScrollContainerProps>`
  --spacing-h: ${({ theme }) => theme.deprecated_.sizeBasis};
  --spacing-v: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};

  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: ${({ theme, gap }) => math(`${theme.deprecated_.sizeBasis} * ${gap}`)};

  /*
    Add padding and equal horizontal negative margin so that shadows are visible outside
    the boundaries of the container element while still maintaining the same appearance.
  */
  padding: 0 var(--spacing-h) var(--spacing-v);
  margin: 15px 0 15px calc(var(--spacing-h) * -1);
`;
