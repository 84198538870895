import { darken, hideVisually, lighten } from 'polished';
import { type FC, type ReactNode } from 'react';
import { type FieldErrors } from 'react-hook-form6';
import styled from 'styled-components';

type TagStyleVariant = 'default' | 'outline';

export type CheckboxProps = {
  checked?: boolean;
  label: ReactNode;
  onChange?: (checked: boolean) => void;
  name: string;
  displayAsTag?: boolean;
  errors?: FieldErrors;
  tagStyleVariant?: TagStyleVariant;
};

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${hideVisually()}
`;

const CheckIcon = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  width: ${(props) => props.theme.deprecated_.sizeBasis};
  height: ${(props) => props.theme.deprecated_.sizeBasis};
  background: ${(props) => (props.checked ? props.theme.deprecated_.colors.text : '#FFF')};
  transition: all 0.2s linear;
  margin-right: ${(props) => `calc(${props.theme.deprecated_.sizeBasis} / 2)`};
  border-width: ${(props) => props.theme.deprecated_.border.size};
  border-style: ${(props) => props.theme.deprecated_.border.style};
  border-color: ${(props) => (props.checked ? 'transparent' : props.theme.deprecated_.border.color)};
  border-radius: ${(props) => props.theme.deprecated_.border.radius};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 3px ${(props) => darken(0.2, props.theme.deprecated_.colors.gray)};
  }
`;

export const StyledCheckboxLabel = styled.label<{
  displayAsTag: boolean;
  checked: boolean;
  tagStyleVariant: TagStyleVariant;
}>`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};

  ${({ displayAsTag, checked, theme, tagStyleVariant }) => {
    let backgroundColor;
    let borderColor;

    if (checked) {
      backgroundColor = {
        default: theme.deprecated_.colors.brandPurple,
        outline: theme.deprecated_.colors.lightPurple,
      }[tagStyleVariant];

      borderColor = {
        default: theme.deprecated_.colors.brandPurple,
        outline: theme.deprecated_.colors.darkPurple,
      }[tagStyleVariant];
    } else {
      backgroundColor = lighten(0.1, theme.deprecated_.colors.lightGray);
      borderColor = backgroundColor;
    }

    return (
      displayAsTag &&
      `
        transition: background-color 0.2s linear, border-color 0.2s linear;
        border-radius: ${theme.deprecated_.border.radius};
        padding: calc(0.7rem - 2px);
        background-color: ${backgroundColor};
        border: 2px solid ${borderColor};

        > ${CheckIcon} {
            display: none;
        }
    `
    );
  }}
`;

const StyledError = styled.small`
  color: ${(props) => `${props.theme.deprecated_.colors.red}`};
`;

export const Checkbox: FC<CheckboxProps> = ({
  checked = false,
  label,
  onChange,
  name,
  displayAsTag = false,
  errors,
  tagStyleVariant = 'default',
  ...props
}) => (
  <StyledCheckboxLabel displayAsTag={displayAsTag} checked={checked} tagStyleVariant={tagStyleVariant}>
    <HiddenCheckbox checked={checked} onChange={(e) => onChange && onChange(e.target.checked)} {...props} />
    <CheckIcon viewBox="0 0 24 24" checked={checked}>
      <polyline points="20 6 9 17 4 12" />
    </CheckIcon>
    <span style={{ verticalAlign: 'top' }}>{label}</span>
    <StyledError>{errors?.[name]?.message}</StyledError>
  </StyledCheckboxLabel>
);
