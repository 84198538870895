import styled from 'styled-components';

export const Root = styled.div<{ borderColor: string }>`
  border-radius: ${({ theme }) => theme.borderRadius['02']};
  border: 2px ${({ borderColor }) => borderColor} solid;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.spacing['02']};
  flex: 1 0 270px;
`;

export const HeaderBackground = styled.img<{ backgroundColor: string }>`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: url(#summaryContentPath);
  max-height: ${({ theme }) => theme.spacing['11']};
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor};
`;

export const HeaderIcon = styled.img`
  position: absolute;
  top: ${({ theme }) => theme.spacing['05']};
  right: ${({ theme }) => theme.spacing['04']};
  width: 64px;
`;

export const Title = styled.h5`
  margin-top: ${({ theme }) => theme.spacing['01']};
  margin-left: ${({ theme }) => theme.spacing['04']};
  margin-right: ${({ theme }) => theme.spacing['11']};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 1.2;
  letter-spacing: normal;
  color: white;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Content = styled.div`
  display: flex;
`;
