import { type Dispatch, type SetStateAction } from 'react';

import { type SelectableTagProps } from './SelectableTag';

export type FilterItem = {
  pk: number;
  name: string;
};

export const getToggleSelectedTagCallback =
  (data: FilterItem | undefined, setState: Dispatch<SetStateAction<FilterItem[]>>): SelectableTagProps['onSelect'] =>
  (isSelected) => {
    if (!data) {
      return;
    }

    setState((currentState) => {
      if (!isSelected) {
        return currentState.filter((selectedItem) => selectedItem.pk !== data.pk);
      }
      return [...currentState, data];
    });
  };

export const getFilterItems = <Data extends FilterItem>(data: Data[] | undefined): FilterItem[] =>
  data?.map((item) => ({
    pk: item.pk,
    name: item.name,
  })) ?? [];

export const orderAgeRanges = (ageRanges: FilterItem[]) => {
  const pkOrder = [19, 4, 10, 13, 24, 15, 27];
  return Array.from(ageRanges).sort((a, b) => pkOrder.indexOf(a.pk) - pkOrder.indexOf(b.pk));
};

export const orderTagsByPk = (tags: FilterItem[]) => Array.from(tags).sort((a, b) => a.pk - b.pk);
