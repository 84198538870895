import { parseISO } from 'date-fns';

import {
  type UpcomingAppointmentViewer_viewer_upcomingAppointments,
  type UpcomingAppointmentViewer_viewer_upcomingAppointments_provider,
  type UpcomingAppointmentViewer_viewer_upcomingAppointments_service,
} from '../../../../graphql/__generated__/UpcomingAppointmentViewer';
import { type UpcomingProvider, type UpcomingService, type UpcomingSession } from '../../types';

const gqlProviderToUpcomingProvider = (
  gqlProvider: UpcomingAppointmentViewer_viewer_upcomingAppointments_provider
): UpcomingProvider => {
  return {
    ...gqlProvider,
  };
};

export const gqlServiceToUpcomingService = (
  gqlService: UpcomingAppointmentViewer_viewer_upcomingAppointments_service
): UpcomingService => {
  return {
    ...gqlService,
  };
};

export const gqlSessionToUpcomingSession = (
  gqlSession: UpcomingAppointmentViewer_viewer_upcomingAppointments
): UpcomingSession => {
  return {
    ...gqlSession,
    startDatetime: parseISO(gqlSession.startDatetime),
    endDatetime: parseISO(gqlSession.endDatetime),
    service: gqlServiceToUpcomingService(gqlSession.service),
    provider: gqlProviderToUpcomingProvider(gqlSession.provider),
  };
};
