import { type FC, type PropsWithChildren } from 'react';

import {
  StyledTag,
  SubmissionHeaderButton,
  SubmissionHeaderButtonsContainer,
  SubmissionHeaderContainer,
  SubmissionHeaderItem,
  SubmissionHeaderTitle,
  SubmissionHeaderTitleContainer,
} from './styled';

export type SubmissionHeaderProps = {
  title: string;
  subject: string;
  age?: string;
  worries: string;
  seekingCare?: boolean;
  isChild: boolean;
  handleCopy: () => void;
  handleDetailedCopy: () => void;
};

export const SubmissionHeader: FC<PropsWithChildren<SubmissionHeaderProps>> = ({
  title,
  subject,
  age,
  worries,
  seekingCare,
  isChild,
  handleCopy,
  handleDetailedCopy,
}) => {
  return (
    <SubmissionHeaderContainer>
      <SubmissionHeaderTitleContainer>
        <SubmissionHeaderTitle>{title}</SubmissionHeaderTitle>

        {isChild && (
          <StyledTag textSize="0.75rem" backgroundColor={seekingCare ? 'lightGreen' : 'gray50'}>
            {seekingCare ? 'Seeking care' : 'Not seeking care'}
          </StyledTag>
        )}
      </SubmissionHeaderTitleContainer>
      {subject && <SubmissionHeaderItem>Subject: {subject}</SubmissionHeaderItem>}
      {age && <SubmissionHeaderItem>Age: {age}</SubmissionHeaderItem>}
      {worries && <SubmissionHeaderItem>Worries: {worries}</SubmissionHeaderItem>}
      <SubmissionHeaderButtonsContainer>
        <SubmissionHeaderButton variant="outline" size="smallest" onClick={handleCopy}>
          Copy Info
        </SubmissionHeaderButton>
        <SubmissionHeaderButton variant="outline" size="smallest" onClick={handleDetailedCopy}>
          Copy Detailed Info
        </SubmissionHeaderButton>
      </SubmissionHeaderButtonsContainer>
    </SubmissionHeaderContainer>
  );
};
