import { useQuery } from '@apollo/client';

import {
  type ProfileChildQuery,
  type ProfileChildQuery_child,
} from '../../../../graphql/__generated__/ProfileChildQuery';
import { PROFILE_CHILD_QUERY } from '../../../../graphql/profile';
import { useGraphQLErrorHandling } from '../../../hooks';

type UseChildInfo = (childId: string, isLoggedIn: boolean) => ProfileChildQuery_child | undefined;

/**
 * Based on a childId returns Child Info.
 * @param childId the id of the child in our system
 * @returns childInfo
 */
export const useChildInfo: UseChildInfo = (childId, isLoggedIn) => {
  const { data, error } = useQuery<ProfileChildQuery>(PROFILE_CHILD_QUERY, {
    variables: { id: Number(childId) },
    skip: !childId || !isLoggedIn,
  });
  useGraphQLErrorHandling(error);
  const childInfo = data?.child;

  return childInfo;
};
