import { type Conversation } from '@twilio/conversations';

import { type ConversationPreview } from '$shared/contexts/Conversations/conversationPreview';

/**
 * Represents an object in which the keys are conversation sids
 * and the values are the number of unread messages in that conversation.
 */
export type CountByConversation = Record<string, number>;

export const getCountByConversation = async (conversations: ConversationPreview[]): Promise<CountByConversation> =>
  conversations.reduce<Promise<CountByConversation>>(async (prevCountsPromise, conversation) => {
    const count = await getUnreadMessagesCount(conversation);

    // It's really important that the await for the previous result happens after all the awaits in the current iteration,
    // otherwise the reducer will run sequentially instead of in parallel.
    const prevCounts = await prevCountsPromise;

    return {
      ...prevCounts,
      [conversation.sid]: count,
    };
  }, Promise.resolve({}));

export const getTotalCount = (countByConversation: CountByConversation): number =>
  Object.keys(countByConversation).reduce((prevCount, currKey) => {
    return prevCount + countByConversation[currKey];
  }, 0);

export const getUnreadMessagesCount = async (conversation: ConversationPreview | Conversation): Promise<number> => {
  const { lastReadMessageIndex, lastMessage } = conversation;

  // Special case for when the viewer marks all messages as read in a conversation
  // Since this is the most significant scenario affected by getUnreadMessagesCount's cache
  if (lastMessage && lastReadMessageIndex === lastMessage.index) {
    return 0;
  }

  let count = await conversation.getUnreadMessagesCount();
  if (count === null) {
    count = await conversation.getMessagesCount();
  }
  return count;
};
