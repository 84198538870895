import { type FC, type PropsWithChildren } from 'react';

import { Modal } from '@littleotter/legacy-components';

import { NewConversationForm } from './NewConversationForm';

type NewConversationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const NewConversationModal: FC<PropsWithChildren<NewConversationModalProps>> = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onEscapeKeydown={onClose} onBackgroundClick={onClose} onCloseButtonClick={onClose}>
    <NewConversationForm />
  </Modal>
);
