export const firstRightVariants = {
  exit: {
    opacity: 0,
    x: '100%',
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.4,
      type: 'spring',
    },
  },
};

export const secondRightVariants = {
  ...firstRightVariants,
  enter: {
    ...firstRightVariants.enter,
    transition: {
      delay: 0.8,
      duration: 0.6,
      type: 'spring',
    },
  },
};
