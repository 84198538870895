import { gql, useQuery, type QueryHookOptions } from '@apollo/client';

import { type NextAppointmentQuery } from './__generated__/NextAppointmentQuery';

const NEXT_APPOINTMENT_QUERY = gql`
  query NextAppointmentQuery {
    viewer {
      id
      nextAppointment {
        id
        startDatetime
        provider {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const useNextAppointmentQuery = (options?: QueryHookOptions<NextAppointmentQuery>) =>
  useQuery<NextAppointmentQuery>(NEXT_APPOINTMENT_QUERY, options);
