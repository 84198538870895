import { AnimatePresence } from 'framer-motion';
import { type FC, type PropsWithChildren } from 'react';

import { PageNotFound } from '../../pages/PageNotFound';
import { AuthenticatedRoute } from '../AuthenticatedRoute';
import { TransitionSwitch, type TransitionSwitchProps } from '../TransitionSwitch';

export const RouteSwitch: FC<PropsWithChildren<TransitionSwitchProps>> = ({ children, ...props }) => (
  <TransitionSwitch {...props} render={({ children: _children }) => <AnimatePresence>{_children}</AnimatePresence>}>
    {children}

    <AuthenticatedRoute>
      <PageNotFound />
    </AuthenticatedRoute>
  </TransitionSwitch>
);
