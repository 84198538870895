import { Loading } from '@littleotter/zz-legacy-components';

import * as styled from './SmartBookKickoffCall.styled';

export interface SmartBookKickoffCallViewProps {
  viewModel: {
    instructions: {
      showLoader: boolean;
      showContent: boolean;
    };
    display: {
      title: string;
      content: string;
      buttonText: string;
    };
    effects: {
      onButtonClick: () => void;
    };
  };
}

export const SmartBookKickoffCallView = ({ viewModel }: SmartBookKickoffCallViewProps) => {
  if (viewModel.instructions.showLoader) {
    return (
      <styled.Root>
        <Loading />
      </styled.Root>
    );
  }

  if (!viewModel.instructions.showContent) {
    return null;
  }

  return (
    <styled.Root>
      <styled.Title>{viewModel.display.title}</styled.Title>
      <styled.Content>{viewModel.display.content}</styled.Content>
      <styled.StyledButton onClick={viewModel.effects.onButtonClick}>
        {viewModel.display.buttonText}
      </styled.StyledButton>
    </styled.Root>
  );
};
