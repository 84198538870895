import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import { IoIosLogOut } from 'react-icons/io';
import styled from 'styled-components';

import { Header, Link, Modal } from '@littleotter/legacy-components';

import { DJANGO_BACKEND_SERVICE } from '$configs/env';

import { MarginChildren } from '..';
import { routes } from '../../routes';
import { useInvalidSubscriptionErrors } from './hooks/useInvalidSubscriptionErrors';
import { LinkButtom } from './ModalContent';

const Content = styled.p`
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis}`)};
  word-wrap: break-word;
`;

const InlineForm = styled.form`
  display: inline;
`;
const Logout = styled.p`
  text-align: end;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.large};
`;
export const InvalidSubscriptionModal: FC<PropsWithChildren> = () => {
  const logoutRoute = routes.legacyLogout.url();
  const errors = useInvalidSubscriptionErrors();
  if (!errors.length) {
    return null;
  }

  return (
    <Modal isOpen>
      <MarginChildren>
        <Header as="h4">Invalid Subscription</Header>
        <Content>
          To continue using Little Otter please{' '}
          <InlineForm action={`${DJANGO_BACKEND_SERVICE.ROOT_URL}/stripe/customer_portal_session`} method="POST">
            <LinkButtom type="submit" text="pay your membership fee." />
          </InlineForm>{' '}
          If you have issues please contact:{' '}
          <Link href="mailto:hello@littleotterhealth.com" isAnchor>
            hello@littleotterhealth.com
          </Link>
        </Content>
        <Logout>
          <Link href={logoutRoute} variant="clearStyles" isAnchor>
            <IoIosLogOut />
          </Link>
        </Logout>
      </MarginChildren>
    </Modal>
  );
};
