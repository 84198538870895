import { gql } from '@apollo/client';

import { RESOURCES_FRAGMENT } from './resources-fragment';
import UPCOMING_APPOINTMENT_FRAGMENT from './upcoming-appointment-fragment';

export const CARE_HOME_QUERY = gql`
  query CareHomeQuery {
    viewer {
      id
      firstName
      isDemoAccount
      hasCompletedAppointments
      hasConfirmedAppointments
      asCaregiver {
        address {
          state {
            allowed
          }
        }
      }
      family {
        id
        children {
          id
          firstName
          lastName
          email
          dateOfBirth
          worryDomains {
            edges {
              node {
                id
                pk
                shortName
                name
              }
            }
          }
          ageTag {
            id
            pk
            name
          }
        }
        resourcesFromWorryDomains(first: 4) {
          ...ResourcesFragment
        }
        nextAppointment {
          ...UpcomingAppointment
        }
        careStatus {
          activelyInCare
          previouslyInCare
          everInCare
        }
        hasValidInsuranceAuthorizations
      }
    }
  }
  ${UPCOMING_APPOINTMENT_FRAGMENT}
  ${RESOURCES_FRAGMENT}
`;
