import { hideVisually } from 'polished';
import { type FC } from 'react';
import styled from 'styled-components';

import { StyledLabel } from '../styled';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${hideVisually()}
`;

type Option = {
  label: string;
  value: string | number;
};

export type MultiSelectionOptionsProps = {
  name: string;
  options: Option[];
  onOptionClick: (value: Option['value']) => void;
  selectedValues: Array<Option['value']>;
};

export const MultiSelectionOptions: FC<MultiSelectionOptionsProps> = ({ options, onOptionClick, selectedValues }) => (
  <div>
    {options.map(({ label, value }) => {
      const checked = selectedValues.includes(value);

      return (
        <StyledLabel key={value} isSelected={checked}>
          <HiddenCheckbox checked={checked} onChange={() => onOptionClick(value)} />
          <span style={{ verticalAlign: 'top' }}>{label}</span>
        </StyledLabel>
      );
    })}
  </div>
);
