import { type BaseRoute, type ExtendsAny, type Route } from './types';

type RouteProps = {
  path: string[];
  exact: boolean;
  strict: boolean;
};

// Extends a BaseRoute or a Route (which is structurally a BaseRoute too)
type RoutePropsFn = <Routes extends BaseRoute<Route<ExtendsAny>>[]>(...routes: Routes) => RouteProps;

/**
 * Helper to get the list of props from a `Route` object to be passed to a `<MetadataRoute>` component or similar.
 * Accepts a single route or multiple routes as additional arguments, in which case the path is combined.
 *
 * @example
 * ```tsx
 * const routes = {
 *   one: endRoute('/one'),
 *   two: endRoute('/two').
 * };
 *
 * <MetadataRoute {...routeProps(routes.one)}>
 * -> <MetadataRoute exact path={'/one'} strict={false} />
 *
 * <MetadataRoute {...routeProps(routes.one, routes.two)}>
 * -> <MetadataRoute exact path={['/one', '/two']} strict={false} />
 * ```
 */
export const routeProps: RoutePropsFn = (...routes) => {
  const matchingProps = routes.every((route) => route.exact === routes[0].exact && route.strict === routes[0].strict);
  if (!matchingProps) {
    throw new Error(
      "Passing multiple routes to 'routeProps' is only allowed when all of them share the same value for their 'exact' and 'strict' props."
    );
  }

  return routes.reduce((previousRouteProps, currentRoute) => {
    const props: RouteProps = {
      path: [...(previousRouteProps.path ?? []), currentRoute.path],
      exact: currentRoute.exact,
      strict: currentRoute.strict,
    };
    return props;
  }, {} as RouteProps);
};
