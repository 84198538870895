import { useApolloClient, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { type Option } from '@littleotter/legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { type ProviderMyClientsQuery } from '../../../../graphql/__generated__/ProviderMyClientsQuery';
import { type StaffUsersQuery } from '../../../../graphql/__generated__/StaffUsersQuery';
import { FmhcsSortBy, StaffFmhcsDocument } from '../../../../graphql/lo1/generated';
import { PROVIDER_MY_CLIENTS_QUERY, STAFF_USERS_QUERY } from '../../../../graphql/provider-home';
import { intakeQClientUrl, routes } from '../../../../routes';
import { type MyClientsTableDataItem } from '../../components/MyClientsTable/types';

export const useMyClientsTableData = (
  selectedProviderId: string | null,
  name: string | null,
  first: number,
  skip: number
) => {
  const {
    data,
    loading: myClientsLoading,
    error: myClientsError,
  } = useQuery<ProviderMyClientsQuery>(PROVIDER_MY_CLIENTS_QUERY, {
    variables: { providerId: Number(selectedProviderId), name, first, skip },
  });
  const {
    data: staffUsersData,
    loading: staffUsersLoading,
    error: staffUsersError,
  } = useQuery<StaffUsersQuery>(STAFF_USERS_QUERY);

  useGraphQLErrorHandling(myClientsError, staffUsersError);

  // cannot use LazyQuery multiple times: https://github.com/apollographql/apollo-client/issues/9755
  const gqlClient = useApolloClient();
  const fmhcMap = useMemo(
    () =>
      (data?.families ?? []).reduce(
        (m, family) => {
          const familyFmhcs =
            family.caregivers.length > 0
              ? family.caregivers.reduce(
                  (m2, caregiver) => {
                    const key = `${caregiver.firstName} ${caregiver.lastName} (${caregiver.id})`;
                    return {
                      ...m2,
                      [key]: new Promise<string | undefined>((resolve, reject) => {
                        return gqlClient
                          .query({
                            query: StaffFmhcsDocument,
                            variables: {
                              request: {
                                familyId: family.id,
                                submitterId: caregiver.id,
                                input: {
                                  skip: 0,
                                  limit: 1,
                                  sortBy: FmhcsSortBy.SubmissionTime,
                                  filter: {
                                    isSubmitted: true,
                                  },
                                },
                              },
                            },
                          })
                          .then((res) => res.data?.StaffFmhcs?.[0]?.id)
                          .then((fmhcId) => {
                            if (!fmhcId) {
                              throw new Error('no latest fmhc found');
                            }
                            resolve(fmhcId);
                          })
                          .catch((r) => reject(r));
                      }),
                    };
                  },
                  {} as { [key: string]: Promise<string | undefined> }
                )
              : {};
          return {
            ...m,
            [family.id]: familyFmhcs,
          };
        },
        {} as { [familyId: string]: { [key: string]: Promise<string | undefined> } }
      ),
    [data?.families, gqlClient]
  );
  const tableData: MyClientsTableDataItem[] = useMemo(
    () =>
      data?.families?.map<MyClientsTableDataItem>((family) => ({
        family: {
          id: family.id,
          name: family.name,
          code: family.code,
        },
        caregivers: family.caregivers.map((caregiver) => ({
          id: caregiver.id,
          name: `${caregiver.firstName} ${caregiver.lastName}`,
          profileLink: intakeQClientUrl(caregiver.externalClientUuid),
        })),
        children: family.children.map((child) => ({
          id: child.id,
          name: `${child.firstName} ${child.lastName}`,
          profileLink: intakeQClientUrl(child.externalClientUuid),
        })),
        providerIds: family.providers?.map((provider) => provider.id) ?? [],
        nextAppointment: family.nextAppointment
          ? {
              datetime: new Date(family.nextAppointment.startDatetime),
              providerId: family.nextAppointment.provider.id,
              providerName: `${family.nextAppointment.provider.firstName} ${family.nextAppointment.provider.lastName}`,
            }
          : undefined,
        conversationLink: family.conversationWithProvider
          ? routes.messages.conversation.url({ id: family.conversationWithProvider.conversationSid })
          : undefined,
        latestFmhcIds: fmhcMap[family.id] ?? undefined,
      })) ?? [],
    [data?.families, fmhcMap]
  );
  const totalCount = useMemo(() => {
    return data?.familiesTotalCount ? data.familiesTotalCount : 1;
  }, [data?.familiesTotalCount]);

  const providerSelectOptions = useMemo(() => {
    return (
      staffUsersData?.users.map<Option<string>>((provider) => ({
        label: `${provider.firstName} ${provider.lastName}`,
        value: provider.id,
      })) ?? []
    );
  }, [staffUsersData?.users]);

  return {
    tableData,
    totalCount,
    providerSelectOptions,
    viewer: staffUsersData?.viewer,
    myClientsLoading,
    myClientsError,
    staffUsersLoading,
    staffUsersError,
  };
};
