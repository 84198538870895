import { type FC, type PropsWithChildren } from 'react';

import { MyClients } from './MyClients';
import { ProviderHomeContainer } from './styled';
import { TodaysSchedule } from './TodaysSchedule';

export const ProviderHome: FC<PropsWithChildren> = () => (
  <ProviderHomeContainer>
    <TodaysSchedule />
    <MyClients />
  </ProviderHomeContainer>
);
