import { gql } from '@apollo/client';

export const WORRY_DOMAIN_CATEGORY_FRAGMENT = gql`
  fragment WorryDomainCategoryFragment on WorryDomainCategoryType {
    pk
    id
    name
  }
`;

export const WORRY_DOMAIN_FRAGMENT = gql`
  fragment WorryDomainFragment on WorryDomainType {
    pk
    id
    name
    shortName
    category {
      id
      ...WorryDomainCategoryFragment
    }
  }

  ${WORRY_DOMAIN_CATEGORY_FRAGMENT}
`;

export const WORRY_DOMAINS_CONNECTION_FRAGMENT = gql`
  fragment WorryDomainsConnectionFragment on WorryDomainTypeConnection {
    edges {
      node {
        ...WorryDomainFragment
      }
    }
  }

  ${WORRY_DOMAIN_FRAGMENT}
`;

export const VIEWER_FAMILY_CHILDREN_WORRY_DOMAINS_FRAGMENT = gql`
  fragment ViewerFamilyChildrenWorryDomainsFragment on UserType {
    id
    worryDomains {
      ...WorryDomainsConnectionFragment
    }
    family {
      id
      worryDomains {
        ...WorryDomainsConnectionFragment
      }
      children {
        id
        firstName
        worryDomains {
          ...WorryDomainsConnectionFragment
        }
      }
    }
  }

  ${WORRY_DOMAINS_CONNECTION_FRAGMENT}
`;
