import { type FC, type PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { routes } from '../../routes';
import { useViewer } from '../../shared/contexts/Viewer';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';

const pagesWithoutNav = [routes.paymentDetails.base, routes.authOnboarding.base, routes.child.base, routes.matchList];

export const NavBar: FC<PropsWithChildren> = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.deprecated_.breakpoints.md})`,
  });
  const { viewer, isInAllowedState, isStaffViewer, loading } = useViewer();
  const location = useLocation();

  if (loading) return null;

  const isOnUnauthenticatedRoute = routes.terms.test(location.pathname);

  const isOnPageWithoutNav = pagesWithoutNav.some((route) => route.test(location.pathname));

  const shouldShowNavigation = !!viewer && !isOnUnauthenticatedRoute && !isOnPageWithoutNav;

  if (!shouldShowNavigation) return null;

  if (isDesktop) return <DesktopNav isStaff={isStaffViewer} isInAllowedState={isInAllowedState} />;

  return <MobileNav isStaff={isStaffViewer} isInAllowedState={isInAllowedState} />;
};

export { MobileNavBuffer } from './MobileNav';
