import styled from 'styled-components';

export const AuthorizationTooltipContent = styled.div`
  max-width: 320px;
`;

export const IconAligner = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledForm = styled.form`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    width: auto;
  }

  > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;

export const FieldContainer = styled.div`
  width: 100%;
  display: ${(props) => (props.hidden ? `none` : `block`)};
`;
