import { useEffect, useState, type FC, type PropsWithChildren } from 'react';

import { useViewer } from '$shared/contexts/Viewer';

import { PageWideLoading } from '../../components/PageWideLoading';
import { Checkup } from './Checkup';
import { useStartFmhcSession } from './hooks/gql';
import { type CheckupStartProps } from './props';

/**
 * CheckupNew starts a new FMHC session and begins the FMHC with the new FMHC id
 */
export const CheckupNew: FC<PropsWithChildren<CheckupStartProps>> = (props) => {
  const { viewer, loading: viewerLoading } = useViewer();
  const [startFmhcSession, fmhcIdLoading] = useStartFmhcSession();
  const isLoading = fmhcIdLoading || viewerLoading;
  const [fmhcId, setFmhcId] = useState('');
  const { id: viewerId, firstName: viewerFirstName, lastName: viewerLastName, family: viewerFamily } = viewer ?? {};
  const { id: viewerFamilyId } = viewerFamily ?? {};
  const formattedViewerName = viewerLastName && viewerFirstName ? `${viewerLastName}, ${viewerFirstName}` : undefined;
  useEffect(() => {
    (async () => {
      if (!viewerFamilyId || !viewerId || !formattedViewerName) return;
      const newFmhcId = await startFmhcSession(viewerFamilyId, viewerId, formattedViewerName);
      setFmhcId(newFmhcId);
    })();
  }, [startFmhcSession, viewerId, viewerFamilyId, formattedViewerName]);

  if (isLoading || fmhcId === '') {
    return <PageWideLoading />;
  }
  return <Checkup {...props} fmhcId={fmhcId} />;
};
