import { type FC, type PropsWithChildren } from 'react';
import { RiAddLine } from 'react-icons/ri';
import styled from 'styled-components';

import { IconLinkRow, IconLinkRowElement } from '../../styled';
import { CircleIcon } from './components';

const TextSpan = styled.span`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;

export const AddChildButton: FC<PropsWithChildren<{ disabled?: boolean; onClick?: () => void }>> = ({
  disabled,
  onClick,
}) => {
  // styled divs required because vertical-align not working with FaPlusCircle
  const color = disabled ? `rgba(0, 0, 0, 0.3)` : `#276ebe`;

  return (
    <IconLinkRow onClick={disabled ? () => undefined : onClick}>
      <IconLinkRowElement style={{ color }}>
        <CircleIcon size={36}>
          <RiAddLine size={32} />
        </CircleIcon>
      </IconLinkRowElement>
      <IconLinkRowElement style={{ color }}>
        <TextSpan>Add another child</TextSpan>
      </IconLinkRowElement>
    </IconLinkRow>
  );
};
