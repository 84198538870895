import { math } from 'polished';
import styled, { type DefaultTheme } from 'styled-components';

type CategorizationProps = {
  color: keyof DefaultTheme['deprecated_']['colors'];
};

export const StyledCategorization = styled.span<CategorizationProps>`
  color: ${({ theme, color }) => theme.deprecated_.colors[color]};
`;

export const TextContainer = styled.p`
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.75`)};
`;

export const Separator = styled.span.attrs({ children: '•' })`
  display: inline-block;
  margin: 0 ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;
