import { type FC, type PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { Route, type RouteProps } from 'react-router-dom';

import titleCase from '$shared/utils/strings';

import { MotionElement } from './styled';

const getTitle = (path?: RouteProps['path'], title?: string) => {
  const appTitle = 'Little Otter';

  const pathStr: string = Array.isArray(path) ? path[0] : (path ?? '');
  const pathSegments = pathStr
    .replace(/^\//, '')
    .split('/')
    .filter((route) => route && !route.startsWith(':'))
    .map((route, index, list) => (title && index === list.length - 1 ? title : route.replace('-', ' ')));

  if (title && !pathSegments.length) {
    pathSegments.push(title);
  }

  return [appTitle, ...pathSegments].join(' | ');
};

export type MetadataRouteProps = RouteProps & {
  title?: string;
  fullHeight?: boolean;
  withoutMobileNavbar?: boolean;
};

export const MetadataRoute: FC<PropsWithChildren<MetadataRouteProps>> = ({
  fullHeight,
  withoutMobileNavbar,
  ...props
}) => {
  const { path, children, title: titleProp } = props;
  const title = getTitle(path, titleProp);

  return (
    <MotionElement
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: 'easeOut' }}
      $fullHeight={fullHeight}
      $withoutMobileNavbar={withoutMobileNavbar}
    >
      <Helmet>
        <title>{titleCase(title)}</title>
      </Helmet>
      <Route {...props}>{children}</Route>
    </MotionElement>
  );
};
