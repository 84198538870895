import { darken, math } from 'polished';
import { css } from 'styled-components';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'underline'
  | 'brandPurple'
  | 'textBlue'
  | 'clearStyles'
  | 'link'
  | 'whiteLink'
  | 'outline'
  | 'outlineBold';

export type ButtonSize = 'smallest' | 'small' | 'large' | 'stretch' | 'full-stretch';

const getColorStylesFromVariant = (variant?: ButtonVariant) => {
  if (variant === 'secondary') {
    return css`
      background-color: ${({ theme }) => theme.deprecated_.colors.green};
      color: ${({ theme }) => theme.deprecated_.colors.white};

      &:active:not(:disabled) {
        background-color: ${({ theme }) => darken(0.1, theme.deprecated_.colors.green)};
      }
    `;
  }
  if (variant === 'tertiary') {
    return css`
      background-color: ${({ theme }) => theme.deprecated_.colors.lightestGray};
      color: ${({ theme }) => theme.deprecated_.colors.gray};

      &:active:not(:disabled) {
        background-color: ${({ theme }) => darken(0.1, theme.deprecated_.colors.lightestGray)};
      }
    `;
  }
  if (variant === 'underline') {
    return css`
      background-color: transparent;
      color: ${({ theme }) => theme.deprecated_.colors.black};

      &:hover {
        text-decoration: underline;
        box-shadow: none;
      }

      &:disabled {
        background-color: transparent;
        color: gray;
      }
    `;
  }
  if (variant === 'outline') {
    return css`
      background-color: ${({ theme }) => theme.deprecated_.colors.white};
      color: ${({ theme }) => theme.deprecated_.colors.textBlue};
      border: 1px solid;

      active:not(:disabled) {
        background-color: ${({ theme }) => darken(0.1, theme.deprecated_.colors.lightestGray)};
      }
    `;
  }
  if (variant === 'outlineBold') {
    return css`
      background-color: ${({ theme }) => theme.deprecated_.colors.white};
      color: ${({ theme }) => theme.deprecated_.colors.backgroundBlue};
      border: ${({ theme }) =>
        `${math(`${theme.deprecated_.sizeBasis} * 0.125`)} solid ${theme.deprecated_.colors.backgroundBlue}`};
      font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};

      active:not(:disabled) {
        background-color: ${({ theme }) => darken(0.1, theme.deprecated_.colors.lightestGray)};
      }
    `;
  }
  if (variant === 'brandPurple') {
    return css`
      background-color: ${({ theme }) => theme.deprecated_.colors.brandPurple};
      color: ${({ theme }) => theme.deprecated_.colors.text};

      &:active:not(:disabled) {
        background-color: ${({ theme }) => darken(0.1, theme.deprecated_.colors.brandPurple)};
      }
    `;
  }
  if (variant === 'textBlue') {
    return css`
      background-color: ${({ theme }) => theme.deprecated_.colors.textBlue};
      color: ${({ theme }) => theme.deprecated_.colors.white};

      &:active:not(:disabled) {
        background-color: ${({ theme }) => darken(0.1, theme.deprecated_.colors.textBlue)};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.deprecated_.colors.lighterBackgroundBlue};
        color: ${({ theme }) => theme.deprecated_.colors.white};
      }
    `;
  }
  return css`
    background-color: ${({ theme }) => theme.deprecated_.colors.text};
    color: ${({ theme }) => theme.deprecated_.colors.white};

    &:active:not(:disabled) {
      background-color: ${({ theme }) => darken(0.1, theme.deprecated_.colors.text)};
    }
  `;
};

const getSizeStyles = (size?: ButtonSize) => {
  if (size === 'small') {
    return css`
      font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
      padding: ${({ theme }) => theme.deprecated_.fontSizes.small};
    `;
  }
  if (size === 'smallest') {
    return css`
      font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
      padding: ${({ theme }) => theme.deprecated_.fontSizes.smallest};
      font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
    `;
  }
  if (size === 'stretch' || size === 'full-stretch') {
    return css`
      padding: ${({ theme }) => theme.deprecated_.sizeBasis};
      width: 100%;

      @media (min-width: 750px) {
        margin: 0 auto;
        max-width: ${size === 'stretch' ? '250px' : 'none'};
      }
    `;
  }
  return css`
    padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  `;
};

export type ButtonStylesProps = {
  $variant?: ButtonVariant;
  $size?: ButtonSize;
  iconOnly?: boolean;
};

export const buttonStyles = css<ButtonStylesProps>`
  border-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  min-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 10`)};
  border: ${({ theme }) => `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} transparent`};
  color: #000;
  cursor: pointer;
  transition:
    box-shadow 0.2s linear,
    background-color 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 4px 8px ${({ theme }) => theme.deprecated_.colors.lightGray};
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.deprecated_.colors.lightGray};
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
    border-color: transparent;
    color: ${({ theme }) => darken(0.2, theme.deprecated_.colors.gray)};
  }

  ${({ iconOnly }) =>
    !iconOnly &&
    css`
      svg {
        margin-right: 5px;
      }
    `}

  ${({ $variant }) => getColorStylesFromVariant($variant)}
  ${({ $size }) => getSizeStyles($size)}
`;
