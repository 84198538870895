import styled from 'styled-components';

import worriesAnxiety from './assets/WorriesAnxiety.svg';

export const ModalContent = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
`;

export const StyledImage = styled.img.attrs({
  src: worriesAnxiety,
})`
  margin-bottom: 15px;
`;

export const StyledDescription = styled.p`
  color: ${({ theme }) => theme.deprecated_.colors.dimGray};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  white-space: break-spaces;
  margin: 24px 0;
`;

export const StyledActionsContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 32px 40px;
  text-align: center;
  background: ${({ theme }) => theme.deprecated_.colors.white};

  @media (max-height: 670px) {
    position: relative;
    padding: 0;
    margin-bottom: 80px;
  }

  @media (min-width: 750px) {
    position: relative;
    max-width: 350px;
    margin-top: 50px;
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.deprecated_.colors.textBlue};
  display: block;
  margin-top: 24px;
  text-decoration: underline;
  cursor: pointer;
`;
