import { type FC, type PropsWithChildren } from 'react';

import { type DomainReportContext } from '../../../../../graphql/lo1/generated';
import { parseFullMarkdown } from '../../../../FmhcCheckup/utils/markdownParser';
import { StyledContextContent, StyledHtmlContainer, StyledTitle } from './styles';

export const ContextContent: FC<PropsWithChildren<DomainReportContext>> = ({ content, contextType }) => {
  let title = 'Context Text';

  switch (contextType) {
    case 'Explanation':
      title = 'What does this mean?';
      break;

    case 'CallToAction':
      title = 'What can I do?';
      break;

    default:
      break;
  }

  return (
    <StyledContextContent>
      <StyledTitle>{title}</StyledTitle>
      <StyledHtmlContainer content={parseFullMarkdown({ template: content })} />
    </StyledContextContent>
  );
};
