import { type FC, type SyntheticEvent } from 'react';

import { Link } from '../Link';
import { DisabledLink, StyledContent } from './styled';

export type _Pagination = {
  offset: number;
  limit: number;
  total: number;
  onPrevPage?: () => void;
  onNextPage?: () => void;
};

export const initialPagination = {
  offset: 0,
  limit: 10,
  total: 0,
};

export const Pagination: FC<_Pagination> = ({ offset, limit, total, onPrevPage, onNextPage }) => {
  const from = offset + 1;
  const to = offset + limit;
  const prevDisabled = offset === 0;
  const nextDisabled = offset + limit >= total;

  const handlePrevPage = (e: SyntheticEvent) => {
    e.preventDefault();
    if (onPrevPage) {
      onPrevPage();
    }
  };

  const handleNextPage = (e: SyntheticEvent) => {
    e.preventDefault();
    if (onNextPage) {
      onNextPage();
    }
  };

  return (
    <StyledContent>
      <div>{prevDisabled ? <DisabledLink>Previous</DisabledLink> : <Link onClick={handlePrevPage}>Previous</Link>}</div>
      <div>{`Showing from ${from} to ${to} of ${total}`}</div>
      <div>{nextDisabled ? <DisabledLink>Next</DisabledLink> : <Link onClick={handleNextPage}>Next</Link>}</div>
    </StyledContent>
  );
};
