type GenderTerms = {
  possessive: string;
  subjective: string;
  objective: string;
};

export const getPronounsFromGender = (gender: string | null): GenderTerms => {
  if (gender === 'male') {
    return {
      possessive: 'his',
      subjective: 'he',
      objective: 'him',
    };
  }
  if (gender === 'female') {
    return {
      possessive: 'her',
      subjective: 'she',
      objective: 'her',
    };
  }
  return {
    possessive: 'their',
    subjective: 'they',
    objective: 'them',
  };
};
