import { type FC } from 'react';

import { ProgressBarAmount, ProgressBarContainer } from './styled';

export type ProgressBarProps = {
  progress: number;
};

export const ProgressBar: FC<ProgressBarProps> = ({ progress }) => (
  <ProgressBarContainer>
    <ProgressBarAmount percentComplete={progress} />
  </ProgressBarContainer>
);
