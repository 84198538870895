import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { routes } from '../../../../routes';

const NOTIFICATION_AUDIO = new Audio(
  'https://littleotter-assets.s3.us-west-2.amazonaws.com/audio/notification-sound.mp3'
);

const NOTIFICATION_TIMEOUT = 15_000;

type ShowNotificationOptions = {
  authorName: string;
  messageBody: string;
  conversationSid: string;
};

type ShowNotification = (options: ShowNotificationOptions) => void;

type UseNotificationResult = ShowNotification;

type UseNotification = () => UseNotificationResult;

export const useNotification: UseNotification = () => {
  const history = useHistory();

  const showNotification = useCallback<ShowNotification>(
    ({ authorName, conversationSid, messageBody }) => {
      if (!authorName || !conversationSid) {
        return;
      }

      const notification = new Notification(`Message from ${authorName}`, {
        body: messageBody,
        data: {
          id: conversationSid,
        },
      });

      notification.addEventListener('show', () => {
        NOTIFICATION_AUDIO.play();
      });

      // When user clicks the notification we send it to the conversation
      notification.addEventListener('click', (e) => {
        const target = e.target as Notification;
        const conversationId = target?.data?.id || '';
        history.push(routes.messages.conversation.url({ id: conversationId }));
      });

      // Close notification after 15 seconds
      setTimeout(() => {
        notification.close();
      }, NOTIFICATION_TIMEOUT);
    },
    [history]
  );

  return showNotification;
};
