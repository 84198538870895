import type { LDClient } from 'launchdarkly-js-client-sdk';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { type BaseFlagObject } from '../types';

// Modifies the return type of the useLDClient hook to have the correct flag types
interface FeatureFlagsClient<T extends BaseFlagObject> extends Omit<LDClient, 'allFlags'> {
  allFlags: () => T;
}

/**
 * A factory function that wraps the `useLDClient` hook from the LaunchDarkly React SDK, providing
 * us the ability to work directly with the launch darkly client.
 *
 * @returns A correctly typed instance of the `useLDClient` hook from the LaunchDarkly React SDK.
 */
export const createUseClientHook = <T extends BaseFlagObject>() => {
  return useLDClient as () => FeatureFlagsClient<T>;
};
