import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useGraphQLErrorHandling } from '$shared/hooks';

import {
  type ProviderFamilyAppointmentsQuery,
  type ProviderFamilyAppointmentsQueryVariables,
} from '../../../../graphql/__generated__/ProviderFamilyAppointmentsQuery';
import { PROVIDER_FAMILY_APPOINTMENTS_QUERY } from '../../../../graphql/provider-family-details';
import { type AppointmentsTableDataItem } from '../components';

export const useAppointmentsTableData = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<ProviderFamilyAppointmentsQuery, ProviderFamilyAppointmentsQueryVariables>(
    PROVIDER_FAMILY_APPOINTMENTS_QUERY,
    {
      variables: { familyId: Number(id) },
    }
  );
  useGraphQLErrorHandling(error);

  const tableData: AppointmentsTableDataItem[] = useMemo(
    () =>
      data?.family.upcomingAppointments.map<AppointmentsTableDataItem>((appointment) => ({
        appointment: {
          startDatetime: new Date(appointment.startDatetime),
          appointmentId: appointment.id,
        },
        type: appointment.service.name,
        providerName: `${appointment.provider.firstName} ${appointment.provider.lastName}`,
        zoomLink: appointment.zoomLink ?? '-',
      })) ?? [],
    [data?.family.upcomingAppointments]
  );

  return { tableData, data, loading, error };
};
