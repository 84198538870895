import styled from 'styled-components';

import { Link } from '@littleotter/legacy-components';

import { linkSpanStyle } from '../NextStepsRow/CheckRow/styled';

export const RowsContainer = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const DescriptionContainer = styled.div`
  padding-top: 8px;
`;

export const ButtonAndLinkContainer = styled.div`
  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const FullLink = styled(Link)`
  width: 100%;
`;

export const SecondaryLink = styled(Link)`
  ${linkSpanStyle}
`;

export const Description = styled.p`
  color: ${({ theme }) => `${theme.deprecated_.colors.text}CC`}; /* 80% opacity */
`;
