import { sanitize } from 'dompurify';
import { math } from 'polished';
import { type FC } from 'react';
import styled, { css } from 'styled-components';

import { headerSizeFromType, headerWeightFromType } from '../Header';
import { linkStyles } from '../Link';
import { tableStyles } from '../Table';

const getHeadingStyles = (type: 'h1' | 'h2' | 'h3' | 'h4') => css`
  font-family: ${({ theme }) =>
    type === 'h4' ? theme.deprecated_.fontFamilies.body : theme.deprecated_.fontFamilies.header};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes[headerSizeFromType[type]]};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights[headerWeightFromType[type]]};
`;

const StyledContainer = styled.div`
  ul {
    list-style-type: disc;
    padding-inline-start: 2rem;
  }

  ol {
    list-style-type: decimal;
    padding-inline-start: 2rem;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
  }

  h1 {
    ${getHeadingStyles('h1')}
  }

  h2 {
    ${getHeadingStyles('h2')}
  }

  h3 {
    ${getHeadingStyles('h3')}
  }

  h4 {
    ${getHeadingStyles('h4')}
  }

  a {
    ${linkStyles}
  }

  p {
    margin-block-start: ${({ theme }) => theme.deprecated_.sizeBasis};
    margin-block-end: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.3`)};
  }

  table {
    ${tableStyles}
  }

  em,
  i {
    font-style: italic;
  }
`;

type HTMLContainerProps = {
  content: string;
  className?: string;
};

export const HTMLContainer: FC<HTMLContainerProps> = ({ content, className }) => (
  <StyledContainer className={className} dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
);
