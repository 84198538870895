import { math } from 'polished';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import styled from 'styled-components';

import { Logo } from '../Logo';

type StyledNavbarProps = {
  $dark: boolean;
  $removeShadow: boolean;
  $bigHeight: boolean;
  $hasExtraIosPadding: boolean;
};

export const StyledNavbar = styled.div<StyledNavbarProps>`
  height: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
    $bigHeight,
    $hasExtraIosPadding,
  }) => math(`${sizeBasis} * ${$bigHeight ? '4' : '2.75'} + ${$hasExtraIosPadding ? '2rem' : '0'}`)};
  padding-top: ${({ $hasExtraIosPadding }) => ($hasExtraIosPadding ? '2rem' : '0')};
  font-size: ${({
    theme: {
      deprecated_: { fontSizes },
    },
  }) => fontSizes.regular};
  position: relative;
  font-weight: ${({
    theme: {
      deprecated_: { fontWeights },
    },
  }) => fontWeights.bold};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme, $dark, $removeShadow }) =>
    $dark
      ? `
      ${
        $removeShadow
          ? `box-shadow: none;`
          : `box-shadow: 0 ${math(`${theme.deprecated_.sizeBasis} * 0.25`)} ${math(
              `${theme.deprecated_.sizeBasis} * 0.25`
            )} 0 ${theme.deprecated_.colors.black}40;`
      }
    background-color: ${theme.deprecated_.colors.text};
    color: ${theme.deprecated_.colors.white};
    `
      : `
    background-color: ${theme.deprecated_.colors.white};
    border-top: 1px solid ${theme.deprecated_.colors.lightestGray};
    border-bottom: 1px solid ${theme.deprecated_.colors.lightestGray};
  `}
`;

type StyledBackArrowProps = {
  $dark: boolean;
  $hasExtraIosPadding: boolean;
};

export const StyledBackArrow = styled(FaLongArrowAltLeft)<StyledBackArrowProps>`
  width: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`${sizeBasis} * 1.31`)};
  height: 100%;
  padding-top: ${({ $hasExtraIosPadding }) => ($hasExtraIosPadding ? '2rem' : '0')};
  position: absolute;
  left: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`${sizeBasis} * 1.375`)};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({ theme, $dark }) => ($dark ? theme.deprecated_.colors.white : theme.deprecated_.colors.dimGray)};
  z-index: 999;
`;

export const StyledLogo = styled(Logo)<{
  $showLogo?: boolean;
}>`
  height: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`${sizeBasis} * 1.31`)};
  width: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`${sizeBasis} * 1.31`)};
  margin-right: ${({
    theme: {
      deprecated_: { sizeBasis },
    },
  }) => math(`${sizeBasis} * 0.5`)};
  ${({ $showLogo }) => !$showLogo && `display: none;`}
`;
