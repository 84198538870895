import { type FC, type PropsWithChildren } from 'react';

import { useBannerMessage } from '$shared/hooks';
import { routeProps } from '$shared/utils/routes';
import { type Route } from '$shared/utils/routes/types';

import { MetadataRoute } from '../../components/MetadataRoute';
import { Page } from '../../components/Page';
import { RouteSwitch } from '../../components/RouteSwitch';
import { routes } from '../../routes';
import { WorryDomains } from './Pages';

type OnboardingRouteProps = {
  route: Route;
};

/**
 * Wraps onboarding route FC with back button + logo header
 */
const OnboardingRoute: FC<PropsWithChildren<OnboardingRouteProps>> = ({ route, children }) => (
  <MetadataRoute {...routeProps(route)} withoutMobileNavbar fullHeight key={route.path}>
    <Page hasHeader hasBackButton hasLogo>
      {children}
    </Page>
  </MetadataRoute>
);

export const AuthOnboarding: FC<PropsWithChildren> = () => {
  const { setBannerMessage } = useBannerMessage();
  const onboardingRoutes = [
    {
      route: routes.authOnboarding.worryDomains,
      component: <WorryDomains setBannerMessage={setBannerMessage} />,
    },
  ];
  return (
    <>
      <RouteSwitch>
        {onboardingRoutes.map(({ route, component }) => OnboardingRoute({ route, children: component }))}
      </RouteSwitch>
    </>
  );
};
