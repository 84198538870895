import { useCallback } from 'react';

import { STORAGE_KEY, useSessionStorage } from './useStorage';

export const usePartnership = (): {
  partnership: string;
  setPartnership: (partnership: string | undefined) => void;
  removePartnership: () => void;
  partnershipId: number;
  setPartnershipId: (partnershipId: number | undefined) => void;
  removePartnershipId: () => void;
} => {
  const [partnership, _setPartnership, removePartnership] = useSessionStorage(STORAGE_KEY.PARTNERSHIP, 'none');
  const [partnershipId, _setPartnershipId, removePartnershipId] = useSessionStorage(STORAGE_KEY.PARTNERSHIP_ID, 0);

  const setPartnership = useCallback(
    (p: string | undefined) => {
      _setPartnership(p ?? 'none');
    },
    [_setPartnership]
  );

  const setPartnershipId = useCallback(
    (p: number | undefined) => {
      _setPartnershipId(p ?? 0);
    },
    [_setPartnershipId]
  );

  return { partnership, setPartnership, removePartnership, partnershipId, setPartnershipId, removePartnershipId };
};
