import { css } from 'styled-components';

export const ConnectedCircleListStyle = css`
  --decoration-size: 0.8em;
  --decoration-color: ${({ theme }) => theme.deprecated_.colors.white};
  --decoration-border-color: ${({ theme }) => theme.deprecated_.colors.slateGray};
  --decoration-border-width: 1px;

  --decoration-line-width: 1px;
  --decoration-line-dash-length: 4px;
  --decoration-line-gap-length: 4px;
  --decoration-line-color: ${({ theme }) => theme.deprecated_.colors.slateGray};
`;
