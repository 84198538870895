import { gql } from '@apollo/client';

export const GET_TERMS_AND_POLICIES_LIST = gql`
  query GetTermsAndPoliciesList {
    termsAndPoliciesList {
      pk
    }
  }
`;

export const TERMS_AND_POLICIES_FRAGMENT = gql`
  fragment TermsAndPoliciesFragment on TermsAndPoliciesType {
    pk
    dateActive
    info
    name
    text
  }
`;

export const GET_TERMS_AND_POLICIES = gql`
  query GetTermsAndPolicies($slug: String!) {
    termsAndPolicies(slug: $slug) {
      ...TermsAndPoliciesFragment
    }
  }

  ${TERMS_AND_POLICIES_FRAGMENT}
`;

export const GET_TERMS_AND_POLICIES_NOT_ACCEPTED_LIST = gql`
  query GetTermsAndPoliciesNotAcceptedList {
    termsAndPoliciesNotAcceptedList {
      ...TermsAndPoliciesFragment
    }
  }

  ${TERMS_AND_POLICIES_FRAGMENT}
`;

export const ACCEPT_TERMS_AND_POLICIES = gql`
  mutation AcceptTermsAndPolicies($input: UserTermConditionsMutationInput!) {
    updateTermsAndPolicies(input: $input) {
      ok
    }
  }
`;
