import { serviceLocations, type TzName } from './locations';

type TimezonesByAbbreviation = {
  [abbreviation: string]: TzName;
};

const timezonesByAbbreviation = serviceLocations.reduce<TimezonesByAbbreviation>(
  (acc, loc) => ({
    ...acc,
    [loc.abbreviation]: loc.timezone as TzName,
  }),
  {}
);

export const retrieveTimezoneByAbbreviation: (abbreviation: string) => string = (abbreviation) => {
  return timezonesByAbbreviation[abbreviation];
};
