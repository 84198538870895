import { type FC, type PropsWithChildren } from 'react';

import { CardContainer, CardTitle } from './styled';

export type FamilyDetailsCardProps = {
  title: string;
  className?: string;
};

export const FamilyDetailsCard: FC<PropsWithChildren<FamilyDetailsCardProps>> = ({ title, children, className }) => (
  <CardContainer>
    <CardTitle>{title}</CardTitle>
    <div className={className}>{children}</div>
  </CardContainer>
);
