import { Capacitor } from '@capacitor/core';
import { motion } from 'framer-motion';
import { math } from 'polished';
import styled from 'styled-components';

import { Message } from './Message';
import { type MessageProps } from './Message/Message';

const isOniOS = Capacitor.getPlatform() === 'ios';
export const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * ${isOniOS ? '7.5' : '4.5'}`)});
  margin: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * -5`)}
    ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * -1`)};
`;
type StyledListProps = {
  conversationClosed?: boolean;
};
export const StyledList = styled(motion.ol)<StyledListProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  padding-bottom: ${({ conversationClosed }) => (conversationClosed ? '120px' : ({ theme }) => theme.spacing['10'])};
  margin-bottom: 0;
  height: ${({ theme }) => `calc(100vh - ${theme.deprecated_.sizeBasis} * 9)`};
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  * {
    overflow-anchor: none;
  }

  > li:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;
type StyledMessageProps = MessageProps & {
  isAnchored?: boolean;
};
export const StyledMessage = styled(Message)<StyledMessageProps>`
  overflow-anchor: ${({ isAnchored }) => (isAnchored ? 'auto' : 'none')};
`;
export type AnchorProps = {
  enabled?: boolean;
};
export const AnchorItem = styled.li<AnchorProps>`
  overflow-anchor: ${({ enabled }) => (enabled ? 'auto' : 'none')};
  margin-top: -1px;

  /* We need the element to have a height for overflow-anchor to work */
  > div {
    height: 1px;
  }
`;

export const OutOfOfficeLabel = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.darkestPurple};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-align: center;
  padding-bottom: ${({ theme }) => theme.spacing['04']};
`;

export const BottomContainer = styled.div`
  position: fixed;
  background: ${({ theme }) => theme.deprecated_.colors.white};
  width: 100%;
  /* The bottom value is the same value as the mobile nav height (bizarre) */
  bottom: max(
    calc(env(safe-area-inset-bottom) + ${({ theme }) => math(`3.5 * ${theme.deprecated_.sizeBasis}`)}),
    ${({ theme }) => math(`4.5 * ${theme.deprecated_.sizeBasis}`)}
  );

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    position: relative;
  }
`;
