import { gql } from '@apollo/client';

export const SEARCH_USER_QUERY = gql`
  query SearchUsersQuery($searchTerm: String!) {
    accountsNamespace {
      searchUsers(searchTerm: $searchTerm) {
        users {
          id
          firstName
          lastName
          email
          intakeqClientId
        }
      }
    }
  }
`;

export const CREATE_CONVERSATION_MUTATION = gql`
  mutation CreateConversationMutation($participantsPks: [Int!]!) {
    createConversation(participantsPks: $participantsPks) {
      ... on ConversationType {
        conversationSid
      }
      ... on ConversationFormError {
        message
        fieldErrors {
          participants
        }
      }
    }
  }
`;
