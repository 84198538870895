import { type FC, type PropsWithChildren } from 'react';

import { SubTitle, Title } from './styled';

export type SummarySectionReportProps = {
  numberOfQuestions: number;
};

export const SummarySectionReport: FC<PropsWithChildren<SummarySectionReportProps>> = ({ numberOfQuestions }) => (
  <>
    <Title>Summary</Title>
    <SubTitle>
      These findings are based on the <strong>{numberOfQuestions} question</strong> checkup you completed about your
      family.
    </SubTitle>
  </>
);
