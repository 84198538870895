import { ErrorBoundary } from '@sentry/react';

import { type IEnvironmentService } from '../../environment/types';
import { SentryReactLogHandler } from './log-handler/SentryReactLogHandler';
import { PlatformSentryReactInitializer, type SentryReactServiceConfig } from './PlatformSentryReactInitializer';

/**
 * Creates a React-specific Sentry instance and exposes the tooling needed to integrate Sentry into our React applications.
 */
export const createSentryReactInitializer = (env: IEnvironmentService, configOverrides?: SentryReactServiceConfig) => {
  const sentry = new PlatformSentryReactInitializer(env, configOverrides);
  const SentryErrorBoundary = ErrorBoundary;
  const SentryLogHandler = SentryReactLogHandler;

  return { sentry, SentryErrorBoundary, SentryLogHandler };
};
