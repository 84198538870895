import { motion } from 'framer-motion';
import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Link } from '@littleotter/legacy-components';

import { useConversations } from '$shared/contexts/Conversations';

import { routes } from '../../routes';

const listVariants = {
  open: {
    opacity: 1,
    x: 0,
    transition: { staggerChildren: 0.05, delayChildren: 0.2 },
  },
  closed: {
    opacity: 0,
    x: '100%',
    transition: { when: 'afterChildren', staggerChildren: 0.02, staggerDirection: -1 },
  },
};

const listItemVariants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '100%' },
};

const StyledNavLinks = styled(motion.ul)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
  border-left: ${({ theme }) =>
    `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.border.color}`};

  li {
    width: 100%;
    text-align: center;
    border-bottom: ${({ theme }) =>
      `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.border.color}`};
    padding: ${({ theme }) => theme.deprecated_.sizeBasis} 0;
  }

  li:last-child {
    border-bottom: none;
  }
`;

const NotificationIcon = styled.div`
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  background: ${({ theme }) => theme.deprecated_.colors.yellow};
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  margin-left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  margin-right: ${({ theme }) => math(`-${theme.deprecated_.sizeBasis}`)};
`;

type NavLink = {
  label: 'Provider Home' | 'Care Den' | 'Messages' | 'Library' | 'Account';
  to: string;
  isAnchor: boolean;
  target?: '_self' | '_blank' | '_parent' | '_top';
};

const PROVIDER_HOME: NavLink = {
  to: routes.provider.home.url(),
  label: 'Provider Home',
  isAnchor: false,
};

const CARE_DEN: NavLink = {
  to: routes.care.home.url(),
  label: 'Care Den',
  isAnchor: false,
};

const LIBRARY: NavLink = {
  to: routes.library.home.url(),
  label: 'Library',
  isAnchor: false,
};

const ACCOUNT: NavLink = {
  to: routes.profile.home.url(),
  label: 'Account',
  isAnchor: false,
};

const MESSAGES: NavLink = {
  to: routes.messages.home.url(),
  label: 'Messages',
  isAnchor: false,
};

export type NavLinksProps = {
  handleCloseNav?: () => void;
  isStaff?: boolean;
  isInAllowedState?: boolean;
};

export const NavLinks: FC<PropsWithChildren<NavLinksProps>> = ({ handleCloseNav, isStaff, isInAllowedState }) => {
  const { unreadMessagesCount } = useConversations();

  const hasNotification = (label: NavLink['label']) => label === 'Messages' && !!unreadMessagesCount;

  let displayedLinks;

  if (isStaff) {
    displayedLinks = [PROVIDER_HOME, CARE_DEN, MESSAGES, LIBRARY, ACCOUNT];
  } else if (isInAllowedState) {
    displayedLinks = [CARE_DEN, MESSAGES, LIBRARY, ACCOUNT];
  } else {
    displayedLinks = [CARE_DEN, LIBRARY, ACCOUNT];
  }

  return (
    <StyledNavLinks variants={listVariants}>
      {displayedLinks.map(({ to, label, isAnchor, target }) => (
        <motion.li key={label} variants={listItemVariants}>
          <Link href={to} onClick={handleCloseNav} isAnchor={isAnchor} target={target || '_self'}>
            {label}
          </Link>
          {hasNotification(label) && <NotificationIcon />}
        </motion.li>
      ))}
    </StyledNavLinks>
  );
};
