import { createStore } from 'little-state-machine';
import { type UpdateStore } from 'little-state-machine/dist/types';
import styled from 'styled-components';

import { NextButton } from '../../../../components';
import { type TantrumSurvey_survey } from '../../../../graphql/__generated__/TantrumSurvey';

const defaultStoreData: TantrumState = {
  tantrumData: {
    child: {
      firstName: '',
      dateOfBirth: '',
    },
    email: '',
    surveyResponses: [],
    surveyResponseIds: [],
    // The actual survey definition is fetched via the TantrumSurvey GQL query
    survey: {
      __typename: 'SurveyType',
      pk: 0,
      worryDomain: null,
      lowRiskThreshold: null,
      highRiskThreshold: null,
      questions: [],
      followUpQuestions: [],
    },
  },
};

createStore(defaultStoreData, {
  name: 'tantrumState',
});

export type TantrumState = {
  tantrumData: TantrumData;
};

export type TantrumData = {
  child: {
    firstName: string;
    dateOfBirth: string;
  };
  email: string;
  surveyResponses: {
    questionPk: number;
    answerPk: number;
    score: number;
  }[];
  surveyResponseIds: number[];
  survey: TantrumSurvey_survey;
};

export const updateState: UpdateStore = (state: TantrumState, payload: Partial<TantrumData>) => {
  return {
    ...state,
    tantrumData: {
      ...state.tantrumData,
      ...payload,
    },
  };
};

export const TantrumButton = styled(NextButton)``;
