import { type FC } from 'react';

import { MultiSelectionOptions } from '../../../Options';
import { getUpdatedMultiSelectionValues } from '../../../Options/MultiSelectionOptions';
import { StyledError } from '../../styled';
import { type CustomWidgetProps } from '../types';

type Options = {
  enumOptions: Array<{ label: string; value: string | number }>;
};

export const MultiSelectionWidget: FC<CustomWidgetProps<Options>> = ({
  id,
  options,
  value: selectedValues,
  onChange,
  rawErrors,
}) => (
  <>
    <MultiSelectionOptions
      name={id}
      options={options.enumOptions as Options['enumOptions']}
      selectedValues={selectedValues ?? []}
      onOptionClick={(value) => {
        onChange(getUpdatedMultiSelectionValues(selectedValues ?? [], value));
      }}
    />
    <StyledError>{rawErrors}</StyledError>
  </>
);
