import { Capacitor } from '@capacitor/core';
import { useCallback, useEffect, useState, type FC, type PropsWithChildren } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Banner, Navbar } from '@littleotter/legacy-components';

import { useViewer } from '$shared/contexts/Viewer';
import { useBannerMessage, useGraphQLErrorHandling } from '$shared/hooks';
import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../../components/MetadataRoute';
import { Page } from '../../../components/Page';
import { PageWideLoading } from '../../../components/PageWideLoading';
import { RouteSwitch } from '../../../components/RouteSwitch';
import { ToastErrorBoundary } from '../../../components/ToastErrorBoundary';
import { useGetFirstFmhcPaymentUrlLazyQuery, useHasPaidFirstFmhcQuery } from '../../../graphql/lo1/generated';
import { routes } from '../../../routes';
import { type FmhcUrlParams } from '../../../routes/reports';
import { type Subject } from '../../FmhcCheckup/types';
import { StateLocationSourceEnum, type StateLocationSource } from '../../PaymentDetails/WelcomeCallPaymentDetails';
import { OverTimeWithPrerequisites } from './OverTime/OverTimeWithPrerequisites';
import { ReportHistory } from './ReportHistory';
import { ReportsContent } from './ReportsContent';
import {
  useAppReportsForSubjects,
  useAppReportsForSubjectsAsStaff,
  useIsStaff,
  useSubmissionMetadata,
  useUpcomingAppointments,
} from './ReportsContent/hooks/useAppReportsForSubjects';
import { useReportTracking } from './ReportsContent/hooks/useReportTracking';
import { type FmhcReportCollection } from './types';

// Necessary child in order to access the params
// provided in the `MetadataRoute`
const RoutedReports: FC<PropsWithChildren> = () => {
  const history = useHistory();
  const { bannerMessage, bannerIsShowing, setBannerMessage } = useBannerMessage();
  const { fmhcId } = useParams<FmhcUrlParams>();
  const { viewer, isInAllowedState, loading: viewerLoading } = useViewer();

  const [isPaywalled, setIsPaywalled] = useState(true);
  const [canPurchaseReport, setCanPurchaseReport] = useState(false);
  const { data: hasPaidFirstFmhc, loading: hasPaidFirstFmhcLoading } = useHasPaidFirstFmhcQuery({
    fetchPolicy: 'no-cache',
  });
  const [
    getFirstFmhcPaymentUrlQuery,
    { data: firstFmhcPaymentUrl, loading: firstFmhcPaymentUrlLoading, error: firstFmhcPaymentError },
  ] = useGetFirstFmhcPaymentUrlLazyQuery();
  useGraphQLErrorHandling(firstFmhcPaymentError);

  // setIsPaywalled effect
  useEffect(() => {
    // User can pay for their first FMHC
    if (hasPaidFirstFmhc?.HasPaidFirstFmhc.firstFmhcId === fmhcId && hasPaidFirstFmhc.HasPaidFirstFmhc.hasPaid) {
      setIsPaywalled(false);
    } else {
      const canSeeReport =
        viewer?.hasConfirmedAppointments ||
        viewer?.family?.careStatus.isMember ||
        viewer?.family?.hasValidInsuranceAuthorizations ||
        false;
      setIsPaywalled(!canSeeReport);
    }
  }, [getFirstFmhcPaymentUrlQuery, hasPaidFirstFmhc, fmhcId, viewer]);

  // getFirstFmhcPaymentUrlQuery effect
  useEffect(() => {
    // User can only pay for their first FMHC
    if (
      hasPaidFirstFmhc &&
      hasPaidFirstFmhc.HasPaidFirstFmhc.firstFmhcId === fmhcId &&
      !hasPaidFirstFmhc.HasPaidFirstFmhc.hasPaid
    ) {
      setCanPurchaseReport(true);
      getFirstFmhcPaymentUrlQuery();
    } else {
      setCanPurchaseReport(false);
    }
  }, [fmhcId, getFirstFmhcPaymentUrlQuery, hasPaidFirstFmhc]);

  const { isStaff, loading: isStaffLoading } = useIsStaff();

  const { reports: reportsAsViewer, loading: appReportsForSubjectsLoading } = useAppReportsForSubjects(
    fmhcId,
    isPaywalled
  );
  const { reports: reportsAsStaff, loading: appReportsForSubjectsAsStaffLoading } =
    useAppReportsForSubjectsAsStaff(fmhcId);
  const { submissionDatetime, submissionUser, loading: submissionMetadataLoading } = useSubmissionMetadata(fmhcId);
  const { upcomingAppointmentDetails, loading: upcomingAppointmentLoading } = useUpcomingAppointments();
  const {
    trackReportOpen,
    trackReportPurchaseClicked,
    trackSummaryClicked,
    trackScheduleFirstSessionBannerClicked,
    trackScheduleFirstSessionSubjectClicked,
    trackScheduleFirstSessionFooterClicked,
    trackSubjectDomainDrawerClicked,
  } = useReportTracking(fmhcId);

  const isFreeAccount = isStaff ? false : isPaywalled;

  useEffect(() => {
    trackReportOpen();
  }, [fmhcId, trackReportOpen]);

  const loading =
    appReportsForSubjectsLoading ||
    submissionMetadataLoading ||
    upcomingAppointmentLoading ||
    isStaffLoading ||
    viewerLoading ||
    appReportsForSubjectsAsStaffLoading ||
    hasPaidFirstFmhcLoading ||
    firstFmhcPaymentUrlLoading;

  const goPurchaseReport = useCallback(
    (reportSubject?: Subject) => {
      if (firstFmhcPaymentUrl) {
        const stripeGatewayUrl = firstFmhcPaymentUrl.GetFirstFmhcPaymentUrl.paymentUrl;
        if (reportSubject) {
          trackReportPurchaseClicked(reportSubject);
        }
        window.location.href = stripeGatewayUrl;
      }
    },
    [firstFmhcPaymentUrl, trackReportPurchaseClicked]
  );

  if (loading) {
    return <PageWideLoading />;
  }

  const existReport = reportsAsStaff.length || reportsAsViewer.length;

  if (!existReport) {
    throw new Error('This report is unavailable');
  }

  const reports = isStaff ? reportsAsStaff : reportsAsViewer;

  const fmhcReport: FmhcReportCollection = {
    submissionUser,
    submissionDatetime,
    isFreeAccount,
    subjectReports: reports,
  };

  const goScheduleWelcomeCall = () => {
    const stateLocationSource: StateLocationSource = {
      source: StateLocationSourceEnum.FmhcReport,
    };

    history.push(routes.paymentDetails.welcomeCall.home.url(), stateLocationSource);
  };

  return (
    <>
      <Banner
        message={bannerMessage.message}
        isShowing={bannerIsShowing}
        variant={bannerMessage.type}
        onTimeout={() => setBannerMessage(null)}
      />
      <ReportsContent
        isPaywalled={isPaywalled && isFreeAccount}
        isInAllowedState={isInAllowedState}
        fmhcReport={fmhcReport}
        goToCareDen={() => history.push(routes.care.home.path)}
        goScheduleWelcomeCall={goScheduleWelcomeCall}
        goPurchaseReport={canPurchaseReport ? goPurchaseReport : undefined}
        upcomingAppointmentDetails={upcomingAppointmentDetails}
        onSummaryClicked={trackSummaryClicked}
        onScheduleFirstSessionBannerClicked={trackScheduleFirstSessionBannerClicked}
        onScheduleFirstSessionSubjectClicked={trackScheduleFirstSessionSubjectClicked}
        onScheduleFirstSessionFooterClicked={trackScheduleFirstSessionFooterClicked}
        onSubjectDomainDrawerClicked={trackSubjectDomainDrawerClicked}
      />
    </>
  );
};

const isOniOS = Capacitor.getPlatform() === 'ios';

export const Reports: FC<PropsWithChildren> = () => (
  <RouteSwitch>
    <MetadataRoute {...routeProps(routes.reports.fmhcs.home)} fullHeight>
      <Page hasHeader hasBackButton headerTitle="Report history">
        <ReportHistory />
      </Page>
    </MetadataRoute>
    <MetadataRoute {...routeProps(routes.reports.fmhcs.overTime)}>
      <ToastErrorBoundary>
        <Page hasHeader hasBackButton headerTitle="Family Overview">
          <OverTimeWithPrerequisites />
        </Page>
      </ToastErrorBoundary>
    </MetadataRoute>
    <MetadataRoute {...routeProps(routes.reports.fmhcs.fmhc)}>
      <Navbar showLogo removeShadow bigHeight dark hasExtraIosPadding={isOniOS} />
      <ToastErrorBoundary>
        <RoutedReports />
      </ToastErrorBoundary>
    </MetadataRoute>
  </RouteSwitch>
);
