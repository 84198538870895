import { consoleDebug } from '@littleotter/kit/utils';

import { PlatformEnvironmentService } from './PlatformEnvironmentService';
import type { IEnvironmentService } from './types';

/**
 * Create a new instance of the PlatformEnvironmentService.
 */
export const createEnvironmentService = (): IEnvironmentService => {
  const service = new PlatformEnvironmentService();

  consoleDebug(`[platform.environment] Creating environment service in '${service.runtimeContext}' runtime context`);

  return service;
};
