import { useState, type FC } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useElementSize } from 'usehooks-ts';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { Icon } from '../Icon';
import { LogoLoading } from '../LogoLoading';
import {
  DocumentContainer,
  SpinnerContainer,
  StyledNextButton,
  StyledPageControls,
  StyledPageIndicator,
  StyledPrevButton,
} from './styled';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export type PdfViewerProps = {
  fileUrl?: string | File;
};

const PdfLoading = () => (
  <SpinnerContainer>
    <LogoLoading />
  </SpinnerContainer>
);

export const PdfViewer: FC<PdfViewerProps> = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [ref, { width: wrapperWidth }] = useElementSize();

  const onPdfLoadSuccess = ({ numPages: nextNumPages }: { numPages: number }) => {
    setNumPages(nextNumPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <DocumentContainer ref={ref}>
      <Document file={fileUrl} onLoadSuccess={onPdfLoadSuccess} loading={PdfLoading}>
        <Page pageNumber={pageNumber} width={wrapperWidth || undefined} />
        {numPages > 1 && (
          <StyledPageControls>
            <StyledPrevButton type="button" disabled={pageNumber <= 1} onClick={previousPage}>
              <Icon name="BackArrow" />
            </StyledPrevButton>
            <StyledPageIndicator>
              <b>{pageNumber || (numPages ? 1 : '--')}</b> of {numPages || '--'}
            </StyledPageIndicator>
            <StyledNextButton type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
              <Icon name="BackArrow" />
            </StyledNextButton>
          </StyledPageControls>
        )}
      </Document>
    </DocumentContainer>
  );
};
