export const range = (start: number, end: number, increment: number) => {
  // if the end is not defined...
  const isEndDef = typeof end !== 'undefined';
  // ...the first argument should be the end of the range...
  const endValue = isEndDef ? end : start;

  // ...and 0 should be the start
  const startValue = isEndDef ? start : 0;

  let incrementValue = increment;
  // if the increment is not defined, we could need a +1 or -1
  // depending on whether we are going up or down
  if (typeof increment === 'undefined') {
    incrementValue = Math.sign(endValue - startValue);
  }

  // calculating the lenght of the array, which has always to be positive
  const length = Math.abs((endValue - start) / (incrementValue || 1));

  // In order to return the right result, we need to create a new array
  // with the calculated length and fill it with the items starting from
  // the start value + the value of increment.
  const resultArr = Array.from({ length }).reduce(
    ({ result, current }) => ({
      // append the current value to the result array
      result: [...result, current],
      // adding the increment to the current item
      // to be used in the next iteration
      current: current + incrementValue,
    }),
    { current: start, result: [] }
  );

  return resultArr.result;
};
