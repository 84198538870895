import { useMutation } from '@apollo/client';
import { Client as ConversationsClient } from '@twilio/conversations';
import { useCallback, useEffect, useState } from 'react';

import { logger } from '$services/logging';
import { useViewer } from '$shared/contexts/Viewer';

import { type CreateConversationUserToken } from '../../../../graphql/__generated__/CreateConversationUserToken';
import { CREATE_CONVERSATION_USER_TOKEN } from '../../../../graphql/messages';

export type ClientState = 'success' | 'loading' | 'error' | 'skipped';

type UseConversationsClient = () => [ConversationsClient | null, ClientState];

export const useConversationsClient: UseConversationsClient = () => {
  const [conversationsClient, setConversationClient] = useState<ConversationsClient | null>(null);
  const [clientState, setClientState] = useState<ClientState>('skipped');
  const { isAuthenticatedViewer } = useViewer();

  const [createConversationUserToken, { loading: mutationLoading, error: mutationError }] =
    useMutation<CreateConversationUserToken>(CREATE_CONVERSATION_USER_TOKEN);

  const fetchToken = useCallback(async () => {
    try {
      const { data, errors } = await createConversationUserToken();
      if (data) {
        return data.createConversationUserToken.token;
      }
      logger.warn(`[useConversationsClient] failed to fetch token: ${(errors || []).map((e) => e.message).join(', ')}`);
    } catch (e) {
      logger.error(new Error('[useConversationsClient] failed to fetch token', { cause: e }));
    }
    setClientState('error');
    return null;
  }, [createConversationUserToken, setClientState]);

  // initialize client when user auth changes
  useEffect(() => {
    if (!isAuthenticatedViewer) {
      setConversationClient(null);
      return;
    }
    const initializeClient = async () => {
      try {
        const token = await fetchToken();
        if (token) {
          const client = await ConversationsClient.create(token);
          setConversationClient(client);
        }
      } catch (e) {
        logger.error(new Error('[useConversationsClient] failed to initialize client', { cause: e }));
        setClientState('error');
      }
    };
    initializeClient();
  }, [isAuthenticatedViewer, setConversationClient, fetchToken]);

  // client instance cleanup
  useEffect(() => {
    return () => {
      if (conversationsClient) {
        conversationsClient.shutdown();
      }
    };
  }, [conversationsClient]);

  // update client token when it's about to expire or has already expired
  useEffect(() => {
    if (!conversationsClient) {
      return;
    }

    const updateClientToken = async () => {
      const token = await fetchToken();
      if (!token) {
        return;
      }
      try {
        const client = await conversationsClient.updateToken(token);
        setConversationClient(client);
      } catch (e) {
        logger.error(new Error('[useConversationsClient] failed update client token', { cause: e }));
        setClientState('error');
      }
    };

    const createClientWithToken = async () => {
      const token = await fetchToken();
      if (!token) {
        return;
      }
      try {
        const client = await ConversationsClient.create(token);
        setConversationClient(client);
      } catch (e) {
        logger.error(new Error('[useConversationsClient] failed create client with token', { cause: e }));
        setClientState('error');
      }
    };

    conversationsClient.on('tokenAboutToExpire', updateClientToken);
    conversationsClient.on('tokenExpired', createClientWithToken);

    return () => {
      conversationsClient.off('tokenAboutToExpire', updateClientToken);
      conversationsClient.off('tokenExpired', createClientWithToken);
    };
  }, [conversationsClient, fetchToken]);

  // handle client state changes
  useEffect(() => {
    const handleClientState = () => {
      if (!isAuthenticatedViewer) {
        setClientState('skipped');
        return;
      }

      if (conversationsClient?.connectionState === 'connecting') {
        setClientState('loading');
        return;
      }

      if (mutationError || conversationsClient?.connectionState === 'denied') {
        setClientState('error');
        return;
      }

      if (conversationsClient?.connectionState === 'connected') {
        setClientState('success');
      }
    };

    conversationsClient?.on('connectionStateChanged', handleClientState);
    handleClientState();

    return () => {
      conversationsClient?.off('connectionStateChanged', handleClientState);
    };
  }, [
    conversationsClient?.connectionState,
    mutationLoading,
    mutationError,
    isAuthenticatedViewer,
    conversationsClient,
  ]);

  return [conversationsClient, clientState];
};
