/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { type IEventTrackingService } from './types';

/**
 * A no-op implementation of the event tracking service that can be used in place of the real service
 * when we need to safely disable event tracking.
 */
export class NoopEventTrackingService implements IEventTrackingService {
  init = (...args: any[]) => {
    // no-op
  };

  onServiceReady = (...args: any[]) => {
    // no-op
  };

  identify = (...args: any[]) => {
    // no-op
  };

  clearIdentity = (...args: any[]) => {
    // no-op
  };

  linkUserWithGroup = (...args: any[]) => {
    // no-op
  };

  alias = (...args: any[]) => {
    // no-op
  };

  viewedPage = (...args: any[]) => {
    // no-op
  };

  track = (...args: any[]) => {
    // no-op
  };
}
