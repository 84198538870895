import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Anchorme, type LinkComponentProps } from 'react-anchorme';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Header, Link, ProfilePicture } from '@littleotter/legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { Content, ProviderName, SessionDate } from '.';
import { MarginChildren } from '../../../components';
import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type CareSessionNoteQuery,
  type CareSessionNoteQueryVariables,
} from '../../../graphql/__generated__/CareSessionNoteQuery';
import { CARE_SESSION_NOTE_QUERY } from '../../../graphql/care-sessionnote';
import { MissingQueryDataError } from '../../../graphql/errors';
import { MarginContainer } from './styled';
import { removeVersionTagFromNoteName } from './utils/removeVersionTagFromNoteName';

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SummarySection = styled.div`
  white-space: pre-wrap;
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

const SessionNoteProviderName = styled(ProviderName)`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
`;

export const SessionNote = () => {
  const params = useParams<{ id: string }>();

  const { data, error, loading } = useQuery<CareSessionNoteQuery, CareSessionNoteQueryVariables>(
    CARE_SESSION_NOTE_QUERY,
    { variables: { pk: params.id } }
  );
  useGraphQLErrorHandling(error);

  if (loading) return <PageWideLoading />;
  if (!data) throw new MissingQueryDataError('CareSessionNoteQuery');

  const CustomLink = (props: LinkComponentProps) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Link isAnchor {...props} />;
  };

  return (
    <MarginContainer>
      <MarginChildren>
        <Header as="h4">{removeVersionTagFromNoteName(data.treatmentNote?.noteName || null)}</Header>
        <HeaderSection>
          <ProfilePicture src={data.treatmentNote?.provider?.profilePicture} size="smaller" />
          <Content>
            <SessionDate>{format(new Date(data.treatmentNote?.datetime), 'EEEE, MMMM do yyyy')}</SessionDate>
            <SessionNoteProviderName>
              {data.treatmentNote?.provider
                ? `${data.treatmentNote.provider.firstName} ${data.treatmentNote.provider.lastName}`
                : 'Little Otter Provider'}
            </SessionNoteProviderName>
          </Content>
        </HeaderSection>
        <SummarySection>
          {data.treatmentNote?.summary &&
            data.treatmentNote.summary.map((summaryField) => {
              return (
                <div key={summaryField?.text}>
                  <Header as="h5" style={{ fontWeight: 600 }}>
                    {summaryField?.text}
                  </Header>
                  <p>
                    <Anchorme linkComponent={CustomLink}>{summaryField?.answer || 'Unknown Answer'}</Anchorme>
                  </p>
                </div>
              );
            })}
        </SummarySection>
      </MarginChildren>
    </MarginContainer>
  );
};
