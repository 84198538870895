import { math, rgba } from 'polished';
import styled from 'styled-components';

import { Modal as BaseModal, ModalContent } from '@littleotter/legacy-components';

export const Modal = styled(BaseModal)`
  ${ModalContent} {
    p,
    ul {
      color: ${({ theme }) => rgba(theme.deprecated_.colors.text, 0.8)};
    }

    ul {
      margin-left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
    }

    > :not(:last-child) {
      margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
    }
  }
`;
