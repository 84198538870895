import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import anger from './assets/anger.svg';
import anxiety from './assets/anxiety.svg';
import caregiverHealth from './assets/caregiverHealth.svg';
import depression from './assets/depression.svg';
import eating from './assets/eating.svg';
import focus from './assets/focus.svg';
import sensory from './assets/sensory.svg';
import sleep from './assets/sleep.svg';
import toileting from './assets/toileting.svg';

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

type WorryDomainShortName = keyof typeof shortNameToIllustrationMap;

export type WorryDomainIllustrationType = {
  worryDomainShortName?: WorryDomainShortName;
};

const shortNameToIllustrationMap = {
  focus,
  depression,
  anxiety,
  eating,
  sleep,
  toileting,
  sensory_sensitivities: sensory,
  anger_aggression: anger,
  peer_relationships: null,
  caregiver_health: caregiverHealth,
  covid_19: null,
};

export const WorryDomainIllustration: FC<PropsWithChildren<WorryDomainIllustrationType>> = ({
  worryDomainShortName,
}) => {
  const illustrationPath = worryDomainShortName ? shortNameToIllustrationMap[worryDomainShortName] : null;
  return illustrationPath ? <StyledImg src={illustrationPath} alt="worry domain illustration" /> : null;
};
