import { type FC, type PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';

import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../components/MetadataRoute';
import { Page } from '../../components/Page';
import { RouteSwitch } from '../../components/RouteSwitch';
import { routes } from '../../routes';
import { ReferralAuthorizationForm, ReferralConfirmation, ReferralProviderSelector } from './Pages';

export const Referral: FC<PropsWithChildren> = () => {
  return (
    <RouteSwitch>
      <MetadataRoute {...routeProps(routes.referral.home)}>
        <Redirect to={{ pathname: routes.referral.selector.url() }} />
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.referral.selector)} withoutMobileNavbar fullHeight>
        <Page hasHeader hasBackButton hasLogo>
          <ReferralProviderSelector />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.referral.details)} withoutMobileNavbar fullHeight>
        <Page hasHeader hasBackButton hasLogo>
          <ReferralAuthorizationForm />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.referral.confirmation)} withoutMobileNavbar fullHeight>
        <Page hasHeader hasLogo>
          <ReferralConfirmation />
        </Page>
      </MetadataRoute>
    </RouteSwitch>
  );
};
