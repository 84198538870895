import { rgba } from 'polished';
import styled from 'styled-components';

import { Button } from '@littleotter/legacy-components';

export const Root = styled.div<{ disabled?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => rgba(theme.deprecated_.colors.white, 1.0)};
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  display: flex-col;
  min-height: 75px;
  padding: ${({ theme }) => theme.spacing['05']} ${({ theme }) => theme.spacing['06']};
  margin: ${({ theme }) => theme.spacing['05']} 0;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.text};
  color: ${({ theme }) => theme.deprecated_.colors.darkestPurple};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  text-transform: uppercase;
`;

export const Content = styled.div`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  padding: ${({ theme }) => theme.spacing['02']} ${({ theme }) => theme.spacing['02']};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing['03']};
  margin-bottom: ${({ theme }) => theme.spacing['02']};
`;
