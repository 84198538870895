import { type Client, type Conversation } from '@twilio/conversations';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { logger } from '$services/logging';
import { previewFromConversation, type ConversationPreview } from '$shared/contexts/Conversations/conversationPreview';

import { sortConversationsByActivity } from '../../../../pages/Messages/utils/sortConversationsByActivity';
import { routes } from '../../../../routes';
import { getAllItemsFromPaginator } from '../utils/getAllItemsFromPaginator';
import { useClosedConversationsInfo } from './useClosedConversationsInfo';

export type ConversationsState = 'success' | 'loading' | 'skipped';

type UseTwilioConversations = (conversationsClient: Client | null) => [ConversationPreview[], ConversationsState];

const getSubscribedConversations = async (client: Client): Promise<Conversation[]> => {
  const paginator = await client.getSubscribedConversations();
  const allConversations = await getAllItemsFromPaginator(paginator);
  return sortConversationsByActivity(allConversations);
};

export const useTwilioConversations: UseTwilioConversations = (client) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [conversationsState, setConversationsState] = useState<ConversationsState>('skipped');
  const { pathname } = useLocation();
  const { closedConversations } = useClosedConversationsInfo();

  useEffect(() => {
    let active = true;

    if (!client || !routes.messages.home.test(pathname)) {
      setConversationsState('skipped');
      return;
    }

    const fetchConversations = async () => {
      try {
        const cs = await getSubscribedConversations(client);
        if (active) {
          setConversations(cs);
          setConversationsState('success');
          cs.forEach((c) => {
            c.on('messageAdded', fetchConversations);
          });
          client.on('conversationAdded', fetchConversations);
        }
      } catch (e) {
        logger.error(new Error('failed to fetch conversations from twilio', { cause: e }));
      }
    };

    setConversationsState('loading');
    if (client.connectionState === 'connected') {
      // noinspection JSIgnoredPromiseFromCall
      fetchConversations();
    }

    return () => {
      active = false;
      client.off('conversationAdded', fetchConversations);
    };
  }, [client, pathname]);

  const conversationPreviews = useMemo(() => {
    return [...conversations, ...(closedConversations || [])].map(previewFromConversation);
  }, [conversations, closedConversations]);

  return [conversationPreviews, conversationsState];
};
