import { type FC, type ReactNode } from 'react';
import { type FieldErrors } from 'react-hook-form6';
import styled from 'styled-components';

interface StyledTextareaProps {
  error: boolean;
}

const StyledLabel = styled.label`
  font-weight: ${(props) => props.theme.deprecated_.fontWeights.semiBold};
  cursor: pointer;
  width: 100%;
`;

const StyledError = styled.small`
  color: ${(props) => `${props.theme.deprecated_.colors.red}`};
`;

const StyledTextarea = styled.textarea<StyledTextareaProps>`
  border: ${(props) =>
    `${props.theme.deprecated_.border.size} ${props.theme.deprecated_.border.style} ${
      props.error ? props.theme.deprecated_.colors.red : props.theme.deprecated_.border.color
    }`};
  border-radius: ${(props) => props.theme.deprecated_.border.radius};
  padding: ${(props) => props.theme.deprecated_.sizeBasis};
  margin-top: ${(props) => props.theme.deprecated_.sizeBasis};
  transition: all 0.2s linear;
  width: 100%;

  &:focus {
    border-color: ${(props) => props.theme.deprecated_.colors.text};
    outline-color: ${(props) => (props.error ? props.theme.deprecated_.colors.red : '')};
  }
`;

export type TextareaProps = {
  label: ReactNode;
  name: string;
  onChange?: (value: string | number) => void;
  disabled?: boolean;
  value?: number | string;
  errors?: FieldErrors;
  placeholder?: string;
  rows?: number;
};

export const Textarea: FC<TextareaProps> = ({ label, name, onChange, errors, ...props }) => (
  <StyledLabel>
    <div>{label}</div>
    <StyledTextarea
      name={name}
      onChange={(event) => onChange && onChange(event.target.value)}
      error={Boolean(errors?.[name])}
      {...props}
    />
    <StyledError>{errors?.[name]?.message}</StyledError>
  </StyledLabel>
);
