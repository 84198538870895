import { gql, useQuery } from '@apollo/client';

import { VIEWER_FAMILY_CHILDREN_WORRY_DOMAINS_FRAGMENT } from '../../../../../graphql/worry-domains';
import { type OrderConfirmationWorryDomainsQuery } from './__generated__/OrderConfirmationWorryDomainsQuery';

const ORDER_CONFIRMATION_WORRY_DOMAINS_QUERY = gql`
  query OrderConfirmationWorryDomainsQuery {
    viewer {
      ...ViewerFamilyChildrenWorryDomainsFragment
    }
  }

  ${VIEWER_FAMILY_CHILDREN_WORRY_DOMAINS_FRAGMENT}
`;

export const useWorryDomainsQuery = () =>
  useQuery<OrderConfirmationWorryDomainsQuery>(ORDER_CONFIRMATION_WORRY_DOMAINS_QUERY);
