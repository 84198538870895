import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useState, type FC, type PropsWithChildren } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button, JSONSchemaForm, Link } from '@littleotter/legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';
import { getUrlRootFromWindow } from '$shared/utils/url';

import { AssessmentHeader } from '../../components/AssessmentHeader';
import { PageWideLoading } from '../../components/PageWideLoading';
import {
  type AssessmentStepQuery,
  type AssessmentStepQueryVariables,
} from '../../graphql/__generated__/AssessmentStepQuery';
import {
  type AssessmentStepResponseQuery,
  type AssessmentStepResponseQueryVariables,
} from '../../graphql/__generated__/AssessmentStepResponseQuery';
import {
  type UpdateAssessmentStepResponseMutation,
  type UpdateAssessmentStepResponseMutationVariables,
} from '../../graphql/__generated__/UpdateAssessmentStepResponseMutation';
import {
  ASSESSMENT_STEP_QUERY,
  ASSESSMENT_STEP_RESPONSE_QUERY,
  UPDATE_ASSESSMENT_STEP_RESPONSE_MUTATION,
} from '../../graphql/assessments';
import { MissingQueryDataError } from '../../graphql/errors';
import { routes } from '../../routes';
import { Background, Container } from './components';
import { useAuth, useStoredAssessmentResponseUuid } from './hooks';
import { type OnSubmit } from './types';

type AssessmentStepParams = {
  slug: string;
  step: string;
};

export const AssessmentStep: FC<PropsWithChildren> = () => {
  const { slug, step } = useParams<AssessmentStepParams>();
  const history = useHistory();

  // Ensure user is authorized to view this assessment
  const { loading: authLoading, authorized, redirectTo } = useAuth(slug);

  const [submittedFormData, setSubmittedFormData] = useState<Record<string, unknown> | null>(null);
  const [extraErrors, setExtraErrors] = useState<Record<string, unknown> | null>(null);

  const [assessmentResponseUuid, setAssessmentResponseUuid] = useStoredAssessmentResponseUuid({ slug });

  const {
    data: assessmentData,
    loading: assessmentLoading,
    error: assessmentError,
  } = useQuery<AssessmentStepQuery, AssessmentStepQueryVariables>(ASSESSMENT_STEP_QUERY, {
    variables: {
      slug,
      step: parseInt(step, 10),
    },
  });

  const {
    data: responseData,
    loading: responseLoading,
    error: responseError,
  } = useQuery<AssessmentStepResponseQuery, AssessmentStepResponseQueryVariables>(ASSESSMENT_STEP_RESPONSE_QUERY, {
    variables: {
      assessmentResponseUuid: assessmentResponseUuid ?? '',
      step: parseInt(step, 10),
    },
    skip: !assessmentResponseUuid,
  });

  const [updateAssessmentStepResponse, { loading: updateLoading, error: updateError }] = useMutation<
    UpdateAssessmentStepResponseMutation,
    UpdateAssessmentStepResponseMutationVariables
  >(UPDATE_ASSESSMENT_STEP_RESPONSE_MUTATION, {
    refetchQueries: assessmentResponseUuid
      ? [
          {
            query: ASSESSMENT_STEP_RESPONSE_QUERY,
            variables: {
              assessmentResponseUuid: assessmentResponseUuid ?? '',
              step: parseInt(step, 10),
            },
          },
        ]
      : [],
  });

  useGraphQLErrorHandling(assessmentError, responseError, updateError);

  const params = useParams<{ slug: string; step: string }>();

  const onSubmit = useCallback<OnSubmit>(
    async ({ formData }) => {
      // store user input in a state variable to avoid the form being reset
      // because of a rerender
      setSubmittedFormData(formData);

      const { data: updateData } = await updateAssessmentStepResponse({
        variables: {
          assessmentResponseUuid,
          step: parseInt(step, 10),
          data: JSON.stringify(formData),
        },
      });

      if (!updateData) {
        return;
      }

      if (updateData.assessmentsNamespace.updateStepResponse.__typename === 'JsonSchemaErrorsType') {
        setExtraErrors(JSON.parse(updateData.assessmentsNamespace.updateStepResponse.errors));
        return;
      }

      // reset state variables when submit is successful
      setSubmittedFormData(null);
      setExtraErrors(null);

      const { assessmentResponse, nextStep } = updateData.assessmentsNamespace.updateStepResponse;
      setAssessmentResponseUuid(assessmentResponse.uuid);

      if (!nextStep) {
        if (params.slug === 'holiday') {
          // TODO: Ensure that authorized users can only take the assessment once (mark the order ID as used)
          history.push(routes.assessmentReport.holidayReport.url({ id: assessmentResponse.uuid }));
        } else {
          history.push(routes.assessmentReport.backToSchoolReport.url({ id: assessmentResponse.uuid }));
        }

        return;
      }

      history.push(routes.assessments.assessment.step.url({ slug, step: nextStep.id }));
    },
    [assessmentResponseUuid, history, params.slug, setAssessmentResponseUuid, slug, step, updateAssessmentStepResponse]
  );

  if (assessmentLoading || responseLoading || authLoading) {
    return <PageWideLoading />;
  }

  if (!authorized) {
    window.location.href = redirectTo || getUrlRootFromWindow();
    return <PageWideLoading />;
  }

  if (!assessmentData?.assessmentsNamespace.assessment) throw new MissingQueryDataError('AssessmentStepQuery');

  const { assessment } = assessmentData.assessmentsNamespace;
  const jsonSchema = JSON.parse(assessment.step.jsonSchema);
  const uiSchema = JSON.parse(assessment.step.uiSchema);

  // TODO: figure out what to do if previous step response was submitted with a different jsonSchema or UiSchema
  // E.g.
  // - User submitted a step response.
  // - Staff updates the schema for that step.
  // - User tries to load saved responses into step rendered with updated schema
  const stepResponseData = responseData?.assessmentsNamespace.assessmentResponse?.stepResponse?.data;
  const formData = submittedFormData ?? (!updateLoading && stepResponseData ? JSON.parse(stepResponseData) : undefined);

  return (
    <section>
      <Background />
      <AssessmentHeader>
        <Link variant="clearStyles" isAnchor href={assessment.marketingUrl}>
          <img src={assessment.headerImage} alt={`${assessment.name} Assessment Logo`} />
        </Link>
      </AssessmentHeader>
      <Container>
        <JSONSchemaForm
          schema={jsonSchema}
          uiSchema={uiSchema}
          formData={formData}
          onSubmit={onSubmit}
          extraErrors={extraErrors}
        >
          <Button type="submit" variant="secondary" isLoading={updateLoading}>
            {assessment.step.nextStep ? 'Next' : 'View Assessment Report'}
          </Button>
        </JSONSchemaForm>
      </Container>
    </section>
  );
};
