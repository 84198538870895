import styled from 'styled-components';

export const StyledContent = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  padding: 20px 0;
  text-align: center;

  > div:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.deprecated_.colors.gray50};
    padding-right: 15px;
  }
`;

export const DisabledLink = styled.span`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;
