export const SvgPath = () => (
  <svg width="0" height="0">
    <defs>
      <clipPath
        id="summaryContentPath"
        clipPathUnits="objectBoundingBox"
        transform="scale(0.00162601626, 0.004237288136)"
      >
        <path d="M0 184.696C0 184.696 148.649 171.503 307.5 184.696C466.351 197.888 615 236 615 236V-6.79493e-06H0V184.696Z" />
      </clipPath>
    </defs>
  </svg>
);
