import { type WatchQueryFetchPolicy } from '@apollo/client';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { useFmhcsQuery as _useFmhcsQuery, FmhcsSortBy, type FmhcsQuery } from '../../graphql/lo1/generated';

/**
 * Gets submitted or unsubmitted FMHCs past a certain date threshold.
 *
 * If getting submitted FMHCs, response is ordered by descending submission time, and date threshold is for submission time.
 *
 * If getting unsubmitted FMHCs, response is ordered by descending start time, and date threshold is for start time.
 */
export const useFmhcsQuery = ({
  isSubmitted,
  dateThreshold,
  limit = 1,
  skip = 0,
  skipQuery = false,
  fetchPolicy = undefined,
}: UseFmhcsQueryProps): [FmhcsQuery['Fmhcs'] | undefined, boolean, () => void] => {
  const { data, loading, error, refetch } = _useFmhcsQuery({
    variables: {
      input: {
        filter: {
          isSubmitted,
          ...(isSubmitted
            ? { submissionTimeIsAfter: dateThreshold?.toISOString() }
            : { startTimeIsAfter: dateThreshold?.toISOString() }),
        },
        sortBy: isSubmitted ? FmhcsSortBy.SubmissionTime : FmhcsSortBy.StartTime,
        limit,
        skip,
      },
    },
    skip: !!skipQuery,
    fetchPolicy,
  });
  useGraphQLErrorHandling(error);
  return [data?.Fmhcs, loading, refetch];
};

export type UseFmhcsQueryProps = {
  isSubmitted: boolean;
  dateThreshold?: Date;
  limit?: number;
  skip?: number;
  skipQuery?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy | undefined;
};
