export type TimeSeriesDatum = { value: number | null; date: Date };
export type TimeSeries = TimeSeriesDatum[];
export type ScoreTimeSeries = {
  name: string;
  threshold: number;
  maxScore: number;
  timeSeries: TimeSeries;
};

export enum CATEGORICAL_SCORE {
  TYPICAL = 'TYPICAL',
  CONCERNING = 'CONCERNING',
  IMPACTING = 'IMPACTING',
  MISSING = 'MISSING',
}

// ORDER MATTERS: order determines the presentation order
export enum CONTEXT_TYPE {
  WHAT_DOES_THIS_MEAN,
  WHAT_CAN_I_DO,
}

export type DomainContexts = { contextType: CONTEXT_TYPE; context: string }[];
export type Subject = {
  id: string;
  displayName: string;
  type: SUBJECT_TYPE;
};
export enum SUBJECT_TYPE {
  FAMILY,
  CAREGIVER,
  CHILD,
}
