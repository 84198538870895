import { math } from 'polished';
import { type FC } from 'react';
import ReactTooltip, { type TooltipProps } from 'react-tooltip';
import styled from 'styled-components';

const StyledTooltip = styled(ReactTooltip)`
  &.__react_component_tooltip.show {
    opacity: 1;
    font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
    border-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};

    &.type-dark {
      color: ${({ theme }) => theme.deprecated_.colors.white};
      background-color: ${({ theme }) => theme.deprecated_.colors.black};

      &:after {
        border-top-color: ${({ theme }) => theme.deprecated_.colors.black};
      }
    }
  }
`;

export const Tooltip: FC<TooltipProps> = (props) => <StyledTooltip {...props} />;
