import { gql } from '@apollo/client';

import UPCOMING_APPOINTMENT_FRAGMENT from './upcoming-appointment-fragment';

export const UPCOMING_APPOINTMENT_VIEWER = gql`
  query UpcomingAppointmentViewer {
    viewer {
      id
      email
      firstName
      lastName
      phoneNumber
      upcomingAppointments {
        ...UpcomingAppointment
      }
    }
  }
  ${UPCOMING_APPOINTMENT_FRAGMENT}
`;
