import { type FC, type PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import { type Theme } from '@littleotter/legacy-components';

import { CATEGORICAL_SCORE } from '../../types';
import * as Styled from './styled';

export const DomainHeader: FC<PropsWithChildren<DomainHeaderProps>> = ({ title, categoricalScore }) => {
  const {
    deprecated_: { colors },
  } = useTheme() as Theme;
  const categoricalScoreColors = {
    [CATEGORICAL_SCORE.CONCERNING]: colors.red,
    [CATEGORICAL_SCORE.IMPACTING]: colors.red,
    [CATEGORICAL_SCORE.TYPICAL]: colors.green,
    [CATEGORICAL_SCORE.MISSING]: colors.gray,
  };
  return (
    <Styled.Root>
      <Styled.CategoricalScore color={categoricalScoreColors[categoricalScore]}>
        {categoricalScore}
      </Styled.CategoricalScore>
      <Styled.Title>{title}</Styled.Title>
    </Styled.Root>
  );
};

export interface DomainHeaderProps {
  title: string;
  categoricalScore: CATEGORICAL_SCORE;
}
