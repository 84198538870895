import { type Message as TwilioMessage } from '@twilio/conversations';
import { useCallback, useEffect, useState, type FC, type PropsWithChildren } from 'react';

import { type ViewerQuery_viewer } from '../../../../../graphql/__generated__/ViewerQuery';
import { type MessageAttributes } from '../../types';
import { MessageItem } from './components/MessageItem';
import { StyledListItem } from './components/styled';
import { useAuthorName } from './hooks';

export type MessageProps = {
  message: TwilioMessage;
  viewer: ViewerQuery_viewer;
  read?: boolean;
  className?: string;
  isDeleted?: boolean;
};

const EXPIRATION_TIME = 120000;

export const Message: FC<PropsWithChildren<MessageProps>> = ({ message, viewer, read, isDeleted, className }) => {
  const [mediaUrl, setMediaUrl] = useState<string | undefined>();
  const [expired, setExpired] = useState<boolean>(false);

  const fetchMediaUrl = useCallback(async () => {
    if (message.type === 'media' && message.media) {
      const url = await message.media.getContentTemporaryUrl();
      if (url) {
        setMediaUrl(url);
        setExpired(false);

        setTimeout(() => {
          setExpired(true);
        }, EXPIRATION_TIME);

        return url;
      }
    }
  }, [message.media, message.type]);

  const OpenFileUrl = useCallback(async () => {
    let url: string | undefined;
    if (expired) {
      url = await fetchMediaUrl();
    }

    window.open(url ?? mediaUrl, '_blank');
  }, [expired, fetchMediaUrl, mediaUrl]);

  useEffect(() => {
    fetchMediaUrl();
  }, [fetchMediaUrl]);

  const {
    author: authorId,
    body: messageBody,
    dateCreated,
    index: messageIndex,
    media,
    attributes,
    conversation,
  } = message;

  // Apparently (due to a bug) the filename only shows up when uploading using multipart/form-data
  // and setting it in the Content-Disposition header.
  // So sendMessage also sets it as a message custom attribute as well, to be used as a fallback.
  const mediaFilename =
    message.type === 'media' ? ((media && media.filename) ?? (attributes as MessageAttributes).filename) : undefined;

  const isOutgoing = authorId === viewer.id;

  const authorName = useAuthorName({
    authorId,
    conversationSid: conversation.sid,
  });

  return (
    <StyledListItem
      isOutgoing={isOutgoing}
      id={`message${messageIndex}`}
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      className={className}
    >
      <MessageItem
        isOutgoing={isOutgoing}
        authorName={authorName}
        mediaUrl={mediaUrl}
        fileName={mediaFilename}
        contentType={media?.contentType}
        messageBody={messageBody}
        dateCreated={dateCreated}
        isRead={!!read}
        isDeleted={isDeleted}
        conversationSid={message.conversation.sid}
        messageSid={message.sid}
        isStaff={viewer.isStaff}
        onClick={OpenFileUrl}
      />
    </StyledListItem>
  );
};
