import { useCallback, type FC, type PropsWithChildren } from 'react';

import { type SelectProps } from '@littleotter/legacy-components';

import { Section, Select, Text } from '../styled';
import { type FilterItem } from '../utils';

type Options = SelectProps<number>['options'];
type OnChange = NonNullable<SelectProps<string>['onChange']>;

export type WorryDomainFilterProps = {
  data: FilterItem[] | undefined;
  selected: FilterItem | undefined;
  onSelect?: (item: FilterItem | undefined) => void;
};

export const WorryDomainFilter: FC<PropsWithChildren<WorryDomainFilterProps>> = ({ data, selected, onSelect }) => {
  const options: Options =
    data?.map((item) => ({
      value: item.pk,
      label: item.name,
    })) ?? [];

  const onChange: OnChange = useCallback(
    (value) => {
      const pk = parseInt(value, 10) || undefined;
      const currentWorryDomain = data?.find((item) => item.pk === pk);
      onSelect?.(
        currentWorryDomain
          ? {
              pk: currentWorryDomain.pk,
              name: currentWorryDomain.name,
            }
          : undefined
      );
    },
    [data, onSelect]
  );

  return (
    <Section>
      <Text>Filter content by focus area</Text>
      <Select
        name="worryDomains"
        placeholder="Select focus area"
        options={options}
        value={selected?.pk}
        onChange={onChange}
      />
    </Section>
  );
};
