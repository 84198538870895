import { gql } from '@apollo/client';

export const FAMILY_WORRY_DOMAINS_QUERY = gql`
  query FamilyWorryDomainsQuery($familyId: Int!) {
    family(familyId: $familyId) {
      id
      caregivers {
        id
        firstName
        worryDomains {
          edges {
            node {
              pk
              name
            }
          }
        }
      }
      children {
        id
        firstName
        lastName
        gender
        dateOfBirth
        isSeekingCare
        worryDomains {
          edges {
            node {
              pk
              name
            }
          }
        }
      }
      worryDomains {
        edges {
          node {
            pk
            name
          }
        }
      }
    }
  }
`;
