import { math } from 'polished';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as BaseHerb1Image } from '../assets/Herb1.svg';
import { animationDuration, baseAnimationDelay } from '../constants';

const herb1Animation = keyframes`
  from {
    opacity: 0;
    transform: translate(2rem, 3rem);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const Herb1Image = styled(BaseHerb1Image)`
  position: absolute;
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 9.5`)};
  height: auto;
  top: 0;
  left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.1`)};

  animation-name: ${herb1Animation};
  animation-duration: ${animationDuration};
  animation-delay: ${baseAnimationDelay};
  animation-timing-function: linear;
  animation-fill-mode: both;
`;
