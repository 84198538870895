import { useQuery } from '@apollo/client';
import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useGraphQLErrorHandling } from '$shared/hooks';
import { getProviderTitleNameFromCode } from '$shared/utils/providerTitles';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { ProviderBioCard } from '../../../components/ProviderBioCard';
import { type ProviderBioQuery, type ProviderBioQueryVariables } from '../../../graphql/__generated__/ProviderBioQuery';
import { MissingQueryDataError } from '../../../graphql/errors';
import { PROVIDER_BIO_QUERY } from '../../../graphql/profile';

const PageContainer = styled.article`
  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
  }
`;

export const ProviderBioPage: FC<PropsWithChildren> = () => {
  const params = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<ProviderBioQuery, ProviderBioQueryVariables>(PROVIDER_BIO_QUERY, {
    variables: { id: Number(params.id) },
  });
  useGraphQLErrorHandling(error);

  if (loading) return <PageWideLoading />;

  const viewer = data?.viewer;

  if (!data || !viewer) throw new MissingQueryDataError('ProviderBioQuery');

  const {
    user: { firstName, lastName, providerTitle, profilePicture, bio },
  } = data;

  const description = bio ?? '';

  return (
    <PageContainer>
      <ProviderBioCard
        fullName={`${firstName}${lastName ? ` ${lastName}` : ''}`}
        title={getProviderTitleNameFromCode(providerTitle ?? '') ?? ''}
        profilePictureSrc={profilePicture}
        description={description}
      />
    </PageContainer>
  );
};
