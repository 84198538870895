type BaseWorryDomainNode = {
  pk: number;
};

type BaseWorryDomainEdge<WorryDomainNode extends BaseWorryDomainNode = BaseWorryDomainNode> = {
  node: WorryDomainNode | null;
};

export type ChildWithWorryDomains<WorryDomainNode extends BaseWorryDomainNode = BaseWorryDomainNode> = {
  worryDomains: {
    edges: (BaseWorryDomainEdge<WorryDomainNode> | null)[];
  };
};

/**
 * Get a flat map of worry domains across all children, filtering duplicates and nullish values
 */
export const getWorryDomainsFromChildren = <WorryDomainNode extends BaseWorryDomainNode>(
  children: ChildWithWorryDomains<WorryDomainNode>[]
) =>
  children.reduce<NonNullable<WorryDomainNode>[]>((worryDomainsAcc, child) => {
    const childWorryDomains = child.worryDomains.edges
      .map((worryDomain) => worryDomain?.node)
      .filter(
        (worryDomain): worryDomain is NonNullable<WorryDomainNode> =>
          !!worryDomain && !worryDomainsAcc.find((existing) => existing.pk === worryDomain.pk)
      );

    return [...worryDomainsAcc, ...childWorryDomains];
  }, []);
