export type StateLocationSource = {
  source?: string;
};

// TODO: These will work in Legacy only. We aren't tracking in next-gen but we need to be in the near future.
export enum StateLocationSourceEnum {
  ExpertPath = 'Kickoff path',
  FmhcReport = 'Fmhc report',
  CareDen = 'Care den',
  CareJourney = 'Care journey',
  CareTeam = 'Care team',
  SchedulePage = 'Schedule page',
  MentalHealthWellnessReport = 'Mental Health Wellness Report',
  ReferralDeeplink = 'Referral deeplink',
  PartnerOnboarding = 'Partner onboarding',
  Unknown = 'Unknown',
}
