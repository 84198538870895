import { type FC, type PropsWithChildren } from 'react';

import { routes } from '../../../routes';
import { useViewer } from '../../../shared/contexts/Viewer';
import { WithPrerequisites } from '../../../shared/utils/rerouter/WithPrerequisites';
import { CareTeam } from './CareTeam';

export const CareTeamWithPrerequisites: FC<PropsWithChildren> = () => {
  const { isInAllowedState, loading } = useViewer();
  return (
    <WithPrerequisites
      prerequisites={[
        {
          predicate: !isInAllowedState,
          route: routes.home,
        },
      ]}
      loading={loading}
    >
      <CareTeam />
    </WithPrerequisites>
  );
};
