import { type ConversationParticipantsQuery_conversation_participants } from './__generated__/ConversationParticipantsQuery';

type GetParticipantNameOptions = {
  authorId: string;
  participants: ConversationParticipantsQuery_conversation_participants[];
};

type GetParticipantName = (options: GetParticipantNameOptions) => string;

export const getParticipantName: GetParticipantName = ({ authorId, participants }) => {
  const author = participants.find((participant) => participant.id === authorId);

  if (!author) {
    return '';
  }

  return `${author.firstName} ${author.lastName}`;
};
