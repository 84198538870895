import { type CSSProperties, type FC } from 'react';

import { ReactComponent as GreenOtter } from '../../assets/greenOtter.svg';
import { ReactComponent as Kelp } from '../../assets/kelp.svg';
import { ReactComponent as PurpleOtter } from '../../assets/purpleOtter.svg';
import { ReactComponent as Wordmark } from '../../assets/wordmark.svg';

export type LogoProps = {
  variant?: 'wordmark' | 'greenotter' | 'purpleotter' | 'kelp';
  sizeType?: 'width' | 'height';
  sizeAmount?: number | string;
  style?: CSSProperties;
  className?: string;
};

export const Logo: FC<LogoProps> = ({ variant = 'purpleotter', sizeType, sizeAmount, ...restProps }) => {
  const passedProps = {
    ...restProps,
    width: sizeType === 'width' ? sizeAmount : undefined,
    height: sizeType === 'height' ? sizeAmount : undefined,
  };

  switch (variant) {
    case 'wordmark':
      return <Wordmark {...passedProps} />;
    case 'greenotter':
      return <GreenOtter {...passedProps} />;
    case 'kelp':
      return <Kelp {...passedProps} />;
    default:
      return <PurpleOtter {...passedProps} />;
  }
};
