import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { math } from 'polished';
import { useState } from 'react';
import styled from 'styled-components';

import { Button, Header, LogoLoading } from '@littleotter/legacy-components';

import { useEventTracking } from '$services/event-tracking';
import { type BannerMessage } from '$shared/hooks';

import { MarginChildren } from '..';
import {
  type CancelAppointmentMutation,
  type CancelAppointmentMutationVariables,
} from '../../graphql/__generated__/CancelAppointmentMutation';
import { CancellationTypes } from '../../graphql/__generated__/globalTypes';
import { type UpcomingAppointment as UpcomingAppointmentFragment } from '../../graphql/__generated__/UpcomingAppointment';
import { CANCEL_APPOINTMENT } from '../../graphql/cancel-appintment';
import { UPCOMING_APPOINTMENT_VIEWER } from '../../graphql/upcoming-appointment-viewer';
import { type UpcomingSession } from '../../pages/Care/types';
import IntakeQBookingWidget, { type WidgetConfigType } from '../IntakeQBookingWidget';

const ButtonRow = styled.div`
  display: flex;
  > * {
    width: 50%;
  }
  > *:first-child {
    margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  }
`;

type RescheduleProps = {
  serviceId?: string;
  practitionerId?: string | null;
  cancelAppointmentFunc: (cancellationType: CancellationTypes) => Promise<void>;
};

const Reschedule = ({ serviceId, practitionerId, cancelAppointmentFunc }: RescheduleProps) => {
  const { data, error, loading } = useQuery(UPCOMING_APPOINTMENT_VIEWER);

  if (loading || error || !data?.viewer) return <LogoLoading />;

  const config: WidgetConfigType = {
    serviceId,
    callback: () => cancelAppointmentFunc(CancellationTypes.reschedule),
  };
  if (practitionerId) {
    config.practitionerId = practitionerId;
  }

  return (
    <IntakeQBookingWidget
      clientId={data.viewer.id}
      clientEmail={data.viewer.email}
      clientName={`${data.viewer.firstName} ${data.viewer.lastName}`}
      clientPhone={data.viewer.phoneNumber}
      config={config}
    />
  );
};

// The reschedule welcome call service IDs still charge $90 but do not require credit card input.
// The only difference between these services is the required credit card input.
// TODO: https://littleotterhealth.atlassian.net/browse/PD-639
const prodNonSubscriberRescheduleWelcomeCallServiceId = '05b12e2e-f09d-4384-9fd3-84de4e4a0d08';
const prodNonSubscriberWelcomeCallServiceId = 'b363da19-c5eb-4309-9fd6-2b942ec6e430';
const testingNonSubscriberRescheduleWelcomeCallServiceId = 'd4ff7ac5-bebc-42f3-b9ec-72258ca57537';
const testingNonSubscriberWelcomeCallServiceId = 'dafcd5ca-97d3-432f-aac7-0432b22d1b05';

type EditAppointmentProps = {
  appointment: UpcomingSession | UpcomingAppointmentFragment;
  closeModal: () => void;
  setBannerMessage: (message: BannerMessage | null) => void;
};

export const EditAppointment = ({ appointment, closeModal, setBannerMessage }: EditAppointmentProps) => {
  const { track } = useEventTracking();
  const [showReschedule, setShowReschedule] = useState(false);
  const [cancelAppointment] = useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(
    CANCEL_APPOINTMENT
  );

  const cancelAppointmentFunc = async (cancellationType: CancellationTypes) => {
    await cancelAppointment({
      variables: { input: { appointmentId: appointment.id, cancellationType } },
      refetchQueries: [{ query: UPCOMING_APPOINTMENT_VIEWER }],
    });
    closeModal();
    setBannerMessage({
      type: 'success',
      message: `Appointment successfully ${cancellationType === 'cancel' ? 'cancelled' : 'rescheduled'}`,
    });
  };

  if (showReschedule) {
    let serviceId = appointment.service.id;
    // TODO: serviceIds to API so we don't need to do things like this
    // Prod
    if (serviceId === prodNonSubscriberWelcomeCallServiceId) {
      serviceId = prodNonSubscriberRescheduleWelcomeCallServiceId;
      // Testing
    } else if (serviceId === testingNonSubscriberWelcomeCallServiceId) {
      serviceId = testingNonSubscriberRescheduleWelcomeCallServiceId;
    }
    return (
      <Reschedule
        serviceId={serviceId}
        practitionerId={appointment.provider.externalProviderId}
        cancelAppointmentFunc={cancelAppointmentFunc}
      />
    );
  }

  const handleRescheduleClick = () => {
    track('CLICKED_RESCHEDULE_APPOINTMENT');
    setShowReschedule(true);
  };

  const handleCancelClick = () => {
    track('CLICKED_CANCEL_APPOINTMENT');
    cancelAppointmentFunc(CancellationTypes.cancel);
  };

  return (
    <MarginChildren>
      <Header as="h3">Edit Appointment</Header>
      <ul>
        <li>Time: {format(new Date(appointment.startDatetime), "EEEE, MMMM do 'at' h:mma")}</li>
        <li>
          Provider:{' '}
          {appointment.provider
            ? `${appointment.provider.firstName} ${appointment.provider.lastName}`
            : 'Little Otter Provider'}
        </li>
        <li>Type: {appointment.service.name}</li>
      </ul>
      <ButtonRow>
        <Button onClick={handleRescheduleClick}>Reschedule</Button>
        <Button variant="tertiary" onClick={handleCancelClick}>
          Cancel Appointment
        </Button>
      </ButtonRow>
    </MarginChildren>
  );
};
