import styled, { css } from 'styled-components';

import { type CollapseContentContainerVariant, type CollapseContentIconVariant } from './types';

type ContainerVariantProps = {
  containerVariant: CollapseContentContainerVariant;
};

const compactContainerStyles = css`
  border-bottom: 1px solid ${({ theme }) => theme.deprecated_.colors.lightGray};
`;

const cardContainerStyles = css`
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: 0.5rem;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.07);
`;

export const CollapseContainer = styled.div<ContainerVariantProps>`
  ${({ containerVariant }) =>
    ({
      compact: compactContainerStyles,
      card: cardContainerStyles,
    })[containerVariant]}
`;

export const Toggle = styled.button<ContainerVariantProps>`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  cursor: pointer;
  padding: ${({ containerVariant }) =>
    ({
      compact: '1rem',
      card: '1rem 0.7rem',
    })[containerVariant]};
  text-align: unset;
  background-color: unset;
  color: ${({ theme }) => theme.deprecated_.colors.text};
`;

export const ToggleContent = styled.div`
  flex-grow: 1;
`;

export const StyledToggleIcon = styled.div<{ isOpen: boolean; variant: CollapseContentIconVariant }>`
  flex-shrink: 0;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.deprecated_.colors.gray};

  transition: transform 0.1s linear;
  ${({ isOpen, variant }) => {
    switch (variant) {
      case 'plus-minus':
        return isOpen ? '' : 'transform: rotate(-90deg);';
      default:
        return isOpen ? 'transform: rotate(90deg) translateX(-3px);' : '';
    }
  }}
`;

export const CollapsibleContent = styled.div<{ isOpen: boolean; maxHeight: string }>`
  transition: ${({ isOpen }) => (isOpen ? 'max-height 1s ease-in-out' : 'max-height 0.5s cubic-bezier(0, 1, 0, 1)')};
  max-height: ${({ isOpen, maxHeight }) => (isOpen ? maxHeight : '0')};
  overflow: hidden;
`;

export const InnerContent = styled.div<ContainerVariantProps>`
  padding: ${({ containerVariant }) =>
    ({
      compact: '0.5rem 1rem',
      card: '0 0.7rem 1rem',
    })[containerVariant]};
`;
