import styled, { css } from 'styled-components';

const marginChildren = (marginValue: number) => css`
  & > *:not(:last-child) {
    margin-bottom: ${marginValue}rem;
  }
`;

export const ProviderHomeContainer = styled.article`
  ${marginChildren(4)}
`;

export const ScheduleSection = styled.section`
  ${marginChildren(1.5)}
`;

export const MyClientsSection = styled.section`
  ${marginChildren(1.5)}
`;

export const CardsContainer = styled.div`
  ${marginChildren(1)}
`;
