import { darken } from 'polished';
import React, { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: color 0.2s linear;
  display: inline-flex;
  padding-top: calc(${({ theme }) => theme.deprecated_.sizeBasis} / 1.5);
  padding-bottom: calc(${({ theme }) => theme.deprecated_.sizeBasis} / 1.5);

  &:active {
    color: ${({ theme }) => darken(0.33, theme.deprecated_.colors.gray)};
  }

  & > *:not(:last-child) {
    margin-right: calc(${({ theme }) => theme.deprecated_.sizeBasis} / 2);
  }
`;

interface ActionButtonProps {
  icon: React.JSX.Element;
  onClick?: () => void;
}

export const ActionButton: FC<PropsWithChildren<ActionButtonProps>> = ({ icon, onClick, children }) => (
  <StyledButton onClick={onClick}>
    {icon}
    {children}
  </StyledButton>
);
