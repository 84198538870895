import styled from 'styled-components';

import { Header, SignalStrengthBars } from '@littleotter/legacy-components';

import { AssessmentReportBarChart } from '../../../../components/AssessmentReportBarChart';

export const Section = styled.section`
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const SubSectionHeader = styled(Header).attrs({ as: 'h5' })`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;

export const UnorderedList = styled.ul`
  list-style: revert;
  padding-inline-start: revert;
`;

export const ColorCardContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;
`;

export const CenteredSignalStrengthBars = styled(SignalStrengthBars)`
  margin: auto;
`;

export const CenteredAssessmentReportBarChart = styled(AssessmentReportBarChart)`
  margin: auto;
`;
