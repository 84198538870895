export enum LOG_LEVEL {
  TRACE = 0,
  DEBUG = 10,
  INFO = 20,
  WARN = 30,
  ERROR = 40,
  FATAL = 50,
}

const isLogLevel = (logLevel?: string): logLevel is keyof typeof LOG_LEVEL => {
  if (logLevel && logLevel in LOG_LEVEL) {
    return true;
  }
  return false;
};

export const DEFAULT_LOG_LEVEL: keyof typeof LOG_LEVEL = isLogLevel(process.env.NEXT_PUBLIC_LOG_LEVEL)
  ? process.env.NEXT_PUBLIC_LOG_LEVEL
  : 'INFO';

/**
 * The log levels added to this array indicate that the related log functions should be dispatched as errors.
 */
export const ERROR_DISPATCH_LOG_LEVELS = ['ERROR', 'FATAL'] as const;
