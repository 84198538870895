import styled from 'styled-components';

import { GRAPH_HEIGHT } from '../../constants';

export const Root = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  height: ${GRAPH_HEIGHT}px;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing['06']};
  position: relative;
`;
