import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { FullButton, Header } from '@littleotter/legacy-components';

import { MarginChildren } from '../../../../../../components';
import { Description } from './Description';
import { GrowthImage } from './GrowthImage';

const paragraph =
  'This is a high-level version of your report. Schedule your first session ' +
  'to go in-depth into what this means for your family, get actionable ' +
  'feedback, and leave with recommendations you can implement right away.';

export interface ContinuingWithUsProps {
  onCTAClick?: () => void;
}

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2.5rem;
  img {
    max-width: 250px;
    height: auto;
    width: 100%;
    margin: 0 auto;
  }
`;

export const ContinuingWithUs: FC<PropsWithChildren<ContinuingWithUsProps>> = ({ onCTAClick }) => {
  return (
    <MarginChildren>
      <ImageContainer>
        <GrowthImage />
      </ImageContainer>
      <Header as="h5" recoleta>
        For an in-depth view of your report, book your first care session.
      </Header>
      <Description>{paragraph}</Description>
      <FullButton variant="secondary" onClick={onCTAClick}>
        <strong>Talk to a child mental health expert</strong>
      </FullButton>
    </MarginChildren>
  );
};
