import { math } from 'polished';
import styled from 'styled-components';

import { Header } from '@littleotter/legacy-components';

export const Container = styled.div`
  width: 100%;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 9.5`)};
  position: relative;
  display: flex;
  justify-content: end;
  flex-direction: column;
`;

export const Title = styled(Header).attrs({ as: 'h1' })`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 2`)};
  line-height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
`;

export const SubTitle = styled.p`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.text}CC;
`;

export const ContainerText = styled.div`
  margin: 0 ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
`;

export const HeaderBackground = styled.img<{
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}>`
  position: absolute;
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};
  object-fit: cover;
  z-index: -1;
`;
