import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { parseSearchString, stringifySearchParams } from '../../../utils';

type BaseSearchParams<QueryParams> = {
  [K in keyof QueryParams]?: string | string[];
};

export const useSearchParams = <
  SearchParams extends BaseSearchParams<SearchParams> = Record<string, string | string[]>,
>(): [
  SearchParams,
  {
    addSearchParams: (searchParams: SearchParams) => void;
    pushSearchParams: (searchParams: SearchParams) => void;
    replaceSearchParams: (searchParams: SearchParams) => void;
  },
] => {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => parseSearchString<SearchParams>(location.search), [location.search]);

  const addSearchParams = useCallback(
    (params: SearchParams) => {
      return history.push({ search: stringifySearchParams({ ...searchParams, ...params }) });
    },
    [searchParams, history]
  );
  const pushSearchParams = useCallback(
    (params: SearchParams) => history.push({ search: stringifySearchParams(params) }),
    [history]
  );
  const replaceSearchParams = useCallback(
    (params: SearchParams) => history.replace({ search: stringifySearchParams(params) }),
    [history]
  );

  return [
    searchParams,
    {
      addSearchParams,
      pushSearchParams,
      replaceSearchParams,
    },
  ];
};
