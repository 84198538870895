import { gql } from '@apollo/client';

const UPCOMING_APPOINTMENT_FRAGMENT = gql`
  fragment UpcomingAppointment on IntakeQAppointmentType {
    id
    status
    startDatetime
    endDatetime
    service {
      id
      name
    }
    provider {
      id
      firstName
      lastName
      providerTitle
      profilePicture
      externalProviderId
    }
    zoomLink
  }
`;

export default UPCOMING_APPOINTMENT_FRAGMENT;
