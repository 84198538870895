import { addDays } from 'date-fns';

import type { Fmhc } from '../../../graphql/lo1/generated';
import { routes } from '../../../routes';

const HOME_CHECKUP_HREF = routes.checkup.home.path;
const CARE_OVER_TIME_HREF = routes.reports.fmhcs.overTime.path;

export class Presenter {
  vm: ViewModel = {
    title: '',
  };

  private pm;

  constructor(pm: ProgrammersModel) {
    this.pm = pm;
  }

  load() {
    if (this.pm.canContinueFmhc) {
      this.vm = {
        href: HOME_CHECKUP_HREF,
        label: 'Required',
        title: 'Continue your Child & Family Mental Health Check-up!',
      };
      return;
    }

    if (!this.pm.hasSubmittedFirstFmhc) {
      this.vm = {
        href: HOME_CHECKUP_HREF,
        label: 'Required',
        title: 'Take a Child & Family Mental Health Check-up!',
      };
      return;
    }

    if (this.pm.canRetakeFmhc) {
      this.vm = {
        href: HOME_CHECKUP_HREF,
        label: 'Required',
        title: 'Retake your Check-up for an updated mental health report!',
      };
      return;
    }

    if (this.pm.canAccessCareOverTime) {
      this.vm = {
        href: CARE_OVER_TIME_HREF,
        label: 'New Feature',
        title: `View your family's care over time report`,
      };
      return;
    }

    // If we hit this case, the user has already submitted an FMHC and is not eligible for a re-take yet.
    this.vm = {
      isEnabled: false,
      label: 'Required',
      title: `Your next check-up will be available on ${this.getNextCheckupDateString()}`,
    };
  }

  private getNextCheckupDateString() {
    if (!this.pm.mostRecentFmhc?.submissionTime) {
      return '';
    }

    const submissionDateTime = new Date(this.pm.mostRecentFmhc.submissionTime);
    return addDays(submissionDateTime, this.pm.retakeThreshold).toLocaleDateString('en-us', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }
}

export interface ProgrammersModel {
  retakeThreshold: number;
  hasSubmittedFirstFmhc: boolean;
  canContinueFmhc: boolean;
  canRetakeFmhc: boolean;
  mostRecentFmhc: Fmhc | undefined;
  canAccessCareOverTime?: boolean;
}

interface ViewModel {
  href?: string;
  title: string;
  label?: string;
  isEnabled?: boolean;
}
