import { math } from 'polished';
import styled from 'styled-components';

import { ReactComponent as BaseKoalasImage } from '../assets/Koalas.svg';

export const KoalasImage = styled(BaseKoalasImage)`
  position: absolute;
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 10`)};
  height: auto;
  top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.2`)};
  left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.3`)};
`;
