type Props = {
  text: string;
  variables?: {
    [key: string]: string;
  };
};

/**
 * Given a string with '{{}}' that are considered variables, this function replaces those values with variables provider.
 * @param props.text string
 * @param props.variables object with variables to be replaced
 *
 * @example
 * ```
 * interpolateUrl({ text: 'This is an example with the child {{child_name}}', variables: { child_name: 'Noah' } }); // => 'This is an example with the child Noah'
 * ```
 */
const interpolateUrl = ({ text, variables = {} }: Props): string =>
  text.replace(/{{\s*(.*?)\s*}}/g, (_, identifier) => variables[identifier] ?? '');

/**
 * Given a string with '{{}}' that are considered variables, this function replaces those values with variables provider.
 * If some variables are not provided this function will throw an error
 * @param props.text string
 * @param props.variables object with variables to be replaced
 *
 * @example
 * ```
 * replaceStringVariables({ text: 'This is an example with the child {{child_name}}', variables: { child_name: 'Noah' } }); // => 'This is an example with the child Noah'
 * ```
 */
export const replaceStringVariables = ({ text, variables }: Props): string => {
  const result = interpolateUrl({ text, variables });
  const hasError = result.includes('undefined');
  if (hasError) throw new Error('Some variables are not defined');
  return result;
};
