import { gql, useQuery } from '@apollo/client';

import { type WelcomeCallQuery } from './__generated__/WelcomeCallQuery';

const INTAKEQ_SERVICE_FRAGMENT = gql`
  fragment IntakeqServiceFragment on IntakeQServiceType {
    id
    name
    price
    durationInMinutes
  }
`;
export const WELCOME_CALL_QUERY = gql`
  query WelcomeCallQuery {
    authenticatedWelcomeCallService {
      ...IntakeqServiceFragment
    }
  }

  ${INTAKEQ_SERVICE_FRAGMENT}
`;

export const useWelcomeCallQuery = () => useQuery<WelcomeCallQuery>(WELCOME_CALL_QUERY);
