import { lighten } from 'polished';
import styled, { type DefaultTheme } from 'styled-components';

export const Card = styled.div<{ backgroundColor: keyof DefaultTheme['deprecated_']['colors'] }>`
  width: 10rem;
  height: 5rem;
  border-radius: 5px;
  overflow: hidden;
  color: ${({ theme, backgroundColor }) =>
    backgroundColor === 'lightGray' ? lighten(0.2, theme.deprecated_.colors.black) : theme.deprecated_.colors.text};
  background-color: ${({ theme, backgroundColor }) => theme.deprecated_.colors[backgroundColor]};
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

export const LeftSection = styled.div`
  flex-grow: 1;
`;

export const RightSection = styled.div`
  align-self: flex-end;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;

export const RiskText = styled.div`
  margin-top: 0.5rem;
`;

export const Illustration = styled.img<{ isGrayscale: boolean }>`
  max-width: 5.75rem;
  ${({ isGrayscale }) => (isGrayscale ? 'filter: grayscale(1);' : '')}
`;
