import { math } from 'polished';
import styled from 'styled-components';

import { Button, Header, Tag } from '@littleotter/legacy-components';

import bgLeftShape from './assets/bg-left-shape.svg';
import bgRightShape from './assets/bg-right-shape.svg';
import bgTopShape from './assets/bg-top-shape.svg';

export const ReportContainer = styled.article`
  max-width: ${({ theme }) => theme.deprecated_.breakpoints.md};
  margin: 0 auto ${({ theme }) => theme.deprecated_.sizeBasis};
  padding: ${({ theme }) =>
    `0 ${math(`${theme.deprecated_.sizeBasis} * 0.75`)} 0 ${math(`${theme.deprecated_.sizeBasis} * 0.875`)}`};
`;

export const SubmissionWrapper = styled.div`
  position: relative;
`;

export const SubmissionContainer = styled.article`
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1`)};
  border-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1`)};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.75`)};
  background-color: ${({ theme }) => theme.deprecated_.colors.ghostWhite};
  position: relative;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
    border-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
    padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
  }
`;

export const ReportHeaderContainer = styled.header`
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
`;

export const SubmissionHeaderContainer = styled.header`
  margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const SubmissionHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SubmissionHeaderTitle = styled.h1`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
  line-height: 150%;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.75`)};
  }
`;

export const SubmissionHeaderItem = styled.p`
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1`)};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  line-height: 150%;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
  }
`;

export const SubmissionHeaderButtonsContainer = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: space-evenly;

  @media (min-width: 720px) {
    position: absolute;
    flex-direction: column;
    right: 30px;
    top: 15px;
  }
`;

export const SubmissionHeaderButton = styled(Button)`
  width: 80px;
  margin-bottom: 5px;
`;

export const ReportsSummaryCards = styled.div`
  display: grid;
  grid-template-rows: auto;
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.375`)};
  gap: ${({ theme }) => `${math(`${theme.deprecated_.sizeBasis} * 0.625`)}`};

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    gap: ${({ theme }) =>
      `${math(`${theme.deprecated_.sizeBasis} * 1.5`)} ${math(`${theme.deprecated_.sizeBasis} * 1.25`)}`};
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledBgLeftShape = styled.img.attrs({
  src: bgLeftShape,
})`
  position: absolute;
  left: 0;
  top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.125`)};
  z-index: -1;
  transform: translateX(-75%);

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    transform: translateX(-45%);
  }
`;

export const StyledBgRightShape = styled.img.attrs({
  src: bgRightShape,
})`
  position: absolute;
  right: 0;
  top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 15.75`)};
  z-index: -1;
  transform: translateX(80%);

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    transform: translateX(35%);
  }
`;

export const StyledBgTopShape = styled.img.attrs({
  src: bgTopShape,
})`
  position: absolute;
  top: 0px;
  z-index: -1;
  right: 0px;
  transform: translateX(20%) translateY(-80%);

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    right: 20%;
    transform: translateY(-75%);
  }
`;

export const ReportsBackgroundContainer = styled.div`
  position: relative;
  padding-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.25`)};
  overflow: hidden;
`;

export const ReportHeaderTitle = styled.h1`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.25`)};
  line-height: 150%;
`;

export const ReportHeaderSubtitle = styled.div`
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.75rem`)};
`;

export const ReportPointSeparator = styled.span<{
  horizontalMargin: number;
}>`
  &:before {
    content: '•';
    display: inline-block;
    margin: 0 ${({ theme, horizontalMargin }) => math(`${theme.deprecated_.sizeBasis} * ${horizontalMargin}`)};
  }
`;

export const HashLink = styled.a`
  text-decoration: none;
  user-select: none;
  cursor: pointer;
`;

export const StyledTag = styled(Tag)`
  border-radius: 3.5rem;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  margin-left: auto;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    margin-left: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

export const StyledContextContent = styled.div`
  margin-top: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const ReportContentTitle = styled(Header).attrs({ as: 'h5' })`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  color: ${({ theme }) => theme.deprecated_.colors.text};
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.5`)};
`;

export const StyledList = styled.ol`
  list-style: decimal;
  margin-left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
`;

export const StyledAnswer = styled.strong`
  white-space: nowrap;
`;

export const StyledLoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  background: #fff;
  z-index: 999;
  padding-top: 50px;
`;
