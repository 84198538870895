import styled, { css } from 'styled-components';

import { ConnectedCircleListStyle, ConnectedDiscListStyle, DiscListStyle } from './styled-variants';

export const ListDecorationStyle = css`
  display: block;
  position: absolute;
  left: 0;
  top: calc((var(--line-height) * 1em - var(--decoration-size)) / 2);
  height: var(--decoration-size);
  width: var(--decoration-size);
  border-radius: 50%;
  border: var(--decoration-border-width) solid var(--decoration-border-color);
  background-color: var(--decoration-color);
`;

export const ListDecorationLineStyle = css`
  --decoration-line-pattern-length: calc(var(--decoration-line-dash-length) + var(--decoration-line-gap-length));

  display: block;
  position: absolute;
  top: calc((var(--line-height) * 1em + var(--decoration-size)) / 2);
  left: calc((var(--decoration-size) - var(--decoration-line-width)) / 2);
  width: var(--decoration-line-width);
  height: calc(100% - var(--decoration-size));
  background-image: linear-gradient(
    to bottom,
    var(--decoration-line-color) var(--decoration-line-dash-length),
    transparent var(--decoration-line-dash-length)
  );
  background-size: var(--decoration-line-width) var(--decoration-line-pattern-length);
  background-repeat: repeat-y;
`;

export const ListStyles = css`
  /* List items' line height */
  /* Set it to the line height of the items' first child */
  /* so that the decorations will be vertically centered */
  --line-height: ${({ theme }) => theme.deprecated_.lineHeight};

  --decoration-gap: 0.8em;

  --item-gap: ${({ theme }) => theme.deprecated_.sizeBasis};

  --decoration-size: 0.8em;
  --decoration-color: ${({ theme }) => theme.deprecated_.colors.white};
  --decoration-border-color: ${({ theme }) => theme.deprecated_.colors.slateGray};
  --decoration-border-width: 1px;

  --decoration-line-width: 1px;
  --decoration-line-dash-length: 4px;
  --decoration-line-gap-length: 4px;
  --decoration-line-color: ${({ theme }) => theme.deprecated_.colors.slateGray};

  list-style: none;

  li {
    position: relative;
    padding-left: calc(var(--decoration-size) + var(--decoration-gap));

    /* List item decoration */
    &::before {
      content: '';

      ${ListDecorationStyle};
    }

    &:not(:last-of-type) {
      padding-bottom: var(--item-gap);

      /* Vertical line connecting all items */
      &::after {
        content: '';

        ${ListDecorationLineStyle}
      }
    }
  }
`;

type StyledUlProps = {
  variant: 'disc' | 'connected-disc' | 'connected-circle';
};

export const StyledUl = styled.ul<StyledUlProps>`
  ${ListStyles}

  ${({ variant }) =>
    ({
      disc: DiscListStyle,
      'connected-disc': ConnectedDiscListStyle,
      'connected-circle': ConnectedCircleListStyle,
    })[variant]}
`;
