import styled from 'styled-components';

export const StyledCaregiverLinks = styled.ul`
  margin: 10px auto 20px;
  text-align: center;
`;

export const StyledCaregiverLinkRow = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  & > span,
  & > a {
    background-color: #f8f8f8;
    border-radius: 10px;
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  & > a {
    color: ${({ theme }) => theme.deprecated_.colors.textBlue};

    &:hover {
      text-decoration: underline;
    }
  }
`;
