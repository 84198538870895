import { type FC, type PropsWithChildren } from 'react';

import { type PromptOptions } from '../../../../types';
import { parseInlineMarkdown } from '../../../../utils/markdownParser';
import { StyledButton, StyledHeader, StyledImg, StyledNote, StyledText } from '../styled';

export type QuestionPromptProps = {
  promptOptions: PromptOptions;
  totalQuestions: number;
  nextButtonText?: string;
  goNext: () => void;
};

export const QuestionPrompt: FC<PropsWithChildren<QuestionPromptProps>> = ({
  promptOptions,
  totalQuestions,
  goNext,
  nextButtonText,
}) => {
  const { prompt, promptImage, questionText, duration } = promptOptions;
  const parsedPrompt = prompt && parseInlineMarkdown({ template: prompt });

  // Arrow function used to remove SyntheticEvent that is passed as an argument
  const onNextClick = () => goNext();

  const buttonText = nextButtonText ?? 'Next';

  // TODO: Decide if we want to use dangerouslySet and evaluate it's risks
  // Consulting with Martin on this. original: <StyledText>{questionText}</StyledText>
  return (
    <>
      <StyledImg src={promptImage} />
      {parsedPrompt && <StyledHeader dangerouslySetInnerHTML={{ __html: parsedPrompt }} />}
      {questionText && <StyledText dangerouslySetInnerHTML={{ __html: questionText }} />}
      <StyledNote>
        {totalQuestions} questions {duration && ` • ${duration}`}
      </StyledNote>
      <StyledButton onClick={onNextClick} variant="secondary" size="large">
        {buttonText}
      </StyledButton>
    </>
  );
};
