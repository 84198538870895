import {
  Conversation,
  type ConversationEvent,
  type ConversationEventTypeMap,
  type LastMessage,
  type Message,
  type Paginator,
  type Participant,
} from '@twilio/conversations';

import { getLastActiveAt } from '../../../pages/Messages/utils/getMessageLastActiveAt';
import { type ClosedConversationInfo } from './hooks/useClosedConversationsInfo';

// ConversationPreview is used in the ConversationList component tree. This is necessary since we have two kinds of
// conversation objects to display in the list:
//
//   - @twilio/conversations Conversation (from the SDK)
//   - ClosedConversation (from littleotterone's GraphQL)
//
// The SDK only returns active + inactive conversations, which is why we fetch closed conversations from LO1.
//
// Any Conversation fields/methods we need to reference in the ConversationList component tree can be added to this
// interface.
export type ConversationPreview = {
  sid: string;
  lastActiveAt: Date;
  closed: boolean;
  lastMessage?: LastMessage;
  lastReadMessageIndex: number | null;
  getMessagesCount: () => Promise<number>;
  getMessages: (pageSize?: number, anchor?: number, direction?: 'backwards' | 'forward') => Promise<Paginator<Message>>;
  getParticipants: () => Promise<Participant[]>;
  getUnreadMessagesCount: () => Promise<number | null>;
  on: <Event extends ConversationEvent>(
    event: Event,
    callback: (event: ConversationEventTypeMap[Event]) => void
  ) => void;
  off: <Event extends ConversationEvent>(
    event: Event,
    callback: (event: ConversationEventTypeMap[Event]) => void
  ) => void;
};

export const previewFromConversation = (convo: Conversation | ClosedConversationInfo): ConversationPreview => {
  if (convo instanceof Conversation) {
    return {
      sid: convo.sid,
      lastActiveAt: getLastActiveAt(convo),
      closed: false,
      lastMessage: convo.lastMessage,
      lastReadMessageIndex: convo.lastReadMessageIndex,
      getMessages: convo.getMessages.bind(convo),
      getMessagesCount: convo.getMessagesCount.bind(convo),
      getParticipants: convo.getParticipants.bind(convo),
      getUnreadMessagesCount: convo.getUnreadMessagesCount.bind(convo),
      on: convo.on.bind(convo),
      off: convo.off.bind(convo),
    };
  }
  return {
    sid: convo.sid,
    lastActiveAt: getLastActiveAt(convo),
    closed: true,
    lastReadMessageIndex: null,
    getMessages: () => Promise.resolve(noopMessagePaginator),
    getMessagesCount: () => Promise.resolve(0),
    getParticipants: () => Promise.resolve([]),
    getUnreadMessagesCount: () => Promise.resolve(0),
    on: () => {
      // do nothing
    },
    off: () => {
      // do nothing
    },
  };
};

const noopMessagePaginator: Paginator<Message> = {
  hasNextPage: false,
  hasPrevPage: false,
  items: [],
  nextPage: () => Promise.resolve(noopMessagePaginator),
  prevPage: () => Promise.resolve(noopMessagePaginator),
};
