import styled from 'styled-components';

type ContainerProps = {
  barAmount: number;
};

export const Container = styled.div<ContainerProps>`
  --bar-base-height: 3rem;
  --bar-width: 0.75rem;
  --bar-margin: 0.5rem;

  width: ${({ barAmount }) => `calc(${barAmount} * var(--bar-width) + ${barAmount - 1} * var(--bar-margin))`};
`;

export const GraphContainer = styled.div`
  display: flex;
  height: 12rem;

  & > *:not(:last-child) {
    margin-right: var(--bar-margin);
  }
`;

export const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: var(--bar-width);
`;

type BarProps = {
  percent: number;
  active: boolean;
};

export const Bar = styled.div<BarProps>`
  border-radius: 3px;
  height: ${({ percent }) => `calc(var(--bar-base-height) + ${percent / 100} * (100% - var(--bar-base-height)))`};
  background-color: ${({ theme, active }) =>
    active ? theme.deprecated_.colors.green : theme.deprecated_.colors.lightBackground};
`;

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
`;

export const LabelText = styled.div`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  margin-right: 1rem;
  flex: 0 0 auto;
`;

export const ArrowBody = styled.div`
  position: relative;
  height: 1px;
  flex: 1 0 1rem;
  margin-right: 0.5rem;
  background-color: #000;

  &:after {
    content: '';
    position: absolute;
    right: -0.5rem;
    bottom: -0.25rem;
    border-left: 0.5rem solid #000;
    border-top: 0.25rem solid transparent;
    border-right: 0;
    border-bottom: 0.25rem solid transparent;
  }
`;
