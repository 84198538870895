import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
  padding-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  position: relative;
  max-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 32`)};
  margin: 0 auto;

  h3,
  h4 {
    text-align: center;
  }

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4`)};
    padding-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4.5`)};
  }
`;

const Content = styled.div`
  max-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 19`)};
  margin: 0 auto;

  animation-name: ${fadeInAnimation};
  animation-duration: 0.3s;
  animation-delay: 1s;
  animation-fill-mode: both;
`;

export const FadeInContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};
