import { math, rgba } from 'polished';
import { useEffect, useRef, useState, type FC, type PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Tag as BaseTag } from '@littleotter/legacy-components';

type TagVariant = 'primary' | 'secondary';

type TagProps = {
  selectableTagVariant: TagVariant;
  selected: boolean;
  onClick: () => void;
};

const primaryTagStyles = css<TagProps>`
  background-color: ${({ selected, theme }) =>
    selected ? theme.deprecated_.colors.yellow : theme.deprecated_.colors.lightestGray};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.75`)};
`;

const secondaryTagStyles = css<TagProps>`
  background-color: ${({ selected, theme }) =>
    rgba(selected ? theme.deprecated_.colors.yellow : theme.deprecated_.colors.lightGray, 0.4)};
  color: ${({ selected, theme }) => rgba(theme.deprecated_.colors.text, selected ? 1 : 0.4)};
`;

const Tag = styled(BaseTag)<TagProps>`
  margin: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.35`)};
  cursor: pointer;
  user-select: none;

  ${({ selectableTagVariant }) =>
    ({
      primary: primaryTagStyles,
      secondary: secondaryTagStyles,
    })[selectableTagVariant]};
`;

export type SelectableTagProps = {
  variant?: TagVariant;
  selected?: boolean;
  onSelect?: (isSelected: boolean) => void;
};

export const SelectableTag: FC<PropsWithChildren<SelectableTagProps>> = ({ selected, onSelect, variant, children }) => {
  const onSelectRef = useRef(onSelect);
  const [isSelected, setIsSelected] = useState(selected ?? false);

  useEffect(() => {
    setIsSelected(selected ?? false);
  }, [selected]);

  useEffect(() => {
    onSelectRef.current = onSelect;
  }, [onSelect]);

  useEffect(() => {
    onSelectRef.current?.(isSelected);
  }, [isSelected]);

  const toggleSelected = () => setIsSelected((value) => !value);

  return (
    <Tag selected={isSelected} onClick={toggleSelected} selectableTagVariant={variant ?? 'primary'}>
      {children}
    </Tag>
  );
};
