import { type FC, type PropsWithChildren } from 'react';
import { IoMdPhotos } from 'react-icons/io';
import styled from 'styled-components';

import aacpIllustration from './assets/aacpIllustration.svg';
import illustration0 from './assets/illustration0.svg';
import illustration1 from './assets/illustration1.svg';
import illustration2 from './assets/illustration2.svg';
import illustration3 from './assets/illustration3.svg';
import illustration4 from './assets/illustration4.svg';
import illustration5 from './assets/illustration5.svg';
import illustration6 from './assets/illustration6.svg';
import illustration7 from './assets/illustration7.svg';
import illustration8 from './assets/illustration8.svg';
import illustration9 from './assets/illustration9.svg';
import memberResourceHeaderImage from './assets/memberResourceHeaderImage.svg';

const defaultIllustrations = [
  illustration0,
  illustration1,
  illustration2,
  illustration3,
  illustration4,
  illustration5,
  illustration6,
  illustration7,
  illustration8,
  illustration9,
];

const StyledImg = styled.img`
  width: 100%;
`;

export const ResourceIllustration: FC<PropsWithChildren<{ pk: number; tags: string[] }>> = ({ pk, tags }) => {
  const whichIllustration = pk % 10;
  let illustrationPath: string = defaultIllustrations[whichIllustration];

  if (tags.includes('Parent Questions')) {
    illustrationPath = aacpIllustration;
  } else if (tags.includes('Membership')) {
    illustrationPath = memberResourceHeaderImage;
  } else if (!illustrationPath) {
    return <IoMdPhotos />;
  }

  return <StyledImg src={illustrationPath} alt="resource illustration" />;
};
