import { math } from 'polished';
import BasePicker from 'react-month-picker';
import styled, { css } from 'styled-components';

import { Input as BaseInput } from '../Input';

import 'react-month-picker/css/month-picker.css';

// Add another pseudo element with background color to fill the space behind the curved edge of an active .rmp-btn
const activeBtnSecondBackgroundStyles = css`
  .rmp-pad .rmp-btn.range {
    position: relative !important;
    overflow: visible !important;

    &:before {
      background-color: ${({ theme }) => theme.deprecated_.colors.lightYellow} !important;
      position: absolute !important;
      z-index: -1;
      top: 0;
      bottom: 0;
    }
  }

  .rmp-pad:first-of-type {
    // When selecting the start date, set the background on the next element and move it behind the active one.
    // As long as the active element is followed by a selected one,
    // and the selected one is not the first element in its row.
    @media (max-width: 767px) {
      .rmp-btn.range.active + .rmp-btn.range.select:not(:nth-child(4n + 1)):before {
        left: -50%;
        right: 50%;
        content: '';
      }
    }

    @media (min-width: 768px) {
      .rmp-btn.range.active + .rmp-btn.range.select:not(:nth-child(3n + 1)):before {
        left: -50%;
        right: 50%;
        content: '';
      }
    }
  }

  .rmp-pad:last-of-type {
    // When selecting the end date, set the background on the active one directly.
    // As long as the active element is preceded by a selected one.
    .rmp-btn.range.select + .rmp-btn.range.active:before {
      left: 0;
      right: 50%;
      content: '';
    }
  }
`;

export const Picker = styled(BasePicker)`
  .rmp-popup {
    border-radius: ${({ theme }) => theme.deprecated_.border.radius} !important;

    @media (max-width: 767px) {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      margin-bottom: max(
        calc(env(safe-area-inset-bottom) + ${({ theme }) => math(`3.5 * ${theme.deprecated_.sizeBasis}`)}),
        ${({ theme }) => math(`4.5 * ${theme.deprecated_.sizeBasis}`)}
      ) !important;
    }
  }

  .rmp-pad {
    background-color: transparent !important;
  }

  .rmp-btn.range {
    &:hover {
      background-color: transparent !important;
    }

    &.active {
      background-color: ${({ theme }) => theme.deprecated_.colors.yellow} !important;
      border-radius: ${({ theme }) => theme.deprecated_.border.radius} !important;
    }

    &.select {
      background-color: ${({ theme }) => theme.deprecated_.colors.lightYellow} !important;
    }
  }

  ${activeBtnSecondBackgroundStyles};
`;

export const Input = styled(BaseInput)`
  user-select: none;
  cursor: default;
`;
