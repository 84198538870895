import { math } from 'polished';
import { type ComponentType, type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

type Alignment = 'top' | 'center' | 'bottom';

type IconProps = {
  className?: string;
};

type GrayCardWrapperProps = {
  alignIcon: Alignment;
};

const GrayCardWrapper = styled.div<GrayCardWrapperProps>`
  background-color: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  }

  .icon {
    width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
    height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
    color: ${({ theme }) => theme.deprecated_.colors.lightRed};
    margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
    flex: 0 0 auto;
    align-self: ${({ alignIcon }) =>
      ({
        top: 'flex-start',
        center: 'center',
        bottom: 'flex-end',
      })[alignIcon]};
  }
`;

export type GrayCardProps = {
  Icon?: ComponentType<IconProps>;
  alignIcon?: Alignment;
  className?: string;
};

export const GrayCard: FC<PropsWithChildren<GrayCardProps>> = ({ Icon, alignIcon = 'center', className, children }) => (
  <GrayCardWrapper alignIcon={alignIcon} className={className}>
    {Icon && <Icon className="icon" />}
    <p>{children}</p>
  </GrayCardWrapper>
);
