import styled, { css } from 'styled-components';

const marginChildren = (spacing: number) => css`
  & > *:not(:last-child) {
    margin-bottom: ${spacing}rem;
  }
`;

export const FamilyDetailsContainer = styled.div`
  ${marginChildren(2)}
`;

export const FamilyDetailsTopSectionContainer = styled.div`
  display: grid;
  gap: 2rem;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    grid-template-columns: 2fr 3fr;
  }
`;

export const FamilyDetailsTitleSubSection = styled.section`
  ${marginChildren(1)}
`;

export const StyledSection = styled.section`
  ${marginChildren(1)}
`;
