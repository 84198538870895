import { type FC, type PropsWithChildren } from 'react';

import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../components/MetadataRoute';
import { RouteSwitch } from '../../components/RouteSwitch';
import { routes } from '../../routes';
import { Assessment } from './Assessment';
import { AssessmentStep } from './AssessmentStep';

export const Assessments: FC<PropsWithChildren> = () => (
  <>
    <RouteSwitch>
      <MetadataRoute {...routeProps(routes.assessments.assessment.home)}>
        <Assessment />
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.assessments.assessment.step)}>
        <AssessmentStep />
      </MetadataRoute>
    </RouteSwitch>
  </>
);
