import { useMemo } from 'react';

import { getWorryDomainsFromChildren } from '$shared/utils/getWorryDomainsFromChildren';

import { type ViewerFamilyChildrenWorryDomainsFragment } from '../../../../../graphql/__generated__/ViewerFamilyChildrenWorryDomainsFragment';

type UseWorryDomains = (viewer: ViewerFamilyChildrenWorryDomainsFragment | null | undefined) => string;

/**
 * Get a string separated comma of all of the worry domains:
 * - for every children, filtered for nullish values and duplicates
 * - for the viewer
 * - for the viewer's family
 */
export const useWorryDomains: UseWorryDomains = (viewer) =>
  useMemo(() => {
    if (!viewer || !viewer.family) {
      return '';
    }

    const childrenWorryDomainsNodes = getWorryDomainsFromChildren(viewer.family.children);

    const childrenWorryDomains = childrenWorryDomainsNodes.map((node) => node.name);
    const caregiverWorryDomains = viewer.worryDomains.edges.map((edge) => edge?.node?.name ?? '');
    const familyWorryDomains = viewer.family.worryDomains.edges.map((edge) => edge?.node?.name ?? '');

    return [...childrenWorryDomains, ...caregiverWorryDomains, ...familyWorryDomains]
      .filter((worryDomain) => !!worryDomain)
      .join(', ');
  }, [viewer]);
