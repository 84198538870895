import { useEffect, type FC, type PropsWithChildren } from 'react';

import { useEventTracking } from '$services/event-tracking';
import { getChildIntroImage } from '$shared/utils/childIntroImage';

import { ChildIntroImage } from '../../../components';
import {
  StyledActionContainer,
  StyledButton,
  StyledButtonLink,
  StyledContainer,
  StyledContent,
  StyledText,
} from './styled';

enum CheckupStepEnum {
  FIRST = 'First',
  CHILD_INTRO = 'Child Intro',
  FINAL = 'Final',
}

export type ChildIntroProps = {
  childName: string;
  isSeekingCare?: boolean;
  trackingSubject?: string;
  onSkipChild: () => void;
  goNextPage: () => void;
};

export const ChildIntroContent: FC<PropsWithChildren<ChildIntroProps>> = ({
  childName,
  isSeekingCare = true,
  trackingSubject,
  onSkipChild,
  goNextPage,
}) => {
  const icon = getChildIntroImage(childName);

  const { track } = useEventTracking();
  useEffect(() => {
    track('VIEWED_CHECKUP_STEP', {
      step: CheckupStepEnum.CHILD_INTRO,
      subject: trackingSubject,
    });
  }, [trackingSubject, track]);

  return (
    <StyledContainer>
      <StyledContent>
        <ChildIntroImage src={icon} />
        <StyledText>
          Let's focus on <span>{childName}</span>.
        </StyledText>
      </StyledContent>
      <StyledActionContainer>
        <StyledButton onClick={goNextPage} variant="secondary">
          Let’s Start
        </StyledButton>
        {!isSeekingCare && <StyledButtonLink onClick={onSkipChild}>Skip for now</StyledButtonLink>}
      </StyledActionContainer>
    </StyledContainer>
  );
};
