import { math } from 'polished';
import { type FC, type PropsWithChildren, type ReactElement } from 'react';
import { type IconType } from 'react-icons';
import styled from 'styled-components';

import { theme as themestyle } from '../../styles';

type Alignment = 'top' | 'center' | 'bottom';

type CardWrapperProps = {
  background: string;
  alignIcon: Alignment;
};

const CardWrapper = styled.div<CardWrapperProps>`
  background-color: ${({ background }) => background};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  }

  .icon {
    width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
    height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
    color: ${({ theme }) => theme.deprecated_.colors.lightRed};
    margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
    flex: 0 0 auto;
    align-self: ${({ alignIcon }) =>
      ({
        top: 'flex-start',
        center: 'center',
        bottom: 'flex-end',
      })[alignIcon]};
  }
`;

const StyledImage = styled.img`
  align-self: flex-start;
  margin: 0 ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.75`)};
`;

export type CardProps = {
  Icon?: IconType | ReactElement;
  Image?: string;
  background?: string;
  alignIcon?: Alignment;
  className?: string;
};

export const Card: FC<PropsWithChildren<CardProps>> = ({
  Icon,
  Image,
  background = themestyle.deprecated_.colors.lightestGray,
  alignIcon = 'center',
  className,
  children,
}) => (
  <CardWrapper background={background} alignIcon={alignIcon} className={className}>
    <>
      {Icon}
      {Image && <StyledImage src={Image} />}
      <p>{children}</p>
    </>
  </CardWrapper>
);
