import { format } from 'date-fns';
import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/legacy-components';

import { type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType } from '../../../graphql/__generated__/BackToSchoolReportQuery';
import {
  CaregiverMentalHealth,
  ChildEmotionalAndSocialHealth,
  FamilyStress,
  SchoolFeelings,
  Summary,
} from './sections';
import { Article, Disclaimer, MainHeader, StyledBrandCard } from './styled';

export type BackToSchoolReportContentProps = {
  reportResult: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType;
  updatedAt: Date;
};

export const BackToSchoolReportContent: FC<PropsWithChildren<BackToSchoolReportContentProps>> = ({
  reportResult,
  updatedAt,
}) => {
  const {
    childInfo,
    generalRisks,
    familyStress,
    emotionalChallenges,
    socialChallenges,
    caregiverAnxiety,
    caregiverDepression,
    wordCloud,
    schoolAnxiety,
    newSchool,
    teacherCalled,
    socialStrengths,
  } = reportResult;

  return (
    <Article>
      <MainHeader>
        <Header as="h3">Mental Health Check-Up & Toolkit</Header>
        <p>
          {format(updatedAt, 'MMMM d, y')} | {childInfo.childName} | {childInfo.childAge} Years Old
        </p>
        <p>Completed by {childInfo.caregiverName}</p>
      </MainHeader>

      <StyledBrandCard>
        <strong>Every family has faced challenges this year.</strong> This toolkit gives you insights and actionable
        ways to support your whole family during the transition to the new school year.
      </StyledBrandCard>

      <Summary generalRisksResult={generalRisks} />

      <ChildEmotionalAndSocialHealth
        emotionalChallengesResult={emotionalChallenges}
        socialChallengesResult={socialChallenges}
      />

      {(caregiverAnxiety || caregiverDepression) && (
        <CaregiverMentalHealth
          caregiverAnxietyResult={caregiverAnxiety}
          caregiverDepressionResult={caregiverDepression}
          generalCaregiverMentalHealthResult={generalRisks.caregiverMentalHealth}
        />
      )}

      <FamilyStress familyStressResult={familyStress} />

      <SchoolFeelings
        wordCloud={wordCloud}
        schoolAnxiety={schoolAnxiety}
        newSchool={newSchool}
        teacherCalled={teacherCalled}
        socialStrengths={socialStrengths}
      />

      <Disclaimer>
        <em>
          The content is not intended to be a substitute for professional medical advice, diagnosis, or treatment.
          Always seek the advice of your physician or other qualified health provider with any questions you may have
          regarding a medical condition.
        </em>
      </Disclaimer>
    </Article>
  );
};
