import { type ReactElement } from 'react';
import { type TableInstance } from 'react-table';

import { Table } from '@littleotter/legacy-components';

type BaseTableProps<T extends object> = {
  tableInstance: TableInstance<T>;
};

export const BaseTable = <T extends object>({
  tableInstance: { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow },
}: BaseTableProps<T>): ReactElement => (
  // getHeaderGroupProps, getHeaderProps, getRowProps, and getCellProps already inject the key prop
  /* eslint-disable react/jsx-key */
  <Table {...getTableProps()}>
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell) => (
              <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            ))}
          </tr>
        );
      })}
    </tbody>
  </Table>
  /* eslint-enable react/jsx-key */
);
