import styled from 'styled-components';

export const MarginChildren = styled.div`
  > * {
    margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => `${theme.deprecated_.colors.text}CC`}; /* 80% opacity */
`;

export const StyledForm = styled.form`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    width: auto;
  }

  > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;

export const StyledActionsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 34px 24px;
  margin-bottom: 4rem;

  a {
    display: block;
  }

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    max-width: 300px;
    margin: 0 auto;
    position: relative;
    padding: 50px 24px;
  }
`;
