import { type LocationDescriptor } from 'history';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { routes } from '../../../routes';
import { type Route } from '../routes/types';
import { REDIRECT_KEY, RedirectError, type LocationState } from './types';

type useRedirectCallbackProps = {
  defaultRedirectTo?: Route;
  state?: Record<string, unknown>;
  persistState?: boolean;
};
/**
 * Returns a callback to redirect to where the redirect param says to go.
 *
 * If `persistState`, then the current location state persists into the next location,
 * overridden by any new values in `state`.
 */
export const useRedirectCallback = ({
  defaultRedirectTo = routes.care.home,
  state = {},
  persistState = false,
}: useRedirectCallbackProps): (() => void) => {
  const history = useHistory<LocationState>();
  const redirectTo = getRedirectValue();
  return useCallback(() => {
    const currentState = persistState ? history.location.state : {};
    history.push({
      pathname: redirectTo ?? defaultRedirectTo.url(),
      state: { ...currentState, ...state },
    });
  }, [defaultRedirectTo, history, persistState, redirectTo, state]);
};

type useGotoProps = {
  route: Route;
  redirect?: Route;
  forward?: boolean;
  extraParams?: string[];
  state?: Record<string, unknown>;
  persistState?: boolean;
  referrerLocation?: string;
};
/**
 * Returns a callback to go to the `route` specified.
 *
 * If `persistState`, then the current location state persists into the next location,
 * overridden by any new values in `state`.
 *
 * See {@link getLocationDescriptor} for details.
 */
export const useGoto = ({
  route,
  redirect,
  forward = false,
  extraParams = [],
  state = {},
  persistState = false,
  referrerLocation,
}: useGotoProps): (() => void) => {
  const history = useHistory<LocationState>();
  const refLocation = referrerLocation ?? history.location.pathname;
  const currentState = persistState ? history.location.state : {};
  const locationWithRedirect = getLocationDescriptor({
    route,
    redirect,
    forward,
    extraParams,
    state: { ...currentState, ...state },
    referrerLocation: refLocation,
  });
  return useCallback(() => history.push(locationWithRedirect), [history, locationWithRedirect]);
};

/**
 * Returns a LocationDescriptor with the route specified.
 *
 * If `redirect` is specified, a redirect param is added to the search params.
 *
 * If `forward` is true, then the current redirect search param is forwarded.
 *
 * If `extraParams` are passed, then those params are added as part of the URL search params
 *
 * If both redirect and forward are specified, a {@link RedirectError} is thrown.
 */
export const getLocationDescriptor = ({
  route,
  redirect,
  forward = false,
  extraParams = [],
  state = {},
  referrerLocation,
}: useGotoProps): LocationDescriptor<LocationState> => {
  if (redirect && forward) {
    throw new RedirectError('cannot redirect with redirect AND forward');
  }
  const redirectTo = getRedirectValue();
  const redirectValue = redirect?.url() || (forward && redirectTo);
  const redirectParam = redirectValue ? `${REDIRECT_KEY}=${redirectValue}` : '';
  const searchParams = redirectParam.length ? [redirectParam, ...extraParams] : extraParams;
  return {
    pathname: route.url(),
    search: searchParams.join('&'),
    state: { ...state, from: referrerLocation },
  };
};

export const getRedirectValue = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get(REDIRECT_KEY);
  return redirectTo;
};
