export type TzName = string & { readonly: true };

enum ServiceLocation {
  Alabama = 'Alabama',
  Alaska = 'Alaska',
  AmericanSamoa = 'American Samoa',
  Arizona = 'Arizona',
  Arkansas = 'Arkansas',
  California = 'California',
  Colorado = 'Colorado',
  Connecticut = 'Connecticut',
  Delaware = 'Delaware',
  DistrictOfColumbia = 'District Of Columbia',
  FederatedStatesOfMicronesia = 'Federated States Of Micronesia',
  Florida = 'Florida',
  Georgia = 'Georgia',
  Guam = 'Guam',
  Hawaii = 'Hawaii',
  Idaho = 'Idaho',
  Illinois = 'Illinois',
  Indiana = 'Indiana',
  Iowa = 'Iowa',
  Kansas = 'Kansas',
  Kentucky = 'Kentucky',
  Louisiana = 'Louisiana',
  Maine = 'Maine',
  MarshallIslands = 'Marshall Islands',
  Maryland = 'Maryland',
  Massachusetts = 'Massachusetts',
  Michigan = 'Michigan',
  Minnesota = 'Minnesota',
  Mississippi = 'Mississippi',
  Missouri = 'Missouri',
  Montana = 'Montana',
  Nebraska = 'Nebraska',
  Nevada = 'Nevada',
  NewHampshire = 'New Hampshire',
  NewJersey = 'New Jersey',
  NewMexico = 'New Mexico',
  NewYork = 'New York',
  NorthCarolina = 'North Carolina',
  NorthDakota = 'North Dakota',
  NorthernMarianaIslands = 'Northern Mariana Islands',
  Ohio = 'Ohio',
  Oklahoma = 'Oklahoma',
  Oregon = 'Oregon',
  Palau = 'Palau',
  Pennsylvania = 'Pennsylvania',
  PuertoRico = 'Puerto Rico',
  RhodeIsland = 'Rhode Island',
  SouthCarolina = 'South Carolina',
  SouthDakota = 'South Dakota',
  Tennessee = 'Tennessee',
  Texas = 'Texas',
  Utah = 'Utah',
  Vermont = 'Vermont',
  VirginIslands = 'Virgin Islands',
  Virginia = 'Virginia',
  Washington = 'Washington',
  WestVirginia = 'West Virginia',
  Wisconsin = 'Wisconsin',
  Wyoming = 'Wyoming',
}

type ServiceLocationMetadata = {
  location: ServiceLocation;
  abbreviation: string;
  timezone: TzName;
};

export const serviceLocations: ServiceLocationMetadata[] = [
  {
    location: ServiceLocation.Alabama,
    abbreviation: 'AL',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.Alaska,
    abbreviation: 'AK',
    timezone: 'AKST' as TzName, // TODO: Handle Aleutian Islands
  },
  {
    location: ServiceLocation.AmericanSamoa,
    abbreviation: 'AS',
    timezone: 'SST' as TzName,
  },
  {
    location: ServiceLocation.Arizona,
    abbreviation: 'AZ',
    timezone: 'MST' as TzName,
  },
  {
    location: ServiceLocation.Arkansas,
    abbreviation: 'AR',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.California,
    abbreviation: 'CA',
    timezone: 'PST' as TzName,
  },
  {
    location: ServiceLocation.Colorado,
    abbreviation: 'CO',
    timezone: 'MST' as TzName,
  },
  {
    location: ServiceLocation.Connecticut,
    abbreviation: 'CT',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.Delaware,
    abbreviation: 'DE',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.DistrictOfColumbia,
    abbreviation: 'DC',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.FederatedStatesOfMicronesia,
    abbreviation: 'FM',
    timezone: 'Pacific/Chuuk' as TzName,
  },
  {
    location: ServiceLocation.Florida,
    abbreviation: 'FL',
    timezone: 'EST' as TzName, // TODO: West of Apalachicola River uses CST
  },
  {
    location: ServiceLocation.Georgia,
    abbreviation: 'GA',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.Guam,
    abbreviation: 'GU',
    timezone: 'ChST' as TzName,
  },
  {
    location: ServiceLocation.Hawaii,
    abbreviation: 'HI',
    timezone: 'HST' as TzName,
  },
  {
    location: ServiceLocation.Idaho,
    abbreviation: 'ID',
    timezone: 'MST' as TzName, // TODO: North of the Salmon River uses PST
  },
  {
    location: ServiceLocation.Illinois,
    abbreviation: 'IL',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.Indiana,
    abbreviation: 'IN',
    timezone: 'EST' as TzName, // TODO: Some counties use CST
  },
  {
    location: ServiceLocation.Iowa,
    abbreviation: 'IA',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.Kansas,
    abbreviation: 'KS',
    timezone: 'CST' as TzName, // TODO: Some counties use MST
  },
  {
    location: ServiceLocation.Kentucky,
    abbreviation: 'KY',
    timezone: 'CST' as TzName, // TODO: West half of the state uses CST, East half of the state uses EST
  },
  {
    location: ServiceLocation.Louisiana,
    abbreviation: 'LA',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.Maine,
    abbreviation: 'ME',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.MarshallIslands,
    abbreviation: 'MH',
    timezone: 'Pacific/Kwajalein' as TzName,
  },
  {
    location: ServiceLocation.Maryland,
    abbreviation: 'MD',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.Massachusetts,
    abbreviation: 'MA',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.Michigan,
    abbreviation: 'MI',
    timezone: 'EST' as TzName, // TODO: Some counties use CST
  },
  {
    location: ServiceLocation.Minnesota,
    abbreviation: 'MN',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.Mississippi,
    abbreviation: 'MS',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.Missouri,
    abbreviation: 'MO',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.Montana,
    abbreviation: 'MT',
    timezone: 'MST' as TzName,
  },
  {
    location: ServiceLocation.Nebraska,
    abbreviation: 'NE',
    timezone: 'CST' as TzName, // TODO: Western part uses MST
  },
  {
    location: ServiceLocation.Nevada,
    abbreviation: 'NV',
    timezone: 'PST' as TzName, // TODO: Two small towns use MST
  },
  {
    location: ServiceLocation.NewHampshire,
    abbreviation: 'NH',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.NewJersey,
    abbreviation: 'NJ',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.NewMexico,
    abbreviation: 'NM',
    timezone: 'MST' as TzName,
  },
  {
    location: ServiceLocation.NewYork,
    abbreviation: 'NY',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.NorthCarolina,
    abbreviation: 'NC',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.NorthDakota,
    abbreviation: 'ND',
    timezone: 'CST' as TzName, // TODO: Western part of the state uses MST
  },
  {
    location: ServiceLocation.NorthernMarianaIslands,
    abbreviation: 'MP',
    timezone: 'ChST' as TzName,
  },
  {
    location: ServiceLocation.Ohio,
    abbreviation: 'OH',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.Oklahoma,
    abbreviation: 'OK',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.Oregon,
    abbreviation: 'OR',
    timezone: 'PST' as TzName, // TODO: Part of Malheur County uses MST
  },
  {
    location: ServiceLocation.Palau,
    abbreviation: 'PW',
    timezone: 'Pacific/Palau' as TzName,
  },
  {
    location: ServiceLocation.Pennsylvania,
    abbreviation: 'PA',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.PuertoRico,
    abbreviation: 'PR',
    timezone: 'AST' as TzName,
  },
  {
    location: ServiceLocation.RhodeIsland,
    abbreviation: 'RI',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.SouthCarolina,
    abbreviation: 'SC',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.SouthDakota,
    abbreviation: 'SD',
    timezone: 'CST' as TzName, // TODO: Western South Dakota uses MST
  },
  {
    location: ServiceLocation.Tennessee,
    abbreviation: 'TN',
    timezone: 'CST' as TzName, // TODO: Middle uses CST, East uses EST
  },
  {
    location: ServiceLocation.Texas,
    abbreviation: 'TX',
    timezone: 'CST' as TzName, // TODO: Some counties use MST
  },
  {
    location: ServiceLocation.Utah,
    abbreviation: 'UT',
    timezone: 'MST' as TzName,
  },
  {
    location: ServiceLocation.Vermont,
    abbreviation: 'VT',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.VirginIslands,
    abbreviation: 'VI',
    timezone: 'AST' as TzName,
  },
  {
    location: ServiceLocation.Virginia,
    abbreviation: 'VA',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.Washington,
    abbreviation: 'WA',
    timezone: 'PST' as TzName,
  },
  {
    location: ServiceLocation.WestVirginia,
    abbreviation: 'WV',
    timezone: 'EST' as TzName,
  },
  {
    location: ServiceLocation.Wisconsin,
    abbreviation: 'WI',
    timezone: 'CST' as TzName,
  },
  {
    location: ServiceLocation.Wyoming,
    abbreviation: 'WY',
    timezone: 'MST' as TzName,
  },
];
