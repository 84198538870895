import { Capacitor } from '@capacitor/core';
import { motion } from 'framer-motion';
import { math } from 'polished';
import styled from 'styled-components';

import { Header, Link } from '@littleotter/legacy-components';

import HomeIllustration from './assets/homeIllustration.svg';
import { UpcomingSessions } from './components';

const isOniOS = Capacitor.getPlatform() === 'ios';

export const ContainerMargin = styled.div`
  max-width: ${({ theme }) => theme.deprecated_.breakpoints.md};
  margin: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)} auto 0;
  position: relative;
  padding: ${({ theme }) =>
    `${math(`${theme.deprecated_.sizeBasis} * 2`)} ${math(`${theme.deprecated_.sizeBasis} * 2`)}`};
  border-top-left-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  border-top-right-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
`;

export const ContainerBackground = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.deprecated_.colors.lightestGray};
`;

export const CardNav = styled.div<{ inverseColors?: boolean }>`
  width: 100%;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 6.5`)};
  background-color: ${({ theme }) => theme.deprecated_.colors.cream};
  padding: ${({ theme }) =>
    `${math(`${theme.deprecated_.sizeBasis} * 1.25`)} ${math(`${theme.deprecated_.sizeBasis} * 1.5`)}`};
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;

  ${({ inverseColors, theme }) =>
    inverseColors &&
    `background-color: ${theme.deprecated_.colors.text}; color: ${theme.deprecated_.colors.white}; box-shadow: 0px 26px 60px rgba(0, 0, 0, 0.05);`}

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.deprecated_.border.color};
    box-shadow: ${({ theme }) => `0 4px 8px ${theme.deprecated_.colors.lightGray}`};
  }
`;

export const IllustrationContainer = styled.div`
  flex-shrink: 0;
  width: ${({ theme }) => math(`4 * ${theme.deprecated_.sizeBasis}`)};
  height: ${({ theme }) => math(`4 * ${theme.deprecated_.sizeBasis}`)};
  font-size: ${({ theme }) => math(`3 * ${theme.deprecated_.sizeBasis}`)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
`;

export const BigText = styled.div`
  flex-shrink: 1;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.large};
  color: ${({ theme }) => theme.deprecated_.colors.lightGray};
`;

export const FloatRightLink = styled(Link)`
  float: right;
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 0.875`)};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.regular};

  &:visited {
    color: ${({ theme }) => theme.deprecated_.colors.gray};
  }
`;

export const TopIllustration = styled.img.attrs({
  src: HomeIllustration,
})`
  min-height: 90vh;
  min-width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
`;

export const TopIllustrationContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: absolute;
  min-height: 90vh;
`;

export const HeaderContainer = styled.div`
  position: relative;
  min-height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 12`)};
  padding: ${({ theme }) => `0 ${theme.deprecated_.sizeBasis}`};
  padding-top: ${({ theme }) =>
    isOniOS ? math(`${theme.deprecated_.sizeBasis} * 10.25`) : math(`${theme.deprecated_.sizeBasis} * 7.25`)};
  max-width: ${({ theme }) => theme.deprecated_.breakpoints.md};
  margin: 0 auto;
`;

export const MovedUp = styled(motion.div)`
  position: relative;
`;

export const MotionMarginChildren = styled(motion.div)`
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

export const SmallText = styled.p`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
`;

export const StyledUpcomingSessions = styled(UpcomingSessions)`
  margin: ${({ theme }) => `${math(`${theme.deprecated_.sizeBasis} * 0.5`)} 0`};
`;

export const StyledHeader = styled(Header)`
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.25`)};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3`)};
`;
