import { gql, useLazyQuery } from '@apollo/client';

import { type ProviderQuery, type ProviderQueryVariables } from './__generated__/ProviderQuery';

export const PROVIDER_QUERY = gql`
  query ProviderQuery($providerId: String!) {
    provider(providerId: $providerId) {
      id
      firstName
      lastName
    }
  }
`;

export const useLazyProviderQuery = () => useLazyQuery<ProviderQuery, ProviderQueryVariables>(PROVIDER_QUERY);
