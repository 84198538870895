import { useMemo } from 'react';

import { useGetAssessmentSubmissionsQuery, type AssessmentSubmission } from '../../../../graphql/lo1/generated';
import { getSubjectHash } from '../../utils/subject';

export const useGqlAssessmentSubmissionData = (fmhcId: string) => {
  const { data: gqlAssessmentSubmissionsData, loading } = useGetAssessmentSubmissionsQuery({
    variables: { request: { fmhcId } },
  });

  const gqlSubmissions = useMemo(
    () =>
      (gqlAssessmentSubmissionsData?.GetAssessmentSubmissions.submissions ?? []).reduce((map, assessmentSubmission) => {
        map.set(getSubjectHash(assessmentSubmission.subject), assessmentSubmission);
        return map;
      }, new Map<string, AssessmentSubmission>()),
    [gqlAssessmentSubmissionsData]
  );

  return { loading, gqlSubmissions };
};
