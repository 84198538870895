import styled from 'styled-components';

export const WorryDomainsContentContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const WorryDomainsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
