import { type FC, type PropsWithChildren } from 'react';

import { StyledContainer, StyledHeader, StyledLink } from './styled';

export type TimelineHeaderProps = {
  seeAllUrl?: string;
  text?: string;
  removePaddingTop?: boolean;
};

export const TimelineHeader: FC<PropsWithChildren<TimelineHeaderProps>> = ({ seeAllUrl, text, removePaddingTop }) => (
  <StyledContainer removePaddingTop={removePaddingTop}>
    <StyledHeader as="h4">{text}</StyledHeader>
    {seeAllUrl && <StyledLink href={seeAllUrl}>See All</StyledLink>}
  </StyledContainer>
);
