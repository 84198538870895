import { hashString } from '$shared/utils/hash';

import bg_blue from './assets/backgrounds/bg_blue.svg';
import bg_dkpurple from './assets/backgrounds/bg_dkpurple.svg';
import bg_green from './assets/backgrounds/bg_green.svg';
import bg_ltpurple from './assets/backgrounds/bg_ltpurple.svg';
import bg_orange from './assets/backgrounds/bg_orange.svg';
import bg_yellow from './assets/backgrounds/bg_yellow.svg';

const bgImages = [bg_blue, bg_dkpurple, bg_green, bg_ltpurple, bg_yellow, bg_orange];

export const getBackground = (childName: string) => {
  const length = bgImages.length - 1;
  const value = hashString(childName) % length;
  return bgImages[value];
};
