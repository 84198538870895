import { Sex } from '../../../graphql/lo1/generated';

export const genderToGqlSex = (gender: string): Sex => {
  const lowerGender = gender.toLowerCase();
  if (lowerGender.startsWith('m')) {
    return Sex.Male;
  }
  if (lowerGender.startsWith('f')) {
    return Sex.Female;
  }
  return Sex.Unknown;
};
