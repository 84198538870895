import { type Conversation, type Paginator } from '@twilio/conversations';

/**
 * This function returns a promise that resolves to a list of all the
 * conversations from a Twilio Paginator.
 *
 * Since `client.getSubscribedConversations()` returns a `Paginator` with 50
 * items on each page, we need to iterate all the pages to get all the
 * conversations.
 *
 * @param paginator
 * @param accumulator list of Twilio conversations used internally for recursion
 *
 * @returns promise that resolves to list of Twilio conversations
 */
export const getAllItemsFromPaginator = async (
  paginator: Paginator<Conversation>,
  accumulator: Conversation[] = []
): Promise<Conversation[]> => {
  const updatedAcc = [...accumulator, ...paginator.items];

  if (!paginator.hasNextPage) {
    return updatedAcc;
  }

  const nextPage = await paginator.nextPage();
  return getAllItemsFromPaginator(nextPage, updatedAcc);
};
