import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/
    v4.0 | 20180602
    License: none (public domain)
    */
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    main, menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, main, menu, nav, section {
        display: block;
    }
    /* HTML5 hidden-attribute fix for newer browsers */
    *[hidden] {
        display: none;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
        border-left-color: ${(props) => props.theme.deprecated_.colors.yellow};
        border-left-width: 7px;
        border-left-style: solid;
        font-size: ${(props) => props.theme.deprecated_.fontSizes.h5};
        font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
        padding-left: ${(props) => props.theme.deprecated_.sizeBasis};

        p {
            line-height: 150%;
        }
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    ::-ms-clear {
        display: none;
    }
    ::-ms-reveal {
        display: none;
    }
    select::-ms-expand {
        display: none;
    }
    /* Bootstrap Styling */
    html {
        font-size: 100%;
        box-sizing: border-box;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -ms-overflow-style: scrollbar;
        -webkit-tap-highlight-color: transparent;
    }
    *,
    ::after,
    ::before {
        box-sizing: inherit;
    }
    input:focus, [tabindex="-1"]:focus {
        outline: 0 !important;
    }
    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }
    ol,
    ul {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    ol ol,
    ol ul,
    ul ol,
    ul ul {
        margin-bottom: 0;
    }
    b,
    strong {
        font-weight: bolder;
    }
    img {
        vertical-align: middle;
        border-style: none;
    }
    svg:not(:root) {
        overflow: hidden;
    }
    [role=button],
    a,
    area,
    button,
    input,
    label,
    select,
    summary,
    textarea {
        -ms-touch-action: manipulation;
        touch-action: manipulation;
    }
    table {
        border-collapse: collapse;
    }
    th {
        text-align: left;
    }
    label {
        display: inline-block;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }
    button,
    input {
        overflow: visible;
    }
    button,
    select {
        text-transform: none;
    }
    [type=button]::-moz-focus-inner,
    [type=reset]::-moz-focus-inner,
    [type=submit]::-moz-focus-inner,
    button::-moz-focus-inner {
        padding: 0;
        border-style: none;
    }
    [type=reset],
    [type=submit],
    button,
    html [type=button] {
        -webkit-appearance: button;
    }
    textarea {
        overflow: auto;
        resize: vertical;
    }
    [type=number]::-webkit-inner-spin-button,
    [type=number]::-webkit-outer-spin-button {
        height: auto;
    }
    ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button;
    }
    [hidden] {
        display: none !important;
    }
    small {
        font-size: 80%;
        font-weight: 400;
    }

    body {
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: ${(props) => props.theme.deprecated_.fontFamilies.body};
        font-weight: ${(props) => props.theme.deprecated_.fontWeights.regular};
        font-size: ${(props) => props.theme.deprecated_.fontSizes.regular};
        color: ${(props) => props.theme.deprecated_.colors.text};
        background-color: ${(props) => props.theme.deprecated_.colors.white};
        letter-spacing: 0.25px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
        line-height: ${(props) => props.theme.deprecated_.lineHeight};
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
    }

    b, strong {
        font-weight: ${(props) => props.theme.deprecated_.fontWeights.bold};
    }

    .fade-enter{
        opacity: 0;
     }
     .fade-exit{
        opacity: 1;
     }
     .fade-enter-active{
        opacity: 1;
     }
     .fade-exit-active{
        opacity: 0;
     }
     .fade-enter-active,
     .fade-exit-active{
        transition: opacity 300ms ease-out;
     }
`;

export default GlobalStyle;
