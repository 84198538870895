import styled from 'styled-components';

import reportBackgroundLeft from './assets/reportBackgroundLeft.svg';
import reportBackgroundRight from './assets/reportBackgroundRight.svg';

export const Root = styled.div`
  margin: ${({ theme }) => theme.spacing['06']} auto;
  position: relative;
  padding: ${({ theme }) => theme.spacing['06']};
  max-width: ${({ theme }) => theme.containerSizes.md};
`;

export const LatestCheckupStatus = styled.p`
  margin: ${({ theme }) => theme.spacing['09']} 0 ${({ theme }) => theme.spacing['06']};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  letter-spacing: normal;

  a {
    margin: ${({ theme }) => theme.spacing['03']};
    font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  }
`;

export const HeaderBgContainer = styled.div`
  position: absolute;
  top: 56px;
  height: 140px;
  width: 100%;
  z-index: 0;
`;

export const HeaderBgLeft = styled.div`
  position: absolute;
  background: url(${reportBackgroundLeft}) no-repeat;
  background-size: 500px;
  height: 100%;
  width: 200px;
`;

export const HeaderBgRight = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background: url(${reportBackgroundRight}) no-repeat;
  background-size: 80px;
  background-position: right;
  height: 100%;
  width: 80px;
`;
