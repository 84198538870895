import { gql } from '@apollo/client';

export const RESOURCE_TAG_FRAGMENT = gql`
  fragment ResourceTagFragment on ResourceTagType {
    id
    pk
    name
  }
`;

export const RESOURCE_TAGS_QUERY = gql`
  query ResourceTags {
    tags {
      edges {
        node {
          ...ResourceTagFragment
        }
      }
    }
  }

  ${RESOURCE_TAG_FRAGMENT}
`;
