import { type FC } from 'react';

import { ReactComponent as Add } from './assets/add.svg';
import { ReactComponent as ArrowLeft } from './assets/arrowLeft.svg';
import { ReactComponent as ArrowRight } from './assets/arrowRight.svg';
import { ReactComponent as Attachment } from './assets/attachment.svg';
import { ReactComponent as Audio } from './assets/audio.svg';
import { ReactComponent as BackArrow } from './assets/back.svg';
import { ReactComponent as Bookmark } from './assets/bookmark.svg';
import { ReactComponent as BookmarkFill } from './assets/bookmarkFill.svg';
import { ReactComponent as Calendar } from './assets/calendar.svg';
import { ReactComponent as CarePlan } from './assets/carePlan.svg';
import { ReactComponent as CarePlanFill } from './assets/carePlanFill.svg';
import { ReactComponent as CarePlanFillNotification } from './assets/carePlanFillNotification.svg';
import { ReactComponent as CarePlanNotification } from './assets/carePlanNotification.svg';
import { ReactComponent as CaretDown } from './assets/caretDown.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as ChevronLeft } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRight } from './assets/chevronRight.svg';
import { ReactComponent as CircularPlus } from './assets/circularPlus.svg';
import { ReactComponent as DarkTheme } from './assets/darkTheme.svg';
import { ReactComponent as Document } from './assets/document.svg';
import { ReactComponent as Edit } from './assets/edit.svg';
import { ReactComponent as Filters } from './assets/filters.svg';
import { ReactComponent as FontSize } from './assets/fontSize.svg';
import { ReactComponent as Help } from './assets/help.svg';
import { ReactComponent as Hide } from './assets/hide.svg';
import { ReactComponent as Home } from './assets/home.svg';
import { ReactComponent as HomeFill } from './assets/homeFill.svg';
import { ReactComponent as LightTheme } from './assets/lightTheme.svg';
import { ReactComponent as ListenArticle } from './assets/listenArticle.svg';
import { ReactComponent as Lock } from './assets/lock.svg';
import { ReactComponent as MailSend } from './assets/mailSend.svg';
import { ReactComponent as Menu } from './assets/menu.svg';
import { ReactComponent as MenuNotification } from './assets/menuNotification.svg';
import { ReactComponent as MessageReceived } from './assets/messageReceived.svg';
import { ReactComponent as Messages } from './assets/messages.svg';
import { ReactComponent as MessageSent } from './assets/messageSent.svg';
import { ReactComponent as MessagesFill } from './assets/messagesFill.svg';
import { ReactComponent as MessagesFillNotification } from './assets/messagesFillNotification.svg';
import { ReactComponent as MessagesNotification } from './assets/messagesNotification.svg';
import { ReactComponent as MessageWaiting } from './assets/messageWaiting.svg';
import { ReactComponent as Picture } from './assets/picture.svg';
import { ReactComponent as Play } from './assets/play.svg';
import { ReactComponent as RecordAudio } from './assets/recordAudio.svg';
import { ReactComponent as RecordAudioFill } from './assets/recordAudioFill.svg';
import { ReactComponent as Resources } from './assets/resources.svg';
import { ReactComponent as ResourcesFill } from './assets/resourcesFill.svg';
import { ReactComponent as ResourcesFillNotification } from './assets/resourcesFillNotification.svg';
import { ReactComponent as ResourcesNotification } from './assets/resourcesNotification.svg';
import { ReactComponent as Search } from './assets/search.svg';
import { ReactComponent as SendMessage } from './assets/sendMessage.svg';
import { ReactComponent as ShareArticle } from './assets/shareArticle.svg';
import { ReactComponent as Show } from './assets/show.svg';
import { ReactComponent as Signout } from './assets/signout.svg';
import { ReactComponent as Trash } from './assets/trash.svg';
import { ReactComponent as Upload } from './assets/upload.svg';
import { ReactComponent as User } from './assets/user.svg';
import { ReactComponent as UserFill } from './assets/userFill.svg';
import { ReactComponent as UserFillNotification } from './assets/userFillNotification.svg';
import { ReactComponent as UserNotification } from './assets/userNotification.svg';
import { ReactComponent as Video } from './assets/video.svg';

export type BaseIconName = keyof typeof baseNameToIconMap;
export type IconName = keyof typeof nameToIconMap;

const baseNameToIconMap = {
  Add,
  ArrowLeft,
  ArrowRight,
  Attachment,
  Audio,
  BackArrow,
  Bookmark,
  Calendar,
  CarePlan,
  CaretDown,
  Check,
  ChevronLeft,
  ChevronRight,
  CircularPlus,
  DarkTheme,
  Document,
  Edit,
  Filters,
  FontSize,
  Help,
  Hide,
  Home,
  HomeFill,
  LightTheme,
  ListenArticle,
  Lock,
  MailSend,
  Menu,
  MessageReceived,
  MessageSent,
  Messages,
  MessageWaiting,
  Picture,
  Play,
  RecordAudio,
  Resources,
  Search,
  SendMessage,
  ShareArticle,
  Show,
  Signout,
  Trash,
  Upload,
  User,
  Video,
} as const;

export const nameToIconMap = {
  ...baseNameToIconMap,
  BookmarkFill,
  CarePlanFillNotification,
  CarePlanFill,
  CarePlanNotification,
  MenuNotification,
  MessagesFillNotification,
  MessagesFill,
  MessagesNotification,
  RecordAudioFill,
  ResourcesFillNotification,
  ResourcesFill,
  ResourcesNotification,
  UserFillNotification,
  UserFill,
  UserNotification,
} as const;

export type IconProps = {
  name: IconName;
};

export const Icon: FC<IconProps> = ({ name, ...rest }) => {
  const IconComponent = nameToIconMap[name];
  return <IconComponent {...rest} />;
};
