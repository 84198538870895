import { gql } from '@apollo/client';

import UPCOMING_APPOINTMENT_FRAGMENT from './upcoming-appointment-fragment';

export const CARE_PLAN_QUERY = gql`
  query CarePlanQuery($notesLimit: Int, $includeNoteSummary: Boolean = false) {
    viewer {
      id
      isDemoAccount
      hasConfirmedAppointments
      family {
        id
        nextAppointment {
          ...UpcomingAppointment
        }
      }
    }
    treatmentNotes(first: $notesLimit) {
      pk
      status
      noteName
      datetime
      summary @include(if: $includeNoteSummary) {
        text
        answer
      }
      provider {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
  ${UPCOMING_APPOINTMENT_FRAGMENT}
`;
