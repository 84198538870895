import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import { persistAuth } from '../Assessments/hooks';

const HolidayCheckupGate = () => {
  persistAuth('holiday', window.location.href);
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Redirect
        to={{
          pathname: '/assessment/holiday',
        }}
      />
    </>
  );
};

export default HolidayCheckupGate;
