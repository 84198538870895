import { useMutation, useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren } from 'react';
import { Controller, useForm } from 'react-hook-form6';

import { FullButton, Header, Input, Select } from '@littleotter/legacy-components';

import { logger } from '$services/logging';
import { type BannerMessage } from '$shared/hooks';
import { joinWords } from '$shared/utils/joinWords';
import setServerErrors from '$shared/utils/setServerErrors';
import { stateList } from '$shared/utils/stateList';

import {
  type AddNewCaregiverMutation,
  type AddNewCaregiverMutationVariables,
} from '../../../graphql/__generated__/AddNewCaregiverMutation';
import { type AllowedStatesQuery } from '../../../graphql/__generated__/AllowedStatesQuery';
import { ALLOWED_STATES_QUERY } from '../../../graphql/allowed-states';
import { MissingMutationDataError } from '../../../graphql/errors';
import { ADD_NEW_CAREGIVER_MUTATION } from '../../../graphql/profile';
import { StyledForm } from '../components';

const stateOptions = stateList.map((state) => ({
  label: state.name,
  value: state.abbreviation,
}));

type AddNewCaregiverFormData = {
  firstName: string;
  lastName: string;
  email: string;
  state: string;
};

const addNewCaregiverDefaultValues: AddNewCaregiverFormData = {
  firstName: '',
  lastName: '',
  email: '',
  state: '',
};

type AddNewCaregiverFormProps = {
  setBannerMessage: (message: BannerMessage | null) => void;
  onSuccessfulSubmit: () => void;
};

export const AddNewCaregiverForm: FC<PropsWithChildren<AddNewCaregiverFormProps>> = ({
  setBannerMessage,
  onSuccessfulSubmit,
}) => {
  const { control, handleSubmit, errors, setError, formState, reset } = useForm<AddNewCaregiverFormData>({
    defaultValues: addNewCaregiverDefaultValues,
    mode: 'all',
  });
  const { isValid } = formState;

  const { data: allowedStatesData, loading: allowedStatesLoading } = useQuery<AllowedStatesQuery>(ALLOWED_STATES_QUERY);
  const validateState = (stateCode: string) => {
    if (!allowedStatesData) {
      return '';
    }
    const allowedStatesCodes: string[] = allowedStatesData.allowedStates.map((state) => state.code);
    const allowedStatesNames: string[] = allowedStatesData.allowedStates.map((state) => state.name);
    const allowedStatesPhrase: string = joinWords(allowedStatesNames);
    return allowedStatesCodes.includes(stateCode) || `Little Otter provides services in ${allowedStatesPhrase} only`;
  };

  const [addNewCaregiver, { loading }] = useMutation<AddNewCaregiverMutation, AddNewCaregiverMutationVariables>(
    ADD_NEW_CAREGIVER_MUTATION
  );

  const onSubmit = async (formData: AddNewCaregiverFormData) => {
    try {
      const inputData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        state: formData.state,
      };
      const result = await addNewCaregiver({ variables: { ...inputData } });

      if (!result.data) {
        throw new MissingMutationDataError('AddNewCaregiverMutation');
      }

      const mutationData = result.data.inviteCaregiver;

      switch (mutationData.__typename) {
        case 'UserType':
          setBannerMessage({ type: 'success', message: 'Caregiver created successfully' });
          reset();

          onSuccessfulSubmit();
          return;
        case 'InviteCaregiverError':
          setBannerMessage({ type: 'error', message: mutationData.message });
          setServerErrors(mutationData.fieldErrors, setError);
          return;
        default:
          throw new Error('Unknown result typename');
      }
    } catch (e) {
      logger.error(new Error('Error adding new caregiver', { cause: e }));
      setBannerMessage({ type: 'error', message: 'Something went wrong. Try again later.' });
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Header as="h3">Add New Caregiver</Header>
      <Controller
        as={Input}
        control={control}
        label="First Name"
        name="firstName"
        rules={{ required: "Caregiver's first name is required" }}
        errors={errors}
      />
      <Controller
        as={Input}
        control={control}
        label="Last Name"
        name="lastName"
        rules={{ required: "Caregiver's last name is required" }}
        errors={errors}
      />
      <Controller
        as={Input}
        control={control}
        label="Email"
        name="email"
        type="email"
        rules={{ required: "Caregiver's email is required" }}
        errors={errors}
      />
      <Controller
        as={Select}
        control={control}
        name="state"
        label="US State"
        placeholder="Please select a state"
        options={stateOptions}
        rules={{
          required: 'US State is required',
          validate: validateState,
        }}
        errors={errors}
      />
      <FullButton isLoading={loading} disabled={!isValid || allowedStatesLoading}>
        Add Caregiver
      </FullButton>
    </StyledForm>
  );
};
