type FieldErrors<T> = Omit<T, '__typename'>;

/**
 * @param errors Object in which each key corresponds to a field name of a form. The `__typename` key is ignored by this
 * function
 * @param setError `react-hook-form` function used to set field errors. Can be retrieved from `useForm`
 */
const setServerErrors = <T>(
  errors: { [P in keyof FieldErrors<T>]?: string[] | null },
  setError: (fieldName: keyof FieldErrors<T>, error: { type: string; message: string }) => void
) =>
  Object.keys(errors).forEach((key) => {
    const errorKey = key as keyof FieldErrors<T>;
    const errorArr = errors[errorKey];
    // ignore `__typename` and fields without errors
    if (key !== '__typename' && errorArr) {
      setError(errorKey, {
        type: 'server',
        // remove trailing periods in errors and format errors in a single string separated by periods
        message: errorArr.map((error) => error.replace(/\.+$/, '')).join('. '),
      });
    }
  });

export default setServerErrors;
