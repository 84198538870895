import { type FC } from 'react';

import { ProfilePictureImage, ProfilePictureWrapper } from './styled';

export type ProfilePictureProps = {
  src: string;
  className?: string;
};

export const ProfilePicture: FC<ProfilePictureProps> = ({ src, className }) => (
  <ProfilePictureWrapper className={className}>
    <ProfilePictureImage src={src} alt="Header image" />
  </ProfilePictureWrapper>
);
