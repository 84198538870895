import { motion } from 'framer-motion';
import { math } from 'polished';
import styled from 'styled-components';

export type MotionElementProps = {
  $fullHeight?: boolean;
  $withoutMobileNavbar?: boolean;
};

export const MotionElement = styled(motion.div)<MotionElementProps>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  ${({ $fullHeight, theme }) =>
    $fullHeight
      ? `
        padding-bottom: max(
            calc(env(safe-area-inset-bottom) + ${math(`3.5 * ${theme.deprecated_.sizeBasis}`)}),
            ${math(`4.5 * ${theme.deprecated_.sizeBasis}`)}
        );
     `
      : `
        padding-bottom: max(
            calc(env(safe-area-inset-bottom) + ${math(`5 * ${theme.deprecated_.sizeBasis}`)}),
            ${math(`5 * ${theme.deprecated_.sizeBasis}`)}
        );
    `}
  ${({ $withoutMobileNavbar }) =>
    $withoutMobileNavbar &&
    `
    padding-bottom: 0;
  `}
`;
