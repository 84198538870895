import { math } from 'polished';
import styled from 'styled-components';

import { Card } from '../Card';

export const StyledCard = styled(Card)`
  min-height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4.25`)};
  display: flex;
  align-items: center;
`;

export const StyledText = styled.p`
  display: inline;
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.text};
`;

export const StyledIcon = styled.img`
  display: inline-block;
  margin: 0 ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const StyledContainer = styled.div`
  padding: ${({ theme }) => theme.deprecated_.sizeBasis} 0;
`;
