import { useQuery } from '@apollo/client';

import {
  type CarePlanQuery,
  type CarePlanQuery_treatmentNotes,
  type CarePlanQuery_treatmentNotes_provider,
  type CarePlanQueryVariables,
} from '../../../../graphql/__generated__/CarePlanQuery';
import { CARE_PLAN_QUERY } from '../../../../graphql/care-plan';
import { removeVersionTagFromNoteName } from '../../Journey/utils/removeVersionTagFromNoteName';

export type ParsedTreatmentNotes = {
  date: Date;
  id: string;
  title: string;
  avatar: string | undefined;
  subtitle: string;
  original: CarePlanQuery_treatmentNotes;
};

export const useLatestSessionRecap = () => {
  const { data, error, loading } = useQuery<CarePlanQuery, CarePlanQueryVariables>(CARE_PLAN_QUERY, {
    variables: { notesLimit: 3, includeNoteSummary: true },
  });

  const notesWithCleanNames: CarePlanQuery_treatmentNotes[] | undefined = data?.treatmentNotes?.map((note) => ({
    ...note,
    noteName: removeVersionTagFromNoteName(note.noteName).trim(),
  }));

  const getProviderName = (provider: CarePlanQuery_treatmentNotes_provider | null) => {
    if (!provider) {
      return '';
    }

    return `${provider.firstName} ${provider.lastName}`;
  };

  const treatmentNotesWithSummary = notesWithCleanNames?.filter((note) => !!note.summary?.length);

  const parsedTreatmentNotes = treatmentNotesWithSummary?.map(
    (treatmentNote) =>
      ({
        date: new Date(treatmentNote.datetime),
        id: treatmentNote.pk,
        title: treatmentNote.noteName || '',
        avatar: treatmentNote?.provider?.profilePicture,
        subtitle: getProviderName(treatmentNote.provider),
        original: treatmentNote,
      }) as ParsedTreatmentNotes
  );

  return {
    latestSessionRecap: parsedTreatmentNotes,
    error,
    loading,
  };
};
