import { generatePath, matchPath } from 'react-router-dom';

import { type Route, type RouteParams, type RouteProps } from './types';

type GetRoute = {
  (path: string, props?: RouteProps): Route;
  <Params extends RouteParams>(path: string, props?: RouteProps): Route<Params>;
};

// Keeping the explicit Route return type here is apparently needed for TS to properly track references
// In practice though, from a typing perspective, it's not needed and it won't make a difference
// since the function will be typed according to the generic overrides from the GetRoute type
export const getRoute: GetRoute = (path: string, props?: RouteProps): Route<RouteParams | void> => ({
  path,
  exact: !!props?.exact,
  strict: !!props?.strict,
  test: (url: string, propsOverrides?: RouteProps) => !!matchPath(url, { path, ...props, ...propsOverrides }),
  url: (params?: RouteParams) => generatePath(path, params),
});
