import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo, type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { useGraphQLErrorHandling } from '$shared/hooks';
import { useGoto } from '$shared/utils/rerouter';

import { PageWideLoading } from '../../../../components/PageWideLoading';
import { CaregiverToChildRelationshipRelationship } from '../../../../graphql/__generated__/globalTypes';
import { routes } from '../../../../routes';
import { getRedirectValue } from '../../../../shared/utils/rerouter/rerouter';
import { ChildGender, Relationship, type Child, type RemoveChildResult } from '../../types';
import {
  type DeleteUserChild,
  type DeleteUserChild_deleteChildFromFamily_DeleteChildFromFamilyError,
  type DeleteUserChildVariables,
} from '../queries/__generated__/DeleteUserChild';
import {
  type UserFamilyChildrenQuery,
  type UserFamilyChildrenQuery_viewer_family_children,
} from '../queries/__generated__/UserFamilyChildrenQuery';
import { USER_FAMILY_CHILDREN_QUERY } from '../queries/children';
import { DELETE_USER_CHILD_MUTATION } from '../queries/delete';
import { ChildSummaryPage } from './ChildSummaryPage';

const mapChildren: (viewerId: string, gqlFamilyData: UserFamilyChildrenQuery_viewer_family_children[]) => Child[] = (
  viewerId,
  gqlFamilyData
) =>
  gqlFamilyData.map((gqlChild) => {
    const gqlRelationship = gqlChild.caregiverRelationships.find(
      (relationShip) => relationShip.caregiver.id === viewerId
    )?.relationship;

    const relationship = gqlRelationship
      ? {
          [CaregiverToChildRelationshipRelationship.RELATIVE]: Relationship.Relative,
          [CaregiverToChildRelationshipRelationship.FAMILY_FRIEND]: Relationship.FamilyFriend,
          [CaregiverToChildRelationshipRelationship.GRANDPARENT]: Relationship.Grandparent,
          [CaregiverToChildRelationshipRelationship.GUARDIAN]: Relationship.Guardian,
          [CaregiverToChildRelationshipRelationship.OTHER]: Relationship.Other,
          [CaregiverToChildRelationshipRelationship.PARENT]: Relationship.Parent,
        }[gqlRelationship]
      : undefined;

    return {
      id: parseInt(gqlChild.id, 10),
      firstName: gqlChild.firstName,
      lastName: gqlChild.lastName,
      preferredName: gqlChild.preferredName,
      displayName: gqlChild.displayName,
      dateOfBirth: gqlChild.dateOfBirth.toString(),
      relationship,
      gender: gqlChild.gender ? ChildGender[gqlChild.gender as keyof typeof ChildGender] : undefined,
      pronouns: gqlChild.pronouns,
      isSeekingCare: gqlChild.isSeekingCare,
    };
  });

export const ChildSummary: FC<PropsWithChildren> = () => {
  // If redirect, use redirect. Otherwise, go to worry domains.
  // ! HACK(PD-1731): originally, child summary would always lead to the worry domains page.
  // ! Now, it should not, and we have separate prerequisites for worry domains
  const redirect = getRedirectValue();
  const history = useHistory();
  const goNextPage = redirect
    ? () => history.push(redirect)
    : () => history.push(routes.authOnboarding.worryDomains.url());

  const goInputPage = useGoto({ route: routes.child.form, forward: true });

  const {
    data: gqlFamilyData,
    loading: familyChildrenIsLoading,
    error,
  } = useQuery<UserFamilyChildrenQuery>(USER_FAMILY_CHILDREN_QUERY, { fetchPolicy: 'no-cache' });
  useGraphQLErrorHandling(error);

  const familyChildren = useMemo(
    () => mapChildren(gqlFamilyData?.viewer?.id ?? '', gqlFamilyData?.viewer?.family?.children ?? []),
    [gqlFamilyData?.viewer?.id, gqlFamilyData?.viewer?.family?.children]
  );

  const [deleteUserChild] = useMutation<DeleteUserChild, DeleteUserChildVariables>(DELETE_USER_CHILD_MUTATION, {
    refetchQueries: [USER_FAMILY_CHILDREN_QUERY, 'UserFamilyChildrenQuery'],
  });

  const onRemoveChild = useCallback(
    async (childId: number): Promise<RemoveChildResult> => {
      const { data: deleteUserChildData, errors: gqlErrors } = await deleteUserChild({ variables: { childId } });
      const errorMessage = (
        deleteUserChildData?.deleteChildFromFamily as DeleteUserChild_deleteChildFromFamily_DeleteChildFromFamilyError
      )?.message;
      const success = !gqlErrors && !errorMessage;
      return {
        success,
        errorMessage,
      };
    },
    [deleteUserChild]
  );

  if (familyChildrenIsLoading) {
    return <PageWideLoading />;
  }

  return (
    <ChildSummaryPage
      childs={familyChildren}
      goInputPage={goInputPage}
      onRemoveChild={onRemoveChild}
      goNextPage={goNextPage}
    />
  );
};
