import { useGraphQLErrorHandling } from '$shared/hooks';

import {
  useStaffFmhcsQuery as _useStaffFmhcsQuery,
  FmhcsSortBy,
  type StaffFmhcsQuery,
} from '../../graphql/lo1/generated';
import { type UseFmhcsQueryProps } from './useFmhcsQuery';

/**
 * Gets submitted or unsubmitted FMHCs past a certain date threshold.
 *
 * If getting submitted FMHCs, response is ordered by descending submission time, and date threshold is for submission time.
 *
 * If getting unsubmitted FMHCs, response is ordered by descending start time, and date threshold is for start time.
 */
export const useStaffFmhcsQuery = ({
  familyId,
  submitterId,
  isSubmitted,
  dateThreshold,
  limit = 1,
  skip = 0,
  skipQuery = false,
  fetchPolicy = undefined,
}: UseStaffFmhcsQueryProps): [StaffFmhcsQuery['StaffFmhcs'] | undefined, boolean, () => void] => {
  const { data, loading, error, refetch } = _useStaffFmhcsQuery({
    variables: {
      request: {
        familyId,
        submitterId,
        input: {
          filter: {
            isSubmitted,
            ...(isSubmitted
              ? { submissionTimeIsAfter: dateThreshold?.toISOString() }
              : { startTimeIsAfter: dateThreshold?.toISOString() }),
          },
          sortBy: isSubmitted ? FmhcsSortBy.SubmissionTime : FmhcsSortBy.StartTime,
          limit,
          skip,
        },
      },
    },
    skip: !!skipQuery,
    fetchPolicy,
  });
  useGraphQLErrorHandling(error);
  return [data?.StaffFmhcs, loading, refetch];
};

type UseStaffFmhcsQueryProps = {
  familyId: string;
  submitterId: string;
} & UseFmhcsQueryProps;
