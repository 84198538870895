import styled from 'styled-components';

import { BrandCard } from '@littleotter/legacy-components';

export const Article = styled.article`
  [id] {
    scroll-margin-top: 5rem;
  }

  & > section:not(:last-child) {
    margin-bottom: 2.5rem;
  }
`;

export const MainHeader = styled.header`
  & > :first-child {
    margin-bottom: 1rem;
  }
`;

export const StyledBrandCard = styled(BrandCard)`
  margin: 1rem 0;
`;

export const Disclaimer = styled.footer`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  line-height: 1.5;

  em {
    font-style: italic;
  }
`;
