import styled from 'styled-components';

export const Root = styled.div`
  overflow: hidden;
  position: relative;
`;

export const CategoricalScore = styled.h5<{ color: string }>`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  color: ${({ color }) => color};
  letter-spacing: normal;
`;

export const Title = styled.h4`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  letter-spacing: normal;
`;
