import { type DefaultTheme } from 'styled-components';

import { type Segment } from '../types';
import { sortSegments } from './sortSegments';

export const getProgressColor = (
  segments: Segment[],
  progress: number
): keyof DefaultTheme['deprecated_']['colors'] | undefined =>
  sortSegments(segments).reduceRight<keyof DefaultTheme['deprecated_']['colors'] | undefined>((acc, segment) => {
    // Pick the color of the segment where the progress still hasn't reached the threshold
    if (progress <= segment.threshold) {
      return segment.color;
    }

    // Fall back to the color of the segment to the left (reduceRight), if the current one has no color
    return acc ?? segment.color;
  }, undefined);
