import { type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { MetadataRoute } from '../../../../../components/MetadataRoute';
import { CheckupFrame } from '../../../components';
import { type QuestionAnswer, type QuestionData, type SubjectQuestions } from '../../../types';
import { QuestionContent } from './components/QuestionContent';
import { type GetAnswers, type SetAnswer } from './types';

export type QuestionsContentProps = {
  baseRoute: string;
  lastButtonText?: string;
  getAnswers: GetAnswers;
  setAnswer: SetAnswer;
  questions: QuestionData[];
  onCompletion: (answers: QuestionAnswer[]) => void;
  subjectsQuestions: SubjectQuestions[];
  subjectIndex: number;
  trackingSubject?: string;
};
export const QuestionsContent: FC<PropsWithChildren<QuestionsContentProps>> = ({
  baseRoute,
  lastButtonText,
  getAnswers,
  setAnswer,
  questions,
  onCompletion,
  subjectsQuestions,
  subjectIndex,
  trackingSubject,
}) => {
  const history = useHistory();

  const renderedQuestions = questions.map((questionData, questionIndex) => {
    const path = questionIndex === 0 ? baseRoute : `${baseRoute}/${questionIndex}`;
    const isLastQuestion = questionIndex >= questions.length - 1;
    const goNextPage = () => history.push(`${baseRoute}/${questionIndex + 1}`);
    const onAnswerDelayForAnimation = 300;
    const onAnswer = (answer?: QuestionAnswer) => {
      setTimeout(() => {
        if (answer) {
          setAnswer(answer.variableName, answer);
        }
        if (!isLastQuestion) {
          return goNextPage();
        }
        onCompletion(Object.values(getAnswers()));
      }, onAnswerDelayForAnimation);
    };
    return (
      <MetadataRoute fullHeight exact path={path} key={`question-${questionData.question.id}`}>
        <CheckupFrame
          navbarShowLogo
          navbarDark
          goBack={history.goBack}
          question={questionData}
          totalQuestions={questions.length}
          questionIndex={questionIndex + 1}
          subjectsQuestionsLength={subjectsQuestions.length}
          subjectIndex={subjectIndex}
          subject={subjectsQuestions[subjectIndex].subject}
        >
          <QuestionContent
            nextButtonText={isLastQuestion ? lastButtonText : undefined}
            getAnswer={() => getAnswers()[questionData.question.id]}
            totalQuestions={questions.length}
            questionIndex={questionIndex + 1}
            trackingSubject={trackingSubject}
            question={questionData}
            onAnswer={onAnswer}
          />
        </CheckupFrame>
      </MetadataRoute>
    );
  });

  return <>{renderedQuestions}</>;
};
