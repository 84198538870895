import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Collapse } from '@littleotter/legacy-components';

import { IconWithBackground, type IconWithBackgroundName } from '../../../../components/IconWithBackground';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  margin-left: 1rem;
`;

export type CollapseWithIconProps = {
  iconName: IconWithBackgroundName;
  label: string;
  defaultOpen?: boolean;
};

export const CollapseWithIcon: FC<PropsWithChildren<CollapseWithIconProps>> = ({
  iconName,
  label,
  defaultOpen,
  children,
}) => (
  <Collapse
    label={
      <Container>
        <IconWithBackground name={iconName} />
        <Label>{label}</Label>
      </Container>
    }
    defaultOpen={defaultOpen}
  >
    {children}
  </Collapse>
);
