import { getRoute } from './getRoute';
import { type BaseRoute, type ExtendsAny, type Route, type RouteParams } from './types';

interface RecursiveRouteList {
  [key: string]: Route<ExtendsAny> | BaseRoute<Route<ExtendsAny>> | RecursiveRouteList;
}

type ParentRouteRoutes<RouteParam extends Route<ExtendsAny>> = {
  base: BaseRoute<RouteParam>;
  home: RouteParam;
};

type RouteListWithBaseOrHome = { base: ExtendsAny } | { home: ExtendsAny };
type RouteListWithoutBaseAndHome = ParentRouteRoutes<never>;

type RouteListBuilder<Params extends RouteParams | void = void> = {
  <Routes extends RecursiveRouteList>(
    routes: Routes extends RouteListWithBaseOrHome ? RouteListWithoutBaseAndHome & Routes : Routes
  ): ParentRouteRoutes<Route<Params>> & Routes;
};

type ParentRoute = {
  (path: string): RouteListBuilder;
  <Params extends RouteParams>(path: string): RouteListBuilder<Params>;
};

/**
 * Helper used to create a "parent" route with auto generated `home` and `base` sub-routes
 * as well as a provided record of additional sub-routes.
 *
 * @returns A function that receives `routes`: a record of `endRoute`s and other `parentRoute`s,
 * and returns a record of `Route` objects, consisting of:
 * - `base`: Meant to be used to delegate routing for all sub-routes of the provided `path` to another component,
 *   with `exact` set to `false`.
 * - `home`: Meant to be used as the end route for the provided path, with `exact` set to `true`.
 * - all the `routes` passed to the returned function.
 *
 * @example
 * ```typescript
 * const routes = {
 *   countries: parentRoute('/countries')({
 *     list: endRoute('/countries/list'),
 *     country: parentRoute<{ id: string }>('/countries/country/:id')({
 *       cities: endRoute<{ id: string }>('/countries/country/:id/cities'),
 *     }),
 *   }),
 * };
 *
 * routes.countries.base
 * -> { path: '/countries', exact: false, ... }
 *
 * routes.countries.home
 * -> { path: '/countries', exact: true, url: () => string, ... }
 *
 * routes.countries.list
 * -> { path: '/countries/list', exact: true, url: () => string, ... }
 *
 * routes.countries.country.base
 * -> { path: '/countries/country/:id', exact: false, ... }
 *
 * routes.countries.country.home
 * -> { path: '/countries/country/:id', exact: true, url: (id: string) => string, ... }
 *
 * routes.countries.country.cities
 * -> { path: '/countries/country/:id/cities', exact: true, url: (id: string) => string, ... }
 * ```
 */
export const parentRoute: ParentRoute =
  (path: string) =>
  <Routes>(routes: Routes) => ({
    base: getRoute(path, { exact: false }),
    home: getRoute(path, { exact: true }),
    ...routes,
  });
