import { getInterval } from './getInterval';
import { type Point } from './types';

type InterpolatingLinearFunction = (points: readonly Point[]) => (x: number) => number;

/**
 * Get a function to linearly interpolate a value given a set of points.
 *
 * Returns a function defined as a continuous piecewise function which corresponds to a set of
 * linear sub-functions defined in the intervals between the passed `points` x coordinates,
 * returning the y coordinate of the line segments joining said `points`.
 * If the returned function is called with an x coordinate outside the range of the passed `points`,
 * returns the same value, behaving as an identity function.
 */
export const interpolatingLinearFunction: InterpolatingLinearFunction = (points) => {
  return (x) => {
    const interval = getInterval(x, points);

    if (!interval) {
      return x;
    }

    const [[x0, y0], [x1, y1]] = interval;

    // Slope of the line passing through points (x0, y0) and (x1, y1)
    const m = (y1 - y0) / (x1 - x0);

    // Equation for the line with slope `m` passing through (x0, y0)
    return m * (x - x0) + y0;
  };
};
