import { type CartesianMarkerProps } from '@nivo/core';

import { GRAPH_HEIGHT } from '../../constants';

export const getThresholdMarker = ({
  label,
  maxScore,
  threshold,
  thresholdColor,
  fontSize,
  fontWeight,
}: getThresholdMarkerProps): CartesianMarkerProps => ({
  axis: 'y',
  value: threshold - markerAdjustment(maxScore, GRAPH_HEIGHT),
  legend: label,
  legendOrientation: 'horizontal',
  legendPosition: 'top',
  lineStyle: {
    strokeWidth: 0, // no marker line. Use line from Threshold.
  },
  textStyle: {
    fill: thresholdColor,
    fontSize,
    fontWeight,
    textTransform: 'uppercase',
  },
});

export interface getThresholdMarkerProps {
  label: string;
  maxScore: number;
  threshold: number;
  thresholdColor: string;
  fontSize: string;
  fontWeight: number;
}

/*
The marker adjustment unit is SCORE, not pixels.

The larger the max score, the more squeezed together the graph is, which means the threshold marker needs
to be adjusted by a larger score.

The larger the graph height, the less squeezed together the graph is, which means the threshold marker
needs to be adjusted by a smaller score.

Observations:
* For a fixed graph height, the relationship between max score and the adjustment is linear
* For a fixed max score, the relationship between graph height and the adjustment is linear

Note: this is a hyperbolic paraboloid, not a flat plane: https://www.wolframalpha.com/input?i=%280.6+%2F+7%29+*+%281.7+-+0.0035+*+x%29+*+y
*/
const markerAdjustment = (maxScore: number, graphHeight: number) => (0.6 / 7) * (1.7 - 0.0035 * graphHeight) * maxScore;
