import { gql } from '@apollo/client';

import { RESOURCE_FRAGMENT } from './resource-fragment';

export const LIBRARY_RESOURCE_QUERY = gql`
  query LibraryResource($pk: ID!) {
    resource(pk: $pk) {
      ...ResourceFragment
    }
    viewer {
      id
      favorites {
        edges {
          node {
            id
            pk
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
`;

export const ADD_TO_FAVORITES_MUTATION = gql`
  mutation AddToFavorites($resourceId: Int!) {
    addUserFavorites(input: { resourceId: $resourceId }) {
      user {
        id
        favorites {
          edges {
            node {
              id
              pk
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_FROM_FAVORITES_MUTATION = gql`
  mutation RemoveFromFavorites($resourceId: Int!) {
    deleteUserFavorites(input: { resourceId: $resourceId }) {
      user {
        id
        favorites {
          edges {
            node {
              id
              pk
            }
          }
        }
      }
    }
  }
`;
