import { type FC } from 'react';

import { ProgressBar, type ProgressBarProps } from '../ProgressBar';
import { SegmentedWrapper } from './styled';
import { type Segment } from './types';
import { getProgressColor, getSegmentBoundaries } from './utils';

export type SegmentedProgressBarProps = {
  segments: Segment[];
} & ProgressBarProps;

export const SegmentedProgressBar: FC<SegmentedProgressBarProps> = ({ segments, progress, ...props }) => {
  const segmentBoundaries = getSegmentBoundaries(segments);
  const progressColor = getProgressColor(segments, progress);

  return (
    <SegmentedWrapper boundaries={segmentBoundaries} progressColor={progressColor}>
      <ProgressBar progress={progress} {...props} />
    </SegmentedWrapper>
  );
};
