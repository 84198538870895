import { rgba } from 'polished';
import styled from 'styled-components';

import { Button } from '@littleotter/zz-legacy-components';

export const Root = styled.div<{ disabled?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => rgba(theme.deprecated_.colors.lightestGray, 1.0)};
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  border: 1px dashed ${({ theme }) => theme.deprecated_.colors.lightGray};
  display: flex-col;
  min-height: 190px;
  padding: ${({ theme }) => theme.spacing['05']} ${({ theme }) => theme.spacing['06']};
  margin: ${({ theme }) => theme.spacing['05']} 0;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.text};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
`;

export const Content = styled.div`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.regular};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  padding: ${({ theme }) => theme.spacing['02']} ${({ theme }) => theme.spacing['02']};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing['03']};
  margin-bottom: ${({ theme }) => theme.spacing['02']};
`;
