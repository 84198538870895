import styled from 'styled-components';

export const StyledLabel = styled.label`
  font-weight: ${(props) => props.theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  cursor: pointer;
  width: 100%;
`;

export const StyledLabelDiv = styled.div`
  line-height: 1.2;
  margin-bottom: 1rem;
`;

export const StyledError = styled.small`
  color: ${(props) => `${props.theme.deprecated_.colors.red}`};
`;
