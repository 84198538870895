import styled from 'styled-components';

import familyOtter from './assets/Family_otters.svg';

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

export const StyledImage = styled.img.attrs({
  src: familyOtter,
})`
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
`;

export const StyledTextWrapper = styled.div`
  text-align: center;
  max-width: 500px;
`;

export const StyledPrimaryText = styled.div`
  font-size: 28px;
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  margin-bottom: 20px;
`;

export const StyledSecondaryText = styled.div`
  font-size: 16px;
  letter-spacing: ${({ theme }) => theme.deprecated_.letterSpacing};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  text-align: left;
`;

export const StyledActionsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 34px 24px;

  a {
    display: block;
  }

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    max-width: 300px;
    margin: 0 auto;
    position: relative;
    padding: 50px 24px;
  }
`;
