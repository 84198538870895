import { type FC, type PropsWithChildren } from 'react';

import { WorryDomainCard } from '../../../components/WorryDomainCard';
import { type WorryDomainShortName } from '../../../components/WorryDomainIcon';
import { type LibraryWorryDomains_worryDomains } from '../../../graphql/__generated__/LibraryWorryDomains';

type WorryDomainsProps = {
  worryDomains: LibraryWorryDomains_worryDomains['edges'];
  onClick: (id: number | null) => () => void;
};

const WorryDomains: FC<PropsWithChildren<WorryDomainsProps>> = ({ worryDomains, onClick }) => (
  <>
    {worryDomains.map((edge) => {
      if (!edge?.node) {
        return null;
      }

      const { name, pk, shortName } = edge.node;

      return (
        <WorryDomainCard key={pk} onClick={onClick(pk)} worryDomainShortName={shortName as WorryDomainShortName}>
          {name}
        </WorryDomainCard>
      );
    })}
  </>
);

export default WorryDomains;
