import styled from 'styled-components';

export const Root = styled.div`
  overflow: auto;
  position: relative;
  margin-top: ${({ theme }) => theme.spacing['03']};
  padding: ${({ theme }) => theme.spacing['02']};

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: ${({ theme }) => theme.spacing['05']};
  flex: 0 0 300px;

  @media (max-width: 768px) {
    margin: 0
    margin-right: -${({ theme }) => theme.spacing['07']};
  }

  /* Style scrollbar for chrome, safari*/
  ::-webkit-scrollbar {
    background: transparent;
    height: ${({ theme }) => theme.spacing['03']};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.deprecated_.colors.gray};
    border-radius: ${({ theme }) => theme.borderRadius['03']};
  }

  /* Style scrollbar for firefox */
  scrollbar-color: ${({ theme }) => theme.deprecated_.colors.gray} transparent;
`;
