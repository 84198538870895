import { type Subject } from 'src/pages/FmhcCheckup/types';

import { type SmartBookKickoffCallViewProps } from './SmartBookKickoffCall.view';

interface PresenterInput {
  state: {
    isLoading: boolean;
  };
  data: {
    subject: Subject;
    isInAllowedState?: boolean;
    hasConfirmedAppointments?: boolean;
    hasCompletedAppointments?: boolean;
  };
  actions: {
    goToKickoffCallScheduling: () => void;
  };
}

interface PresenterOutput {
  viewModel: SmartBookKickoffCallViewProps['viewModel'];
}

export const smartBookKickoffCallPresenter = ({ state, data, actions }: PresenterInput): PresenterOutput => {
  return {
    viewModel: {
      instructions: {
        showLoader: state.isLoading,
        // Display the view if the user is in an allowed state & hasn't booked a kickoff call yet (no confirmed or completed appointments)
        showContent:
          !state.isLoading &&
          !!data.isInAllowedState &&
          !data.hasConfirmedAppointments &&
          !data.hasCompletedAppointments,
      },
      display: {
        title: getTitle(data.subject.subjectType),
        content: getContent(data.subject.subjectType),
        buttonText: 'Get Support Now',
      },
      effects: {
        onButtonClick: () => {
          actions.goToKickoffCallScheduling();
        },
      },
    },
  };
};

const getTitle = (subjectType: Subject['subjectType']): string => {
  const titleMap: Record<typeof subjectType, string> = {
    Child: "Don't wait to get your child the care that they need. Book your kickoff call.",
    Caregiver: 'We take care of you so you can take care of your family. Book your kickoff call.',
    Family: "Don't wait to get the care that your family needs. Book your kickoff call.",
  };
  return titleMap[subjectType];
};

const getContent = (subjectType: Subject['subjectType']): string => {
  const contentMap: Record<typeof subjectType, string> = {
    Child:
      'Trusted by 21,000+ families, Little Otter is the one-stop shop for your family to get all of their mental health needs from small concerns around sleep or tantrums all the way through really big stuff, even suicide, ideation, OCD, anxiety, and depression through virtual mental health care.',
    Caregiver:
      "Little Otter is here to support your mental health, not only as a parent and partner--but as a person. Balancing life, work, parenting, and relationships isn't easy. From co-parenting and relationship conflicts to adult ADHD and stress management, our team of clinicians are here to help.",
    Family:
      "At Little Otter, when children feel better, so does the whole family. We offer personalized and comprehensive mental health care that works for families. Our virtual care platform lets you see the best clinicians for your family’s needs ASAP--whether that's therapy, psychiatry, or parent coaching.",
  };
  return contentMap[subjectType];
};
