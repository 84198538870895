import { type FC, type PropsWithChildren } from 'react';

import { ConversationAlertContainer } from './styled';

export type ConversationAlertProps = {
  message: string;
};

export const ConversationAlert: FC<PropsWithChildren<ConversationAlertProps>> = ({ message }) => (
  <ConversationAlertContainer>{message}</ConversationAlertContainer>
);
