import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import styled from 'styled-components';

import { range } from '../../utils';
import { Button } from '../Button';

export const StyledPaginationBox = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledPageButton = styled(Button)<{ isSelected?: boolean }>`
  margin: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.25`)};
  min-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.3`)};

  color: ${({ theme, isSelected }) => (isSelected ? theme.deprecated_.colors.white : theme.deprecated_.colors.black)};
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.deprecated_.colors.black : theme.deprecated_.colors.cream};
`;

type PageButtonProps = {
  onClick: () => void;
  page?: number;
  isSelected?: boolean;
};

const PageButton: FC<PropsWithChildren<PageButtonProps>> = ({ onClick, page, isSelected, children }) => (
  <StyledPageButton isSelected={isSelected} onClick={onClick}>
    {page}
    {children}
  </StyledPageButton>
);

export type PagePaginatorProps = {
  selectedPage: number;
  totalPages: number;
  pagesToShow: number;
  onClick: (page: number) => void;
};

const calculatePages = (page: number, total: number, toShow: number) => {
  if (page > total || total === 1) {
    return [];
  }
  const midPage = Math.ceil(toShow / 2);
  const sideRange = midPage - 1;

  if (total <= toShow && page <= total) {
    return range(1, total + 1, 1);
  }
  if (page >= midPage && total >= page + sideRange) {
    return range(page - sideRange, page + sideRange + 1, 1);
  }
  if (page < midPage && toShow <= total) {
    return range(1, toShow + 1, 1);
  }
  if (page > total - sideRange) {
    return range(total - toShow, total + 1, 1);
  }
  return [];
};

export const Paginator: FC<PropsWithChildren<PagePaginatorProps>> = ({
  pagesToShow,
  selectedPage,
  totalPages,
  onClick,
}) => {
  return (
    <StyledPaginationBox>
      {totalPages > pagesToShow + 1 && selectedPage > 1 && (
        <PageButton onClick={() => onClick(1)}>
          <FaAngleDoubleLeft />
        </PageButton>
      )}
      {totalPages > pagesToShow && selectedPage > 1 && (
        <PageButton onClick={() => onClick(selectedPage - 1)}>
          <FaAngleLeft />
        </PageButton>
      )}
      {calculatePages(selectedPage, totalPages, pagesToShow).map((page) => (
        <PageButton key={page} isSelected={selectedPage === page} page={page} onClick={() => onClick(page)} />
      ))}
      {totalPages > pagesToShow && selectedPage < totalPages && (
        <PageButton onClick={() => onClick(selectedPage + 1)}>
          <FaAngleRight />
        </PageButton>
      )}
      {totalPages > pagesToShow + 1 && selectedPage < totalPages && (
        <PageButton onClick={() => onClick(totalPages)}>
          <FaAngleDoubleRight />
        </PageButton>
      )}
    </StyledPaginationBox>
  );
};
