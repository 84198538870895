import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { type ConversationPreview } from '$shared/contexts/Conversations/conversationPreview';

import {
  type ConversationsInfo,
  type ConversationsInfo_conversations,
  type ConversationsInfoVariables,
} from '../../../../graphql/__generated__/ConversationsInfo';
import { CONVERSATIONS_INFO_QUERY } from '../../../../graphql/messages';

export type ConversationInfo = ConversationsInfo_conversations;
export type ConversationsInfoState = 'success' | 'loading' | 'error' | 'skipped';

type UseConversationsInfo = (conversations: ConversationPreview[]) => [ConversationInfo[], ConversationsInfoState];

export const useConversationsInfo: UseConversationsInfo = (conversations) => {
  const conversationSids = conversations.map((conversation) => conversation.sid);

  const { data, loading, error } = useQuery<ConversationsInfo, ConversationsInfoVariables>(CONVERSATIONS_INFO_QUERY, {
    variables: { sidIn: conversationSids },
    skip: !conversations.length,
  });

  const conversationsInfo = useMemo(() => data?.conversations || [], [data?.conversations]);
  const state = useMemo<ConversationsInfoState>(() => {
    if (!conversations.length) return 'skipped';
    if (loading) return 'loading';
    if (error || !data) return 'error';
    return 'success';
  }, [conversations, data, error, loading]);

  return [conversationsInfo, state];
};
