/**
 * Removes all 'invalid' values from an array (undefined, null, NaN)
 *
 * @param array The array to remove all 'invalid' values from.
 * @returns A new array with all 'invalid' values removed.
 */
export const removeInvalidValues = <T>(array: (T | undefined | null)[]): T[] => {
  const isT = (t: T | undefined | null): t is T => {
    return t !== undefined && t !== null && !Number.isNaN(t);
  };
  return array.filter(isT);
};

/**
 * Checks if the provided value is an array and is empty.
 *
 * * The scope of this function is intentionally focused on detecting if an array is empty. For all other
 * * types of values, it will return `false`. If you want to broadly check if an unknown value is empty,
 * * consider using `isEmpty` from `lodash-es`.
 *
 * @param value The value to check.
 * @returns `true` if the value is an array AND is empty , `false` otherwise.
 */
export const isArrayAndEmpty = <T>(value?: T) => {
  return Array.isArray(value) && value.length === 0;
};
