import styled from 'styled-components';

type StyledDivProps = {
  mobileBackgroundSrc: string;
  desktopBackgroundSrc: string;
};

export const StyledDiv = styled.div<StyledDivProps>`
  background-image: url(${({ mobileBackgroundSrc }) => mobileBackgroundSrc});
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    background-image: url(${({ desktopBackgroundSrc }) => desktopBackgroundSrc});
  }
`;
