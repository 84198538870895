import { math } from 'polished';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)} ${({ theme }) => theme.deprecated_.sizeBasis};
  position: relative;
`;

export const HeaderIconContainer = styled.div`
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;

export const HeaderLeftSection = styled.div`
  position: absolute;
`;

export const HeaderCenterSection = styled.div`
  padding: 0 ${({ theme }) => theme.deprecated_.sizeBasis};
  text-align: center;
  flex: 1;
`;
