import { type UpcomingAppointmentViewer as GqlUpcomingAppointment } from '../../../../../../graphql/__generated__/UpcomingAppointmentViewer';
import { formatDate } from '../../../../../Provider/utils';
import { type UpcomingAppointmentDetails } from '../../../types';

export const gqlUpcomingAppointmentToUpcomingAppointmentDetails = (
  gqlUpcomingAppointmentData?: GqlUpcomingAppointment
): UpcomingAppointmentDetails | undefined => {
  if (
    !gqlUpcomingAppointmentData ||
    !gqlUpcomingAppointmentData.viewer?.upcomingAppointments ||
    gqlUpcomingAppointmentData.viewer?.upcomingAppointments.length === 0
  ) {
    return undefined;
  }
  const appointments = gqlUpcomingAppointmentData.viewer.upcomingAppointments;
  const [nextAppointment] = [...appointments].sort((first, second) => first.startDatetime - second.startDatetime);
  const {
    provider: { firstName, lastName },
    startDatetime,
    service,
  } = nextAppointment;

  let providerFullNameWithTitle = firstName;
  if (lastName) {
    providerFullNameWithTitle = `${providerFullNameWithTitle} ${lastName}`;
  }

  return {
    appointmentTitle: service.name,
    startTime: formatDate(startDatetime, "EEEE, MMM do 'at' h:maaa"),
    // last name includes title
    providerFullNameWithTitle,
  };
};
