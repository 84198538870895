import { Capacitor } from '@capacitor/core';
import type * as H from 'history';
import { math } from 'polished';
import { type FC, type PropsWithChildren, type ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Header, Icon, Logo } from '@littleotter/legacy-components';

const isOniOS = Capacitor.getPlatform() === 'ios';

const StyledHeader = styled.header<{ hasHeader?: boolean }>`
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  padding-top: ${({ theme }) => isOniOS && math(`${theme.deprecated_.sizeBasis} * 3`)};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  ${({ hasHeader }) => hasHeader && ` box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.03);`}
`;

const BackButton = styled(Button)`
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  position: absolute;
`;

type PageProps = {
  hasHeader?: boolean;
  headerTitle?: ReactNode;
  hasLogo?: boolean;
  hasBackButton?: boolean;
  backButtonRouteOverride?: H.LocationDescriptor;
};

export const Page: FC<PropsWithChildren<PageProps>> = ({
  children,
  hasHeader,
  headerTitle,
  hasLogo,
  hasBackButton,
  backButtonRouteOverride,
}) => {
  const history = useHistory();

  const headerContent = (
    <Header as="h5" useBodyFont style={{ textAlign: 'center' }}>
      {hasLogo ? <Logo variant="wordmark" sizeType="height" sizeAmount="1.5rem" /> : headerTitle}
    </Header>
  );

  const handleBackButtonAction = () => {
    return backButtonRouteOverride ? history.push(backButtonRouteOverride) : history.goBack();
  };

  return (
    <>
      {hasHeader && (
        <StyledHeader hasHeader={hasHeader}>
          {hasBackButton && (
            <BackButton variant="clearStyles" onClick={handleBackButtonAction}>
              <Icon name="ArrowLeft" />
            </BackButton>
          )}
          {headerContent}
        </StyledHeader>
      )}
      {children}
    </>
  );
};
