import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { formatDefaultFlagKeys } from '../common/formatDefaultFlagKeys';
import { createFeatureToggleComponent } from './components';
import { createUseClientHook, createUseFlagsHook } from './hooks';
import type { BaseFlagObject, FeatureFlagsReactConfig } from './types';

/**
 * Launch Darkly React Instance Factory
 *
 * A thin factory wrapper around the Launch Darkly React SDK to allow for easy configuration across multiple
 * applications, without having to install and import the SDK in each application.
 *
 * @param config The Launch Darkly React SDK configuration (https://docs.launchdarkly.com/sdk/client-side/react/react-web#configuring-the-react-sdk)
 */
export const createFeatureFlagsInstance = <T extends BaseFlagObject>(config: FeatureFlagsReactConfig<T>) => {
  const { flags, ...rest } = config;

  const configWithFormattedKeys = { ...rest, flags: formatDefaultFlagKeys(flags) };

  const ProviderPromise = async () => {
    const initialSleepMs = 100;
    let attempt = 1;
    const maxAttempts = 10;

    while (true) {
      try {
        return await asyncWithLDProvider(configWithFormattedKeys);
      } catch (e) {
        if (attempt === maxAttempts) {
          throw e;
        }
        const sleepMs = initialSleepMs * 2 ** (attempt - 1);
        // eslint-disable-next-line no-console
        console.warn(
          `asyncWithLDProvider threw an error (attempt ${attempt}). Sleeping for ${sleepMs}ms and trying again`,
          e
        );

        await sleep(sleepMs);
        attempt += 1;
      }
    }
  };

  const flagsHook = createUseFlagsHook<T>();
  const clientHook = createUseClientHook<T>();

  const ToggleComponent = createFeatureToggleComponent<T>();

  return { ProviderPromise, flagsHook, clientHook, ToggleComponent };
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
