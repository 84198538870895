import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { type SavedResources } from '../../../graphql/__generated__/SavedResources';
import { SAVED_RESOURCES_QUERY } from '../../../graphql/library-saved';
import Resources from '../components/Resources';

const MarginChildren = styled.div`
  margin-top: ${({ theme }) => theme.deprecated_.sizeBasis};
  > *:not(last-child) {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

export const Saved: FC<PropsWithChildren> = () => {
  const { data } = useQuery<SavedResources>(SAVED_RESOURCES_QUERY);

  if (!data?.viewer) {
    return null;
  }

  const resources = data.viewer.favorites.edges;

  return (
    <MarginChildren>
      <p>Save articles, videos, tools, resources here so you can return to them at various parts of your journey.</p>
      {resources.length > 0 && <Resources resources={resources} isSaved />}
    </MarginChildren>
  );
};
