import { math } from 'polished';
import styled from 'styled-components';

export const Tabs = styled.nav`
  display: flex;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  width: 100vw;
  padding-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4.625`)};
`;

export type TabProps = {
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
};

export const Tab = styled.div<TabProps>`
  flex: 1;
  padding: calc(${(props) => props.theme.deprecated_.sizeBasis} / 2);
  border-bottom: ${(props) =>
    `${props.theme.deprecated_.border.size} ${props.theme.deprecated_.border.style} ${props.theme.deprecated_.border.color}`};
  text-align: center;
  cursor: pointer;
  transition: all 0.3s linear;

  @media (min-width: ${(props) => props.theme.deprecated_.breakpoints.md}) {
    padding: ${(props) => props.theme.deprecated_.sizeBasis};
  }

  ${(props) =>
    props.isDisabled &&
    `color: ${props.theme.deprecated_.colors.lightGray};
      cursor: auto;
    `}
  ${(props) =>
    props.isActive &&
    `
      border-bottom-width: 2px;
      border-bottom-color: ${props.theme.deprecated_.colors.backgroundBlue};
    `}

  ${(props) =>
    !props.isDisabled &&
    `&:hover {
      font-weight: ${props.theme.deprecated_.fontWeights.bold};
      border-bottom-width: 2px;
    }`}
`;
