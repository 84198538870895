import * as Sentry from '@sentry/react';

import { BaseHandler } from '../../../logging/handlers/__BaseHandler';
import type { ErrorData, ILogHandler } from '../../../logging/types';
import { sentryErrorFormatter } from '../../utils';

/**
 * A custom Sentry log handler for React Applications that integrates Sentry's reporting API with our
 * Platform Log Service. Adding this handler to our service will pipe log events to Sentry (assuming Sentry is properly
 * configured and initialized upstream).
 */
export class SentryReactLogHandler extends BaseHandler implements ILogHandler {
  /**
   * A selected subset of Sentry's client API. We should only set the methods we use in this class.
   */
  private client;

  constructor() {
    super();
    this.client = {
      captureException: Sentry.captureException,
      captureMessage: Sentry.captureMessage,
      withScope: Sentry.withScope,
    };
    this.errorFormatter = sentryErrorFormatter;
  }

  DEBUG(message: string, context?: object) {
    this.client.captureMessage(message, { level: 'debug', tags: { ...context } });
  }

  ERROR(error: Error | string, errorData?: ErrorData, context?: object) {
    this.client.captureException(error, {
      level: 'error',
      tags: { ...context },
      extra: { ...errorData },
    });
  }

  FATAL(error: Error | string, errorData: ErrorData, context?: object) {
    this.client.captureException(error, {
      level: 'fatal',
      tags: { ...context },
      extra: { ...errorData },
    });
  }
}
