import styled, { type DefaultTheme } from 'styled-components';

import { ProgressBarAmount, ProgressBarContainer } from '../ProgressBar';
import { type SegmentBoundaries } from './utils';

type SegmentedWrapperProps = {
  boundaries: SegmentBoundaries[];
  progressColor?: keyof DefaultTheme['deprecated_']['colors'];
};

export const SegmentedWrapper = styled.div<SegmentedWrapperProps>`
  --gap: 4px;

  clip-path: polygon(
    ${({ boundaries }) =>
      boundaries
        .map(
          ([min, max]) => `
            ${max >= 100 ? '100%' : `calc(${max}% - (var(--gap) / 2))`} 0%,
            ${max >= 100 ? '100%' : `calc(${max}% - (var(--gap) / 2))`} 100%,
            ${min <= 0 ? '0%' : `calc(${min}% + (var(--gap) / 2))`} 100%,
            ${min <= 0 ? '0%' : `calc(${min}% + (var(--gap) / 2))`} 0%
          `
        )
        .join(', ')}
  );

  ${ProgressBarContainer} {
    border: none;
    margin-bottom: 0;
    background: ${({ theme }) => theme.deprecated_.colors.gray50};
  }

  ${ProgressBarAmount} {
    border-radius: var(--progress-bar-border-radius);
    background-color: ${({ theme, progressColor }) =>
      progressColor ? theme.deprecated_.colors[progressColor] : 'var(--progress-bar-color)'};
    transition:
      width 500ms ease-out,
      background-color 250ms ease-out;
  }
`;
