/* eslint-disable no-console */
import { hasErrorShape, isErrorInstance } from '@littleotter/kit/utils';

import type { IEnvironmentService } from '../environment/types';
import { shouldDispatchAsError, type LogLevelName } from './__internal__';
import type { ILoggerService, ILogHandler } from './types';

/**
 * A platform-level service that provides a consistent interface for applications to log to multiple configured handlers.
 */
export class PlatformLoggerService implements ILoggerService {
  /**
   * An instance of our environment service.
   */
  private readonly env;

  /**
   * An array of configured log handlers that will be used to log messages.
   */
  private handlers;

  /**
   * A flag that indicates whether or not the log service has been initialized.
   * We only want to initialize the log service once.
   */
  private isInitialized = false;

  constructor(env: IEnvironmentService, handlers: ILogHandler[]) {
    this.handlers = handlers.filter((h) => h.isEnabled(env));
    this.env = env;
  }

  /**
   * Initializes the log service by calling the `init` method on each handler (if it exists).
   *
   * ! This  method should only be called once, and should be called as early as possible in the application lifecycle.
   */
  init() {
    if (this.isInitialized) {
      console.warn('[platform.logging] Attempted to initialize logging service more than once.');
      return;
    }

    this.handlers.forEach((handler) => {
      if (typeof handler.init === 'function') {
        handler.init(this.env.getAppConfig());
      }
    });

    this.isInitialized = true;
  }

  trace(message: string, context?: object) {
    this.dispatch('TRACE', message, context);
  }

  debug(message: string, context?: object) {
    this.dispatch('DEBUG', message, context);
  }

  info(message: string, context?: object) {
    this.dispatch('INFO', message, context);
  }

  warn(message: string, context?: object) {
    this.dispatch('WARN', message, context);
  }

  error(error: unknown, context?: object) {
    this.dispatch('ERROR', error, context);
  }

  fatal(error: unknown, context?: object) {
    this.dispatch('FATAL', error, context);
  }

  /**
   * Attempts to safely dispatches a log event to the provided function, but only if we're not in SSR mode.
   *
   * * If for some reason we can't dispatch log, we should log the failure to the console directly.
   * * Failing to log should not fail the application.
   *
   * @param level - The log level of the event being dispatched.
   * @param content - The content to log
   * @param context - Any custom contextual data to include with the log.
   */
  private dispatch(level: LogLevelName, content: string | unknown, context?: object) {
    this.handlers.forEach((handler) => {
      try {
        if (!PlatformLoggerService.shouldDispatchToHandler(level, handler)) {
          return;
        }

        // Errors are a bit more complicated than standard string-based log events. We need to format the error into a message and error meta data.
        if (shouldDispatchAsError(level)) {
          // Use the handler's error formatter if it exists, otherwise use the default formatter.
          const formatter = handler.errorFormatter ?? PlatformLoggerService.defaultErrorFormatter;
          const formatted = formatter(content);

          handler[level]?.(formatted.error, { ...formatted.errorData }, { ...context });
          return;
        }

        // Non-error log events should only accept string content
        if (typeof content !== 'string') {
          return;
        }

        handler[level]?.(content, context);
      } catch (err) {
        console.error('[PlatformLoggerService]: Error attempting to dispatching event', {
          err,
          handler,
          level,
          content,
          context,
        });
      }
    });
  }

  /**
   * Determines if a log event should be dispatched to the provided handler based on:
   *
   * 1. The handler having a method implemented for the log level of the event.
   * 2. The log level is currently enabled for the given handler.
   */
  private static shouldDispatchToHandler(level: LogLevelName, handler: ILogHandler): boolean {
    if (typeof handler[level] !== 'function') {
      return false;
    }
    return handler.isEnabledForLevel(level);
  }

  /**
   * Formats an Error into an object with a message and error meta data.
   *
   * @param error - The error to format
   *
   * @returns {string} - The error message
   * @returns {object} - The error name and stack | The error type
   */
  private static defaultErrorFormatter(error: unknown) {
    const isError = isErrorInstance(error) || hasErrorShape(error);

    const _error = isError ? error.message : String(error);
    const errorData = isError
      ? { errorName: error.name, errorStack: error.stack, errorCause: error.cause }
      : { errorType: typeof error };

    return {
      error: _error,
      errorData,
    };
  }
}
