import { type FC, type PropsWithChildren } from 'react';

import { SubjectType, type ChildSubject, type Subject } from '../../../../../FmhcCheckup/types';
import { ClipboardIconImg } from './ClipboardIconImg';
import {
  ButtonContainer,
  CardContainer,
  Container,
  IconContainer,
  PurchaseButton,
  ScheduleButton,
  Title,
  TitleContainer,
} from './styled';

const prepareDomainNamesMarkdown = (domainNames: string[]) => {
  if (domainNames.length === 1) {
    return domainNames[0];
  }
  const lastWorryDomain = domainNames[domainNames.length - 1];
  return `${domainNames.slice(0, domainNames.length - 1).join(', ')} and ${lastWorryDomain}`;
};
const createCaregiverReportMessage = (domainNames: string[], isInAllowedState: boolean) => {
  const markdownDomainNames = prepareDomainNamesMarkdown(domainNames);
  if (isInAllowedState) {
    return `
  By scheduling a welcome session, you'll get to see how you scored on ${markdownDomainNames},
  and then get to speak to a mental health professional about it.`;
  }
  return `
  To see how you scored on ${markdownDomainNames},
  purchase the full report.`;
};

const createChildReportMessage = (subject: ChildSubject, domainNames: string[], isInAllowedState: boolean) => {
  const markdownDomainNames = prepareDomainNamesMarkdown(domainNames);
  if (isInAllowedState) {
    return `
  By scheduling a welcome session, you’ll get to see how ${subject.firstName} scored on
  ${markdownDomainNames}, and then get to speak to a mental health professional about it.`;
  }
  return `
  To see how ${subject.firstName} scored on ${markdownDomainNames},
  purchase the full report.`;
};

export type FullReportCardProps = {
  subject: Subject;
  domainNames: string[];
  isInAllowedState: boolean;
  goPurchaseReport?: () => void;
  goScheduleWelcomeCall: () => void;
};

export const PaywallReportCard: FC<PropsWithChildren<FullReportCardProps>> = ({
  subject,
  domainNames,
  isInAllowedState,
  goPurchaseReport,
  goScheduleWelcomeCall,
}) => {
  const title =
    subject.subjectType === SubjectType.Child
      ? `See the rest of ${(subject as ChildSubject).firstName}'s report`
      : 'See the rest of your report';
  const body =
    subject.subjectType === SubjectType.Child
      ? createChildReportMessage(subject as ChildSubject, domainNames, isInAllowedState)
      : createCaregiverReportMessage(domainNames, isInAllowedState);

  return (
    <CardContainer>
      <TitleContainer>
        <IconContainer>
          <ClipboardIconImg />
        </IconContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <Container>{body}</Container>
      <ButtonContainer>
        {goPurchaseReport && (
          <PurchaseButton type="button" onClick={goPurchaseReport}>
            Unlock Full Report With Promo Code: FAMILY
          </PurchaseButton>
        )}
        {isInAllowedState && (
          <ScheduleButton type="button" onClick={goScheduleWelcomeCall}>
            Schedule Your Free Kickoff Call
          </ScheduleButton>
        )}
      </ButtonContainer>
    </CardContainer>
  );
};
