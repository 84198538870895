import { type FC, type PropsWithChildren, type ReactNode } from 'react';

import { ErrorBoundary, type ErrorHandlerFn } from '$services/errors';

import { ToastError } from '../ToastError/ToastError';

type ToastErrorBoundaryProps = {
  onCatch?: ErrorHandlerFn;
  children?: ReactNode;
};

export const ToastErrorBoundary: FC<PropsWithChildren<ToastErrorBoundaryProps>> = ({ children, onCatch }) => {
  const handleCatch: ErrorHandlerFn = (error, componentStack) => {
    if (typeof onCatch === 'function') {
      onCatch(error, componentStack);
    }
  };

  return (
    <ErrorBoundary fallback={() => <ToastError />} onError={handleCatch}>
      {children}
    </ErrorBoundary>
  );
};
