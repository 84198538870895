import { getPronounsFromGender } from './getPronounsFromGender';

export const replaceTextInSurvey = (text: string, name: string, gender: string | null) => {
  const genderTerms = getPronounsFromGender(gender);
  const textReplacement = {
    '{childName}': name,
    '{childPossessivePronoun}': genderTerms.possessive,
    '{childSubjectivePronoun}': genderTerms.subjective,
    '{childObjectivePronoun}': genderTerms.objective,
  };
  const textReplacementRegex = new RegExp(Object.keys(textReplacement).join('|'), 'gi');
  const replacedText = text.replace(
    textReplacementRegex,
    (matched) => textReplacement[matched as keyof typeof textReplacement]
  );

  return replacedText;
};
