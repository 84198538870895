import {
  Children,
  cloneElement,
  isValidElement,
  type FC,
  type PropsWithChildren,
  type ReactElement,
  type ReactNode,
} from 'react';
import {
  matchPath,
  useLocation,
  useRouteMatch,
  type match as ReactRouterMatch,
  type SwitchProps,
} from 'react-router-dom';

export type TransitionSwitchProps = SwitchProps & {
  render?: (props: { children: ReactNode }) => ReactElement;
};

/**
 * Alternative to react-router Switch component for convenient route transitions
 *
 * Inspired by https://github.com/jcoreio/react-router-transition-switch
 */
export const TransitionSwitch: FC<PropsWithChildren<TransitionSwitchProps>> = ({ children, render }) => {
  const location = useLocation();
  const contextMatch = useRouteMatch();

  const { element, match } = Children.toArray(children).reduce<{
    element: ReactElement | null;
    match: ReactRouterMatch | null;
  }>(
    (acc, child) => {
      if (acc.match === null && isValidElement(child)) {
        const { path: pathProp, from } = child.props;

        const _element = child;
        const path = pathProp ?? from;
        const _match = path ? matchPath(location.pathname, { ...child.props, path }) : contextMatch;

        return { element: _element, match: _match };
      }
      return acc;
    },
    { element: null, match: null }
  );

  const routeElement =
    match && element ? cloneElement(element, { location, computedMatch: match, key: element?.key ?? match.url }) : null;

  const props = { location, match, children: routeElement };

  if (render) return render(props);

  return routeElement;
};
