import styled from 'styled-components';

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const HeaderContainer = styled.div`
  padding-bottom: 32px;
`;

export const Description = styled.p`
  color: ${({ theme }) => `${theme.deprecated_.colors.dimGray}`};
`;

export const StyledLabel = styled.label`
  font-weight: ${(props) => props.theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  cursor: pointer;
  width: 100%;
  > div {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;
