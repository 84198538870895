import { gql } from '@apollo/client';

export const PROVIDER_APPOINTMENTS_QUERY = gql`
  query ProviderAppointmentsQuery($startDatetime: DateTime, $endDatetime: DateTime) {
    appointments(startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      startDatetime
      endDatetime
      zoomLink
      client {
        id
        firstName
        lastName
        externalClientUuid
      }
      provider {
        id
        firstName
        lastName
      }
    }
  }
`;

export const STAFF_USERS_QUERY = gql`
  query StaffUsersQuery {
    viewer {
      id
      isSuperuser
    }
    users(isStaff: true) {
      id
      firstName
      lastName
    }
  }
`;

export const PROVIDER_MY_CLIENTS_QUERY = gql`
  query ProviderMyClientsQuery($providerId: Int, $name: String, $first: Int, $skip: Int) {
    familiesTotalCount(providerId: $providerId, name: $name)
    families(providerId: $providerId, name: $name, first: $first, skip: $skip) {
      id
      name
      code
      caregivers {
        id
        firstName
        lastName
        externalClientUuid
      }
      children {
        id
        firstName
        lastName
        externalClientUuid
      }
      providers {
        id
      }
      nextAppointment {
        id
        startDatetime
        provider {
          id
          firstName
          lastName
        }
      }
      conversationWithProvider(providerId: $providerId) {
        id
        conversationSid
      }
    }
  }
`;
