import { boolEnv, resolveEnvVar } from '@littleotter/kit/env';

import { type IEnvironmentService } from '../environment/types';
import { type ILoggerService } from '../logging/types';
import { NoopEventTrackingService } from './NoopEventTrackingService';
import { PlatformEventTrackingService } from './PlatformEventTrackingService';
import { RudderstackProvider } from './providers/rudderstack/RudderstackProvider';
import { type IEventTrackingService } from './types';

export const createEventTrackingService = (env: IEnvironmentService, logger: ILoggerService): IEventTrackingService => {
  const isEnabled = boolEnv(
    resolveEnvVar(process.env.ENABLE_EVENT_TRACKING || process.env.NEXT_PUBLIC_ENABLE_EVENT_TRACKING, '0')
  );

  if (!env.isServer && isEnabled) {
    logger.debug('[platform.event-tracking] Creating event tracking service');
    const provider = new RudderstackProvider(logger);
    return new PlatformEventTrackingService(logger, provider);
  }

  return new NoopEventTrackingService();
};
