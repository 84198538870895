import { math } from 'polished';
import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  min-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 17.5`)};
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const StyledHeader = styled.header`
  width: 100%;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5`)};
  position: relative;
`;

export const StyledBackground = styled.img`
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  clip-path: url(#summaryContentPath);
  max-height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5`)};
`;

export const StyledTitleIcon = styled.img`
  position: absolute;
  top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.625`)};
  right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.625`)};
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5`)};
`;

export const StyledContent = styled.div`
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const TextStyles = css`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  color: ${({ theme }) => theme.deprecated_.colors.white};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledTitleContainer = styled.div`
  position: absolute;
  padding: 0 ${({ theme }) => theme.deprecated_.sizeBasis};
  bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.125`)};
  width: ${({ theme }) => `calc(100% - ${math(`${theme.deprecated_.sizeBasis} * 5`)})`};
`;

export const StyledTitle = styled.h2`
  ${TextStyles}
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
`;

export const StyledSubtitle = styled.p`
  ${TextStyles}
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.85`)};
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * -0.5`)};
`;
