import { ApolloClient, from, HttpLink, InMemoryCache, type Operation } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { extractFiles } from 'extract-files';

import { GRAPHQL_ROUTER_URI, LO_GQL_URI } from '$configs/env';

import { customFetch } from './customFetch';
import { goToLogin, type ErrorTypes } from './errorHandlers';

type ApolloError = {
  code: ErrorTypes;
  operation?: Operation;
};
type ErrorHandler = (error: ApolloError) => void;

const cache = new InMemoryCache();

// TODO::AUTH - @Chris I tried, but it broke, so to unblock me I removed try/catch but I'm assuming we want to come back and try to do error handling here if the token isn't fetched?
const clerkAuthLink = (getToken: () => Promise<string | null>) =>
  setContext(async (_, { headers }) => {
    const token = await getToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

const createUnauthenticatedErrorLink = (newError?: ErrorHandler) =>
  onError(({ graphQLErrors, networkError }) => {
    const isUnauthenticatedGraphQLError = graphQLErrors?.some((error) => error.extensions?.code === 'UNAUTHENTICATED');
    const isUnauthenticatedNetworkError =
      networkError && 'statusCode' in networkError && [403, 302].includes(networkError.statusCode);
    const isUnauthenticatedError = isUnauthenticatedGraphQLError || isUnauthenticatedNetworkError;

    if (!isUnauthenticatedError) {
      return;
    }

    cache.reset().finally(() => {
      goToLogin();
      newError?.({ code: 'UNAUTHENTICATED' });
    });
  });

type CreateClientArgs = {
  errorHandler?: ErrorHandler;
  getToken: () => Promise<string | null>;
};

export const createClient = ({ errorHandler, getToken }: CreateClientArgs) => {
  const httpLink = new RetryLink({
    delay: {
      initial: 300,
      jitter: true,
      max: 2000,
    },
    attempts: {
      max: 10,
      retryIf: (error) => !!error,
    },
  }).split(
    (operation) => extractFiles(operation).files.size > 0,
    createUploadLink({
      uri: LO_GQL_URI, // the router does not support file uploads
      credentials: 'include',
      fetch: customFetch,
    }),
    new HttpLink({
      uri: GRAPHQL_ROUTER_URI,
      credentials: 'include',
    })
  );

  const selectedAuthLink = clerkAuthLink(getToken);

  return new ApolloClient({
    cache,
    link: from([createUnauthenticatedErrorLink(errorHandler), selectedAuthLink, httpLink]),
  });
};
