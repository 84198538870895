import { type FC, type PropsWithChildren } from 'react';

import { ReportGrayCardBody, ReportGrayCardContainer, ReportGrayCardIcon, ReportGrayCardTitle } from './styled';

export type ReportGrayCardProps = {
  icon: string | JSX.Element;
  title: string;
  className?: string;
};

export const ReportGrayCard: FC<PropsWithChildren<ReportGrayCardProps>> = ({ icon, title, children, className }) => (
  <ReportGrayCardContainer className={className}>
    <ReportGrayCardIcon>{icon}</ReportGrayCardIcon>
    <ReportGrayCardBody>
      <ReportGrayCardTitle>{title}</ReportGrayCardTitle>
      {children}
    </ReportGrayCardBody>
  </ReportGrayCardContainer>
);
