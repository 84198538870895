import { gql, useQuery } from '@apollo/client';

import { WORRY_DOMAINS_CONNECTION_FRAGMENT } from '../../../../../graphql/worry-domains';
import { type OnboardingAvailableWorryDomainsQuery } from './__generated__/OnboardingAvailableWorryDomainsQuery';

const ONBOARDING_AVAILABLE_WORRY_DOMAINS_QUERY = gql`
  query OnboardingAvailableWorryDomainsQuery {
    childWorryDomains: worryDomains(categorySlug: "child") {
      ...WorryDomainsConnectionFragment
    }

    caregiverWorryDomains: worryDomains(categorySlug: "caregiver") {
      ...WorryDomainsConnectionFragment
    }

    familyWorryDomains: worryDomains(categorySlug: "family") {
      ...WorryDomainsConnectionFragment
    }
  }

  ${WORRY_DOMAINS_CONNECTION_FRAGMENT}
`;

export const useOnboardingAvailableWorryDomainsQuery = () =>
  useQuery<OnboardingAvailableWorryDomainsQuery>(ONBOARDING_AVAILABLE_WORRY_DOMAINS_QUERY);
