import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

const StepChipContainer = styled.div<{ backgroundColor: string; textColor: string }>`
  width: 61px;
  height: 18px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-size: 10px;
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export type StepChipType = 'optional' | 'required' | 'none';

export interface StepChipProps {
  type: StepChipType;
}

export const StepChip: FC<PropsWithChildren<StepChipProps>> = ({ type }) => {
  if (type === 'none') {
    return <StepChipContainer backgroundColor="#fff" textColor="#fff" />;
  }
  const colorSet = {
    required: {
      textColor: '#EE9462',
      backgroundColor: '#FFEEE4',
    },
    optional: {
      textColor: '#57BDBA',
      backgroundColor: '#ECFDFC',
    },
  }[type];
  return <StepChipContainer {...colorSet}>{type}</StepChipContainer>;
};
