import { type FC, type PropsWithChildren } from 'react';

import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../components/MetadataRoute';
import { Page } from '../../components/Page';
import { RouteSwitch } from '../../components/RouteSwitch';
import { routes } from '../../routes';
import { Explore } from './Explore';
import ForYou from './ForYou';
import { LibraryHome } from './Home';
import Resource from './Resource';
import { Saved } from './Saved';

export const Library: FC<PropsWithChildren> = () => {
  return (
    <RouteSwitch>
      <MetadataRoute {...routeProps(routes.library.home)}>
        <Page hasHeader headerTitle="Resources">
          <LibraryHome />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.library.forYou)}>
        <Page hasHeader headerTitle="For You" hasBackButton>
          <ForYou />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.library.explore)}>
        <Page hasHeader headerTitle="Explore" hasBackButton>
          <Explore />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.library.saved)}>
        <Page hasHeader headerTitle="Saved" hasBackButton>
          <Saved />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.library.resource)} title="resource">
        <Page hasBackButton>
          <Resource />
        </Page>
      </MetadataRoute>
    </RouteSwitch>
  );
};
