import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Button, type ButtonProps } from '../Button';

const StyledButton = styled(Button)`
  width: 100%;
`;

export const FullButton: FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};
