import { rgba } from 'polished';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const ICON_SIZE = '40px';

export const Link = styled(RouterLink)`
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  display: block;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  &:hover {
    opacity: 0.9;
  }
`;
export const DisabledLink = styled.div`
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  display: block;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
`;

export const Root = styled.div<{ disabled?: boolean }>`
  align-items: center;
  background-color: ${({ theme, disabled }) =>
    disabled ? rgba(theme.deprecated_.colors.gray50, 0.4) : rgba(theme.deprecated_.colors.brandPurple, 0.4)};
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  display: flex;
  min-height: 75px;
  gap: ${({ theme }) => theme.spacing['05']};
  padding: ${({ theme }) => theme.spacing['04']} ${({ theme }) => theme.spacing['05']};
`;

export const IconContainer = styled.div`
  flex: 0 0 ${ICON_SIZE};
`;

export const ArrowContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
`;

export const Required = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.deprecated_.colors.darkestPurple};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.smaller};
  gap: ${({ theme }) => theme.spacing['01']};
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing['01']};
  text-transform: uppercase;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.text};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
`;
