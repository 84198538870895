import styled from 'styled-components';

import { addAlpha } from '$shared/utils/styles';

export const Root = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  border: ${({ theme }) => `${theme.spacing['01']} solid ${theme.deprecated_.colors.lightGray}`};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing['06']};
  position: relative;
  padding: ${({ theme }) => theme.spacing['05']};
`;

export const Title = styled.h4`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h4};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  letter-spacing: normal;
`;

export const WorriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing['03']};
  margin-top: ${({ theme }) => theme.spacing['03']};
`;

export const Worry = styled.p`
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  background: ${({ theme }) => addAlpha(theme.deprecated_.colors.red, 0.3)};
  padding: ${({ theme }) => theme.spacing['03']};
`;
