import { useCallback, type FC, type PropsWithChildren } from 'react';

import { IMPACT_DOMAIN, type Score } from '../../../types';
import { DomainCard, type DomainCardType } from '../DomainCard';
import { DomainScore } from '../DomainScore';
import { DomainHeader } from '../styled';
import { DomainCardsContainer, DomainScoresContainer, StyledListItem } from './styled';

export type DomainItemProps = {
  name: string;
  isDisabled?: boolean;
  scores: Score[];
  explanation?: string;
  callToAction?: string;
  onDomainCardClick?: (type: DomainCardType) => void;
};

export const DomainItem: FC<PropsWithChildren<DomainItemProps>> = ({
  name,
  scores,
  explanation,
  callToAction,
  isDisabled,
  onDomainCardClick,
}) => {
  const handleDomainCardClick = useCallback(
    (type: DomainCardType) => () => {
      onDomainCardClick?.(type);
    },
    [onDomainCardClick]
  );

  return (
    <StyledListItem>
      <DomainHeader>{name}</DomainHeader>
      <DomainScoresContainer>
        {scores.map(({ categorizations, label, maxScore, rawScore, threshold }) => (
          <DomainScore
            key={`domain-item-${name}-${label}`}
            label={name === IMPACT_DOMAIN ? label : undefined}
            rawScore={rawScore}
            threshold={threshold}
            maxScore={maxScore}
            lessThresholdLabel={categorizations.belowThreshold}
            greaterEqualThresholdLabel={categorizations.aboveThreshold}
            disabled={isDisabled}
          />
        ))}
      </DomainScoresContainer>
      <DomainCardsContainer>
        <DomainCard
          disabled={isDisabled}
          type="Explanation"
          content={explanation || ''}
          onClick={handleDomainCardClick('Explanation')}
          defaultOpen
        />
        <DomainCard
          disabled={isDisabled}
          type="CallToAction"
          content={callToAction || ''}
          onClick={handleDomainCardClick('CallToAction')}
        />
      </DomainCardsContainer>
    </StyledListItem>
  );
};
