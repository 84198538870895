import styled from 'styled-components';

export const StyledList = styled.ul`
  margin-left: 1rem;
  margin-bottom: 0;
  list-style-type: disc;
`;

export const CellText = styled.div`
  line-height: 1.5;
`;

export const GrayCellText = styled(CellText)`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;
