import { type FC } from 'react';

import { WithPrerequisites } from '$shared/utils/rerouter/WithPrerequisites';

import { routes } from '../../routes';
import { useViewer } from '../../shared/contexts/Viewer';
import { PaymentDetails } from './PaymentDetails';

export const PaymentDetailsWithPrerequisites: FC = () => {
  const { isInAllowedState, isStaffViewer, loading } = useViewer();
  return (
    <WithPrerequisites
      prerequisites={[
        {
          predicate: !isStaffViewer && !isInAllowedState,
          route: routes.home,
        },
      ]}
      loading={loading}
    >
      <PaymentDetails />
    </WithPrerequisites>
  );
};
