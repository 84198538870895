import Form, { type AjvError, type FormProps } from '@rjsf/core';
import { type ReactElement } from 'react';

import { NullField } from './fields';
import { FieldTemplate, ObjectFieldTemplate } from './templates';
import {
  ContentWidget,
  DateWidget,
  HeaderWidget,
  HelpTextWidget,
  ImageWidget,
  MultiSelectionWidget,
  ProgressWidget,
  SelectWidget,
  SingleSelectionWidget,
  TextWidget,
} from './widgets';

const transformErrors = (errors: AjvError[]) =>
  errors.map((validationError: AjvError) => {
    const newError: AjvError = { ...validationError };
    if (newError.name === 'required') {
      newError.message = 'This question is required.';
    }
    return newError;
  });

/**
 * Render a rjsf Form with our custom templates, fields, widgets and options.
 *
 * If there are children, render them. Otherwise pass an empty array of children to Form.
 * An empty array is needed so that `children` is truthy and Form doesn't render the default submit button.
 */
export const JSONSchemaForm = <T,>({ children, ...props }: FormProps<T>): ReactElement => (
  <Form
    FieldTemplate={FieldTemplate}
    ObjectFieldTemplate={ObjectFieldTemplate}
    showErrorList={false}
    fields={{
      NullField,
    }}
    widgets={{
      ContentWidget,
      DateWidget,
      HeaderWidget,
      HelpTextWidget,
      ImageWidget,
      MultiSelectionWidget,
      ProgressWidget,
      SelectWidget,
      SingleSelectionWidget,
      TextWidget,
    }}
    transformErrors={transformErrors}
    {...props}
  >
    {children || []}
  </Form>
);
