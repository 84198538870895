import { format } from 'date-fns';
import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/legacy-components';

import { FadeInContainer } from '../FadeInContainer';
import starsImageSrc from './assets/Stars.svg';
import { Details, MoreInfo, Stars, TopHeaderContainer } from './styled';

export type OrderDetailsProps = {
  appointmentDatetime: Date;
  practitionerFirstName: string;
  practitionerLastName: string;
  practitionerCredentials?: string;
  worryDomains: string;
};

export const OrderDetails: FC<PropsWithChildren<OrderDetailsProps>> = ({
  appointmentDatetime,
  practitionerFirstName,
  practitionerLastName,
  practitionerCredentials,
  worryDomains,
}) => {
  const formattedDate = `${format(appointmentDatetime, 'eee, LLL d')} at ${format(appointmentDatetime, 'h:mm b O')}`;
  const practitionerInfo = practitionerCredentials
    ? `${practitionerFirstName} ${practitionerLastName}, ${practitionerCredentials}`
    : `${practitionerFirstName} ${practitionerLastName}`;

  return (
    <FadeInContainer>
      <TopHeaderContainer>
        <Header as="h3">You’re in!</Header>
        <Stars src={starsImageSrc} />
      </TopHeaderContainer>
      <Header as="h4">We can’t wait to meet you.</Header>
      <Details>
        <div>
          <p>When:</p>
        </div>
        <div>
          <p>{formattedDate}</p>
        </div>
        <div>
          <p>Who:</p>
        </div>
        <div>
          <p>{practitionerInfo}</p>
        </div>
        <div>
          <p>Worries:</p>
        </div>
        <div>
          <p>{worryDomains}</p>
        </div>
      </Details>
      <MoreInfo>Video chat details sent via email.</MoreInfo>
    </FadeInContainer>
  );
};
