import { isWithinInterval } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

/**
 * Holiday break happens between Dec 17 5pm PT and Jan 3 8am PT
 */
export const isWithinHolidayBreak = (date: Date): boolean => {
  /*
   * For simplicity, we check if the given date is NOT in the holiday break
   * range
   *
   * |  previous year  |     year of `date`      |    next year    |
   * |             | break | (this interval) | break |             |
   */
  const yearOfDate = date.getUTCFullYear();
  const interval = {
    start: zonedTimeToUtc(`${yearOfDate}-01-03T08:00:00.001`, 'America/Los_Angeles'),
    end: zonedTimeToUtc(`${yearOfDate}-12-17T16:59:59.999`, 'America/Los_Angeles'),
  };

  return !isWithinInterval(date, interval);
};
