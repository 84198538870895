import * as Sentry from '@sentry/react';

import { boolEnv, listEnv, numberEnv, resolveEnvVar } from '@littleotter/kit/env';
import { removeInvalidValues } from '@littleotter/kit/utils';

import { type IEnvironmentService } from '../../environment/types';

export type SentryReactServiceConfig = Omit<
  Sentry.BrowserOptions,
  'dsn' | 'enabled' | 'release' | 'tracesSampleRate' | 'environment'
>;

/**
 * A platform-level initialization wrapper around Sentry's React SDK.
 * It sets reasonable defaults for all applications (allowing for config overrides), and automatically infers
 * settings from environment variables.
 */
export class PlatformSentryReactInitializer {
  /**
   * An instance of our environment service.
   */
  private env;

  /**
   * The Sentry DSN.
   */
  private sentryDsn?: string = 'not-set';

  /**
   * Determines whether or not Sentry is enabled.
   */
  private sentryIsEnabled;

  /**
   * Determines whether or not Sentry release tracking is enabled.
   */
  private isSentryReleaseEnabled?: boolean;

  /**
   * Determines the percentage of performance-related transactions that will be sent to Sentry.
   */
  private sentryTracesSampleRate;

  /**
   * Determine the replay sample rate for the application.  1 means 100% of all interactions are recorded.
   * You may want this to be 100% while in development and sample at a lower rate in production.
   */
  private sentryReplaysSessionSampleRate;

  /**
   * If the entire session is not sampled for replay (above), use the below sample rate to sample
   * sessions when an error occurs.
   */
  private sentryReplaysOnErrorSampleRate = 1.0;

  /**
   * The custom config overrides provided by the invoking application
   */
  private configOverrides;

  /**
   * A flag that indicates whether or not Sentry has been initialized.
   * We only want to initialize Sentry once.
   */
  private isInitialized = false;

  constructor(env: IEnvironmentService, configOverrides: SentryReactServiceConfig = {}) {
    this.env = env;

    // Set environment variables
    this.sentryDsn = resolveEnvVar(process.env.SENTRY_DSN);
    this.sentryIsEnabled = boolEnv(resolveEnvVar(process.env.ENABLE_SERVICE_SENTRY, '0'));
    this.isSentryReleaseEnabled = boolEnv(resolveEnvVar(process.env.SENTRY_ENABLE_RELEASE, '0'));
    this.sentryTracesSampleRate = numberEnv(resolveEnvVar(process.env.SENTRY_TRACES_SAMPLE_RATE, '0'));
    this.sentryReplaysSessionSampleRate = numberEnv(
      resolveEnvVar(process.env.SENTRY_REPLAYS_SESSIONS_SAMPLE_RATE, '0')
    );

    this.configOverrides = configOverrides;
  }

  init() {
    if (this.isInitialized) {
      // eslint-disable-next-line no-console
      console.error('[PlatformSentryReactInitializer]: Attempted to initialize service more than once.');
      return;
    }

    if (this.sentryIsEnabled && !this.sentryDsn) {
      throw new Error(
        '[PlatformSentryReactInitializer]: Sentry is enabled via ENABLE_SERVICE_SENTRY, but the SENTRY_DSN env var is missing.'
      );
    }

    try {
      const config = this.getConfig();
      Sentry.init(config);
      this.isInitialized = true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[PlatformSentryReactInitializer]: Failed to initialize service.', error);
    }
  }

  /**
   * Merges the default config and inferred environment information with the custom config overrides
   * provided by the invoking application.
   *
   * --- Integration Notes ---
   *
   * * CaptureConsole
   * @see https://docs.sentry.io/platforms/javascript/configuration/integrations/plugin/#extraerrordata
   * When developing locally, the CaptureConsole integration will cause a bunch of duplicate errors to be logged to Sentry.
   * This is because logger.error will cause console output, which will cause Sentry to log the error again.
   * The reported console errors resulting from this integration will likely have odd formatting and will typically be
   * missing error metadata. It's not a cause for concern.
   *
   * * ExtraErrorData
   * @see https://docs.sentry.io/platforms/javascript/configuration/integrations/plugin/#extraerrordata
   * This plugin will attempt to extract all non-native properties from an error object and add them to the Sentry
   * event as extra data. This plugin is enabled as a fail-safe in case errors with custom or unexpected properties
   * are thrown somewhere we don't have full control over.
   *
   * * Replay
   * @see https://docs.sentry.io/platforms/javascript/session-replay/configuration/
   * Rewind and replay your application's DOM state, key user interactions like mouseclicks and scrolls, network
   * requests, and console entries in a single combined UI inspired by your browser's DevTools.
   * By default, our Session Replay SDK masks all DOM text content, images, and user input, giving you heightened
   * confidence that no sensitive data leaves the browser.
   *
   *
   */
  private getConfig(): Sentry.BrowserOptions {
    // We only want to forward console errors in non-local environments. This helps make testing Sentry locally easier
    // by avoiding duplicate errors and console-forwarded errors with missing metadata being sent to Sentry.
    const consoleIntegration = this.env.isLocalEnvironment
      ? null
      : Sentry.captureConsoleIntegration({ levels: ['error'] });
    const integrations = removeInvalidValues([
      Sentry.browserTracingIntegration(),
      Sentry.extraErrorDataIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true,
        blockAllMedia: false,
        // See: https://docs.sentry.io/platforms/javascript/session-replay/configuration/#network-details
        networkDetailAllowUrls: listEnv(process.env.SENTRY_NETWORK_DETAIL_ALLOW_URLS),
        networkRequestHeaders: ['cookie', 'x-session-id'],
        networkResponseHeaders: ['set-cookie'],
      }),
      consoleIntegration,
    ]);

    return {
      release: this.isSentryReleaseEnabled ? this.env.appVersion : undefined,
      dsn: this.sentryDsn,
      environment: this.env.appEnvironment,
      tracesSampleRate: this.sentryTracesSampleRate,
      enabled: this.sentryIsEnabled && !!this.sentryDsn,
      replaysSessionSampleRate: this.sentryReplaysSessionSampleRate,
      replaysOnErrorSampleRate: this.sentryReplaysOnErrorSampleRate,
      integrations,
      ...this.configOverrides,
    };
  }
}
