import {
  type OnboardingWorryDomainsQuery_viewer_family_children_worryDomains,
  type OnboardingWorryDomainsQuery_viewer_family_worryDomains,
  type OnboardingWorryDomainsQuery_viewer_worryDomains,
} from '../../../../../Onboarding/hooks/__generated__/OnboardingWorryDomainsQuery';

export const extractWorryDomains = (
  wds:
    | OnboardingWorryDomainsQuery_viewer_worryDomains
    | OnboardingWorryDomainsQuery_viewer_family_worryDomains
    | OnboardingWorryDomainsQuery_viewer_family_children_worryDomains
    | undefined
    | null
): string[] => (wds?.edges ?? []).map((edge) => edge?.node?.name).filter((wd): wd is string => !!wd);
