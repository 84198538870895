import { type FC, type PropsWithChildren } from 'react';

import { ReactComponent as Anger } from './assets/anger.svg';
import { ReactComponent as Anxiety } from './assets/anxiety.svg';
import { ReactComponent as Depression } from './assets/depression.svg';
import { ReactComponent as Eating } from './assets/eating.svg';
import { ReactComponent as Focus } from './assets/focus.svg';
import { ReactComponent as Heart } from './assets/heart.svg';
import { ReactComponent as Relationships } from './assets/relationships.svg';
import { ReactComponent as Sensory } from './assets/sensory.svg';
import { ReactComponent as Sleep } from './assets/sleep.svg';
import { ReactComponent as Toileting } from './assets/toileting.svg';

export type WorryDomainShortName = keyof typeof shortNameToIconMap;

export type WorryDomainIconType = {
  worryDomainShortName?: WorryDomainShortName;
};

const shortNameToIconMap = {
  focus: Focus,
  depression: Depression,
  anxiety: Anxiety,
  eating: Eating,
  sleep: Sleep,
  toileting: Toileting,
  sensory_sensitivities: Sensory,
  anger_aggression: Anger,
  caregiver_health: Heart,
  covid_19: Heart,
  peer_relationships: Relationships,
};

export const WorryDomainIcon: FC<PropsWithChildren<WorryDomainIconType>> = ({ worryDomainShortName }) => {
  if (worryDomainShortName && shortNameToIconMap[worryDomainShortName]) {
    const IconToRender = shortNameToIconMap[worryDomainShortName];

    return <IconToRender />;
  }
  return <Heart />;
};
