import { differenceInMonths } from 'date-fns';

import { type ViewerQuery_viewer_family_children } from '../../../graphql/__generated__/ViewerQuery';
import { type FamilyWorryDomainsQuery_family_children } from '../../Reports/Fmhc/ReportsContent/hooks/__generated__/FamilyWorryDomainsQuery';
import { SubjectType, type ChildSubject } from '../types';

const calculateChildAgeInMonths = (childDateOfBirth: string): number =>
  differenceInMonths(new Date(), new Date(childDateOfBirth));

export const gqlChildToChildSubject = (
  child: ViewerQuery_viewer_family_children | FamilyWorryDomainsQuery_family_children
): ChildSubject => {
  const childAgeInMonths = calculateChildAgeInMonths(child.dateOfBirth);
  const childMember: ChildSubject = {
    id: child.id,
    gender: child.gender ?? '',
    firstName: child.firstName,
    lastName: child.lastName ?? '',
    dateOfBirth: child.dateOfBirth,
    ageInMonths: childAgeInMonths,
    subjectType: SubjectType.Child,
    isSeekingCare: child.isSeekingCare,
  };
  return childMember;
};
