import { type FC, type PropsWithChildren } from 'react';

import { StyledZeroStateCardContainer, StyledZeroStateCardDescription, StyledZeroStateCardLink } from './styled';

export type ZeroStateCardProps = {
  highlightedText?: string;
  normalText?: string;
  linkLabel?: string;
  linkHref?: string;
};

export const ZeroStateCard: FC<PropsWithChildren<ZeroStateCardProps>> = ({
  highlightedText,
  normalText,
  linkLabel,
  linkHref,
}) => (
  <StyledZeroStateCardContainer>
    <StyledZeroStateCardDescription>
      {highlightedText && <b>{highlightedText} </b>}
      {normalText}
    </StyledZeroStateCardDescription>
    {linkLabel && <StyledZeroStateCardLink href={linkHref}>{linkLabel}</StyledZeroStateCardLink>}
  </StyledZeroStateCardContainer>
);
