import { type Subject } from 'src/pages/FmhcCheckup/types';

import { useViewer } from '$shared/contexts/Viewer';
import { scheduleKickoffCallProxyUrl } from '$shared/utils/proxy';

import { smartBookKickoffCallPresenter } from './SmartBookKickoffCall.presenter';
import { SmartBookKickoffCallView } from './SmartBookKickoffCall.view';

interface SmartBookKickoffCallProps {
  subject: Subject;
}

export const SmartBookKickoffCall = ({ subject }: SmartBookKickoffCallProps) => {
  const { viewer, loading, isInAllowedState } = useViewer();

  const presenter = smartBookKickoffCallPresenter({
    state: {
      isLoading: loading,
    },
    data: {
      subject,
      isInAllowedState,
      hasConfirmedAppointments: viewer?.hasConfirmedAppointments,
      hasCompletedAppointments: viewer?.hasCompletedAppointments,
    },
    actions: {
      goToKickoffCallScheduling: () => {
        window.location.href = scheduleKickoffCallProxyUrl;
      },
    },
  });

  return <SmartBookKickoffCallView viewModel={presenter.viewModel} />;
};
