import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { inject as injectVercelAnalytics } from '@vercel/analytics';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle, theme } from '@littleotter/legacy-components';

import { getFeatureFlagProvider, useFeatureFlagClient, useFeatureFlagIdentityManager } from '$services/featureFlags';
import { logger } from '$services/logging';

import { AppDefaultMode } from './AppDefaultMode';
import { SentryErrorBoundary as ErrorBoundary, init, useMountBootstrap } from './bootstrap.client';
import { reportWebVitals, sendToVercelAnalytics } from './webVitalsReporting';

init();

const App = () => {
  useMountBootstrap();

  const { refreshIdentity } = useFeatureFlagIdentityManager();

  // See: https://support.launchdarkly.com/hc/en-us/articles/12998125691419-Error-LaunchDarklyFlagFetchError-network-error-
  const featureFlagClient = useFeatureFlagClient();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (args: any[]) => logger.warn(`[feature-flags] error: ${args}`);
    featureFlagClient.on('error', onError);
    return () => featureFlagClient.off('error', onError);
  }, [featureFlagClient]);

  // This effect makes sure the feature flag identity is properly set on an app reload
  // NOTE: by default, the feature flag identity is ANONYMOUS
  //   (see src/infra/services/featureFlags/index.ts#featureFlagClientConfig)
  // ANOTHER NOTE: refreshing identity at this level enables us to turn on maintenance mode
  //   and allow select engineers or staff to continue to access the app in default mode.
  useEffect(() => {
    refreshIdentity();
  }, [refreshIdentity]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AppDefaultMode />
    </ThemeProvider>
  );
};

(async () => {
  // At present, our FeatureFlagProvider is render blocking, ensuring all feature flags are resolved before proceeding
  const FeatureFlagProvider = await getFeatureFlagProvider();
  const container = document.getElementById('root');
  if (container === null) {
    throw new Error('the #root div is missing');
  }
  const root = createRoot(container);
  root.render(
    <ErrorBoundary>
      <FeatureFlagProvider>
        <App />
      </FeatureFlagProvider>
    </ErrorBoundary>
  );
})();

// noinspection JSIgnoredPromiseFromCall
defineCustomElements(window);
reportWebVitals(sendToVercelAnalytics);
injectVercelAnalytics();
