import { format, isToday, parseISO } from 'date-fns';

const DEFAULT_FORMAT_STRING = 'EEEE MMMM d';

export const formatDate = (date: Date, formatString?: string): string => {
  const resolvedFormatString = formatString ?? DEFAULT_FORMAT_STRING;
  try {
    return formatDateSimple(date, resolvedFormatString);
  } catch (e) {
    return formatDateComplicated(date, resolvedFormatString);
  }
};

const formatDateSimple = (date: Date, formatString: string): string => {
  if (isToday(date)) {
    return 'Today';
  }

  return format(date, formatString);
};

const formatDateComplicated = (date: Date, formatString: string): string => {
  // Golang datetime string includes fractions of seconds, e.g. 2022-05-22T04:32:38.703246Z
  // Truncate the fractional part of time, and format will work fine.
  const datestr = date.toString().replace(/\.[0-9]*/g, '');
  return format(parseISO(datestr), formatString);
};
