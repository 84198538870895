import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Button, Header, Icon, Link, ProfilePicture } from '@littleotter/legacy-components';

import { routes } from '../../routes';

const StyledCard = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  padding: ${({ theme }) =>
    `${math(`${theme.deprecated_.sizeBasis} * 1.25`)} ${math(`${theme.deprecated_.sizeBasis} * 1.5`)}`};
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: 0px 26px 60px rgba(0, 0, 0, 0.05);
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;
export const CardLeftSection = styled.div`
  flex-shrink: 0;
`;

const CardCenter = styled.div`
  flex-grow: 1;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  }
`;

const StyledCardActions = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => theme.deprecated_.sizeBasis};
  > :first-child {
    margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
  > button {
    flex: 1;
    min-width: auto;
  }
`;

const SmallText = styled.span`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;

const StyledIcon = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.deprecated_.colors.white};
  }
`;

type ProviderCardProps = {
  fullName: string;
  title: string;
  profilePictureSrc: string;
  pk?: number;
  onSeeCompleteBioClick?: () => void;
  onMessageButtonClick?: () => void;
  onScheduleButtonClick?: () => void;
};

export const ProviderCard: FC<PropsWithChildren<ProviderCardProps>> = ({
  fullName,
  title,
  profilePictureSrc,
  pk,
  onSeeCompleteBioClick,
  onMessageButtonClick,
}) => (
  <StyledCard>
    <div style={{ width: '100%', display: 'flex' }}>
      <CardLeftSection>
        <ProfilePicture size="smaller" src={profilePictureSrc} />
      </CardLeftSection>
      <CardCenter>
        <Header as="h5">{fullName}</Header>
        <div>
          <SmallText>{title}</SmallText>
        </div>
        <div>
          {pk !== undefined && <Link href={routes.profile.care.bio.url({ id: pk })}>See complete bio</Link>}
          {pk === undefined && (
            <Button variant="link" onClick={onSeeCompleteBioClick}>
              See complete bio
            </Button>
          )}
        </div>
      </CardCenter>
    </div>
    <StyledCardActions>
      {onMessageButtonClick && (
        <Button onClick={onMessageButtonClick}>
          <StyledIcon name="MessagesFill" />
          Message
        </Button>
      )}
    </StyledCardActions>
  </StyledCard>
);
