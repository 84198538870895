/**
 * UTM cookie prefix to be used for storing UTM parameters in cookies
 */
export const UTM_PARAMETER_COOKIE_PREFIX = 'lo_utm';

/**
 * Default Facebook Cookie names set by the Facebook Pixel
 */
export const FACEBOOK_CLICK_ID_COOKIE_NAME = '_fbc';
export const FACEBOOK_BROWSER_ID_COOKIE_NAME = '_fbp';

/**
 * This is a custom cookie we set manually in our Squarespace App on the Meta Landing Page.
 * We need to forward the cookie value to rudderstack events to improve ad performance.
 * @deprecated - prefer _fbc cookie
 */
export const DEPERECATED_FACEBOOK_LO_CLICK_ID_COOKIE_NAME = 'lo_fbc';

/**
 * This is a custom cookie we set manually in our Squarespace App on the Meta Landing Page.
 * We need to forward the cookie value to rudderstack events to improve ad performance.
 * @deprecated - prefer _fbp cookie
 */
export const DEPRECATED_FACEBOOK_LO_BROWSER_ID_COOKIE_NAME = 'lo_fbp';
