import { endRoute, parentRoute } from '$shared/utils/routes';

export const careRoutes = {
  care: parentRoute('/care')({
    journey: parentRoute('/care/journey')({
      sessionNote: endRoute<{ id: string }>('/care/journey/:id'),
    }),
    team: endRoute('/care/team'),
    schedule: endRoute('/care/schedule'),
  }),
};
