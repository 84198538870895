import { useCallback, useState, type FC, type PropsWithChildren, type ReactNode } from 'react';

import { CollapseContent } from './CollapseContent';
import { type CollapseContentContainerVariant, type CollapseContentIconVariant } from './types';

export type CollapseProps = {
  label: ReactNode;
  maxHeight?: string;
  defaultOpen?: boolean;
  iconVariant?: CollapseContentIconVariant;
  containerVariant?: CollapseContentContainerVariant;
  onClick?: () => void;
  className?: string;
};

export const Collapse: FC<PropsWithChildren<CollapseProps>> = ({
  label,
  children,
  maxHeight = '1000px',
  defaultOpen = false,
  iconVariant,
  containerVariant,
  onClick,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const onCollapseClick = useCallback(() => {
    setIsOpen((prev) => !prev);
    onClick?.();
  }, [onClick]);

  return (
    <CollapseContent
      onClick={onCollapseClick}
      label={label}
      isOpen={isOpen}
      maxHeight={maxHeight}
      iconVariant={iconVariant}
      containerVariant={containerVariant}
      className={className}
    >
      {children}
    </CollapseContent>
  );
};
