/**
 * Returns an array of `limit` number of elements from `list`.
 * If the length of `list` is greater than `limit`, the last element will instead be an array of the rest of the items.
 *
 * @param list Original list. Not modified by this function.
 * @param limit Number of elements in the returned array. Must be greater than 1.
 *
 * @example
 * ```
 * limitList(['one', 'two', 'three'], 3); // => ['one', 'two', 'three']
 * limitList(['one', 'two', 'three', 'four'], 3); // => ['one', 'two', ['three', 'four']]
 * limitList([1, { value: 'two' }, 'three', 'four'], 3); // => [1, { value: 'two' }, ['three', 'four']]
 * ```
 */
export const limitList = <T>(list: T[], limit: number) => {
  if (limit < 2) {
    throw new Error('Limit must be greater than 1');
  }

  if (list.length <= limit) {
    return [...list];
  }

  const prevItems = list.slice(0, limit - 1);
  const countItem = list.slice(limit - 1);

  return [...prevItems, countItem];
};
