import { type Segment } from '../types';
import { sortSegments } from './sortSegments';

export type SegmentBoundaries = [min: number, max: number];

export const getSegmentBoundaries = (segments: Segment[]): SegmentBoundaries[] => {
  return sortSegments(segments).map((segment, index, sortedSegments) => {
    const previousThreshold = index > 0 ? sortedSegments[index - 1].threshold : 0;
    const currentThreshold = segment.threshold;

    return [previousThreshold, currentThreshold];
  });
};
