import { math } from 'polished';
import styled from 'styled-components';

import { Button, JSONSchemaForm } from '@littleotter/legacy-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  width: 100%;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  margin-top: auto;
`;

export const StyledLink = styled.p`
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  width: 100%;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  margin-top: auto;
`;

export const StyledForm = styled(JSONSchemaForm)`
  height: 100%;
  display: flex;
  flex-direction: column;

  div > label > span {
    display: block;
    ${({ theme }) => `
      font-family: ${theme.deprecated_.fontFamilies.body};
    `}
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const StyledImg = styled.img`
  display: block;
  margin: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.5`)} auto;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 8.75`)};
`;

export const StyledText = styled.p`
  ${({ theme }) => `
        font-family: ${theme.deprecated_.fontFamilies.body};
        font-weight: ${theme.deprecated_.fontWeights.regular};
        font-size: ${theme.deprecated_.fontSizes.regular};
        color: ${theme.deprecated_.colors.text}CC;
    `}
`;

export const StyledHeader = styled.h1`
  ${({ theme }) => `
        font-family: ${theme.deprecated_.fontFamilies.header};
        font-weight: ${theme.deprecated_.fontWeights.semiBold};
        font-size: ${math(`1.375 * ${theme.deprecated_.sizeBasis}`)};
        margin: ${math(`0.625 * ${theme.deprecated_.sizeBasis}`)} 0 ${math(`1.75 * ${theme.deprecated_.sizeBasis}`)};
        .bold {
            font-weight: ${theme.deprecated_.fontWeights.bold};
        }
    `}
`;

export const StyledNote = styled(StyledText)`
  ${({ theme }) => `
        color: ${theme.deprecated_.colors.text}80;
        margin-top: calc(${theme.deprecated_.sizeBasis} * 1.75);
    `}
`;
