import { gql } from '@apollo/client';

import { SUBSCALE_RESULT_FRAGMENT } from './back-to-school-report';

export const HOLIDAY_REPORT_QUERY = gql`
  query HolidayReportQuery($uuid: String!) {
    assessmentsNamespace {
      assessmentResponse(uuid: $uuid) {
        id
        updatedAt
        result {
          ...HolidayReportFragment
        }
      }
    }
  }

  fragment HolidayResponseChildInfoFragment on HolidayResponseChildInfoType {
    childName
    childAgeYears
    parentName
  }

  fragment HolidaySummaryResultsFragment on SummaryResultType {
    familyDynamics
    childEmotionalAndBehavioralHealth
    caregiverMentalHealthRisk
    holidayStress
  }

  fragment HolidaySubscaleContentFragment on SubScaleContentType {
    meaning
    whatICanDoToSupport
    activityToDo
  }

  fragment HolidayScoreRiskClinicalContent on ScoreRiskClinicalContentType {
    score
    risk
    riskThreshold
    clinical
    scorePercentiles
    content {
      ...HolidaySubscaleContentFragment
    }
  }

  fragment HolidayCaregoverRiskFragment on CaregoverRiskType {
    score
    risk
  }

  fragment HolidayFamilyDynamicsResultsFragment on FamilyDynamicsType {
    holidayGrieving {
      ...HolidaySubscaleContentFragment
    }
    parentingDifferences {
      ...HolidaySubscaleContentFragment
    }
    holidayChildHugs {
      ...HolidaySubscaleContentFragment
    }
    holidayChildSleep {
      ...HolidaySubscaleContentFragment
    }
    holidayChildWeight {
      ...HolidaySubscaleContentFragment
    }
    traditionDifferences {
      ...HolidaySubscaleContentFragment
    }
    difficultFamily {
      ...HolidaySubscaleContentFragment
    }
  }

  fragment HolidayChildEmotionAndBehaviorResultsFragment on ChildEmotionAndBehaviorType {
    totalEmotional {
      ...HolidayScoreRiskClinicalContent
    }
    totalBehavioral {
      ...HolidayScoreRiskClinicalContent
    }
  }

  fragment HolidayCaregiverMentalHealthResultFragment on CaregiverMentalHealthType {
    anxiety {
      ...HolidayCaregoverRiskFragment
    }
    depression {
      ...HolidayCaregoverRiskFragment
    }
    content {
      ...HolidaySubscaleContentFragment
    }
  }

  fragment HolidayReportFragment on HolidayReportType {
    childInfo {
      ...HolidayResponseChildInfoFragment
    }
    summaryResult {
      ...HolidaySummaryResultsFragment
    }
    familyDynamics {
      ...HolidayFamilyDynamicsResultsFragment
    }
    childEmotionAndBehavior {
      ...HolidayChildEmotionAndBehaviorResultsFragment
    }
    caregiverMentalHealth {
      ...HolidayCaregiverMentalHealthResultFragment
    }
    holidayStress {
      ...SubScaleResultFragment
    }
  }
  ${SUBSCALE_RESULT_FRAGMENT}
`;
