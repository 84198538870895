import { format } from 'date-fns';
import { useMemo, type FC, type PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Header, Tag, type Theme } from '@littleotter/legacy-components';

import { replaceTextInSurvey } from '$shared/utils/replaceTextInSurveys';

import { type SurveyResponseDetailsSurveyResponseFragment } from '../../graphql/__generated__/SurveyResponseDetailsSurveyResponseFragment';

const marginChildren = (sizeMultiplier = 1) => css`
  > *:not(:last-child) {
    margin-bottom: calc(${({ theme }) => theme.deprecated_.sizeBasis} * ${sizeMultiplier});
  }
`;

const StyledArticle = styled.article`
  ${marginChildren(2)}
`;

const StyledSection = styled.section`
  ${marginChildren()}
`;

const Label = styled.span`
  display: inline-block;
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  padding: 0.4rem;
  background-color: ${({ theme }) => theme.deprecated_.colors.brandPurple};
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`;

const ScoreContainer = styled.div`
  margin-left: ${({ theme }) => theme.deprecated_.sizeBasis};
  flex-shrink: 0;
`;

export type RiskCategorization = 'low' | 'medium' | 'high';

const getTagBackgroundColor = (riskCategorization: RiskCategorization): keyof Theme['deprecated_']['colors'] =>
  (
    ({
      low: 'green',
      medium: 'yellow',
      high: 'red',
    }) as const
  )[riskCategorization];

type SurveyResponseDetailsProps = {
  surveyResponse: SurveyResponseDetailsSurveyResponseFragment;
  showScores?: boolean;
};

export const SurveyResponseDetails: FC<PropsWithChildren<SurveyResponseDetailsProps>> = ({
  surveyResponse,
  showScores,
}) => {
  const createdAt = useMemo(
    () => format(new Date(surveyResponse.createdAt), 'MMMM d, yyyy'),
    [surveyResponse.createdAt]
  );

  const { child } = surveyResponse;

  if (!surveyResponse.survey || !child) return null;

  const {
    riskCategorization,
    survey: { worryDomain },
  } = surveyResponse;

  return (
    <StyledArticle>
      <StyledSection as="div">
        <Header as="h2">
          {child.displayName}'s {worryDomain?.name}
        </Header>
        <div>{createdAt}</div>
      </StyledSection>
      {riskCategorization && (
        <StyledSection>
          <Header as="h3">Risk Categorization</Header>
          <div>
            <Tag backgroundColor={getTagBackgroundColor(riskCategorization as RiskCategorization)}>
              {riskCategorization}
            </Tag>
          </div>
        </StyledSection>
      )}
      <StyledSection>
        <Header as="h3">Survey Responses</Header>
        {surveyResponse.questionResponses.map(
          ({ id: questionResponseId, question, answer }) =>
            question?.introText &&
            question.endText &&
            answer && (
              <Card key={questionResponseId}>
                <p>
                  {replaceTextInSurvey(question.introText, child.displayName, child.gender)}{' '}
                  <Label>{answer.label}</Label> {replaceTextInSurvey(question.endText, child.displayName, child.gender)}
                </p>
                {showScores && (
                  <ScoreContainer>
                    <Tag>Score: {answer.value}</Tag>
                  </ScoreContainer>
                )}
              </Card>
            )
        )}
      </StyledSection>
    </StyledArticle>
  );
};
