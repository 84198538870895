import { type AnswerInput as GqlAnswerInput } from '../../../graphql/lo1/generated';
import { type QuestionAnswer } from '../types';

export const questionAnswersToGqlAnswerInput = (questionAnswers: QuestionAnswer[]): GqlAnswerInput[] => {
  return questionAnswers.map(getAnswerInput);
};

const getAnswerInput = (questionAnswer: QuestionAnswer): GqlAnswerInput => {
  return {
    questionVariableName: questionAnswer.variableName,
    choice: {
      id: questionAnswer.choice.id,
      choice: questionAnswer.choice.choice,
      value: questionAnswer.choice.value,
    },
  };
};
