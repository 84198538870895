import { type FC } from 'react';

import { ImageElement, ImageWrapper } from './styled';

export type ImageProps = {
  alt: string;
  src: string;
  maxWidth?: string;
  maxHeight?: string;
};

export const Image: FC<ImageProps> = ({ alt, src, maxWidth, maxHeight }) => (
  <ImageWrapper maxWidth={maxWidth} maxHeight={maxHeight}>
    <ImageElement src={src} alt={alt} maxHeight={maxHeight} />
  </ImageWrapper>
);
