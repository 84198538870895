import styled, { css } from 'styled-components';

const marginChildren = (sizeMultiplier = 1) => css`
  > *:not(:last-child) {
    margin-bottom: calc(${({ theme }) => theme.deprecated_.sizeBasis} * ${sizeMultiplier});
  }
`;

export const MarginChildren = styled.div<{ small?: boolean }>`
  ${({ small }) => marginChildren(small ? 0.5 : 1)}
`;

export const StyledSection = styled.section`
  ${marginChildren()}
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`;

export const ProfileDetails = styled.section`
  ${marginChildren(0.5)}
`;

export const ChildInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const WorryDomainsSection = styled.section`
  text-align: center;
  ${marginChildren(0.5)}
`;
