import { type FC, type PropsWithChildren } from 'react';

import { Link } from '@littleotter/legacy-components';

import { StyledVideo } from './styled';

export const VideoElement: FC<PropsWithChildren<{ mediaUrl: string }>> = ({ mediaUrl }) => (
  <StyledVideo controls src={mediaUrl}>
    <p>
      Your browser doesn't support this kind of video. Here is a{' '}
      <Link isAnchor href={mediaUrl} target="_blank">
        Link to Download Video
      </Link>{' '}
      instead.
    </p>
  </StyledVideo>
);
