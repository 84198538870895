import { endRoute, parentRoute } from '$shared/utils/routes';

export type FmhcUrlParams = {
  familyId: string;
  fmhcId: string;
};

export const providerRoutes = {
  provider: parentRoute('/provider')({
    client: parentRoute('/provider/client')({
      details: endRoute<{ id: number }>('/provider/client/:id(\\d+)'),
      surveyResponse: endRoute<{ id: number }>('/provider/client/survey-response/:id'),
    }),
    family: parentRoute('/provider/family')({
      details: endRoute<{ id: number }>('/provider/family/:id'),
      reports: endRoute<FmhcUrlParams>('/provider/family/:familyId/reports/:fmhcId'),
    }),
  }),
};
