import { type FC, type PropsWithChildren } from 'react';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';

import { CircleIcon } from './components';

export interface SummaryCardProps {
  fullName?: string;
  dateOfBirth?: string;
  gender?: string;
  onRemoveClick?: () => void;
}

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 13px;
  background: #f9fafa;
  background: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  border-radius: 8px;
`;

const SummaryText = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameContainer = styled.span`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;

const BioContainer = styled.div`
  padding-top: 0.5rem;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.regular};
  text-transform: capitalize;
`;

const RemoveContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SummaryCard: FC<PropsWithChildren<SummaryCardProps>> = ({
  fullName,
  dateOfBirth,
  gender,
  onRemoveClick,
}) => {
  return (
    <SummaryContainer>
      <SummaryText>
        <NameContainer>{fullName}</NameContainer>
        <BioContainer>
          {dateOfBirth} • {gender}
        </BioContainer>
      </SummaryText>
      <RemoveContainer>
        <CircleIcon color="lightGray" onClick={onRemoveClick}>
          <IoClose />
        </CircleIcon>
      </RemoveContainer>
    </SummaryContainer>
  );
};
