import TagManager from 'react-gtm-module';

import { GOOGLE_TAG_MANGER } from '$configs/env';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

export const gtagAccountRegistration = () => {
  if (!shouldUseGtagManager()) return;
  // eslint-disable-next-line no-console
  console.debug('[services.analytics.gtag] Legacy account registration event fired');
  window.dataLayer.push({
    event: 'account_registration',
  });
};

let isInitialized = false;
export const initializeGTagManager = () => {
  if (!shouldUseGtagManager()) return;
  if (!isInitialized) {
    TagManager.initialize({ gtmId: GOOGLE_TAG_MANGER.CONTAINER_ID });
    isInitialized = true;
    // eslint-disable-next-line no-console
    console.debug('[services.analytics.gtag] Initializing Google Tag Manager');
  }
};

const shouldUseGtagManager = () => {
  return GOOGLE_TAG_MANGER.CONTAINER_ID !== GOOGLE_TAG_MANGER.CONTAINER_ID_DEFAULT;
};
