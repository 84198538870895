import { type Subject } from '../../FmhcCheckup/types';

export const IMPACT_DOMAIN = 'Impact';

export const DEFAULT_CATEGORIZATION: Categorization = {
  belowThreshold: 'Typical',
  aboveThreshold: 'Concerning',
};

export const IMPACT_DOMAIN_CATEGORIZATION: Categorization = {
  belowThreshold: 'Not Impacting',
  aboveThreshold: 'Impacting',
};

export type FmhcReportCollection = {
  submissionDatetime: Date;
  submissionUser: string;
  isFreeAccount: boolean;
  subjectReports: SubjectReportCollection[];
};

export type SubjectReportCollection = {
  title: string;
  subject: Subject;
  numQuestionsAnswered: number;
  worryDomains: string[];
  domainReports: DomainReport[];
  impactReport?: ImpactReport;
};

export type Categorization = {
  belowThreshold: string;
  aboveThreshold: string;
};

export type DomainReport = {
  isDisabled: boolean;
  name: string;
  score: Score;
  explanation: string;
  callToAction: string;
};

export type ImpactReport = {
  score: Score;
  subScores: Score[];
  explanation: string;
  callToAction: string;
};

export type Score = {
  label: string;
  rawScore?: number;
  threshold: number;
  maxScore: number;
  categorizations: Categorization;
};

export type UpcomingAppointmentDetails = {
  startTime: string;
  providerFullNameWithTitle: string;
  appointmentTitle: string;
};
