import { type FC, type PropsWithChildren } from 'react';
import styled, { type DefaultTheme } from 'styled-components';

const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const IconBoundary = styled.div<{ size?: number; rawColor?: string; colorSelector?: (theme: DefaultTheme) => string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: auto;
  width: ${({ size }) => size ?? 20}px;
  height: ${({ size }) => size ?? 20}px;
  background-color: ${({ theme, colorSelector, rawColor }) =>
    rawColor || (colorSelector ? colorSelector(theme) : theme.deprecated_.colors.gray)};
  border-radius: ${({ size }) => (size ? size / 2 : 10)}px;

  z-index: 1;
`;

export type CircleColor = 'lightGray' | 'lightestGray';

export interface CircleIconProps {
  onClick?: () => void;
  size?: number;
  rawColor?: string;
  color?: CircleColor;
}

export const CircleIcon: FC<PropsWithChildren<CircleIconProps>> = ({ children, onClick, size, rawColor, color }) => {
  const colorSelector =
    color === 'lightGray'
      ? (theme: DefaultTheme) => theme.deprecated_.colors.lightGray
      : (theme: DefaultTheme) => theme.deprecated_.colors.lightestGray;

  return (
    <IconBoundary colorSelector={colorSelector} rawColor={rawColor} size={size} onClick={onClick}>
      <CenterRow>{children}</CenterRow>
    </IconBoundary>
  );
};
