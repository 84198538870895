import { useEffect, useState } from 'react';

import { useSearchParams } from '@littleotter/kit/react';
import { initialPagination, type _Pagination } from '@littleotter/legacy-components/src/components/Pagination';

export const usePaginatedData = <T>(items: T[]): { pagination: _Pagination; visibleItems: T[] } => {
  const [{ offset, limit }, { addSearchParams }] = useSearchParams<{
    offset?: string;
    limit?: string;
  }>();
  const _offset = offset ? parseInt(offset, 10) : initialPagination.offset;
  const _limit = limit ? parseInt(limit, 10) : initialPagination.limit;

  const [pagination, setPagination] = useState<_Pagination>(initialPagination);
  const [visibleItems, setVisibleItems] = useState<T[]>([]);

  useEffect(() => {
    setVisibleItems(items.slice(_offset, _offset + _limit));
    setPagination({
      offset: _offset,
      limit: _limit,
      total: items.length,
      onPrevPage: () => {
        addSearchParams({
          offset: (_offset - _limit).toString(),
          limit: _limit.toString(),
        });
      },
      onNextPage: () => {
        addSearchParams({
          offset: (_offset + _limit).toString(),
          limit: _limit.toString(),
        });
      },
    });
  }, [items, _offset, _limit, addSearchParams]);

  return {
    pagination,
    visibleItems,
  };
};
