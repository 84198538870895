import { gql } from '@apollo/client';

export const ALLOWED_STATES_QUERY = gql`
  query AllowedStatesQuery {
    allowedStates {
      code
      name
    }
  }
`;
