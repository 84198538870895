import { type ViewerQuery_viewer_family_children } from '../../../../graphql/__generated__/ViewerQuery';
import {
  DomainReportContextType,
  SubjectType,
  type DomainReportContext,
  type DomainScore,
  type Subject as GqlSubject,
} from '../../../../graphql/lo1/generated';
import { NUM_DATA_POINTS } from './components/threshold/constants';
import { CONTEXT_TYPE, SUBJECT_TYPE, type ScoreTimeSeries, type Subject } from './types';

export const CONTEXT_TYPE_MAP: { [key in DomainReportContextType]: CONTEXT_TYPE } = {
  [DomainReportContextType.CallToAction]: CONTEXT_TYPE.WHAT_CAN_I_DO,
  [DomainReportContextType.Explanation]: CONTEXT_TYPE.WHAT_DOES_THIS_MEAN,
};

export const areMatchingSubjects = (s1: GqlSubject, s2: Subject): boolean => {
  const s1SubjectTypeToMatchCheck: { [key in SubjectType]: boolean } = {
    [SubjectType.Family]: s2.type === SUBJECT_TYPE.FAMILY,
    [SubjectType.Caregiver]: s2.type === SUBJECT_TYPE.CAREGIVER,
    [SubjectType.Child]: s2.type === SUBJECT_TYPE.CHILD && s1.childSubject?.childId === s2.id,
  };
  return s1SubjectTypeToMatchCheck[s1.subjectType];
};

export const mapContexts = (contexts: DomainReportContext[]) => {
  const mappedContexts = contexts.map((context) => ({
    contextType: CONTEXT_TYPE_MAP[context.contextType],
    context: context.content,
  }));
  return mappedContexts.sort((a, b) => a.contextType - b.contextType);
};

export const mapSubDomainScoreTimeSeries = (
  subDomainScores: DomainScore[],
  selectedSubject: Subject
): ScoreTimeSeries[] =>
  subDomainScores.map((score) => ({
    name: formatLabel(score.label, selectedSubject),
    threshold: score.threshold,
    maxScore: score.maxScore,
    timeSeries: score.rawScoresOverTime
      .map((s) => ({
        value: s.value,
        date: new Date(s.datetime),
      }))
      .slice(-NUM_DATA_POINTS),
  }));

export const orderSubjects = (subjects: Subject[], children: ViewerQuery_viewer_family_children[]): Subject[] => {
  const childrenIdMap = children.reduce(
    (agg, c) => ({ ...agg, [c.id]: c }),
    {} as { [key: string]: ViewerQuery_viewer_family_children }
  );
  return [
    ...subjects
      .filter((s) => s.type === SUBJECT_TYPE.CHILD)
      .sort((a, b) => compareViewerFamilyChildren(childrenIdMap[a.id], childrenIdMap[b.id])),
    ...subjects.filter((s) => s.type === SUBJECT_TYPE.CAREGIVER),
    ...subjects.filter((s) => s.type === SUBJECT_TYPE.FAMILY),
  ];
};

// TODO(PD-1103): unify business logic to order [[...children seeking care], [...other children], [...caregivers], family]
// seeking care comes before not seeking care. then alphabetical.
const compareViewerFamilyChildren = (
  a: ViewerQuery_viewer_family_children,
  b: ViewerQuery_viewer_family_children
): number => {
  if (a.isSeekingCare && b.isSeekingCare) {
    return a.firstName.localeCompare(b.firstName);
  }
  if (a.isSeekingCare) {
    return -1;
  }
  if (b.isSeekingCare) {
    return 1;
  }
  return a.firstName.localeCompare(b.firstName);
};

const formatLabel = (label: string, subject: Subject): string => {
  if (label === '{{child_name}}') {
    return subject.displayName;
  }
  return label;
};
