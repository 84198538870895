import styled from 'styled-components';

export const TimelineContainer = styled.div`
  width: 100%;
`;

export const TimelineCardContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

export const TimelineCardsContainer = styled.div`
  ${TimelineCardContainer}:not(:first-child) {
  }
`;

export const TimelineTime = styled.time`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.deprecated_.colors.text};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  line-height: 2.625rem;
`;

export const TimelineDate = styled.span``;

export const TimelineMonth = styled.span`
  font-size: 1rem;
`;

export const TimelineCardContent = styled.div`
  width: 100%;
`;
