import { math } from 'polished';
import styled, { type DefaultTheme } from 'styled-components';

export const SummaryContentContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
`;

export const SummaryContentSection = styled.section`
  flex: 1;
`;

export const SummaryContentSectionTitle = styled.h5<{ color: keyof DefaultTheme['deprecated_']['colors'] }>`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme, color }) => theme.deprecated_.colors[color]};
`;

export const SummaryContentSectionList = styled.ul`
  margin-bottom: 0;
`;

export const SummaryContentSectionListItem = styled.li`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 0.875`)};
  color: ${({ theme }) => theme.deprecated_.colors.text}CC;
`;
