import { math } from 'polished';
import styled from 'styled-components';

export const ProfilePictureImage = styled.img`
  border-radius: 50%;
  padding: 5px;
  object-fit: cover;
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5.5`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5.5`)};
`;

// Done separately, instead of using a 5px border around the img element, to prevent render artifacts
// where the border didn't perfectly 'snap' around the image
export const ProfilePictureWrapper = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.deprecated_.colors.lightBackgroundBlue};
  margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
`;
