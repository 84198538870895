/**
 * UTM cookie prefix to be used for storing UTM parameters in cookies
 */
export const UTM_PARAMETER_COOKIE_PREFIX = 'lo_utm';

/**
 * Default Facebook Cookie names set by the Facebook Pixel
 */
export const FACEBOOK_COOKIE_NAMES = {
  BROWSER_ID: '_fbp', // Facebook Browser Pixel ID
  CLICK_ID: '_fbc', // Facebook click ID
};

/**
 * Default TikTok Cookie names set by the TikTok Pixel
 */
export const TIKTOK_COOKIE_NAMES = {
  PIXEL_ID: '_ttp', // TikTok pixel ID
  CLICK_ID: 'ttclid', // TikTok click ID (similar to gclid for Google)
};
