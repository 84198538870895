import { useMemo, type FC, type PropsWithChildren } from 'react';

import { useGraphQLErrorHandling } from '$shared/hooks';
import { useViewerChildren } from '$shared/utils/rerouter/prerequisites/hooks/useViewerChildrenQuery';
import { WithPrerequisites } from '$shared/utils/rerouter/WithPrerequisites';

import { routes } from '../../../../routes';
import { useViewer } from '../../../../shared/contexts/Viewer';
import { useWorryDomainsQuery } from '../OrderConfirmation/hooks';
import { SessionDetails, type SessionDetailsProps } from './SessionDetails';

export const SessionDetailsWithPrerequisites: FC<PropsWithChildren<SessionDetailsProps>> = ({ ...props }) => {
  const { data: viewerData, loading, error } = useViewerChildren();
  const { data: worryDomainsData, error: worryDomainsError } = useWorryDomainsQuery();
  useGraphQLErrorHandling(error, worryDomainsError);

  // prereq: needs children - uses viewer.family.children not viewer.children
  const needsChildrenPredicate = useMemo(() => viewerData?.viewer?.family?.children.length === 0, [viewerData]);
  const childInfoRoute = routes.child.home;

  // prereq: needsworryDomains - user needs to fill out worry domains
  // ! HACK(PD-1731): use wdVisited to distinguish between users who have selected no worry domains, from users
  // ! who have not yet visited the worry domains page.
  const { wdVisited } = useViewer();
  const needsWorryDomainsPredicate = useMemo(() => {
    // Early returns if there are any worry domains anywhere
    if (worryDomainsData?.viewer?.worryDomains.edges.length !== 0) {
      return false;
    }
    if (worryDomainsData?.viewer?.family?.worryDomains.edges.length !== 0) {
      return false;
    }
    if (worryDomainsData?.viewer?.family?.children.find((c) => c.worryDomains.edges.length !== 0)) {
      return false;
    }
    // ! HACK(PD-1731)
    if (wdVisited) {
      return false;
    }
    return true;
  }, [wdVisited, worryDomainsData]);
  const worryDomainsRoute = routes.authOnboarding.worryDomains;

  return (
    <WithPrerequisites
      prerequisites={[
        {
          predicate: needsChildrenPredicate,
          route: childInfoRoute,
        },
        {
          predicate: needsWorryDomainsPredicate,
          route: worryDomainsRoute,
        },
      ]}
      loading={loading}
    >
      <SessionDetails {...props} />
    </WithPrerequisites>
  );
};
