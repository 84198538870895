import { useEffect, useState } from 'react';

import { useErrorHandler, type GraphQLOperationError } from '../../ErrorHandler';

export const useInvalidSubscriptionErrors = () => {
  const { errorObservable } = useErrorHandler();

  const [errors, setErrors] = useState<GraphQLOperationError[]>([]);

  useEffect(() => {
    if (!errorObservable) {
      return;
    }

    const subscription = errorObservable
      .filter((error) => error.code === 'INVALID_SUBSCRIPTION')
      .subscribe((newError) => {
        setErrors((errorsList) => [...errorsList, newError]);
      });

    return () => subscription.unsubscribe();
  }, [errorObservable]);

  return errors;
};
