import { type FC } from 'react';

import { ArrowBody, Bar, BarContainer, Container, GraphContainer, LabelText, LegendContainer } from './styled';

export type SignalStrengthBarsProps = {
  barAmount?: number;
  activeBars: number;
  axisLabel?: string;
  className?: string;
};

export const SignalStrengthBars: FC<SignalStrengthBarsProps> = ({
  barAmount = 5,
  activeBars,
  axisLabel,
  className,
}) => {
  return (
    <Container barAmount={barAmount} className={className}>
      <GraphContainer>
        {Array.from({ length: barAmount }, (_v, k) => (
          <BarContainer key={k}>
            <Bar percent={((k + 1) / barAmount) * 100} active={k < activeBars} />
          </BarContainer>
        ))}
      </GraphContainer>
      {axisLabel && (
        <LegendContainer>
          <LabelText>{axisLabel}</LabelText>
          <ArrowBody />
        </LegendContainer>
      )}
    </Container>
  );
};
