import { type Conversation } from '@twilio/conversations';
import { compareDesc } from 'date-fns';

import { getLastActiveAt } from './getMessageLastActiveAt';

export const sortConversationsByActivity = (conversations: Conversation[]): Conversation[] => {
  conversations.sort((a, b) => {
    return compareDesc(getLastActiveAt(a), getLastActiveAt(b));
  });
  return conversations;
};
