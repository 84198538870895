import { assessmentReportRoutes } from './assessmentReports';
import { assessmentRoutes } from './assessments';
import { careRoutes } from './care';
import { checkupRoutes } from './checkup';
import { childRoutes } from './child';
import { homeRoutes } from './home';
import { libraryRoutes } from './library';
import { logoutRoutes } from './logout';
import { matchListLandingRoutes } from './matchListLanding';
import { messageRoutes } from './messages';
import { onboardingRoutes } from './onboarding';
import { paymentDetailsRoutes } from './payment-details';
import { profileRoutes } from './profile';
import { providerRoutes } from './provider';
import { referralRoutes } from './referral';
import { reportsRoutes } from './reports';
import { tantrumRoutes } from './tantrum';
import { termsRoutes } from './terms';

export const publicRoutes = {
  ...assessmentReportRoutes,
  ...assessmentRoutes,
  ...onboardingRoutes,
  ...tantrumRoutes,
  ...termsRoutes,
};

export const authenticatedRoutes = {
  ...homeRoutes,
  ...logoutRoutes,
  ...careRoutes,
  ...libraryRoutes,
  ...profileRoutes,
  ...providerRoutes,
  ...messageRoutes,
  ...checkupRoutes,
  ...reportsRoutes,
  ...paymentDetailsRoutes,
  ...childRoutes,
  ...referralRoutes,
  ...matchListLandingRoutes,
};

export const routes = {
  ...publicRoutes,
  ...authenticatedRoutes,
};

// You can either pass the client ID or UUID. IDs are scoped to IntakeQ accounts whereas UUIDs are globally unique.
export const intakeQClientUrl = (id: string | null | undefined) => {
  if (id) {
    return `https://intakeq.com/#/client/${id}`;
  }
  return null;
};
