import { gql } from '@apollo/client';

export const SURVEY_FRAGMENT = gql`
  fragment SurveyFragment on SurveyType {
    pk
    worryDomain {
      pk
      name
    }
    lowRiskThreshold
    highRiskThreshold
    questions(followupQuestions: false) {
      ...QuestionFragment
      answers {
        id
        pk
        label
        value
        followupQuestions {
          pk
        }
      }
    }
    followUpQuestions: questions(followupQuestions: true) {
      ...QuestionFragment
      answers {
        id
        pk
        label
        value
        followupQuestions {
          pk
        }
      }
    }
  }

  fragment QuestionFragment on SurveyQuestionType {
    pk
    id
    introText
    endText
    order
    isFollowupQuestion
  }
`;

export const SURVEYS_QUERY = gql`
  query GetIntakeSurveys($worryDomainIdIn: String) {
    surveys(surveyType: "intake", worryDomainIdIn: $worryDomainIdIn) {
      edges {
        node {
          ...SurveyFragment
        }
      }
    }
  }

  ${SURVEY_FRAGMENT}
`;

export const SUBMIT_SURVEY_RESPONSES_MUTATION = gql`
  mutation SubmitSurveyResponsesMutation($input: SubmitSurveyResponsesMutationInput!) {
    submitSurveyResponse(input: $input) {
      surveyResponses {
        pk
      }
    }
  }
`;

export const SURVEY_RESPONSE_QUERY = gql`
  query SurveyResponsesQuery($pkIn: String) {
    surveyResponses(pkIn: $pkIn) {
      edges {
        node {
          pk
          riskCategorization
          score
          survey {
            pk
            worryDomain {
              pk
              name
              shortName
            }
          }
        }
      }
    }
  }
`;
