import { gql, useQuery } from '@apollo/client';

import { type ViewerChildren } from './__generated__/ViewerChildren';

export const VIEWER_CHILDREN = gql`
  query ViewerChildren {
    viewer {
      family {
        children {
          id
        }
      }
    }
  }
`;

export const useViewerChildren = () => useQuery<ViewerChildren>(VIEWER_CHILDREN, { fetchPolicy: 'no-cache' });
