import { useCallback, type FC, type PropsWithChildren } from 'react';

import { HorizontalScroll } from '@littleotter/legacy-components';

import { useRefList } from '$shared/hooks';

import { BackToTop } from '../../../../components/BackToTop';
import { routes } from '../../../../routes';
import { getSubjectName, isChildSubject, type ChildSubject, type Subject } from '../../../FmhcCheckup/types';
import { type FmhcReportCollection, type SubjectReportCollection, type UpcomingAppointmentDetails } from '../types';
import { FullReportCard, ReportHeader, SummarySectionReport, type DomainCardType } from './components';
import { ContinuingWithUs } from './components/ContinuingWithUs/ContinuingWithUs';
import { ReportForSubject } from './ReportForSubject';
import {
  FullLink,
  ReportsPageStyles,
  StyledCareDenSavedReportCard,
  StyledFreeReportCard,
  StyledHorizontalRule,
  StyledMarginReport,
  StyledReportsContainer,
  StyledSummaryCard,
  SummaryCardMargin,
} from './styled';

const getSummaryCardSubjectAge = (s: Subject): string => {
  if (isChildSubject(s)) {
    const child = s as ChildSubject;
    const { ageInMonths } = child;
    const getAgeInYears = (_ageInMonths = 0) => Math.floor(_ageInMonths / 12);
    const age = ageInMonths > 23 ? `${getAgeInYears(ageInMonths).toString()} years` : `${ageInMonths} months`;
    return age;
  }

  return '';
};

export type ReportsContentProps = {
  isPaywalled?: boolean;
  isInAllowedState: boolean;
  fmhcReport: FmhcReportCollection;
  upcomingAppointmentDetails?: UpcomingAppointmentDetails;
  goScheduleWelcomeCall: (reportSubject?: Subject) => void;
  goPurchaseReport?: (reportSubject?: Subject) => void;
  goToCareDen: () => void;
  onSummaryClicked: (subject: Subject) => void;
  onScheduleFirstSessionBannerClicked: () => void;
  onScheduleFirstSessionSubjectClicked: (subject: Subject) => void;
  onScheduleFirstSessionFooterClicked: () => void;
  onSubjectDomainDrawerClicked: (reportSubject: Subject, domainName: string, drawer: DomainCardType) => void;
};

export const ReportsContent: FC<PropsWithChildren<ReportsContentProps>> = ({
  isPaywalled,
  isInAllowedState,
  fmhcReport,
  upcomingAppointmentDetails,
  goScheduleWelcomeCall,
  goPurchaseReport,
  goToCareDen,
  onSummaryClicked,
  onScheduleFirstSessionBannerClicked,
  onScheduleFirstSessionSubjectClicked,
  onScheduleFirstSessionFooterClicked,
  onSubjectDomainDrawerClicked,
}) => {
  const refs = useRefList(fmhcReport.subjectReports.length);

  const onSummaryCardClick = useCallback(
    (subjectReport: SubjectReportCollection, index: number) => () => {
      refs[index].current?.scrollIntoView(true);
      onSummaryClicked(subjectReport.subject);
    },
    [refs, onSummaryClicked]
  );

  const onScheduleFirstSessionBannerClick = useCallback(() => {
    onScheduleFirstSessionBannerClicked();
    goScheduleWelcomeCall();
  }, [goScheduleWelcomeCall, onScheduleFirstSessionBannerClicked]);

  const onScheduleFirstSessionSubjectClick = useCallback(
    (subjectReport: SubjectReportCollection) => () => {
      onScheduleFirstSessionSubjectClicked(subjectReport.subject);
      goScheduleWelcomeCall(subjectReport.subject);
    },
    [goScheduleWelcomeCall, onScheduleFirstSessionSubjectClicked]
  );

  const onScheduleFirstSessionFooterClick = useCallback(() => {
    onScheduleFirstSessionFooterClicked();
    goScheduleWelcomeCall();
  }, [goScheduleWelcomeCall, onScheduleFirstSessionFooterClicked]);

  const onSubjectDomainCardClick = useCallback(
    (subjectReport: SubjectReportCollection) => (domainName: string, cardType: DomainCardType) => {
      onSubjectDomainDrawerClicked(subjectReport.subject, domainName, cardType);
    },
    [onSubjectDomainDrawerClicked]
  );

  const submissionDate = fmhcReport.submissionDatetime;
  const submitterName = fmhcReport.submissionUser;

  const totalNumQuestionsAnswered = fmhcReport.subjectReports
    .map((report): number => {
      return report.numQuestionsAnswered;
    })
    .reduce((sum, curr) => {
      return sum + curr;
    }, 0);

  return (
    <StyledMarginReport>
      <ReportsPageStyles />
      <ReportHeader completedBy={submitterName} date={submissionDate} />
      <StyledReportsContainer>
        {fmhcReport.isFreeAccount && (
          <StyledFreeReportCard
            isInAllowedState={isInAllowedState}
            goScheduleWelcomeCall={onScheduleFirstSessionBannerClick}
            goPurchaseReport={goPurchaseReport}
          />
        )}
        <SummarySectionReport numberOfQuestions={totalNumQuestionsAnswered} />
      </StyledReportsContainer>
      <HorizontalScroll>
        {fmhcReport.subjectReports.map((subjectReport, index) => (
          <SummaryCardMargin key={`summary-card-${subjectReport.title}`}>
            <StyledSummaryCard
              scores={subjectReport.domainReports.map((di) => di.score)}
              title={getSubjectName(subjectReport.subject)}
              subtitle={getSummaryCardSubjectAge(subjectReport.subject)}
              onClick={onSummaryCardClick(subjectReport, index)}
            />
          </SummaryCardMargin>
        ))}
      </HorizontalScroll>
      <StyledReportsContainer>
        {fmhcReport.subjectReports.map((report, index) => (
          <section
            ref={refs[index]}
            key={`report-for-subject-${report.subject.subjectType}-
            ${isChildSubject(report.subject) ? report.subject.firstName + report.subject.lastName : ''}`}
          >
            <ReportForSubject
              isPaywalled={isPaywalled}
              isInAllowedState={isInAllowedState}
              reportForSubject={report}
              goScheduleWelcomeCall={onScheduleFirstSessionSubjectClick(report)}
              goPurchaseReport={goPurchaseReport ? () => goPurchaseReport(report.subject) : undefined}
              isFreeAccount={fmhcReport.isFreeAccount}
              onDomainCardClick={onSubjectDomainCardClick(report)}
            />
          </section>
        ))}
        <StyledCareDenSavedReportCard />
        <StyledHorizontalRule />
        {/* user has already had a welcome call but no current sessions booked
         CTA to schedule next session and include care team details
         TODO: https://app.asana.com/0/1202247291201112/1202316948398583/f */}
        {fmhcReport.isFreeAccount && isInAllowedState && (
          <ContinuingWithUs onCTAClick={onScheduleFirstSessionFooterClick} />
        )}
        {!fmhcReport.isFreeAccount && isInAllowedState && upcomingAppointmentDetails && (
          <FullReportCard
            onClick={goToCareDen}
            titleIcon="⭐️"
            titleTemplate="Your Upcoming Schedule"
            bodyTemplate="You're taking big steps. We're so glad we can support you on your care journey!"
            buttonText="Go to Care Den"
            secondaryTitle={upcomingAppointmentDetails.appointmentTitle}
            secondaryText={`${upcomingAppointmentDetails.startTime} with ${upcomingAppointmentDetails.providerFullNameWithTitle}`}
          />
        )}
        <FullLink href={routes.care.home.url()} variant="underline">
          <strong>Go to your Care Den</strong>
        </FullLink>
      </StyledReportsContainer>
      <BackToTop />
    </StyledMarginReport>
  );
};
