import { darken } from 'polished';
import { css } from 'styled-components';

export const linkStyles = css`
  color: ${({ theme }) => theme.deprecated_.colors.textBlue};
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.2s linear;

  &:visited {
    color: ${({ theme }) => theme.deprecated_.colors.textBlue};
  }

  &:hover {
    color: ${({ theme }) => darken(0.11, theme.deprecated_.colors.textBlue)};
  }

  &:active {
    color: ${({ theme }) => darken(0.33, theme.deprecated_.colors.textBlue)};
  }
`;
