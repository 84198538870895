import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { logger } from '$services/logging';

import {
  type AddChildAuthorization,
  type AddChildAuthorization_addInsuranceAuthorization as AddChildAuthorizationResult,
  type AddChildAuthorizationVariables,
} from '../../../../graphql/__generated__/AddChildAuthorization';
import {
  type ChildAuthorizationQuery_insuranceReferral as ChildAuthorization,
  type ChildAuthorizationQuery,
  type ChildAuthorizationQueryVariables,
} from '../../../../graphql/__generated__/ChildAuthorizationQuery';
import { type InsuranceReferralInput } from '../../../../graphql/__generated__/globalTypes';
import { ADD_CHILD_AUTHORIZATION_MUTATION, CHILD_AUTHORIZATION_QUERY } from '../../../../graphql/referral';
import { useGraphQLErrorHandling } from '../../../hooks';
import { isValidUuidFormat } from '../../../utils/uuid';

export type OnAddChildAuthorizationType = (childAuthorizationInput: InsuranceReferralInput) => Promise<{
  isValid: boolean;
  errorMessages?: SaveChildAuthorizationInputErrors | ChildAuthorizationNotInDbError;
}>;

export type SaveChildAuthorizationInputErrors = {
  dateOfBirth: string;
  referralNumber: string;
};

export type ChildAuthorizationNotInDbError = {
  showAuthorizationNumberNotInDB: boolean;
};

type UseChildAuthorization = ({
  isLoggedIn,
  childReferralUuid,
  childReferralNumber,
}: {
  isLoggedIn: boolean;
  childReferralUuid: string;
  childReferralNumber: string;
}) => {
  childAuthorizationData?: ChildAuthorization | null;
  onAddChildAuthorization: OnAddChildAuthorizationType;
  isChildAuthorizationLoading: boolean;
};

const formatGqlErrorMessages: (
  addChildAuthorization?: AddChildAuthorizationResult
) => SaveChildAuthorizationInputErrors | ChildAuthorizationNotInDbError | undefined = (addChildAuthorization) => {
  if (addChildAuthorization?.__typename === 'AddInsuranceAuthorizationError') {
    const formatErrorArray = (errorArray: string[] | null | undefined) => (errorArray || []).join(', ');
    return {
      dateOfBirth: formatErrorArray(addChildAuthorization.fieldErrors.dateOfBirth),
      referralNumber: formatErrorArray(addChildAuthorization.fieldErrors.referralNumber),
    };
  }
  if (addChildAuthorization?.__typename === 'ReferralNumberNotInDBError') {
    return {
      showAuthorizationNumberNotInDB: true,
    };
  }
};

const isValidReferralNumberFormat = (referralNumber: string) => {
  return !Number.isNaN(+referralNumber) && referralNumber.length === 10;
};

export const useChildAuthorization: UseChildAuthorization = ({
  isLoggedIn,
  childReferralUuid,
  childReferralNumber,
}) => {
  const [addChildAuthorization] = useMutation<AddChildAuthorization, AddChildAuthorizationVariables>(
    ADD_CHILD_AUTHORIZATION_MUTATION
  );
  const {
    data: childAuthData,
    error,
    loading: isChildAuthorizationLoading,
  } = useQuery<ChildAuthorizationQuery, ChildAuthorizationQueryVariables>(CHILD_AUTHORIZATION_QUERY, {
    variables: { uuid: childReferralUuid, referralNumber: childReferralNumber },
    skip:
      !isLoggedIn ||
      !childReferralUuid ||
      !childReferralNumber ||
      !isValidReferralNumberFormat(childReferralNumber) ||
      !isValidUuidFormat(childReferralUuid),
  });
  useGraphQLErrorHandling(error);

  /**
   * Saves authorization data on DB, if there is any error it returns an array compatible with useForm hook.
   * @param childAuthorizationInput Child Authorization form's input data.
   * @return {isValid: boolean, errorMessages: string[]} isValid means data was saved correctly, otherwise there will be data on errorMessages.
   */
  const onAddChildAuthorization = useCallback(
    async (
      childAuthorizationInput: InsuranceReferralInput
    ): Promise<{
      isValid: boolean;
      errorMessages?: SaveChildAuthorizationInputErrors | ChildAuthorizationNotInDbError;
    }> => {
      const { data: childAuthorizationResult, errors: gqlErrors } = await addChildAuthorization({
        variables: {
          input: {
            childId: childAuthorizationInput.childId ?? '',
            insurancePartnerId: childAuthorizationInput.insurancePartnerId ?? '',
            referralNumber: childAuthorizationInput.referralNumber ?? '',
            dateOfBirth: childAuthorizationInput.dateOfBirth ?? '',
          },
        },
      });

      const errorMessages = formatGqlErrorMessages(childAuthorizationResult?.addInsuranceAuthorization);
      const isValid = !gqlErrors && !errorMessages;
      if (!isValid) {
        logger.warn('failed to authorize insurance', {
          childId: childAuthorizationInput.childId,
          insurancePartnerId: childAuthorizationInput.insurancePartnerId,
          referralNumber: childAuthorizationInput.referralNumber,
          errorMessage: JSON.stringify(errorMessages),
        });
      }
      return {
        isValid,
        errorMessages,
      };
    },
    [addChildAuthorization]
  );

  return {
    onAddChildAuthorization,
    childAuthorizationData: childAuthData?.insuranceReferral,
    isChildAuthorizationLoading,
  };
};
