import { useCallback, useEffect, useState } from 'react';

import { type OnChangeWorryDomain } from '../types';

export const useWorryDomains = (defaultValues: number[]) => {
  const [selectedWorryDomainPks, setSelectedWorryDomainPks] = useState<number[]>(defaultValues);

  // Reset `selectedWorryDomainPks` when `defaultValues` prop changes
  useEffect(() => {
    setSelectedWorryDomainPks(defaultValues);
  }, [defaultValues]);

  const onChangeWorryDomain = useCallback<OnChangeWorryDomain>(
    ({ worryDomainPk, checked }) => {
      let updatedWorryDomainPks: number[];

      if (checked) {
        updatedWorryDomainPks = [...selectedWorryDomainPks, worryDomainPk];
      } else {
        updatedWorryDomainPks = selectedWorryDomainPks.filter((pk) => pk !== worryDomainPk);
      }

      setSelectedWorryDomainPks(updatedWorryDomainPks);
    },
    [selectedWorryDomainPks]
  );

  return { selectedWorryDomainPks, onChangeWorryDomain };
};
