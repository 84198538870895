type Option = string | number;

/**
 * Useful to update state for the `MultiSelectionOptions` component
 *
 * @param previousValues array of the previously selected values
 *
 * @param valueToToggle value of a single option in `MultiSelectionOptions`
 *
 * @returns the updated array of values
 */
export const getUpdatedMultiSelectionValues = (previousValues: Option[], valueToToggle: Option): Option[] =>
  previousValues.includes(valueToToggle)
    ? previousValues.filter((v) => v !== valueToToggle)
    : previousValues.concat(valueToToggle);
