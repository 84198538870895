import { useQuery } from '@apollo/client';

import { type ViewerQuery } from '../../../../../graphql/__generated__/ViewerQuery';
import { useFmhcOverTimeQuery } from '../../../../../graphql/lo1/generated';
import { VIEWER_QUERY } from '../../../../../graphql/user';
import { SUBJECT_TYPE, type Subject } from '../types';

export const useFmhcOverTime = () => {
  const { data: fmhcOverTimeData, loading: fmhcLoading } = useFmhcOverTimeQuery();
  const { data: viewerData, loading: viewerLoading } = useQuery<ViewerQuery>(VIEWER_QUERY);
  const children: Subject[] =
    viewerData?.viewer?.family?.children.map((child) => ({
      displayName: child.firstName,
      id: child.id,
      type: SUBJECT_TYPE.CHILD,
    })) ?? [];
  const subjects: Subject[] = [
    ...children,
    { displayName: 'You', id: 'you', type: SUBJECT_TYPE.CAREGIVER },
    { displayName: 'Family', id: 'family', type: SUBJECT_TYPE.FAMILY },
  ];
  return { loading: fmhcLoading || viewerLoading, fmhcOverTimeData, subjects };
};
