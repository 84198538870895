import { hasErrorShape, isErrorInstance } from '@littleotter/kit/utils';

import { type ErrorFormatterFn } from '../logging/types';

/**
 * Sentry prefers that errors be instances of the Error class, but we also want to support unknown instances and strings.
 *
 * * Note that we don't need to handle `Error.cause`, because Sentry will automatically handle it by default.
 * @see https://docs.sentry.io/platforms/javascript/configuration/integrations/default/#linkederrors
 *
 * @param error - The error to format
 * @returns {Error|string} - The error | The error message
 * @returns {object} - The error type
 */
export const sentryErrorFormatter: ErrorFormatterFn = (error: unknown) => {
  const isError = isErrorInstance(error) || hasErrorShape(error);

  const _error = isError ? error : String(error);
  const errorData = isError ? {} : { errorType: typeof error };

  return {
    error: _error,
    errorData,
  };
};
