import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/legacy-components';

import { type HolidaySummaryResultsFragment } from '../../../../graphql/__generated__/HolidaySummaryResultsFragment';
import { SummaryCard } from '../components';
import { Section, SummaryCardContainer } from './styled';

type SummaryProps = {
  summaryResult: HolidaySummaryResultsFragment;
};

export const Summary: FC<PropsWithChildren<SummaryProps>> = ({
  summaryResult: { familyDynamics, childEmotionalAndBehavioralHealth, caregiverMentalHealthRisk, holidayStress },
}) => (
  <Section>
    <Header as="h4">Summary of Results</Header>
    <p>
      These boxes show what we learned in four areas that are important for{' '}
      <strong>family mental health and wellness</strong>.
    </p>

    <SummaryCardContainer>
      <SummaryCard
        category="FamilyDynamics"
        risk={familyDynamics}
        href={familyDynamics ? '#family-dynamics' : undefined}
      />
      <SummaryCard
        category="ChildEmotionalAndBehavioralHealth"
        risk={childEmotionalAndBehavioralHealth}
        href="#child-emotional-and-behavioral-health"
      />
      <SummaryCard
        category="CaregiverMentalHealthRisk"
        risk={caregiverMentalHealthRisk}
        href={caregiverMentalHealthRisk ? '#caregiver-anxiety-and-depression' : undefined}
      />
      <SummaryCard category="HolidayStress" risk={holidayStress} href={holidayStress ? '#holiday-stress' : undefined} />
    </SummaryCardContainer>
  </Section>
);
