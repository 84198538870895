import { type IChangeEvent, type ISubmitEvent } from '@rjsf/core';
import { type JSONSchema7 } from 'json-schema';
import { type FC, type PropsWithChildren } from 'react';

import { useEventTracking } from '$services/event-tracking';

import { type QuestionAnswer, type SingleSelectOptions } from '../../../../types';
import { StyledButton, StyledForm } from '../styled';

const FINISHED = 'Finished';

enum CheckupStepEnum {
  FIRST = 'First',
  CHILD_INTRO = 'Child Intro',
  FINAL = 'Final',
}

enum TrackingSubjectEnum {
  PARENT_GUARDIAN = 'parent/guardian',
}

export type SingleSelectionQuestionInputProps = {
  questionId: string;
  answer?: QuestionAnswer;
  singleSelectOptions: SingleSelectOptions;
  required: boolean;
  current: number;
  total: number;
  nextButtonText?: string;
  onAnswer: (data: QuestionAnswer) => void;
};

export const SingleSelectionQuestionInput: FC<PropsWithChildren<SingleSelectionQuestionInputProps>> = ({
  questionId,
  answer,
  singleSelectOptions,
  nextButtonText,
  onAnswer,
}) => {
  const { choices } = singleSelectOptions;
  const questionsMapped = choices?.map((choice) => ({
    const: choice.id.toString(),
    title: choice.choice,
  }));

  const questionStructure = {
    type: 'string',
    oneOf: questionsMapped,
  };

  const schema = {
    properties: {
      [questionId]: questionStructure,
      'help-phrases': {
        type: 'null',
      },
      'phrases-header': {
        type: 'null',
      },
    },
    type: 'object',
  } as JSONSchema7;
  const { track } = useEventTracking();
  type SubmitEvent = ISubmitEvent<{ [key: string]: string }>;
  type ChangeEvent = IChangeEvent<{ [key: string]: string }>;

  const handleSubmit = (event: SubmitEvent | ChangeEvent) => {
    const [variableName, choiceId] = Object.entries(event.formData)[0];
    onAnswer(getQuestionAnswer(singleSelectOptions, { variableName, choiceId }));

    if (nextButtonText === FINISHED) {
      track('FINISHED_CHECKUP');
      track('VIEWED_CHECKUP_STEP', {
        step: CheckupStepEnum.FINAL,
        subject: TrackingSubjectEnum.PARENT_GUARDIAN,
      });
    }
  };

  const buttonText = nextButtonText ?? 'Skip';

  return (
    <StyledForm
      onChange={(event) => handleSubmit(event as ChangeEvent)}
      onSubmit={(event) => handleSubmit(event as SubmitEvent)}
      formData={{ [questionId]: answer?.choice.id.toString() }}
      schema={schema}
      uiSchema={{
        [questionId]: {
          'ui:options': {},
          'ui:widget': 'SingleSelectionWidget',
        },
        'help-phrases': {
          'ui:options': {
            content: singleSelectOptions?.prompt,
            title: singleSelectOptions?.prompt,
          },
          'ui:widget': 'HelpTextWidget',
        },
        'phrases-header': {
          'ui:options': {
            alt: <span dangerouslySetInnerHTML={{ __html: singleSelectOptions?.questionText }} />,
            profilePicture: '',

            title: <span dangerouslySetInnerHTML={{ __html: singleSelectOptions?.questionText }} />,
          },
          'ui:widget': 'HeaderWidget',
        },
        'ui:order': ['help-phrases', 'phrases-header', questionId],
      }}
    >
      <StyledButton type="submit" variant="underline" isLoading={false}>
        {buttonText}
      </StyledButton>
    </StyledForm>
  );
};

const getQuestionAnswer = (
  singleSelectOptions: SingleSelectOptions,
  questionAnswer: RawQuestionAnswer
): QuestionAnswer => {
  const filteredChoices = singleSelectOptions.choices.filter((choice) => {
    return choice.id === questionAnswer.choiceId;
  });
  if (filteredChoices.length === 1) {
    return {
      variableName: questionAnswer.variableName,
      choice: filteredChoices[0],
    };
  }
  // TODO: Figure out how to handle missing question choice errors https://app.asana.com/0/1202247291201112/1202309429318294/f
  return {
    variableName: 'fake',
    choice: {
      id: '',
      choice: 'fake',
      value: 0,
    },
  };
};

type RawQuestionAnswer = {
  variableName: string;
  choiceId: string;
};
