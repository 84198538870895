/**
 * Returns a boolean indicating if the uuid provided is correctly formated.

 * @param uuidString string .
 *
 */
export const isValidUuidFormat = (uuidString: string): boolean => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(uuidString);
};
