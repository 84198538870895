import { type WidgetProps } from '@rjsf/core';
import { type ReactNode } from 'react';
import { type FieldErrors } from 'react-hook-form6';

type BaseInputProps = {
  name: string;
  label?: ReactNode;
  errors?: FieldErrors;
  placeholder?: string;
  disabled?: boolean;
  value?: number | string;
  onChange?: (value: string | number) => void;
};

/**
 * Returns an object with common props to be used on Input components inside
 * widgets
 */
export const getBaseInputProps = ({
  id,
  options,
  label,
  rawErrors,
  placeholder,
  disabled,
  value,
  onChange,
}: WidgetProps): BaseInputProps => ({
  name: id,
  label: <>{options.title ?? label}</>,
  errors: rawErrors?.length ? { [id]: { type: 'jsonschema', message: rawErrors } } : undefined,
  placeholder,
  disabled,
  value: value ?? '',
  onChange: (v: string | number) => onChange(v || undefined),
});
