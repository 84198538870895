export type GenericTemplateVariables = {
  [variableName: string]: string;
};

export type ChildTemplateVariables = {
  child_name: string;
} & GenericTemplateVariables;

export type TemplateVariables = ChildTemplateVariables | GenericTemplateVariables;

export type SanitizedHTML = string;

export interface Choice {
  id: string;
  choice: string;
  value: number;
}

export enum QuestionType {
  SingleSelect,
  Prompt,
}

export interface SingleSelectOptions {
  prompt: SanitizedHTML;
  questionText: SanitizedHTML;
  choices: Choice[];
}

export interface PromptOptions {
  prompt: SanitizedHTML;
  promptImage?: string;
  questionText?: SanitizedHTML;
  duration?: string;
}

export interface Question {
  id: string;
  type: QuestionType;
  singleSelectOptions?: SingleSelectOptions;
  promptOptions?: PromptOptions;
}

export interface QuestionAnswer {
  variableName: string;
  choice: Choice;
}

export interface QuestionData {
  required: boolean;
  question: Question;
}

export enum SubjectType {
  Caregiver = 'Caregiver',
  Child = 'Child',
  Family = 'Family',
}

type TypedSubject = {
  subjectType: SubjectType;
};

export type CaregiverSubject = TypedSubject;
export type FamilySubject = TypedSubject;
export type ChildSubject = {
  id: string;
  gender: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  ageInMonths: number;
  isSeekingCare?: boolean;
} & TypedSubject;

export type Subject = ChildSubject | CaregiverSubject | FamilySubject;

export interface SubjectQuestions {
  subject: Subject;
  questions: QuestionData[];
}

export interface SubjectAnswers {
  subject: Subject;
  answers: QuestionAnswer[];
}

export const isChildSubject = (s: Subject): s is ChildSubject => s.subjectType === SubjectType.Child;
export const isCaregiverSubject = (s: Subject): s is Subject => s.subjectType === SubjectType.Caregiver;
export const isFamilySubject = (s: Subject): s is Subject => s.subjectType === SubjectType.Family;

export enum LabelType {
  Possessive = 'Possessive',
  Reflexive = 'Reflexive',
}

export const getSubjectName = (s: Subject, labelType?: LabelType) => {
  switch (s.subjectType) {
    case SubjectType.Caregiver: {
      const names = {
        Possessive: 'Your',
        Reflexive: 'Yourself',
      };
      return labelType ? names[labelType] : 'You';
    }
    case SubjectType.Family: {
      const names = {
        Possessive: "Your Family's",
        Reflexive: 'Your Family',
      };
      return labelType ? names[labelType] : 'Family';
    }
    default: {
      const child = s as ChildSubject;
      const { firstName } = child;
      const names = {
        Possessive: `${firstName}'s`,
        Reflexive: `${firstName}`, // this is not gramatically correct but we should use the real name
      };
      return labelType ? names[labelType] : firstName;
    }
  }
};
