import { type ConversationsInfo_conversations_participants_outOfOfficeDates as OutOfOfficeDatesType } from 'src/graphql/__generated__/ConversationsInfo';

import { maybeGetCurrentOutOfOfficeInterval } from './maybeGetCurrentOutOfOfficeInterval';

/**
 * Determines if individual is out of office based on their out of office dates
 */
export const isOutOfOffice = (outOfOfficeDates: OutOfOfficeDatesType[]): boolean => {
  if (outOfOfficeDates.length === 0) {
    return false;
  }

  return !!maybeGetCurrentOutOfOfficeInterval(outOfOfficeDates);
};
