import { type BarProps } from '@nivo/bar';
import { type SVGAttributes } from 'react';

import { Tspan } from './styled';

type AlignmentBaseline = SVGAttributes<SVGTextElement>['alignmentBaseline'];

type AxisProps = NonNullable<BarProps['axisBottom']>;

type RenderTick = NonNullable<AxisProps['renderTick']>;

export const Tick: RenderTick = ({ textAnchor, textBaseline, value, x, y, textX, textY }) => {
  const alignmentBaseline = textBaseline as AlignmentBaseline;
  const strValue = value as string;

  if (!strValue.trim().length) {
    return <text />;
  }

  const splitValues = strValue.split(' ').map((word, index) => (
    <Tspan key={word} y="1em" x={0} dy={18 * index}>
      {word}
    </Tspan>
  ));

  return (
    <g transform={`translate(${x},${y})`}>
      <text alignmentBaseline={alignmentBaseline} textAnchor={textAnchor} transform={`translate(${textX}, ${textY})`}>
        {splitValues}
      </text>
    </g>
  );
};
