import { useCallback } from 'react';

import { useEventTracking } from '$services/event-tracking';

import { isChildSubject, type Subject } from '../../../../FmhcCheckup/types';
import { type DomainCardType } from '../components';

export const useReportTracking = (fmhcId: string) => {
  const { track } = useEventTracking();

  const trackReportOpen = useCallback(() => {
    track('OPENED_FMHC_REPORT', { fmhcId });
  }, [fmhcId, track]);

  const trackSummaryClicked = useCallback(
    (reportSubject: Subject) => {
      track('CLICKED_FMHC_REPORT_SUMMARY', {
        fmhcId,
        subjectType: reportSubject.subjectType,
        subjectId: isChildSubject(reportSubject) ? reportSubject.id : undefined,
      });
    },
    [fmhcId, track]
  );

  const trackScheduleFirstSessionBannerClicked = useCallback(() => {
    track('CLICKED_SCHEDULE_KICKOFF', {
      fmhcId,
      component: 'banner',
    });
  }, [fmhcId, track]);

  const trackScheduleFirstSessionSubjectClicked = useCallback(
    (reportSubject: Subject) => {
      track('CLICKED_SCHEDULE_KICKOFF', {
        component: 'subject',
        fmhcId,
        subjectId: isChildSubject(reportSubject) ? reportSubject.id : undefined,
        subjectType: reportSubject.subjectType,
      });
    },
    [fmhcId, track]
  );

  const trackScheduleFirstSessionFooterClicked = useCallback(() => {
    track('CLICKED_SCHEDULE_KICKOFF', {
      component: 'footer',
      fmhcId,
    });
  }, [fmhcId, track]);

  const trackSubjectDomainDrawerClicked = useCallback(
    (reportSubject: Subject, domainName: string, drawer: DomainCardType) => {
      track('CLICKED_FMHC_REPORT_SUBJECT_DOMAIN_DRAWER', {
        fmhcId,
        subjectType: reportSubject.subjectType,
        subjectId: isChildSubject(reportSubject) ? reportSubject.id : undefined,
        domainName,
        drawer,
      });
    },
    [fmhcId, track]
  );

  const trackReportPurchaseClicked = useCallback(
    (reportSubject: Subject) => {
      track('CLICKED_PURCHASE_FMHC_REPORT', {
        fmhcId,
        subjectType: reportSubject.subjectType,
        subjectId: isChildSubject(reportSubject) ? reportSubject.id : undefined,
      });
    },
    [fmhcId, track]
  );

  return {
    trackReportOpen,
    trackReportPurchaseClicked,
    trackSummaryClicked,
    trackScheduleFirstSessionBannerClicked,
    trackScheduleFirstSessionSubjectClicked,
    trackScheduleFirstSessionFooterClicked,
    trackSubjectDomainDrawerClicked,
  };
};
