/**
 * Returns an string with the items comma-separated and with the conjunction `and`.
 
 * @param items string array. Not modified by this function.
 *
 * @example
 * ```
 * joinWords(['California', 'Colorado', 'Florida']); // => 'California, Colorado and Florida'
 * joinWords(['California', 'Colorado']); // => 'California and Colorado'
 * joinWords(['California']); // => 'California'
 * joinWords([]); // => ''
 * ```
 */
export const joinWords = (items: readonly string[]): string => {
  if (items.length === 0) {
    return '';
  }

  if (items.length === 1) {
    return items[0];
  }

  const last_index = items.length - 1;

  return `${items.slice(0, last_index).join(', ')} and ${items[last_index]}`;
};
