import { darken, math } from 'polished';
import styled from 'styled-components';

import { addAlpha } from '$shared/utils/styles';

export const StyledForm = styled.form<{ disabled: boolean }>`
  display: flex;
  align-items: flex-end;
  background-color: ${({ theme, disabled }) =>
    disabled ? addAlpha(theme.deprecated_.colors.lightGray, 0.2) : theme.deprecated_.colors.white};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  border-top: ${({ theme }) =>
    `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.border.color}`};

  & > :not(:last-child) {
    margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 4`)};
  }
`;

export const InputBoxContainer = styled.div`
  flex-grow: 1;
  align-self: center;
`;

export const StyledInputBox = styled.textarea<{ textAreaHeight: string }>`
  border: none;
  resize: none;
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  width: 100%;
  height: ${({ textAreaHeight }) => textAreaHeight};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  color: ${({ theme }) => theme.deprecated_.colors.black};

  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => addAlpha(theme.deprecated_.colors.lightGray, 0.2)};
    background-color: transparent;
  }
`;

export const IconContainer = styled.div`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.large};
  line-height: 0;
`;

export const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: initial;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.5`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.5`)};

  transition:
    background-color 0.2s linear,
    color 0.2s linear;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => darken(0.1, theme.deprecated_.colors.white)};
    outline: none;
  }

  &:active {
    background-color: ${({ theme }) => darken(0.2, theme.deprecated_.colors.white)};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => addAlpha(theme.deprecated_.colors.lightGray, 0.2)};
    background-color: initial;
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-bottom: 1rem;
  }
`;
