import {
  type DomainReport as GqlDomainReport,
  type FmhcReport as GqlFmhcReport,
} from '../../../../../../graphql/lo1/generated';
import { SubjectType, type ChildSubject, type ChildTemplateVariables } from '../../../../../FmhcCheckup/types';
import { replaceStringVariables } from '../../../../../FmhcCheckup/utils/replaceStringVariables';
import { type DomainReport, type ImpactReport, type SubjectReportCollection } from '../../../types';
import { type ChildIdToChildSubjectMap, type ChildIdToWorryDomainsMap } from '../types';
import { gqlDomainReportsToAppReports } from './domainReports';

export const interpolateChildVariables = (templateText: string, variables: ChildTemplateVariables) => {
  return replaceStringVariables({ text: templateText, variables });
};

export const childSubjectToTemplateVariables = (child: ChildSubject): ChildTemplateVariables => ({
  child_name: child.firstName,
});

// TODO: https://app.asana.com/0/1202247291201112/1202334457114982/f
// Fix this: this function has to handle undefined Subject because in Reports.tsx, staff and caregiver
// queries are both executed, and only one of them will actually have defined Subjects.
export const gqlChildReportToAppReportForSubject =
  (
    childrenWorryDomainsByChildId: ChildIdToWorryDomainsMap,
    childSubjectsByChildId: ChildIdToChildSubjectMap,
    isFreeAccount: boolean
  ) =>
  ({
    domainReports: gqlReports,
    numQuestionsAnswered,
    subject: gqlSubject,
  }: GqlFmhcReport): SubjectReportCollection | null => {
    if (!gqlSubject?.childSubject?.childId) {
      throw new Error('Invalid child was passed');
    }
    const { childId } = gqlSubject.childSubject;
    if (!(childId in childSubjectsByChildId)) {
      return null;
    }
    const subject =
      childSubjectsByChildId[childId] ??
      ({
        subjectType: SubjectType.Child,
        firstName: 'Child',
        lastName: 'Child',
        dateOfBirth: '2022-01-01',
        gender: 'Male',
      } as ChildSubject);
    const title = `${subject.firstName}'s Mental Health`;

    const rawDomainItems: GqlDomainReport[] = subject ? gqlReports : [];
    const worryDomains = childrenWorryDomainsByChildId[childId] ?? [];

    const { impact: impactWithoutInterpolation, domains: domainsWithoutInterpolation } = gqlDomainReportsToAppReports(
      rawDomainItems,
      subject,
      isFreeAccount
    );

    const templateVariables = childSubjectToTemplateVariables(subject);

    const impactReport: ImpactReport | undefined = impactWithoutInterpolation && {
      ...impactWithoutInterpolation,
      explanation: interpolateChildVariables(impactWithoutInterpolation.explanation, templateVariables),
      callToAction: interpolateChildVariables(impactWithoutInterpolation.callToAction, templateVariables),
    };

    const domainReports: DomainReport[] = domainsWithoutInterpolation.map((di) => ({
      ...di,
      explanation: interpolateChildVariables(di.explanation, templateVariables),
      callToAction: interpolateChildVariables(di.callToAction, templateVariables),
    }));

    return {
      title,
      worryDomains,
      subject,
      numQuestionsAnswered,
      domainReports,
      impactReport,
    };
  };
