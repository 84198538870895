import { endRoute, parentRoute } from '$shared/utils/routes';

export const profileRoutes = {
  profile: parentRoute('/profile')({
    family: endRoute('/profile/family'),
    me: endRoute('/profile/me'),
    child: endRoute<{ id: number }>('/profile/child/:id'),
    care: parentRoute('/profile/care')({
      bio: endRoute<{ id: number }>('/profile/care/provider/:id'),
    }),
  }),
};
