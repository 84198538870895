import styled from 'styled-components';

export const StyledError = styled.p`
  color: ${(props) => `${props.theme.deprecated_.colors.red}`};
  font-size: small;
  margin-bottom: 16px;
`;

export const GreenSpan = styled.span`
  color: #57bdba;
`;

export const OrangeSpan = styled.span`
  color: #ff7266;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
