import { math } from 'polished';
import { type FC } from 'react';
import { Transition } from 'react-transition-group';
import { type TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import { useTimeout } from '../../hooks/useTimeout';

export type BannerVariant = 'success' | 'warning' | 'error' | 'info';

const StyledBanner = styled.div<{ variant: BannerVariant; state: TransitionStatus }>`
  position: fixed;
  top: calc(${({ theme }) => math(`${theme.deprecated_.sizeBasis} * -4`)} - env(safe-area-inset-top));
  left: 0;
  width: 100%;
  height: calc(${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4`)} + env(safe-area-inset-top));
  padding: ${({ theme }) => theme.deprecated_.sizeBasis};
  padding-top: max(${({ theme }) => theme.deprecated_.sizeBasis}, env(safe-area-inset-top));
  transform: translateY(${({ state }) => (state === 'entered' ? '100%' : '0%')});
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  z-index: 99999;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  color: ${({ theme }) => theme.deprecated_.colors.black};
  background-color: ${({ variant, theme }) =>
    ({
      success: theme.deprecated_.colors.green,
      warning: theme.deprecated_.colors.yellow,
      error: theme.deprecated_.colors.red,
      info: theme.deprecated_.colors.lightBackgroundBlue,
    })[variant]};
`;

interface BannerProps {
  message?: string;
  isShowing: boolean;
  variant?: BannerVariant;
  timeout?: number;
  onTimeout?: () => void;
}

/**
 * Banner component used for global notifications
 *
 * The default variant is `"success"`. Possible values are `"success"`, `"warning"`, `"error"` and `"info"`
 *
 * If `isShowing` is set to `true` and a callback is passed to `onTimeout`, the component will trigger a timeout with a
 * duration in milliseconds defined in the `timeout` prop (default is `5000`). `onTimeout` will be called after that
 * timeout
 *
 * @example
 * ```tsx
 * <Banner
 *   message="Banner Message"
 *   isShowing={bannerIsShowing}
 *   variant="info"
 *   timeout={2000}
 *   onTimeout={() => setBannerMessage(null)}
 * />
 * ```
 */
export const Banner: FC<BannerProps> = ({ message, isShowing, variant, timeout, onTimeout }) => {
  useTimeout(() => onTimeout?.(), isShowing && onTimeout ? (timeout ?? 5000) : null);

  return (
    <Transition in={isShowing} timeout={{ exit: 300 }} mountOnEnter unmountOnExit>
      {(state) => (
        <StyledBanner variant={variant ?? 'success'} state={state}>
          {message}
        </StyledBanner>
      )}
    </Transition>
  );
};
