import { endRoute, parentRoute } from '$shared/utils/routes';

export const checkupRoutes = {
  checkup: parentRoute('/checkup')({
    educationPiece: endRoute('/checkup/education-piece'),
    childName: endRoute('/checkup/child-intro/'),
    questions: endRoute<{ questionNumber: number }>('/checkup/child-intro/questions/:questionNumber'),
  }),
  checkupOnboarding: endRoute('/checkup-onboarding'), // DEPRECATED(12/06/2022):
};
