import { type FC, type PropsWithChildren } from 'react';

import { ReportHeaderContainer, ReportHeaderSubtitle, ReportHeaderTitle, ReportPointSeparator } from './styled';

export type ReportsHeaderProps = {
  familyId: string;
  submissionDate: Date;
  submitterName: string;
  isCaregiver?: boolean;
};

export const ReportsHeader: FC<PropsWithChildren<ReportsHeaderProps>> = ({
  familyId,
  submissionDate,
  submitterName,
  isCaregiver,
}) => {
  const parsedDate = submissionDate.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  const parsedHour = submissionDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' });

  return (
    <ReportHeaderContainer>
      <ReportHeaderTitle as="h3">Family mental health report</ReportHeaderTitle>
      <ReportHeaderSubtitle>
        <p>Family ID {familyId}</p>

        <p>
          {parsedDate}, {parsedHour} <ReportPointSeparator horizontalMargin={0.25} /> Completed by {submitterName}{' '}
          {isCaregiver && <span>(Guardian)</span>}
        </p>
      </ReportHeaderSubtitle>
    </ReportHeaderContainer>
  );
};
