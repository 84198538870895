import styled from 'styled-components';

type ImageWrapperProps = {
  maxWidth?: string;
  maxHeight?: string;
};

export const ImageWrapper = styled.div<ImageWrapperProps>`
  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
`;

type ImageElementProps = {
  maxHeight?: string;
};

export const ImageElement = styled.img<ImageElementProps>`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: ${({ maxHeight }) => maxHeight || '100%'};
`;
