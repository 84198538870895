import { type FC, type PropsWithChildren } from 'react';

import { ConversationAlert } from '../ConversationAlert';

type ConversationAlertObject = {
  key: string;
  message: string;
};

export interface ConversationAlertListProps {
  messages: string[];
}

export const ConversationAlertList: FC<PropsWithChildren<ConversationAlertListProps>> = ({ messages }) => {
  const conversationAlerts = messages.map(
    (message) =>
      ({
        key: message,
        message,
      }) as ConversationAlertObject
  );

  return (
    <>
      {conversationAlerts.map((conversationAlert) => (
        <ConversationAlert key={conversationAlert.key} message={conversationAlert.message} />
      ))}
    </>
  );
};
