import { type FC, type PropsWithChildren } from 'react';

import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../components/MetadataRoute';
import { RouteSwitch } from '../../components/RouteSwitch';
import { routes } from '../../routes';
import { PaymentConfirmationWithPrerequisites } from '../Reports/Fmhc/PaymentConfirmation';
import { WelcomeCallPaymentDetails } from './WelcomeCallPaymentDetails';

export const PaymentDetails: FC<PropsWithChildren> = () => (
  <RouteSwitch>
    <MetadataRoute {...routeProps(routes.paymentDetails.welcomeCall.base)}>
      <WelcomeCallPaymentDetails />
    </MetadataRoute>
    <MetadataRoute {...routeProps(routes.paymentDetails.fmhc.confirmation)}>
      <PaymentConfirmationWithPrerequisites />
    </MetadataRoute>
  </RouteSwitch>
);
