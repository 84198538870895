/**
 * This file was implemented almost verbatim from the Vercel examples.
 * @see https://github.com/vercel/vercel/blob/main/examples/create-react-app/src/vitals.js
 * @see https://github.com/vercel/vercel/blob/main/examples/create-react-app/src/reportWebVitals.js
 *
 * Learn more about Web Vitals here:
 * @see https://vercel.com/docs/concepts/analytics/web-vitals
 */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { Metric } from 'web-vitals';

const vitalsUrl = 'https://vitals.vercel-analytics.com/v1/vitals';

// The `navigator.connection` object is not available in all browsers, so we need to check for it.
// `navigator.connection` is not typed correctly, so we have to ignore it.
function getConnectionSpeed(): string {
  // @ts-ignore
  return 'connection' in navigator && navigator.connection && 'effectiveType' in navigator.connection
    ? // @ts-ignore
      navigator.connection.effectiveType
    : '';
}

/**
 * Sends Web Vitals to Vercel Analytics
 */
export function sendToVercelAnalytics(metric: Metric) {
  // This ENV variable is automatically injected by Vercel. We don't need to add it anywhere.
  const analyticsId = process.env.VERCEL_ANALYTICS_ID;
  if (!analyticsId) {
    return;
  }

  const body = {
    dsn: analyticsId,
    id: metric.id,
    page: window.location.pathname,
    href: window.location.href,
    event_name: metric.name,
    value: metric.value.toString(),
    speed: getConnectionSpeed(),
  };

  const blob = new Blob([new URLSearchParams(body).toString()], {
    // This content type is necessary for `sendBeacon`
    type: 'application/x-www-form-urlencoded',
  });
  if (navigator.sendBeacon) {
    navigator.sendBeacon(vitalsUrl, blob);
  } else
    fetch(vitalsUrl, {
      body: blob,
      method: 'POST',
      credentials: 'omit',
      keepalive: true,
    });
}

/**
 * Gathers web vitals and sends them to a third party analytics service.
 */
export const reportWebVitals = (onPerfEntry?: (metric: Metric) => void) => {
  if (onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};
