import styled from 'styled-components';

export const Root = styled.div`
  margin: ${({ theme }) => theme.spacing['04']};
  margin-top: ${({ theme }) => theme.spacing['08']};
  margin-bottom: ${({ theme }) => theme.spacing['04']};

  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  letter-spacing: normal;
`;

export const Header = styled.div<{ concerning?: boolean }>`
  color: ${({ theme, concerning }) => (concerning ? theme.deprecated_.colors.red : theme.deprecated_.colors.green)};
  margin-bottom: ${({ theme }) => theme.spacing['01']};

  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const List = styled.ol`
  li {
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
`;

export const Container = styled.div`
  display: flex;
  margin: auto;
  flex: 1;
`;
