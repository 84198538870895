import { useCallback, useMemo } from 'react';

type Assessment = {
  slug: string;
};

type StoredAssessmentUuid = [string | null, (uuid: string) => void];

type UseStoredAssessmentResponseUuid = (assessment: Assessment) => StoredAssessmentUuid;

export const useStoredAssessmentResponseUuid: UseStoredAssessmentResponseUuid = ({ slug }) => {
  const key = `${slug}:assessmentResponseUuid`;

  const assessmentResponseUuid = sessionStorage.getItem(key);
  const setAssessmentResponseUuid = useCallback((uuid: string) => sessionStorage.setItem(key, uuid), [key]);

  return useMemo(
    () => [assessmentResponseUuid, setAssessmentResponseUuid],
    [assessmentResponseUuid, setAssessmentResponseUuid]
  );
};
