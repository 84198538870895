import { resolveEnvVar, resolveRequiredEnvVar } from '@littleotter/kit/env';

import { ENV } from './constants';
import type { AppConfig, IEnvironmentService, RuntimeContext } from './types';

/**
 * The PlatformEnvironmentService is responsible for holding useful meta information about the current
 * application environment.
 *
 * ! Do not reference variables in the environment that change on every deploy (ie. VERCEL_GIT_COMMIT_SHA, VERCEL_URL).
 * ! This will result in our build cache being invalidated on every deploy, which is not what we want.
 */
export class PlatformEnvironmentService implements IEnvironmentService {
  /**
   * The ID of the invoking application as defined in the `APP_ID` environment variable. This ID is commonly used
   * to identify the application in services and integrations.
   */
  appID;

  /**
   * The current version of the application as defined in the `APP_VERSION` environment variable.
   */
  appVersion;

  /**
   * The current build environment of the application. This is a more precise environment than what Vercel provides us.
   * The is defined in the `APP_ENVIRONMENT` environment variable, or defaults to `local` if not defined.
   */
  appEnvironment;

  /**
   * Is the current build environment of the application 'local'?
   */
  isLocalEnvironment;

  /**
   * Whether or not the application is running in a server environment
   */
  isServer;

  /**
   * The current NODE_ENV mode of the application (development, production, test)
   */
  mode;

  /**
   * The current runtime context of the application (server, browser)
   */
  runtimeContext;

  constructor() {
    this.appID = resolveRequiredEnvVar('APP_ID', process.env.APP_ID || process.env.NEXT_PUBLIC_APP_ID);
    this.appVersion = resolveRequiredEnvVar(
      'APP_VERSION',
      process.env.APP_VERSION || process.env.NEXT_PUBLIC_APP_VERSION
    );
    this.appEnvironment = resolveEnvVar(
      process.env.APP_ENVIRONMENT || process.env.NEXT_PUBLIC_APP_ENVIRONMENT,
      ENV.LOCAL
    );
    this.isLocalEnvironment = this.appEnvironment === ENV.LOCAL;
    this.isServer = PlatformEnvironmentService.isServer();
    this.mode = process.env.NODE_ENV;
    this.runtimeContext = PlatformEnvironmentService.getRuntimeContext();
  }

  /**
   * Returns an object of common application configuration information needed by most services
   */
  getAppConfig(): AppConfig {
    return {
      appID: this.appID,
      appVersion: this.appVersion,
      appEnvironment: this.appEnvironment,
    };
  }

  /**
   * Checks if the application is running in a server environment
   */
  private static isServer() {
    return typeof window === 'undefined';
  }

  /**
   * Returns the current runtime environment of the application
   */
  private static getRuntimeContext(): RuntimeContext {
    if (!PlatformEnvironmentService.isServer()) {
      return 'browser';
    }
    if (PlatformEnvironmentService.isServer()) {
      return 'server';
    }
    throw new Error('[PlatformEnvironmentService] Could not determine runtime context.');
  }
}
