import { type FC } from 'react';

import { Select } from '../../../Select';
import { getBaseInputProps } from '../../utils';
import { type CustomWidgetProps } from '../types';

type Options = {
  enumOptions: Array<{ label: string; value: string | number }>;
};

export const SelectWidget: FC<CustomWidgetProps<Options>> = (props) => {
  const { options } = props;

  return <Select {...getBaseInputProps(props)} options={options.enumOptions as Options['enumOptions']} />;
};
