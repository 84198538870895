import { type FC, type PropsWithChildren } from 'react';

import { Collapse } from '@littleotter/legacy-components';

import { type CATEGORICAL_SCORE, type DomainContexts, type ScoreTimeSeries } from '../../types';
import { DomainContext } from '../DomainContext';
import { DomainHeader } from '../DomainHeader';
import { ImpactOverTimeLine } from '../ImpactOverTime';
import * as Styled from './styled';

export const ImpactInfo: FC<PropsWithChildren<ImpactInfoProps>> = ({
  name,
  description,
  categoricalScore,
  impactSubScores,
  contexts,
}) => {
  return (
    <Styled.Root>
      <Collapse
        label={<DomainHeader title={name} categoricalScore={categoricalScore} />}
        iconVariant="plus-minus"
        containerVariant="card"
        maxHeight="10000px"
      >
        <Styled.Description>{description}</Styled.Description>
        <ImpactOverTimeLine
          timeSerieses={impactSubScores.map((s) => ({ timeSeries: s.timeSeries, label: `On ${s.name}` }))}
          threshold={1}
          maxScore={Math.max(...impactSubScores.map((s) => s.maxScore))}
        />
        <Styled.ContextContainer>
          {contexts.map((domainContext) => (
            <DomainContext
              key={domainContext.contextType}
              contextType={domainContext.contextType}
              context={domainContext.context}
            />
          ))}
        </Styled.ContextContainer>
      </Collapse>
    </Styled.Root>
  );
};

export interface ImpactInfoProps {
  name: string;
  description: string;
  categoricalScore: CATEGORICAL_SCORE;
  impactSubScores: ScoreTimeSeries[];
  contexts: DomainContexts;
}
