import { type ChartData } from '../types';

type GetBarChartData = (data: ChartData[]) => ChartData[];

// TODO: Figure out a non-horrible way of achieving this.

/**
 * Add two empty bars surrounding the data set to simulate padding.
 */
export const getBarChartData: GetBarChartData = (data) => [
  {
    id: '',
    value: 0,
  },
  ...data,
  {
    id: ' ',
    value: 0,
  },
];
