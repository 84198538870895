import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useEventTracking } from '$services/event-tracking';

export interface CheckupIntroScreenProps {
  beginCheckupHref: string;
}

export const CheckupIntroScreen = (props: CheckupIntroScreenProps) => {
  const { track } = useEventTracking();
  useEffect(() => {
    track('STARTED_CHECKUP');
  }, [track]);

  return <Redirect to={props.beginCheckupHref} />;
};
