import { math } from 'polished';
import styled from 'styled-components';

import arrowBottom from './assets/arrowBottom.svg';

export const StyledLabel = styled.label`
  font-weight: ${(props) => props.theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  cursor: pointer;
  width: 100%;
`;

export const StyledLabelDiv = styled.div`
  line-height: 1.2;
`;

export const StyledError = styled.small`
  color: ${(props) => `${props.theme.deprecated_.colors.red}`};
`;

export const StyledDropdownIndicator = styled.img.attrs({
  src: arrowBottom,
  alt: 'Dropdown indicator',
})`
  width: 0.65rem;
  height: auto;
  margin-right: 0.7rem;
`;

export const StyledIndicatorSeparator = styled.span`
  display: none;
`;

export type StyledControlProps = {
  hasError?: boolean;
  hasLabel?: boolean;
};

export const StyledControl = styled.div<StyledControlProps>`
  > div {
    transition: border 0.2s linear;
    box-shadow: none;
    margin-top: ${({ theme, hasLabel }) => (hasLabel ? theme.deprecated_.sizeBasis : 'unset')};
    border: ${({ theme, hasError }) =>
      `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${
        hasError ? theme.deprecated_.colors.red : theme.deprecated_.border.color
      }`};
    border-radius: ${({ theme }) => theme.deprecated_.border.radius};

    > div:first-of-type {
      color: ${({ theme }) => theme.deprecated_.colors.text};
      font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
      font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
      min-height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.125`)};
      padding-left: ${({ theme }) => theme.deprecated_.sizeBasis};
      font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
    }
  }
`;
