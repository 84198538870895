import {
  type LibraryWorryDomainsAndTags,
  type LibraryWorryDomainsAndTags_tags,
  type LibraryWorryDomainsAndTags_worryDomains,
} from '../../../../graphql/__generated__/LibraryWorryDomainsAndTags';

/**
 * Function that returns the header text of the Library Explore page
 *
 * @param allWorryDomainsAndTagsData gql response containing all the worry domains and tags information
 *
 * @param worryDomainsSearchParam array of worry domain ids
 *
 * @param tagsSearchParam array of tag ids
 */
export const getHeaderText = (
  allWorryDomainsAndTagsData: LibraryWorryDomainsAndTags | undefined,
  worryDomainsSearchParam: string[],
  tagsSearchParam: string[]
) => {
  if (!allWorryDomainsAndTagsData) {
    return '';
  }

  const allWorryDomains: LibraryWorryDomainsAndTags_worryDomains['edges'] =
    allWorryDomainsAndTagsData.worryDomains?.edges ?? [];

  const worryDomainNames = worryDomainsSearchParam
    .map((id) => allWorryDomains.find((value) => value?.node?.pk === Number(id))?.node?.name)
    .filter((value): value is string => value !== undefined);

  const allTags: LibraryWorryDomainsAndTags_tags['edges'] = allWorryDomainsAndTagsData.tags?.edges ?? [];

  const tagNames = tagsSearchParam
    .map((id) => allTags.find((value) => value?.node?.pk === Number(id))?.node?.name)
    .filter((value): value is string => value !== undefined);

  const worryDomainAndTagNames = [...(worryDomainNames ?? []), ...(tagNames ?? [])].join(', ');

  if (worryDomainAndTagNames.length === 0) {
    return '';
  }

  return `Learn more about the Little Otter’s ${worryDomainAndTagNames}`;
};
