import { format, formatDistanceToNowStrict, isSameDay as isSameDayFn, isSameYear as isSameYearFn } from 'date-fns';

export const getTimestampString = (timestamp: Date) => {
  const isSameDay = isSameDayFn(new Date(), timestamp);
  const isSameYear = isSameYearFn(new Date(), timestamp);

  if (isSameDay) {
    return format(timestamp, 'h:mmaaa');
  }
  if (isSameYear) {
    return `${format(timestamp, 'MM-dd h:mmaaa')} (${formatDistanceToNowStrict(timestamp, { addSuffix: true })})`;
  }
  return format(timestamp, 'yyyy-MM-dd h:mmaaa');
};
