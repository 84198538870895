import { math, rgba } from 'polished';
import styled from 'styled-components';

import { Header, Link } from '@littleotter/legacy-components';

import closeIcon from './assets/closeIcon.svg';
import sadOtter from './assets/sadOtter.svg';

export const CardContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  padding: ${({ theme }) => {
    const basicPadding = theme.deprecated_.sizeBasis;
    const padding1 = math(`${basicPadding} * 1.5`);
    const padding2 = math(`${basicPadding} * 2`);
    return `${basicPadding} ${padding2} ${basicPadding} ${padding1}`;
  }};
  box-shadow: ${({ theme }) => `0px 4px 60px ${rgba(theme.deprecated_.colors.japaneseIndigo, 0.08)}`};
  border: ${({ theme }) =>
    `${math(`${theme.deprecated_.border.size} * 2`)} ${theme.deprecated_.border.style} ${rgba('#EE9462', 0.4)}`};
  position: fixed;
  bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  left: ${({ theme }) => theme.deprecated_.sizeBasis};
  transition: 1s;
  width: calc(${({ theme }) => `100% - ${math(`${theme.deprecated_.sizeBasis} * 2`)}`});
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '150%')});
`;

export const SadOtterIcon = styled.img.attrs({
  src: sadOtter,
})`
  margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2.813`)};
`;

export const CloseIcon = styled.img.attrs({
  src: closeIcon,
})`
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
  cursor: pointer;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const Title = styled(Header).attrs({ as: 'h5' })`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 0.875`)};
`;

export const Description = styled.p`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 0.875`)};
`;

export const StyledLink = styled(Link).attrs({ href: 'mailto:support@littleotterhealth.com', isAnchor: true })`
  text-decoration: underline;
`;
