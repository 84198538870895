import styled from 'styled-components';

export const StyledLabel = styled.label<{ isSelected: boolean }>`
  display: block;
  padding: 1rem;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${(props) => props.theme.deprecated_.fontWeights.semiBold};
  text-align: center;

  transition: all 0.2s linear;
  color: ${({ theme, isSelected }) => (isSelected ? theme.deprecated_.colors.white : theme.deprecated_.colors.black)};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.deprecated_.colors.text : theme.deprecated_.colors.lightestGray};
`;
