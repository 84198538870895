import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { type Theme } from '../../types/styled';

const StyledTagsContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};

  & > * {
    flex-shrink: 0;
  }
`;

type TagsContainerProps = {
  className?: string;
};

export const TagsContainer: FC<PropsWithChildren<TagsContainerProps>> = ({ children, className }) => (
  <StyledTagsContainer className={className}>{children}</StyledTagsContainer>
);

export type TagProps = {
  textColor?: keyof Theme['deprecated_']['colors'];
  backgroundColor?: keyof Theme['deprecated_']['colors'];
  backgroundColorHex?: string;
  variant?: 'default' | 'compact';
  textSize?: string;
  className?: string;
};

const commonTagStyles = css<TagProps>`
  background-color: ${({ theme, backgroundColor, backgroundColorHex }) =>
    backgroundColorHex || theme.deprecated_.colors[backgroundColor ?? 'gray50']};
  color: ${({ theme, textColor }) => theme.deprecated_.colors[textColor ?? 'text']};
  display: inline-block;
`;

const DefaultTag = styled.div<TagProps>`
  ${commonTagStyles}
  font-size: ${({ theme, textSize }) => textSize || theme.deprecated_.fontSizes.smaller};
  border-radius: ${(props) => props.theme.deprecated_.border.radius};
  padding: ${(props) =>
    `${math(`${props.theme.deprecated_.sizeBasis} * 0.5`)} ${math(`${props.theme.deprecated_.sizeBasis} * 0.75`)}`};
  letter-spacing: 0.25px;
`;

const CompactTag = styled.div<TagProps>`
  ${commonTagStyles}
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  text-align: center;
  height: 1.25rem;
  line-height: 1.25rem;
  min-width: 1.5rem;
  border-radius: 0.625rem;
  padding: 0 0.25rem;
`;

export const Tag: FC<PropsWithChildren<TagProps>> = ({
  backgroundColor,
  backgroundColorHex,
  textColor,
  children,
  variant = 'default',
  ...rest
}) => {
  const StyledTag = { default: DefaultTag, compact: CompactTag }[variant];

  return (
    <StyledTag
      backgroundColor={backgroundColor}
      textColor={textColor}
      backgroundColorHex={backgroundColorHex}
      variant={variant}
      {...rest}
    >
      {children}
    </StyledTag>
  );
};
