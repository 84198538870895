import { format } from 'date-fns';
import { useMemo } from 'react';
import { type Column, type TableOptions } from 'react-table';

import { Link } from '@littleotter/legacy-components';

import { CellText } from '../styled';
import { type AssessmentsAndDocumentationTableDataItem, type SurveyResponseData } from '../types';

export const useTableOptions = (
  tableData: AssessmentsAndDocumentationTableDataItem[]
): TableOptions<AssessmentsAndDocumentationTableDataItem> => {
  const columns = useMemo(
    (): Column<AssessmentsAndDocumentationTableDataItem>[] => [
      {
        id: 'surveyResponse',
        Header: 'Name of Form',
        accessor: (data) => data.surveyResponse,
        Cell({ value }: { value: SurveyResponseData }) {
          if (!value.externalId) {
            return <CellText>{value.name}</CellText>;
          }

          return (
            <Link isAnchor href={`https://intakeq.com/#/history/${value.externalId}`}>
              {value.name}
            </Link>
          );
        },
      },
      {
        id: 'createdAt',
        Header: 'Date Completed',
        accessor: (data) => data.createdAt,
        Cell({ value }: { value: Date }) {
          return <CellText>{format(value, 'MMM d, y')}</CellText>;
        },
        sortType: 'datetime',
      },
      {
        id: 'informant',
        Header: 'Informant',
        accessor: (data) => data.informant,
      },
      {
        id: 'client',
        Header: 'Client',
        accessor: (data) => data.client,
      },
    ],
    []
  );

  const sortBy = useMemo(() => [{ id: 'createdAt', desc: true }], []);

  return {
    columns,
    data: tableData,
    initialState: {
      sortBy,
    },
  };
};
