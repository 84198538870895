import { Page } from 'react-pdf';
import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DocumentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  margin-top: ${({ theme }) => theme.spacing['04']};
`;

export const StyledPage = styled(Page)`
  margin-bottom: ${({ theme }) => theme.spacing['09']};
`;

export const StyledPageControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.spacing['05']} auto;
`;

export const StyledPageIndicator = styled.div`
  margin: 0 ${({ theme }) => theme.spacing['05']};
`;

export const StyledPrevButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.larger};

  &:disabled {
    filter: opacity(0.2);
    cursor: default;
  }
`;

export const StyledNextButton = styled(StyledPrevButton)`
  transform: rotate(180deg);
`;
