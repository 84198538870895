import { type FC } from 'react';

import { SingleSelectionOptions } from '../../../Options';
import { StyledError } from '../../styled';
import { type CustomWidgetProps } from '../types';

type Options = {
  enumOptions: Array<{ label: string; value: string | number }>;
};

export const SingleSelectionWidget: FC<CustomWidgetProps<Options>> = ({ id, options, value, onChange, rawErrors }) => (
  <>
    <SingleSelectionOptions
      name={id}
      options={options.enumOptions as Options['enumOptions']}
      value={value}
      onChange={onChange}
    />
    <StyledError>{rawErrors}</StyledError>
  </>
);
