import { useCallback, useEffect, useState } from 'react';

import { type Child, type OnChangeChildWorryDomain, type SelectedChildrenWorryDomainPksMap } from '../types';

export const useChildrenWorryDomains = (userChildren: Child[], defaultValues: SelectedChildrenWorryDomainPksMap) => {
  const [selectedChildrenWorryDomainPksMap, setSelectedWorryDomainPksMap] = useState<SelectedChildrenWorryDomainPksMap>(
    {}
  );

  // Reset `selectedChildrenWorryDomainPksMap` when `userChildren` or
  // `defaultValues` prop changes
  useEffect(() => {
    setSelectedWorryDomainPksMap(defaultValues);
  }, [defaultValues, userChildren]);

  const onChangeChildWorryDomain = useCallback<OnChangeChildWorryDomain>(
    ({ childId, worryDomainPk, checked }) => {
      let updatedWorryDomainPks: number[];

      if (checked) {
        updatedWorryDomainPks = [...selectedChildrenWorryDomainPksMap[childId], worryDomainPk];
      } else {
        updatedWorryDomainPks = selectedChildrenWorryDomainPksMap[childId].filter((pk) => pk !== worryDomainPk);
      }

      setSelectedWorryDomainPksMap((prev) => ({ ...prev, [childId]: updatedWorryDomainPks }));
    },
    [selectedChildrenWorryDomainPksMap]
  );

  return { selectedChildrenWorryDomainPksMap, onChangeChildWorryDomain };
};
