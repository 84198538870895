import { useState, type FC } from 'react';
import { BsX } from 'react-icons/bs';
import { type ConversationInfo_conversation_participants } from 'src/graphql/__generated__/ConversationInfo';

import { ProviderTitleEnum } from '../../../../../graphql/__generated__/globalTypes';
import { maybeGetCurrentOutOfOfficeInterval } from '../../utils/maybeGetCurrentOutOfOfficeInterval';
import { AlertContainer, AlertDescription, AlertHeading, CloseIcon } from './styled';

export type ConversationOutOfOfficeMessageProps = {
  providers: ConversationInfo_conversation_participants[];
};

const getProviderNamesString = (outOfOfficeProviders: ConversationInfo_conversation_participants[]) => {
  return outOfOfficeProviders.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(' & ');
};

const getOutOfOfficeHeadingText = (providersOutOfOffice: ConversationInfo_conversation_participants[]) => {
  const names = getProviderNamesString(providersOutOfOffice);
  const text = providersOutOfOffice.length > 1 ? 'are out of office.' : 'is currently out of office.';
  return `${names} ${text}`;
};

const getProviderOutOfOfficeDates = (provider: ConversationInfo_conversation_participants) => {
  const unavailableDates = maybeGetCurrentOutOfOfficeInterval(provider.outOfOfficeDates);
  const startDate = new Date(`${unavailableDates?.startDate}T00:00:00.000`).toLocaleDateString('en-US');
  const endDate = new Date(`${unavailableDates?.endDate}T00:00:00.000`).toLocaleDateString('en-US');
  return {
    startDate,
    endDate,
  };
};

const isParentSpecialistOutOfOffice = (providers: ConversationInfo_conversation_participants[]) => {
  // business logic for parent specialist check
  const parentSpecialistCheck = (provider: ConversationInfo_conversation_participants) => {
    return (
      // provider.id check is for clinician Camila Ramirez who needs the PS OOO msg but doesn't have a PS title
      provider.providerTitle === ProviderTitleEnum.PARENT_SPECIALIST || provider.id === '7076'
    );
  };

  return providers.some((provider) => {
    return parentSpecialistCheck(provider);
  });
};

const getOutOfOfficeMessage = (providers: ConversationInfo_conversation_participants[]) => {
  const standardOutOfOfficeMessage =
    'If you have an urgent need that cannot wait until they are back in office, please contact help@littleotterhealth.com or call 415-449-2813.';
  const parentSpecialistOutOfOfficeMessage =
    'If you have an urgent need that cannot wait until they are back in office, contact integrated-care@littleotterhealth.com or call 415-449-2813. If you need help with billing, please contact help@littleotterhealth.com';

  if (isParentSpecialistOutOfOffice(providers)) {
    return parentSpecialistOutOfOfficeMessage;
  }
  return standardOutOfOfficeMessage;
};

export const ConversationOutOfOfficeMessage: FC<ConversationOutOfOfficeMessageProps> = ({ providers }) => {
  const [messageOpen, setMessageOpen] = useState(true);
  return (
    <>
      {messageOpen && (
        <AlertContainer>
          <CloseIcon onClick={() => setMessageOpen(false)}>
            <BsX />
          </CloseIcon>
          <AlertHeading>{getOutOfOfficeHeadingText(providers)}</AlertHeading>
          {providers.map((provider) => {
            const { startDate, endDate } = getProviderOutOfOfficeDates(provider);
            return (
              <AlertDescription key={provider.id}>
                {provider.firstName} {provider.lastName} is out of office from {startDate} to {endDate}.
              </AlertDescription>
            );
          })}
          <AlertDescription>
            If you are experiencing a psychiatric emergency, please contact your local emergency department or call 911.
          </AlertDescription>
          <AlertDescription>{getOutOfOfficeMessage(providers)}</AlertDescription>
        </AlertContainer>
      )}
    </>
  );
};
