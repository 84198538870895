export type SaveChildErrors = {
  [fieldName in keyof Partial<ChildProfileFormData>]: string;
};

export type SaveChildResult = {
  isValid: boolean;
  errorMessages?: SaveChildErrors;
  userChildId?: string;
};

export type RemoveChildResult = {
  success: boolean;
  errorMessage?: string;
};

export type Child = {
  id?: number;
  firstName: string;
  lastName: string;
  preferredName: string;
  displayName: string;
  dateOfBirth: string;
  relationship?: Relationship;
  gender?: ChildGender;
  pronouns?: string;
  isSeekingCare?: boolean;
};

export type ChildProfileFormData = {
  profilePicture?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  pronouns: string;
  relationship?: string;
  isSeekingCare?: string;
};

export enum ChildGender {
  Female = 'female',
  Male = 'male',
}

export enum Relationship {
  Parent = 'Parent',
  Guardian = 'Guardian',
  Grandparent = 'Grandparent',
  Relative = 'Relative',
  FamilyFriend = 'Family Friend',
  Other = 'Other',
}

export enum Pronouns {
  SheHer = 'She/Her',
  HeHim = 'He/Him',
  TheyThem = 'They/Them',
  Other = 'Other',
}

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}
