import { type ReactNode } from 'react';

import { HorizontalScrollContainer } from './styled';

export type HorizontalScrollProps = {
  gap?: number;
  children: ReactNode;
};

export const HorizontalScroll = ({ children, gap = 1 }: HorizontalScrollProps) => (
  <HorizontalScrollContainer gap={gap}>{children}</HorizontalScrollContainer>
);
