import { gql } from '@apollo/client';

export const USER_FAMILY_CHILDREN_QUERY = gql`
  query UserFamilyChildrenQuery {
    viewer {
      id
      family {
        id
        children {
          id
          firstName
          lastName
          preferredName
          displayName
          dateOfBirth
          gender
          pronouns
          caregiverRelationships {
            id
            caregiver {
              id
            }
            relationship
          }
          isSeekingCare
        }
      }
    }
  }
`;
