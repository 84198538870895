import { gql } from '@apollo/client';

import { RESOURCES_FRAGMENT } from './resources-fragment';

export const LIBRARY_FOR_YOU_QUERY = gql`
  query LibraryForYou {
    viewer {
      id
      family {
        manuallyCuratedResources(first: 10) {
          ...ResourcesFragment
        }
      }
      family {
        children {
          id
          worryDomains {
            edges {
              node {
                id
                name
                resourcesByTag(first: 10) {
                  ...ResourcesFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${RESOURCES_FRAGMENT}
`;
