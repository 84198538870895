import { Loading } from '@littleotter/legacy-components';

import * as styled from './styled';

export const BaseFmhcCtaSkeleton = () => {
  return (
    <styled.Root>
      <Loading />
    </styled.Root>
  );
};
