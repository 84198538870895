import { type FC, type PropsWithChildren, type ReactNode } from 'react';
import styled from 'styled-components';

import { Icon } from '@littleotter/legacy-components';

import {
  CardCenterSection,
  CardLabel,
  CardLeftSection,
  CardRightSection,
  StyledButtonCard,
  StyledFixedLinkCard,
  StyledLinkCard,
} from '../styled';

const StyledCardRightSection = styled(CardRightSection)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  vertical-align: middle;
`;

type SettingsCardContentProps = {
  leftSection: ReactNode;
  text: string;
};

export type SettingsLinkCardProps = SettingsCardContentProps & {
  isAnchor?: boolean;
  onClick?: () => void;
  href?: string;
};

/**
 * Styled card that extends from Link component, used generally for anchors
 */
export const SettingsLinkCard: FC<PropsWithChildren<SettingsLinkCardProps>> = ({
  isAnchor = false,
  onClick,
  href,
  leftSection,
  text,
}) => (
  <StyledLinkCard isAnchor={isAnchor} onClick={onClick} href={href}>
    <CardLeftSection>{leftSection}</CardLeftSection>
    <CardCenterSection>
      <CardLabel>{text}</CardLabel>
    </CardCenterSection>
    <StyledCardRightSection>
      <Icon name="ChevronRight" />
    </StyledCardRightSection>
  </StyledLinkCard>
);

export type SettingsButtonCardProps = SettingsCardContentProps & {
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button';
};

/**
 * Styled card that extends from Button component, used generally when an action is needed upon click or such
 */
export const SettingsButtonCard: FC<PropsWithChildren<SettingsButtonCardProps>> = ({
  onClick,
  type,
  leftSection,
  text,
}) => (
  <StyledButtonCard onClick={onClick} type={type} variant="clearStyles">
    <CardLeftSection>{leftSection}</CardLeftSection>
    <CardCenterSection>
      <CardLabel>{text}</CardLabel>
    </CardCenterSection>
    <StyledCardRightSection>
      <Icon name="ChevronRight" />
    </StyledCardRightSection>
  </StyledButtonCard>
);

export type SettingsFixedLinkCardProps = SettingsCardContentProps & {
  href?: string;
};

/**
 * Similar to SettingsLinkCard, this is a styled card that extends from Link component,
 *  it lacks from other elements inside, just the the text and it is full-width and positioned at the bottom of the page
 */
export const SettingsFixedLinkCard: FC<PropsWithChildren<SettingsFixedLinkCardProps>> = ({ href, text }) => (
  <StyledFixedLinkCard href={href} variant="outline" size="large">
    <CardCenterSection>
      <CardLabel>{text}</CardLabel>
    </CardCenterSection>
  </StyledFixedLinkCard>
);
