import { useLazyQuery } from '@apollo/client';
import { useCallback, useState } from 'react';

import {
  type ConversationParticipantsQuery,
  type ConversationParticipantsQueryVariables,
} from './__generated__/ConversationParticipantsQuery';
import { CONVERSATION_PARTICIPANTS_QUERY } from './ConversationParticipantsQuery';
import { getParticipantName } from './getParticipantName';

type GetAuthorNameOptions = {
  conversationSid: string;
  authorId: string;
};

type GetAuthorName = (options: GetAuthorNameOptions) => Promise<string>;

type UseAuthorNameResult = [getAuthorName: GetAuthorName, lazyAuthorName: string | undefined];

type UseAuthorName = () => UseAuthorNameResult;

export const useLazyAuthorName: UseAuthorName = () => {
  const [lazyAuthorName, setLazyAuthorName] = useState<string>();
  const [getConversationParticipants] = useLazyQuery<
    ConversationParticipantsQuery,
    ConversationParticipantsQueryVariables
  >(CONVERSATION_PARTICIPANTS_QUERY);

  const getAuthorName = useCallback<GetAuthorName>(
    async ({ authorId, conversationSid }) => {
      if (!authorId || !conversationSid) {
        return '';
      }

      const { data: conversationData } = await getConversationParticipants({
        variables: {
          conversationSid,
        },
      });

      if (!conversationData) {
        return '';
      }

      const participantName = getParticipantName({
        authorId,
        participants: conversationData.conversation.participants,
      });

      setLazyAuthorName(participantName);
      return participantName;
    },
    [getConversationParticipants]
  );

  return [getAuthorName, lazyAuthorName];
};
