import { type ObjectFieldTemplateProps } from '@rjsf/core';
import { math } from 'polished';
import { type FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  }
`;

export const ObjectFieldTemplate: FC<ObjectFieldTemplateProps> = ({ properties }) => (
  <Container>
    {properties.map(({ content }) => (
      <div key={content.key}>{content}</div>
    ))}
  </Container>
);
