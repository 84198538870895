import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  type ConversationsInfoByState,
  type ConversationsInfoByStateVariables,
} from 'src/graphql/__generated__/ConversationsInfoByState';

import { ConversationStateArgument, type ConversationState } from '../../../../graphql/__generated__/globalTypes';
import { CONVERSATIONS_INFO_BY_STATE_QUERY } from '../../../../graphql/messages';

export type ClosedConversationInfo = {
  id: string;
  sid: string;
  state: ConversationState;
  lastActiveAt: Date;
};

export type ClosedConversationsInfoState = 'success' | 'loading' | 'error';

export const useClosedConversationsInfo = () => {
  const { data, loading, error } = useQuery<ConversationsInfoByState, ConversationsInfoByStateVariables>(
    CONVERSATIONS_INFO_BY_STATE_QUERY,
    {
      variables: { state: ConversationStateArgument.CLOSED },
    }
  );

  const closedConversations = useMemo(() => {
    let res: ClosedConversationInfo[] = [];
    if (data?.conversationsByState) {
      res = data.conversationsByState.map((convo) => {
        return {
          id: convo.id,
          sid: convo.conversationSid,
          state: convo.state,
          lastActiveAt: new Date(convo.lastMessageAt),
        };
      });
    }
    return res;
  }, [data?.conversationsByState]);
  const state = useMemo<ClosedConversationsInfoState>(() => {
    if (loading) return 'loading';
    if (error || !data) return 'error';
    return 'success';
  }, [data, error, loading]);

  return { closedConversations, state };
};
