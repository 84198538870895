import { gql } from '@apollo/client';

export const ASSESSMENT_STEP_FRAGMENT = gql`
  fragment AssessmentStepFragment on StepType {
    id
    order
    isConditionalStep
    jsonSchema
    uiSchema
    previousStep {
      id
    }
    nextStep {
      id
      isConditionalStep
    }
  }
`;

export const ASSESSMENT_FRAGMENT = gql`
  fragment AssessmentFragment on AssessmentType {
    id
    name
    slug
    headerImage
    marketingUrl
    gtag
    fbq
    uetq
  }
`;

export const ASSESSMENT_QUERY = gql`
  query AssessmentQuery($slug: String!) {
    assessmentsNamespace {
      assessment(slug: $slug) {
        id
        step {
          id
        }
      }
    }
  }
`;

export const ASSESSMENT_STEP_QUERY = gql`
  query AssessmentStepQuery($slug: String!, $step: Int!) {
    assessmentsNamespace {
      assessment(slug: $slug) {
        id
        step(id: $step) {
          id
          ...AssessmentStepFragment
        }
        ...AssessmentFragment
      }
    }
  }

  ${ASSESSMENT_FRAGMENT}
  ${ASSESSMENT_STEP_FRAGMENT}
`;

export const ASSESSMENT_STEP_RESPONSE_FRAGMENT = gql`
  fragment AssessmentStepResponseFragment on StepResponseType {
    id
    data
    createdAt
    updatedAt
    step {
      id
    }
  }
`;

export const ASSESSMENT_STEP_RESPONSE_QUERY = gql`
  query AssessmentStepResponseQuery($assessmentResponseUuid: String!, $step: Int!) {
    assessmentsNamespace {
      assessmentResponse(uuid: $assessmentResponseUuid) {
        id
        stepResponse(stepId: $step) {
          id
          ...AssessmentStepResponseFragment
        }
      }
    }
  }

  ${ASSESSMENT_STEP_RESPONSE_FRAGMENT}
`;

export const ASSESSMENT_RESPONSE_FRAGMENT = gql`
  fragment AssessmentResponseFragment on AssessmentResponseType {
    id
    uuid
    lastCompletedStep {
      nextStep {
        id
        isConditionalStep
      }
    }
  }
`;

export const UPDATE_ASSESSMENT_STEP_RESPONSE_MUTATION = gql`
  mutation UpdateAssessmentStepResponseMutation($assessmentResponseUuid: String, $step: Int!, $data: JSONString!) {
    assessmentsNamespace {
      updateStepResponse(assessmentResponseUuid: $assessmentResponseUuid, stepId: $step, data: $data) {
        ... on StepResponseType {
          id
          nextStep {
            id
            isConditionalStep
          }
          assessmentResponse {
            id
            ...AssessmentResponseFragment
          }
          ...AssessmentStepResponseFragment
        }
        ... on JsonSchemaErrorsType {
          errors
        }
      }
    }
  }

  ${ASSESSMENT_RESPONSE_FRAGMENT}
  ${ASSESSMENT_STEP_RESPONSE_FRAGMENT}
`;
