import { useQuery } from '@apollo/client';
import { useMemo, type FC, type PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type AssessmentSubmission as GqlAssessmentSubmission,
  type FmhcReport as GqlFmhcReport,
} from '../../../graphql/lo1/generated';
import { type FmhcUrlParams } from '../../../routes/provider';
import { getSubjectHash } from '../utils/subject';
import { FAMILY_DATA_QUERY } from './hooks/familyDataQuery';
import { useGqlAssessmentSubmissionData } from './hooks/useGqlAssessmentSubmissionsData';
import { useGqlFmhcReportData } from './hooks/useGqlFmhcReportData';
import { ReportsContent } from './ReportsContent';
import { type FamilyData, type FmhcReport } from './types';
import { sortFmhcReports } from './utils/sortFmhcReports';

// from family ID, get fmhcids.
// from fmhcids, pick one and get submissions and link to report.
export const Reports: FC<PropsWithChildren> = () => {
  const { familyId, fmhcId } = useParams<FmhcUrlParams>();

  const { loading: submissionsLoading, gqlSubmissions } = useGqlAssessmentSubmissionData(fmhcId);

  const { data: familyData, loading: familyDataLoading } = useQuery<FamilyData>(FAMILY_DATA_QUERY, {
    variables: { familyId: Number(familyId) },
  });

  const { loading: reportsLoading, gqlFmhcReports } = useGqlFmhcReportData(fmhcId);

  const loading = submissionsLoading || familyDataLoading || reportsLoading;

  const fmhcReports = useMemo((): FmhcReport[] => {
    const unsortedFmhcReports = joinSubmissionsReports(gqlSubmissions, gqlFmhcReports);
    // Sort FMHC reports so that children seeking care come first, then other children,
    // then caregivers, and then family.
    return sortFmhcReports(unsortedFmhcReports, familyData);
  }, [familyData, gqlSubmissions, gqlFmhcReports]);

  if (loading) {
    return <PageWideLoading />;
  }

  return <ReportsContent familyData={familyData} familyId={familyId} fmhcReports={fmhcReports} />;
};

const joinSubmissionsReports = (
  gqlSubmissions: Map<string, GqlAssessmentSubmission>,
  gqlFmhcReports: Map<string, GqlFmhcReport>
): FmhcReport[] => {
  const reports = new Array<FmhcReport>();
  gqlFmhcReports.forEach((report, subjectString) =>
    reports.push(newFmhcReport(report, gqlSubmissions.get(subjectString)))
  );
  return reports;
};

const newFmhcReport = (
  fmhcReport: GqlFmhcReport,
  assessmentSubmission: GqlAssessmentSubmission | undefined
): FmhcReport => {
  return {
    key: getSubjectHash(fmhcReport.subject),
    subject: fmhcReport.subject,
    submissionMetadata: {
      submissionTime: `${assessmentSubmission?.submissionTime || ''}`,
      submitterId: assessmentSubmission?.submitterId ?? '',
      submitterName: assessmentSubmission?.submitterName ?? '',
    },
    version: assessmentSubmission?.version,
    submissionData: assessmentSubmission,
    gqlFmhcReport: fmhcReport,
  };
};
