import { differenceInHours, format } from 'date-fns';
import { useState, type FC, type PropsWithChildren } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Header, Icon, Link, Modal, ProfilePicture } from '@littleotter/legacy-components';

import { type BannerMessage } from '$shared/hooks';

import { type UpcomingAppointment as UpcomingAppointmentFragment } from '../../graphql/__generated__/UpcomingAppointment';
import { StateLocationSourceEnum } from '../../pages/PaymentDetails/WelcomeCallPaymentDetails';
import { routes } from '../../routes';
import { EditAppointment } from '../EditAppointment';
import {
  Card,
  CTA,
  HeaderContainer,
  Row,
  SeeAllLink,
  StyledIcon,
  StyledScheduleIllustration,
  SubHeader,
} from './styled';

export type UpcomingAppointmentProps = {
  nextAppointment?: UpcomingAppointmentFragment | null;
  setBannerMessage: (message: BannerMessage | null) => void;
  hasConfirmedAppointments: boolean;
};

// TODO: refactor to remove repeating code
export const UpcomingAppointment: FC<PropsWithChildren<UpcomingAppointmentProps>> = ({
  nextAppointment,
  setBannerMessage,
  hasConfirmedAppointments,
}) => {
  const [editAppointmentModalOpen, setEditAppointmentModalOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();

  // uses next-gen schedule appointments page
  const scheduleHref = `${process.env.NEXT_GEN_PROXY_URL}/care/appointments`;

  const closeModal = () => {
    setEditAppointmentModalOpen(false);
  };

  const goToWelcomeCallPayment = () => {
    history.push({
      pathname: routes.paymentDetails.welcomeCall.home.url(),
      state: { source: StateLocationSourceEnum.CareJourney },
    });
  };
  if (nextAppointment) {
    const { startDatetime, provider, service, zoomLink } = nextAppointment;

    const appointmentInNextHour = differenceInHours(new Date(), new Date(startDatetime)) >= -1;

    return (
      <>
        <Card>
          <Row>
            <HeaderContainer>
              <Header as="h4" useBodyFont>
                {service.name}
              </Header>
              <SubHeader>
                with {provider.firstName} {provider.lastName}
              </SubHeader>
            </HeaderContainer>
            <ProfilePicture src={provider.profilePicture} size="smaller" style={{ marginRight: 0 }} />
          </Row>
          {appointmentInNextHour && zoomLink ? (
            <Link href={zoomLink} target="_blank" isAnchor>
              <CTA joinNow>
                <span style={{ flex: 1 }}>{`${format(
                  new Date(startDatetime),
                  "EEEE, MMMM do 'at' h:mmaaa"
                )}  -  Join Call`}</span>
                <Icon name="ArrowRight" />
              </CTA>
            </Link>
          ) : (
            <CTA>
              <StyledIcon name="Calendar" />
              <span style={{ flex: 1 }}>{format(new Date(startDatetime), "EEEE, MMMM do 'at' h:mmaaa")}</span>
            </CTA>
          )}
          {!routes.care.schedule.test(location.pathname, { exact: true }) && (
            <SeeAllLink>
              <Link href={scheduleHref}>See All Appointments</Link>
            </SeeAllLink>
          )}
        </Card>
        <Modal
          isOpen={editAppointmentModalOpen}
          onBackgroundClick={closeModal}
          onEscapeKeydown={closeModal}
          onCloseButtonClick={closeModal}
        >
          <EditAppointment appointment={nextAppointment} closeModal={closeModal} setBannerMessage={setBannerMessage} />
        </Modal>
      </>
    );
  }

  if (!hasConfirmedAppointments) {
    return (
      <Card>
        <Row>
          <HeaderContainer>
            <Header as="h4" useBodyFont>
              Schedule Your Kickoff Session
            </Header>

            <SubHeader>You don't have anything scheduled</SubHeader>
          </HeaderContainer>
          <StyledScheduleIllustration />
        </Row>
        <CTA useYellow onClick={goToWelcomeCallPayment}>
          <span style={{ flex: 1 }}>Schedule your first session</span>
          <StyledIcon name="ArrowRight" />
        </CTA>
      </Card>
    );
  }

  return (
    <Card>
      <Row>
        <HeaderContainer>
          <Header as="h4" useBodyFont>
            No Appointments
          </Header>
          <SubHeader>You don't have anything scheduled</SubHeader>
        </HeaderContainer>
        <StyledScheduleIllustration />
      </Row>
      {!routes.care.schedule.test(location.pathname, { exact: true }) && (
        <Link href={scheduleHref}>
          <CTA useYellow>
            <span style={{ flex: 1 }}>Schedule a new appointment</span>
            <StyledIcon name="ArrowRight" />
          </CTA>
        </Link>
      )}
    </Card>
  );
};
