/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';

import { isValueNullish } from '$shared/utils/values';

import { type STORAGE_KEY } from './storageKey';

/**
 * Gets a value from local or session storage.
 */
const getStorageValue = <T>(storage: Storage, key: string, defaultValue: T) => {
  // Cannot access storage in SSR
  if (typeof window === 'undefined') {
    return defaultValue;
  }

  const savedValue = storage.getItem(key);
  const value = !isValueNullish(savedValue) ? (JSON.parse(savedValue) as T) : defaultValue;
  return value;
};

/**
 * A prefix to identify session and local storage keys saved using
 * the storage hooks in this application.
 */
const STORAGE_KEYS_PREFIX = 'lo_';

const useStorage =
  (storage: Storage, keyPrefix: string) =>
  <T>(storageKey: STORAGE_KEY, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>, () => void] => {
    const key = keyPrefix + storageKey;

    const [value, setValue] = useState<T>(() => {
      return getStorageValue<T>(storage, key, defaultValue);
    });

    useEffect(() => {
      storage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    const removeItem = () => storage.removeItem(key);

    return [value, setValue, removeItem];
  };

/**
 * Saves data in local storage.
 * @param storageKey A string to identify the value being being cached.
 * @param defaultValue The default value when no value has been stored yet.
 * @returns A stateful value, a function to update it, and a function to remove it from storage
 * @example
 * const [collapsed, setCollapsed, removeItem] = useLocalStorage('isSidebarCollapsed', false);
 */
const useLocalStorage = useStorage(localStorage, STORAGE_KEYS_PREFIX);

/**
 * Saves data in session storage.
 *
 * @param storageKey A string to identify the value being being cached.
 * @param defaultValue The default value when no value has been stored yet.
 * @returns A stateful value, a function to update it, and a function to remove it from storage
 * @example
 * const [collapsed, setCollapsed, removeItem] = useSessionStorage('isSidebarCollapsed', false);
 */
const useSessionStorage = useStorage(sessionStorage, STORAGE_KEYS_PREFIX);

export { useLocalStorage, useSessionStorage };
