const unit = 'rem';

export const theme = {
  // New theme stuff should be held below this comment
  containerSizes: {
    md: '980px',
    lg: '1200px',
  },
  fontSizes: {
    smallest: '',
    smaller: '',
    small: '13px',
    regular: '16px',
    regularPlus: '18px',
    big: '20px',
    bigger: '22px',
    large: '24px',
    larger: '32px',
    largest: '48px',
    huge: '64px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 800,
  },
  fontFamilies: {
    secondary: "'Larsseit', Arial, sans-serif",
    primary: "'Recoleta', Georgia, 'Times New Roman', serif",
  },
  spacing: {
    '01': '2px',
    '02': '4px',
    '03': '8px',
    '04': '12px',
    '05': '16px',
    '06': '24px',
    '07': '32px',
    '08': '40px',
    '09': '48px',
    '10': '64px',
    '11': '80px',
    '12': '96px',
    '13': '128px',
    '14': '160px',
  },
  borderRadius: {
    '01': '4px',
    '02': '8px',
    '03': '12px',
  },
  // New theme stuff should be held above this comment
  deprecated_: {
    unit,
    sizeBasis: `1${unit}`,
    fontFamilies: {
      body: "'Larsseit', Arial, sans-serif",
      header: "'Recoleta', Georgia, 'Times New Roman', serif",
    },
    lineHeight: '1.5',
    letterSpacing: '0.25px', // only use with fontFamilies.body
    fontWeights: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 800,
    },
    fontSizes: {
      smallest: `0.512${unit}`,
      smaller: `0.64${unit}`,
      small: `0.8${unit}`,
      regular: `1${unit}`,
      h5: `1.25${unit}`,
      h4: `1.563${unit}`,
      large: `1.953${unit}`,
      larger: `2.441${unit}`,
      largest: `3.052${unit}`,
    },
    border: {
      size: '1px',
      style: 'solid',
      color: '#E7E8E8',
      radius: '8px',
    },
    breakpoints: {
      sm: `25${unit}`, // 400px
      md: `50${unit}`, // 800px
    },
    colors: {
      brandPurple: '#E0CCEF',
      brightUbe: '#D1B0EB',
      japaneseIndigo: '#283E4D',
      text: '#283E4C',
      green: '#56BEBA',
      backgroundBlue: '#2883C6',
      textBlue: '#276EBE',
      blueJeans: '#63ABDF',
      cream: '#F8F7F3',
      red: '#FF7264',
      yellow: '#EDBE62',
      lighterRed: '#FFE9DD',
      lighterBackgroundBlue: '#C9DAEE',
      lightBackground: '#F2F3F5',
      lightPurple: '#F2EAF8',
      lightRed: '#ED9462',
      lightGreen: '#A9DBE5',
      lightBackgroundBlue: '#9CCBED',
      ghostWhite: '#F6F6FC',
      lightYellow: '#F8E5C0',
      darkPurple: '#C5A9DB',
      darkerPurple: '#9465B9',
      darkestPurple: '#743F9D',
      brown: '#94887E',
      dimGray: '#6A6A6A',
      slateGray: '#6E808B',
      darkGray: '#293E4C',
      gray: '#909A9F',
      gray50: '#E7E8E8',
      lightGray: '#CED2D2',
      lightestGray: '#F6F6F6',
      brightGray: '#EAECF6',
      black: '#333333',
      white: '#FFFFFF',
    },
    stripeColors: {
      red: '#EB1C26',
    },
    tagColors: {
      brandPurple: '#E0CCEF',
      green: '#56BEBA',
      cream: '#F8F7F3',
      red: '#FF7264',
      yellow: '#EDBE62',
      lightPurple: '#F3ECF9',
      lightRed: '#ED9462',
      lightGreen: '#A9DBE5',
      lightBackgroundBlue: '#9CCBED',
      lightYellow: '#F8E5C0',
      brown: '#94887E',
      gray: '#909A9F',
      lightGray: '#CED2D2',
    },
  },
} as const;

export type Theme = typeof theme;
