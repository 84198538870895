import { BaseFmhcCtaCard, BaseFmhcCtaSkeleton } from './BaseFmhcCtaCard';
import { useViewModel } from './useViewModel/useViewModel';

export const SmartFmhcCta = () => {
  const vm = useViewModel();

  return vm.loading ? (
    <BaseFmhcCtaSkeleton />
  ) : (
    <BaseFmhcCtaCard href={vm.href} title={vm.title} label={vm.label} isEnabled={vm.isEnabled} />
  );
};
