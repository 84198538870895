import { sanitize } from 'dompurify';
import { marked } from 'marked';

import { type SanitizedHTML, type TemplateVariables } from '../types';
import { replaceStringVariables } from './replaceStringVariables';

type parseMarkdownArgs = {
  template: string;
  variables?: TemplateVariables;
};

/**
 * Given a string with '{{}}' that are considered variables, this function replaces those values with variables provider.
 * If some variables are not provided this function will throw an error.
 * If the string has a markdown format, it will be transformed into an HTML tag, in addition we sanitize HTML that string to avoid XSS attacks
 * This will Not parse new line items. Ex. Any lines that include an `*` at the start will not be parsed correctly
 * @param props.template string
 * @param props.variables object with variables to be replaced
 *
 * @example
 * ```
 * parseInlineMarkdown({ template: 'This is an example with the child {{child_name}}', variables: { child_name: 'Noah' } }); // => '<p>This is an <strong>example</strong> with the child Noah</p>'
 * ```
 */
export const parseInlineMarkdown = ({ template, variables }: parseMarkdownArgs): SanitizedHTML => {
  const templateWithVariables = replaceStringVariables({ text: template, variables });
  const html = marked.parseInline(templateWithVariables);
  const sanitizedHTML = sanitize(html);
  return sanitizedHTML;
};

/**
 * Given a string with '{{}}' that are considered variables, this function replaces those values with variables provider.
 * If some variables are not provided this function will throw an error.
 * If the string has a markdown format will be transformed into an HTML tag, in addition we sanitize HTML that string to avoid XSS attacks
 * @param props.template string
 * @param props.variables object with variables to be replaced
 *
 * @example
 * ```
 * parseFullMarkdown({ template: 'This is an example with the child {{child_name}}', variables: { child_name: 'Noah' } }); // => '<p>This is an <strong>example</strong> with the child Noah</p>'
 * ```
 */
export const parseFullMarkdown = ({ template, variables }: parseMarkdownArgs): SanitizedHTML => {
  const templateWithVariables = replaceStringVariables({ text: template, variables });
  const html = marked.parse(templateWithVariables);
  const sanitizedHTML = sanitize(html);
  return sanitizedHTML;
};
