import { gql } from '@apollo/client';

export const CARE_SESSION_NOTE_QUERY = gql`
  query CareSessionNoteQuery($pk: String!) {
    treatmentNote(pk: $pk) {
      pk
      noteName
      datetime
      summary {
        text
        answer
      }
      provider {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
`;
