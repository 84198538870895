/**
 * Should be thrown when GraphQL data returned from a query is required but not
 * defined
 */
export class MissingQueryDataError extends Error {
  constructor(queryName: string) {
    super(`Missing data in ${queryName} response`);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MissingQueryDataError);
    }

    this.name = 'MissingQueryDataError';
  }
}
