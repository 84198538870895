import { math } from 'polished';
import styled from 'styled-components';

import arrowUp from './arrowUp.svg';

export const StyledArrowUp = styled.img.attrs({
  src: arrowUp,
})`
  color: ${({ theme }) => theme.deprecated_.colors.white};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.75`)};
`;

export const StyledContainerArrowUp = styled.div<{
  $hide: boolean;
}>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-items: center;
  bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.625`)};
  right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.625`)};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.deprecated_.colors.gray}CC;
  transition-property: background-color, opacity, visibility;
  transition-duration: 0.2s;
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.5`)};
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.5`)};

  &:hover {
    background-color: ${({ theme }) => theme.deprecated_.colors.gray};
  }

  @media (max-width: ${(props) => props.theme.deprecated_.breakpoints.md}) {
    bottom: ${({ theme }) => `max( 
      calc(env(safe-area-inset-bottom) + ${math(`5 * ${theme.deprecated_.sizeBasis}`)}), 
      ${math(`5 * ${theme.deprecated_.sizeBasis}`)} 
    )`};
  }

  ${({ $hide }) =>
    $hide &&
    `
        visibility: hidden;
        opacity: 0;
    `}
`;
