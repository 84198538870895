import { math } from 'polished';
import { type ChangeEvent, type FC, type ReactNode } from 'react';
import { type FieldErrors } from 'react-hook-form6';
import styled from 'styled-components';

interface StyledInputProps {
  hasError: boolean;
  hasIcon: boolean;
  hasLabel: boolean;
}

const StyledLabel = styled.label`
  font-weight: ${(props) => props.theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  line-height: 1.5;
  cursor: pointer;
  width: 100%;
`;

const StyledLabelDiv = styled.div`
  line-height: 1.2;
`;

const StyledError = styled.small`
  display: block;
  color: ${({ theme }) => theme.deprecated_.colors.red};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  padding-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 3`)};
`;

const StyledIcon = styled.div`
  color: ${(props) => props.theme.deprecated_.colors.gray};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h4};
  line-height: ${({ theme }) => theme.deprecated_.fontSizes.h4};
  position: absolute;
  left: ${({ theme }) => theme.deprecated_.sizeBasis};
  top: 50%;
  transform: translateY(-50%);

  > * {
    /* avoid inline children elements from messing up the layout
    due to vertical alignment, line height, etc  */
    display: block;
  }
`;

const StyledInput = styled.input<StyledInputProps>`
  border: ${(props) =>
    `${props.theme.deprecated_.border.size} ${props.theme.deprecated_.border.style} ${
      props.hasError ? props.theme.deprecated_.colors.red : props.theme.deprecated_.border.color
    }`};
  border-radius: ${(props) => props.theme.deprecated_.border.radius};
  padding: ${(props) => props.theme.deprecated_.sizeBasis};
  padding-left: ${(props) =>
    props.hasIcon ? math(`${props.theme.deprecated_.sizeBasis} * 3.5`) : props.theme.deprecated_.sizeBasis};
  margin-top: ${(props) => (props.hasLabel ? props.theme.deprecated_.sizeBasis : 0)};
  transition: all 0.2s linear;
  width: 100%;
  background-color: ${(props) =>
    props.disabled ? props.theme.deprecated_.colors.lightestGray : props.theme.deprecated_.colors.white};
  overflow-x: hidden;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};

  &:focus {
    border-color: ${(props) => props.theme.deprecated_.colors.text};
    outline-color: ${(props) => (props.hasError ? props.theme.deprecated_.colors.red : '')};
  }
`;

export type InputProps = {
  type?: 'text' | 'number' | 'password' | 'email' | 'date' | 'file' | 'tel';
  label?: ReactNode;
  name: string;
  onChange?: (value: string | number) => void;
  onCustomChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  accept?: string;
  value?: number | string;
  defaultValue?: number | string;
  errors?: FieldErrors;
  placeholder?: string;
  icon?: ReactNode;
  spellCheck?: boolean;
  readOnly?: boolean;
  onClick?: () => void;
};

export const Input: FC<InputProps> = ({
  type = 'text',
  label,
  name,
  onChange,
  errors,
  onCustomChange,
  icon,
  ...props
}) => (
  <StyledLabel>
    {label && <StyledLabelDiv>{label}</StyledLabelDiv>}
    {icon && <StyledIcon>{icon}</StyledIcon>}
    <StyledInput
      name={name}
      type={type}
      hasLabel={!!label}
      hasIcon={!!icon}
      onChange={(event) => {
        if (onCustomChange) {
          onCustomChange(event);
        }
        if (onChange) {
          onChange(event.target.value);
        }
      }}
      hasError={!!errors?.[name]}
      {...props}
    />
    {errors?.[name] && <StyledError>{errors?.[name]?.message}</StyledError>}
  </StyledLabel>
);
