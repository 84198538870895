import { gql } from '@apollo/client';

export const FAMILY_DATA_QUERY = gql`
  query FamilyDataQuery($familyId: Int!) {
    family(familyId: $familyId) {
      id
      caregivers {
        id
        firstName
        worryDomains {
          edges {
            node {
              pk
              name
            }
          }
        }
      }
      children {
        id
        firstName
        lastName
        isSeekingCare
        pronouns
        gender
        dateOfBirth
        worryDomains {
          edges {
            node {
              pk
              name
            }
          }
        }
      }
      worryDomains {
        edges {
          node {
            pk
            name
          }
        }
      }
    }
  }
`;
