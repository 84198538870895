import { type CSSProperties, type FC, type PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Button, Link, type ButtonProps, type LinkProps } from '@littleotter/legacy-components';

export const MarginChildren = styled.div`
  > * {
    margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;

export const SubText = styled.p<{ noExtraMarginBottom?: boolean }>`
  color: ${(props) => props.theme.deprecated_.colors.gray};
  ${({ noExtraMarginBottom, theme }) =>
    !noExtraMarginBottom && `margin-bottom: calc(3 * ${theme.deprecated_.sizeBasis});`}
`;

const nextButtonStyles = css`
  width: 100%;
  max-width: calc(16 * ${(props) => props.theme.deprecated_.sizeBasis});
  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    margin-top: calc(4 * ${(props) => props.theme.deprecated_.sizeBasis});
  }
`;

const NextButtonComponent = styled(Button)`
  ${nextButtonStyles}
`;

const NextLinkComponent = styled(Link)`
  ${nextButtonStyles}
`;

const NextButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const NextButton: FC<PropsWithChildren<ButtonProps & { onClick?: () => void; style?: CSSProperties }>> = (
  props
) => (
  <NextButtonWrapper>
    <NextButtonComponent {...props} />
  </NextButtonWrapper>
);

export const NextLink: FC<PropsWithChildren<LinkProps>> = (props) => (
  <NextButtonWrapper>
    <NextLinkComponent {...props} />
  </NextButtonWrapper>
);
