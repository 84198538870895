import styled from 'styled-components';

export const Root = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  border: ${({ theme }) => `1px solid ${theme.deprecated_.colors.lightGray}`};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing['06']};
  position: relative;
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.regular};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  letter-spacing: normal;
`;

export const ImpactOverTimeHeader = styled.h5`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
`;

export const ContextContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing['06']} ${theme.spacing['03']} 0 ${theme.spacing['03']}`};
`;
