import { type ApolloError } from '@apollo/client';

export class MultipleApolloError extends Error {
  // Declaring the property here results in a difficult-to-deal-with storybook error,
  // due to mismatching babel versions, @babel/plugin-proposal-class-properties,
  // and webpack 5 javascript module config.
  // Possibly related:
  // https://github.com/storybookjs/storybook/issues/14197
  // https://github.com/storybookjs/storybook/issues/15680

  // TODO: Uncomment once the issue is solved.
  // errors: ApolloError[];

  constructor(errors: ApolloError[]) {
    super(`Multiple Apollo Errors: ${errors.map((error) => error.message).join(', ')}`);

    this.name = 'MultipleApolloError';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.errors = errors;
  }
}
