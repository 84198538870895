import { type FC, type PropsWithChildren } from 'react';

import { CardContainer, StyledArrowRight } from './styled';

export type CardProps = {
  className?: string;
  marginTop?: string;
  onClick: () => void;
};

export const Card: FC<PropsWithChildren<CardProps>> = ({ children, marginTop, className, onClick }) => (
  <CardContainer onClick={onClick} marginTop={marginTop} className={className}>
    <div>{children}</div>
    <StyledArrowRight />
  </CardContainer>
);
