import { gql, useQuery } from '@apollo/client';

import { VIEWER_FAMILY_CHILDREN_WORRY_DOMAINS_FRAGMENT } from '../../../graphql/worry-domains';
import { type OnboardingWorryDomainsQuery } from './__generated__/OnboardingWorryDomainsQuery';

const ONBOARDING_WORRY_DOMAINS_QUERY = gql`
  query OnboardingWorryDomainsQuery {
    viewer {
      ...ViewerFamilyChildrenWorryDomainsFragment
    }
  }

  ${VIEWER_FAMILY_CHILDREN_WORRY_DOMAINS_FRAGMENT}
`;

export const useOnboardingWorryDomainsQuery = () =>
  useQuery<OnboardingWorryDomainsQuery>(ONBOARDING_WORRY_DOMAINS_QUERY, { fetchPolicy: 'no-cache' });
