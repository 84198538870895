import { type FC } from 'react';

import { HTMLContainer } from '../../../HTMLContainer';
import { type CustomWidgetProps } from '../types';
import { Wrapper } from './styled';

export type HelpTextWidgetOptions = {
  content: string;
};

type HelpTextWidgetProps = CustomWidgetProps<HelpTextWidgetOptions>;

export const HelpTextWidget: FC<HelpTextWidgetProps> = ({ options }) => {
  const { content } = options as HelpTextWidgetOptions;

  return (
    <Wrapper>
      <HTMLContainer content={content} />
    </Wrapper>
  );
};
