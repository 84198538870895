import { gql } from '@apollo/client';

export const SUBSCALE_CONTENT_FRAGMENT = gql`
  fragment SubScaleContentFragment on SubScaleContentType {
    meaning
    whatICanDoToSupport
    activityToDo
  }
`;

export const SUBSCALE_RESULT_FRAGMENT = gql`
  fragment SubScaleResultFragment on SubScaleResultType {
    score
    risk
    content {
      ...SubScaleContentFragment
    }
  }
  ${SUBSCALE_CONTENT_FRAGMENT}
`;

export const BACK_TO_SCHOOL_REPORT_QUERY = gql`
  query BackToSchoolReportQuery($uuid: String!) {
    assessmentsNamespace {
      assessmentResponse(uuid: $uuid) {
        id
        updatedAt
        result {
          ...BackToSchoolReportFragment
        }
      }
    }
  }

  fragment ChallengeResultFragment on ChallengeResultType {
    score
    risk
    riskThreshold
    scorePercentiles
    content {
      ...SubScaleContentFragment
    }
  }

  fragment ScoreAndRiskFragment on ScoreAndRiskType {
    score
    risk
  }

  fragment ScoreAndContentFragment on ScoreAndContentType {
    score
    content {
      ...SubScaleContentFragment
    }
  }

  fragment WordFrament on WordType {
    text
    size
  }

  fragment ResponseChildInfoFragment on ResponseChildInfoType {
    childAge
    childName
    caregiverName
  }

  fragment GeneralRiskFragment on GeneralRiskType {
    childSocialEmotional {
      ...ScoreAndRiskFragment
    }
    caregiverMentalHealth {
      ...SubScaleResultFragment
    }
    familyStress {
      ...SubScaleResultFragment
    }
    schoolFeelings {
      ...ScoreAndRiskFragment
    }
  }

  fragment BackToSchoolReportFragment on BackToSchoolReportType {
    childInfo {
      ...ResponseChildInfoFragment
    }
    generalRisks {
      ...GeneralRiskFragment
    }
    emotionalChallenges {
      ...ChallengeResultFragment
    }
    socialChallenges {
      ...ChallengeResultFragment
    }
    caregiverAnxiety {
      ...ScoreAndRiskFragment
    }
    caregiverDepression {
      ...ScoreAndRiskFragment
    }
    familyStress {
      ...SubScaleResultFragment
    }
    socialStrengths {
      ...ScoreAndContentFragment
    }
    schoolAnxiety {
      ...ScoreAndContentFragment
    }
    newSchool {
      ...ScoreAndContentFragment
    }
    teacherCalled {
      ...ScoreAndContentFragment
    }
    wordCloud {
      ...WordFrament
    }
  }
  ${SUBSCALE_CONTENT_FRAGMENT}
  ${SUBSCALE_RESULT_FRAGMENT}
`;
