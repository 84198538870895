import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import styled from 'styled-components';

const Container = styled.div`
  display: inline;
  position: relative;
  user-select: none;
  cursor: pointer;
`;

const CloseIcon = styled(AiFillCloseCircle)`
  position: absolute;
  top: ${({ theme }) => math(`-${theme.deprecated_.sizeBasis} * 0.4`)};
  right: ${({ theme }) => math(`-${theme.deprecated_.sizeBasis} * 0.4`)};
  color: ${({ theme }) => theme.deprecated_.colors.red};
  background: ${({ theme }) => theme.deprecated_.colors.white};
  border: 1px;
  border-style: solid;
  border-radius: 50%;
  box-sizing: content-box;
  border-color: ${({ theme }) => theme.deprecated_.colors.white};
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
`;

export type RemovableElementProps = {
  onClick?: () => void;
};

export const RemovableElement: FC<PropsWithChildren<RemovableElementProps>> = ({ onClick, children }) => (
  <Container onClick={onClick}>
    <CloseIcon />
    {children}
  </Container>
);
