import { type FC, type PropsWithChildren } from 'react';

import * as Styled from './styled';

export const DomainList: FC<PropsWithChildren<DomainListProps>> = ({ concerning, domains }) => {
  const title = concerning ? 'Concerning' : 'Typical';
  return (
    <Styled.Root>
      <Styled.Header concerning={concerning}>{title}</Styled.Header>
      <Styled.List>
        {domains.map((d) => (
          <li key={d}>{d}</li>
        ))}
      </Styled.List>
    </Styled.Root>
  );
};

export interface DomainListProps {
  concerning?: boolean;
  domains: string[];
}
