import { useEffect, useRef } from 'react';

export const useTimeout = (callback: () => void, delay: number | null) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
    }
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, [delay]);
};
