import { math } from 'polished';
import { useEffect, type FC } from 'react';
import styled from 'styled-components';

import { Herb1Image, Herb2Image, Herb3Image, KoalasImage } from './components';
import { animationDurationMs, baseAnimationDelayMs, runningAnimationDelayMs } from './constants';

const Container = styled.div`
  position: relative;
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 17.4`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 14`)};
`;

type KoalaAnimationProps = {
  onAnimationCompletion?: () => void;
};

export const KoalaAnimation: FC<KoalaAnimationProps> = ({ onAnimationCompletion }) => {
  const totalDelay = baseAnimationDelayMs + 3 * (animationDurationMs + runningAnimationDelayMs);
  useEffect(() => {
    if (onAnimationCompletion) {
      setTimeout(onAnimationCompletion, totalDelay);
    }
  }, [onAnimationCompletion, totalDelay]);

  return (
    <Container>
      <Herb1Image />
      <Herb2Image />
      <Herb3Image />
      <KoalasImage />
    </Container>
  );
};
