import { motion } from 'framer-motion';
import { math } from 'polished';
import { useState, type FC, type PropsWithChildren } from 'react';
import { BsX } from 'react-icons/bs';
import styled from 'styled-components';

import { Button, Icon } from '@littleotter/legacy-components';

import { NavLinks } from '../NavLinks';

const StyledButton = styled(Button)`
  position: fixed;
  top: ${({ theme }) => theme.deprecated_.sizeBasis};
  right: ${({ theme }) => theme.deprecated_.sizeBasis};
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  z-index: 9998;
`;

const StyledNav = styled(motion.nav)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 33vw;
  z-index: 9999;
`;

const CloseIcon = styled(motion.div)`
  position: absolute;
  top: ${({ theme }) => theme.deprecated_.sizeBasis};
  right: ${({ theme }) => theme.deprecated_.sizeBasis};
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  cursor: pointer;
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;
const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '100%' },
};

type DesktopNavProps = {
  isStaff?: boolean;
  isInAllowedState?: boolean;
};

export const DesktopNav: FC<PropsWithChildren<DesktopNavProps>> = ({ isStaff, isInAllowedState }) => {
  const [panelOpen, setPanelOpen] = useState(false);

  return (
    <>
      <StyledButton variant="clearStyles" onClick={() => setPanelOpen(true)}>
        <Icon name="Menu" />
      </StyledButton>
      <StyledNav animate={panelOpen ? 'open' : 'closed'} variants={variants} initial={false} role="navigation">
        <NavLinks handleCloseNav={() => setPanelOpen(false)} isStaff={isStaff} isInAllowedState={isInAllowedState} />
        <CloseIcon variants={variants} onClick={() => setPanelOpen(false)}>
          <BsX />
        </CloseIcon>
      </StyledNav>
    </>
  );
};
