import { type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { Header, Icon, Link } from '@littleotter/legacy-components';

import { HeaderCenterSection, HeaderContainer, HeaderIconContainer, HeaderLeftSection } from './styled';

type ConversationHeaderProps = {
  title: string;
  subtitle?: string;
};

export const ConversationHeader: FC<PropsWithChildren<ConversationHeaderProps>> = ({ title, subtitle }) => {
  const history = useHistory();
  return (
    <HeaderContainer>
      <HeaderLeftSection>
        <Link
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
          variant="clearStyles"
        >
          <HeaderIconContainer>
            <Icon name="ArrowLeft" />
          </HeaderIconContainer>
        </Link>
      </HeaderLeftSection>
      <HeaderCenterSection>
        <Header as="h5">{title}</Header>
        {subtitle && <strong>{subtitle}</strong>}
      </HeaderCenterSection>
    </HeaderContainer>
  );
};
