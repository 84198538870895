type ProviderTitleList = {
  code: string;
  name: string;
}[];

export const getProviderTitleNameFromCode = (code: string): string | null =>
  providerTitleList.find((providerTitle) => providerTitle.code === code)?.name ?? null;

const providerTitleList: ProviderTitleList = [
  {
    code: 'parent_coach',
    name: 'Parent Coach',
  },
];
