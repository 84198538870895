import { useEffect, useRef, useState, type FC, type PropsWithChildren } from 'react';

import { useGraphQLErrorHandling } from '$shared/hooks';
import { WithPrerequisites } from '$shared/utils/rerouter/WithPrerequisites';

import { useHasPaidFirstFmhcQuery } from '../../../../graphql/lo1/generated';
import { routes } from '../../../../routes';
import { PaymentConfirmation } from './PaymentConfirmation';

export const PaymentConfirmationWithPrerequisites: FC<PropsWithChildren> = () => {
  const { loading, data, error, refetch } = useHasPaidFirstFmhcQuery({
    fetchPolicy: 'no-cache',
  });
  useGraphQLErrorHandling(error);

  // Retry for RETRY_TIMEOUT duration or until we know the use has paid
  const RETRY_DURATION = 10_000;
  const [retrying, setRetrying] = useState<boolean>(true);
  // WARNING: the retry useeffect loop needs to use REFS or it will run forever and ever.
  // The loading and retrying REFS should be updated BEFORE the state vars
  const retryingRef = useRef(retrying);
  const loadingRef = useRef(loading);
  const timer = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    // stop retrying after timeout
    timer.current = setTimeout(() => {
      retryingRef.current = false;
      setRetrying(false);
    }, RETRY_DURATION);
    return () => clearTimeout(timer.current);
  }, []);
  useEffect(() => {
    // stop retrying if the user has paid
    if (data) {
      if (data.HasPaidFirstFmhc.hasPaid) {
        clearTimeout(timer.current);
        retryingRef.current = false;
        setRetrying(false);
      } else {
        retryingRef.current = true;
        setRetrying(true);
      }
    }
  }, [data]);
  useEffect(() => {
    retryingRef.current = retrying;
  }, [retrying]);
  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);
  useEffect(() => {
    (async () => {
      while (retryingRef.current) {
        // Do not run concurrent refetches
        if (!loadingRef.current) {
          refetch();
        }

        await ((ms: number) => new Promise((r) => setTimeout(r, ms)))(1_000);
      }
    })();
  }, [refetch]);
  const needsToPay = !data?.HasPaidFirstFmhc.hasPaid;

  const nextRoute =
    data?.HasPaidFirstFmhc.hasPaid && data.HasPaidFirstFmhc.firstFmhcId
      ? routes.reports.fmhcs.fmhc.url({ fmhcId: data.HasPaidFirstFmhc.firstFmhcId })
      : routes.care.home.url();

  return (
    <WithPrerequisites
      prerequisites={[{ predicate: needsToPay, route: routes.care.home, noRedirect: true }]}
      loading={loading || retrying}
    >
      <PaymentConfirmation nextRoute={nextRoute} />
    </WithPrerequisites>
  );
};
