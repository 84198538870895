import { type FC } from 'react';

import { StyledBackArrow, StyledLogo, StyledNavbar } from './styled';

export type NavbarProps = {
  showLogo?: boolean;
  dark?: boolean;
  goBack?: () => void;
  removeShadow?: boolean;
  bigHeight?: boolean;
  hasExtraIosPadding?: boolean;
};

export const Navbar: FC<NavbarProps> = ({
  showLogo = false,
  dark = false,
  removeShadow = false,
  bigHeight = false,
  hasExtraIosPadding = false,
  goBack,
}) => {
  return (
    <StyledNavbar
      $removeShadow={removeShadow}
      $bigHeight={bigHeight}
      $dark={dark}
      $hasExtraIosPadding={hasExtraIosPadding}
    >
      {!!goBack && <StyledBackArrow $dark={dark} $hasExtraIosPadding={hasExtraIosPadding} onClick={() => goBack()} />}
      <StyledLogo $showLogo={showLogo} />
      <span>Little Otter</span>
    </StyledNavbar>
  );
};
