import { type FC, type PropsWithChildren } from 'react';

import * as Styled from './styled';

export const WorryDomains: FC<PropsWithChildren<WorryDomainsProps>> = ({ worryDomains }) => {
  return (
    <Styled.Root>
      <Styled.Title>Worries</Styled.Title>
      <Styled.WorriesContainer>
        {worryDomains.map((worry) => (
          <Styled.Worry key={worry}>{worry}</Styled.Worry>
        ))}
      </Styled.WorriesContainer>
    </Styled.Root>
  );
};

export interface WorryDomainsProps {
  worryDomains: string[];
}
