import { gql } from '@apollo/client';

export const PROVIDER_FRAGMENT = gql`
  fragment ProviderFragment on UserType {
    id
    firstName
    lastName
    profilePicture
    providerTitle
    externalProviderId
  }
`;
