import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/legacy-components';

import { interpolatingLinearFunction } from '$shared/utils/interpolatingLinearFunction';

import { type PercentilePoints } from '../../../../components/AssessmentReportBarChart';
import { ChallengeRisks } from '../../../../graphql/__generated__/globalTypes';
import {
  type HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_childEmotionAndBehavior_totalBehavioral,
  type HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_childEmotionAndBehavior_totalEmotional,
} from '../../../../graphql/__generated__/HolidayReportQuery';
import { CollapseSection } from './CollapseSection';
import { CenteredAssessmentReportBarChart, Section } from './styled';

type ChildEmotionalAndSocialHealthProps = {
  emotionalChallengesResult: HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_childEmotionAndBehavior_totalEmotional | null;
  behavioralChallengesResult: HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_childEmotionAndBehavior_totalBehavioral | null;
};

const generateChartData = ({
  emotionalChallengesResult,
  behavioralChallengesResult,
}: ChildEmotionalAndSocialHealthProps) => {
  const result = [];
  if (emotionalChallengesResult) {
    const fitEmotionalScoreToPercentiles = interpolatingLinearFunction(
      emotionalChallengesResult.scorePercentiles as PercentilePoints
    );
    const emotionalData = {
      id: 'Emotional Challenges',
      value: fitEmotionalScoreToPercentiles(emotionalChallengesResult.score),
    };
    result.push(emotionalData);
  }

  if (behavioralChallengesResult) {
    const fitBehavioralScoreToPercentiles = interpolatingLinearFunction(
      behavioralChallengesResult.scorePercentiles as PercentilePoints
    );
    const behavioralData = {
      id: 'Behavorial Challenges',
      value: fitBehavioralScoreToPercentiles(behavioralChallengesResult.score),
    };
    result.push(behavioralData);
  }

  return result;
};

const generateEmotionalChallengesText = (
  emotionalChallengesResult: HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_childEmotionAndBehavior_totalEmotional | null
) => {
  if (!emotionalChallengesResult) {
    return null;
  }
  if (emotionalChallengesResult.clinical === ChallengeRisks.typical) {
    return (
      <>
        <strong>Typical emotional challenges</strong> for your child’s age.
      </>
    );
  }
  return (
    <>
      <strong>Higher level of emotional challenges</strong> for your child’s age.
    </>
  );
};

const generateBehavioralChallengesText = (
  behavioralChallengesResult: HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_childEmotionAndBehavior_totalBehavioral | null
) => {
  if (!behavioralChallengesResult) {
    return null;
  }
  if (behavioralChallengesResult.clinical === ChallengeRisks.typical) {
    return (
      <>
        <strong>Typical behavioral challenges</strong> for your child’s age.
      </>
    );
  }
  return (
    <>
      <strong>Higher level of behavioral challenges</strong> for your child’s age.
    </>
  );
};

export const ChildEmotionalAndBehavioralHealth: FC<PropsWithChildren<ChildEmotionalAndSocialHealthProps>> = ({
  emotionalChallengesResult,
  behavioralChallengesResult,
}) => {
  const concernThreshold = 80;

  return (
    <Section>
      <Header as="h4" id="child-emotional-and-behavioral-health">
        Child Emotion &amp; Behavior
      </Header>
      <p>
        {generateEmotionalChallengesText(emotionalChallengesResult)}
        <br />
        {generateBehavioralChallengesText(behavioralChallengesResult)}
      </p>

      <CenteredAssessmentReportBarChart
        data={generateChartData({ emotionalChallengesResult, behavioralChallengesResult })}
        concernThreshold={concernThreshold}
        yMax={100}
        yGridInterval={20}
      />

      {emotionalChallengesResult && (
        <CollapseSection
          sectionTitle="Emotional Challenges"
          meaningContent={emotionalChallengesResult.content.meaning}
          whatICanDoToSupportContent={emotionalChallengesResult.content.whatICanDoToSupport}
          activityToDoContent={emotionalChallengesResult.content.activityToDo}
        />
      )}
      {behavioralChallengesResult && (
        <CollapseSection
          sectionTitle="Behavioral Challenges"
          meaningContent={behavioralChallengesResult.content.meaning}
          whatICanDoToSupportContent={behavioralChallengesResult.content.whatICanDoToSupport}
          activityToDoContent={behavioralChallengesResult.content.activityToDo}
        />
      )}
    </Section>
  );
};
