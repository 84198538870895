import { type FetchResult } from '@apollo/client';
import { useCallback } from 'react';

import { useGraphQLErrorHandling } from '$shared/hooks';

import {
  useGetAssessmentDefinitionLazyQuery,
  useGetFmhcQuery,
  useMarkFmhcAsSubmittedMutation,
  useStartFmhcMutation,
  useSubmitAssessmentMutation,
  type AnswerInput,
  type Scalars,
  type SubjectInput,
} from '../../../graphql/lo1/generated';
import { gqlAssessmentDefinitionToQuestion } from '../mappers/assessmentDefinitionToQuestionMapper';
import { questionAnswersToGqlAnswerInput } from '../mappers/questionAnswersToGqlAnswers';
import { subjectToGqlSubjectInput } from '../mappers/subjectToGqlSubjectInput';
import {
  isChildSubject,
  SubjectType,
  type ChildSubject,
  type ChildTemplateVariables,
  type QuestionData,
  type SubjectAnswers,
  type SubjectQuestions,
  type TemplateVariables,
} from '../types';
import { parseInlineMarkdown } from '../utils/markdownParser';

enum AssessmentDefinitionID {
  Caregiver = 'caregiver',
  Family = 'family',
  Infant = 'infant',
  Toddler = 'toddler',
  Child = 'child',
}

const chooseChildAssessment = (childAgeInMonths: number): AssessmentDefinitionID => {
  // Under 1
  if (childAgeInMonths <= 11) {
    return AssessmentDefinitionID.Infant;
  }
  // Under 3
  if (childAgeInMonths <= 35) {
    return AssessmentDefinitionID.Toddler;
  }
  return AssessmentDefinitionID.Child;
};

const chooseAssessment = (subjectType: SubjectType) =>
  ({
    [SubjectType.Family]: AssessmentDefinitionID.Family,
    [SubjectType.Caregiver]: AssessmentDefinitionID.Caregiver,
    [SubjectType.Child]: '' as never,
  })[subjectType];

export const useSubmitAssessment = (): [
  (
    fmhcId: string,
    submitterId: string,
    submitterName: string,
    familyMemberAnswers: SubjectAnswers,
    version: string
  ) => Promise<FetchResult>,
  boolean,
] => {
  const [submitAssessmentMutation, { loading: submitAssessmentLoading }] = useSubmitAssessmentMutation();

  const submitAssessment = useCallback(
    async (
      fmhcId: string,
      submitterId: string,
      submitterName: string,
      subjectAnswers: SubjectAnswers,
      version: string
    ): Promise<FetchResult> => {
      const { subject: rawSubject, answers: rawSubjectAnswers } = subjectAnswers;
      const assessmentDefinitionId = isChildSubject(rawSubject)
        ? chooseChildAssessment(rawSubject.ageInMonths)
        : chooseAssessment(rawSubject.subjectType);
      const subject: SubjectInput = subjectToGqlSubjectInput(rawSubject);
      const answers: AnswerInput[] = questionAnswersToGqlAnswerInput(rawSubjectAnswers);
      return submitAssessmentMutation({
        variables: {
          request: { assessmentDefinitionId, subject, answers, fmhcId, submitterId, submitterName, version },
        },
      });
    },
    [submitAssessmentMutation]
  );
  return [submitAssessment, submitAssessmentLoading];
};

export const useGetChildQuestions = (): [(s: ChildSubject) => Promise<SubjectQuestions>, boolean] => {
  const [getAssessmentQuestionData, isLoading] = useGetAssessmentQuestionData();
  return [
    useCallback(
      async (s: ChildSubject) => {
        const childTemplateVariables: ChildTemplateVariables = { child_name: s.firstName };
        return {
          subject: s,
          questions: await getAssessmentQuestionData(chooseChildAssessment(s.ageInMonths), childTemplateVariables),
        };
      },
      [getAssessmentQuestionData]
    ),
    isLoading,
  ];
};

export const useGetFamilyQuestions = (): [() => Promise<SubjectQuestions>, boolean] => {
  const [getAssessmentQuestionData, isLoading] = useGetAssessmentQuestionData();
  return [
    useCallback(async () => {
      return {
        subject: {
          subjectType: SubjectType.Family,
        },
        questions: await getAssessmentQuestionData(chooseAssessment(SubjectType.Family)),
      };
    }, [getAssessmentQuestionData]),
    isLoading,
  ];
};

export const useGetCaregiverQuestions = (): [() => Promise<SubjectQuestions>, boolean] => {
  const [getAssessmentQuestionData, isLoading] = useGetAssessmentQuestionData();
  return [
    useCallback(async () => {
      return {
        subject: {
          subjectType: SubjectType.Caregiver,
        },
        questions: await getAssessmentQuestionData(chooseAssessment(SubjectType.Caregiver)),
      };
    }, [getAssessmentQuestionData]),
    isLoading,
  ];
};

const useGetAssessmentQuestionData = (): [
  (defId: AssessmentDefinitionID, templateVariables?: TemplateVariables) => Promise<QuestionData[]>,
  boolean,
] => {
  const [getAssessmentDefinition, { loading: assessmentDefinitionLoading }] = useGetAssessmentDefinitionLazyQuery();
  const getAssessmentQuestionData = useCallback(
    async (definitionId: AssessmentDefinitionID, templateVariables?: TemplateVariables): Promise<QuestionData[]> => {
      const { data } = await getAssessmentDefinition({
        variables: { request: { assessmentDefinitionId: definitionId } },
      });
      const possibleAssessment = data?.GetAssessmentDefinition?.assessment;
      if (!possibleAssessment) {
        return [];
      }

      const questionData = gqlAssessmentDefinitionToQuestion(possibleAssessment);

      return parseQuestionData(questionData, templateVariables);
    },
    [getAssessmentDefinition]
  );
  return [getAssessmentQuestionData, assessmentDefinitionLoading];
};

export const useMarkFmhcAsSubmitted = () => {
  const [markFmhcAsSubmittedMutation, { loading: markFmhcAsSubmittedLoading }] = useMarkFmhcAsSubmittedMutation();

  const markFmhcAsSubmitted = useCallback(
    async (fmhcId: string, submitterId: string, submitterName: string) => {
      return markFmhcAsSubmittedMutation({
        variables: { request: { fmhcId, submitterId, submitterName } },
      });
    },
    [markFmhcAsSubmittedMutation]
  );
  return {
    markFmhcAsSubmitted,
    markFmhcAsSubmittedLoading,
  };
};

// Function for mapping parseMaredkdown against each set of question data, for prompts and text
const parseQuestionData = (q: QuestionData[], templateVariables?: TemplateVariables): QuestionData[] => {
  return q.map((questionData) => {
    const result = questionData;
    if (result.question.promptOptions?.questionText) {
      result.question.promptOptions.questionText = parseInlineMarkdown({
        template: result.question.promptOptions.questionText,
        variables: templateVariables,
      });
    }

    if (result.question.promptOptions?.prompt) {
      result.question.promptOptions.prompt = parseInlineMarkdown({
        template: result.question.promptOptions.prompt,
        variables: templateVariables,
      });
    }

    if (result.question.singleSelectOptions?.questionText) {
      result.question.singleSelectOptions.questionText = parseInlineMarkdown({
        template: result.question.singleSelectOptions.questionText,
        variables: templateVariables,
      });
    }

    if (result.question.singleSelectOptions?.prompt) {
      result.question.singleSelectOptions.prompt = parseInlineMarkdown({
        template: result.question.singleSelectOptions.prompt,
        variables: templateVariables,
      });
    }
    return result;
  });
};

export const useStartFmhcSession = (): [
  (familyId: string, submitterId: string, submitterName: string) => Promise<Scalars['FmhcId']>,
  boolean,
] => {
  const [startFmhcMutation, { loading: fmhcIdLoading, error }] = useStartFmhcMutation();
  const startFmhcSession = useCallback(
    async (familyId: string, submitterId: string, submitterName: string): Promise<Scalars['FmhcId']> => {
      const { data } = await startFmhcMutation({ variables: { request: { familyId, submitterId, submitterName } } });
      const fmhcId = data?.StartFmhc?.fmhcId;
      return fmhcId ?? '';
    },
    [startFmhcMutation]
  );
  useGraphQLErrorHandling(error);
  return [startFmhcSession, fmhcIdLoading];
};

// uses GetFmhcQuery on the provided fmhcId to identify the FMHC version
export const useGetVersion = (fmhcId: string) => {
  const { data: fmhcData, loading: getVersionLoading } = useGetFmhcQuery({
    variables: { request: { fmhcId } },
    skip: !fmhcId,
  });

  return {
    version: fmhcData?.GetFmhc.fmhc.version,
    getVersionLoading,
  };
};
