import styled from 'styled-components';

import { Header } from '@littleotter/legacy-components';

export const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  padding: 1.5rem;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const CardTitle = styled(Header).attrs({ as: 'h3' })`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  line-height: 1;
  color: ${({ theme }) => theme.deprecated_.colors.lightGray};
`;
