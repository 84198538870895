import { type FC, type PropsWithChildren } from 'react';

import { AudioElement } from './AudioElement';
import { ChatBubbleAttachment, StyledImg, StyledLink } from './styled';
import { VideoElement } from './VideoElement';

export type AttachmentProps = {
  fileName: string | null | undefined;
  mediaUrl: string | null | undefined;
  contentType?: string;
  isOutgoing: boolean;
  isDeleted?: boolean;
  onClick?: () => void;
};

export const Attachment: FC<PropsWithChildren<AttachmentProps>> = ({
  fileName,
  mediaUrl,
  contentType,
  isOutgoing,
  isDeleted,
  onClick,
}) => {
  if (!mediaUrl || !contentType) {
    return null;
  }
  const isImage = /^image\/.+/.test(contentType);
  const isVideo = /^video\/.+/.test(contentType);
  const isAudio = /^audio\/.+/.test(contentType);

  return (
    <ChatBubbleAttachment isDeleted={isDeleted} isOutgoing={isOutgoing}>
      {isImage && <StyledImg src={mediaUrl} alt="attachment" />}
      {isVideo && <VideoElement mediaUrl={mediaUrl} />}
      {isAudio && <AudioElement mediaUrl={mediaUrl} />}
      {!isImage && !isVideo && !isAudio && (
        <StyledLink isOutgoing={isOutgoing} target="_blank" onClick={onClick}>
          {fileName ?? 'Link to File'}
        </StyledLink>
      )}
    </ChatBubbleAttachment>
  );
};
