import { math } from 'polished';
import styled from 'styled-components';

import { Button, ProfilePicture } from '@littleotter/legacy-components';

import calendar from '../../assets/calendar.svg';
import KoalaFamily from './KoalaFamily.svg';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.div<{
  haveUpcomingSessions: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0.68rem;
  margin-left: 1.21rem;
  color: ${({ theme, haveUpcomingSessions }) =>
    haveUpcomingSessions ? theme.deprecated_.colors.text : `${theme.deprecated_.colors.text}CC`};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
`;

export const StyledCalendarIcon = styled.img.attrs({
  src: calendar,
})``;

export const StyledTotal = styled.span`
  color: ${({ theme }) => theme.deprecated_.colors.text};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.875rem`)};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;

export const StyledContent = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.text}CC;
  margin: 1rem auto;
`;

export const StyledButton = styled(Button)`
  font-weight: bold;
  width: 100%;
`;

const StyledPlaceholder = styled.img.attrs({
  src: KoalaFamily,
})`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const ProviderImage = styled(ProfilePicture).attrs({
  Placeholder: StyledPlaceholder,
})`
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.575`)};
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.575`)};
  border: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.3`)} solid
    ${({ theme }) => theme.deprecated_.colors.lightestGray};
  margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const UpcomingSessionsUnorderedList = styled.ul`
  list-style: none;
  color: ${({ theme }) => theme.deprecated_.colors.text}99;
`;

export const UpcomingSessionItem = styled.li`
  margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

export const UpcomingSessionItemContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme }) => theme.deprecated_.colors.text};
`;

export const UpcomingSessionItemContent = styled.p`
  display: inline-block;
  font-weight: bold;
`;
