import styled from 'styled-components';

export const InsuranceAuthCard = styled.div`
  background: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  border-radius: 8px;
  margin: 10px 0;
  padding: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  > div:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const StyledLabelPrimary = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.darkGray};
`;

export const StyledLabelSecondary = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;
