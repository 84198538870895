import { gql } from '@apollo/client';

export const DELETE_USER_CHILD_MUTATION = gql`
  mutation DeleteUserChild($childId: Int!) {
    deleteChildFromFamily(childId: $childId) {
      ... on FamilyType {
        id
        children {
          id
        }
      }
      ... on DeleteChildFromFamilyError {
        message
      }
    }
  }
`;
