import { type CartesianMarkerProps } from '@nivo/core';
import { ResponsiveLine, type LineSvgProps } from '@nivo/line';
import { compareAsc } from 'date-fns';
import { useMemo, type FC, type PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import { type Theme } from '@littleotter/legacy-components';

import { type TimeSeries } from '../../types';
import { dateToString } from '../dateUtils';
import { MAX_X_AXIS, MIN_X_AXIS, NUM_DATA_POINTS, THRESHOLD_X_VALUES } from '../threshold/constants';
import { getThresholdMarker } from '../threshold/marker';
import { thresholdLayerFactory } from '../threshold/ThresholdLayer';
import * as Styled from './styled';

export interface ImpactOverTimeLineProps {
  timeSerieses: TimeSeriesWithLabel[];
  threshold: number;
  maxScore: number;
}

export type TimeSeriesWithLabel = {
  timeSeries: TimeSeries;
  label: string;
};

type GraphData = {
  id: string;
  data: { x: number; y: number | null }[];
  color: string;
};

/**
 * DomainScoreOverTime renders a time series for a domain score with a threshold line and area.
 *
 * The graph has a fixed number of x-axis point and will render each point in the time series data equidistant
 * from each other, regardless of what the actual timestamps are.
 *
 * LIMITATIONS: this only works for a single time series. When we have multiple informants and multiple
 * time series we want to plot on the same chart, we will need to re-design and re-implement the x-axis
 * for this component.
 */
export const ImpactOverTimeLine: FC<PropsWithChildren<ImpactOverTimeLineProps>> = ({
  timeSerieses,
  threshold,
  maxScore,
}) => {
  const {
    deprecated_: { colors, fontSizes, fontWeights },
  } = useTheme() as Theme;

  // HACK: the colors is hard-coded with three colors, because impact has three subscores.
  // This will fail to render as soon as we have more than three impact sub score time series.
  const [normalizedData, dates] = useMemo(
    () => normalizeData(timeSerieses, [colors.backgroundBlue, colors.green, colors.darkerPurple]),
    [colors, timeSerieses]
  );

  // configure axes and grid properties
  const indexToDateStr: { [key: number]: string } = dates.reduce(
    (res, date, index) => {
      res[index + 1] = dateToString(date);
      return res;
    },
    {} as { [key: number]: string }
  );
  const dateIndexAxis = dates.map((_, index) => index + 1);
  const yGridSpacing = threshold > 4 ? Math.round(threshold / 4) : 1;
  const yGridValues = Array.from({ length: Math.round(maxScore / yGridSpacing) }, (_, i) => yGridSpacing * i);
  const axesProperties: Partial<LineSvgProps> = {
    enableGridX: false,
    enableGridY: true,
    gridYValues: yGridValues,
    xScale: { type: 'linear', min: MIN_X_AXIS, max: MAX_X_AXIS },
    yScale: { type: 'linear', min: -0.5, max: maxScore },
    axisLeft: { tickValues: [] },
    axisBottom: {
      format: (idx: number) => indexToDateStr[idx],
      tickValues: dateIndexAxis,
      tickSize: 0,
      tickPadding: 5,
    },
  };

  const commonProperties: Partial<LineSvgProps> = {
    margin: { top: 20, right: 10, bottom: 30, left: 10 },
    theme: {
      background: colors.lightestGray,
    },
  };
  const lineProperties: Partial<LineSvgProps> = {
    colors: (d) => d.color,
    pointSize: 10,
    lineWidth: 2,
    pointBorderColor: { from: 'serieColor' },
  };

  // Define threshold layers - CustomLayer and Marker
  const markers: CartesianMarkerProps[] = [
    getThresholdMarker({
      label: 'concerning',
      maxScore,
      threshold,
      thresholdColor: colors.red,
      fontSize: fontSizes.smaller,
      fontWeight: fontWeights.bold,
    }),
  ];
  const thresholdLayer = thresholdLayerFactory({
    maxScore,
    threshold,
    thresholdXValues: THRESHOLD_X_VALUES,
    thresholdColor: colors.red,
  });
  return (
    <Styled.Root>
      <Styled.LabelContainer>
        {normalizedData.map((data) => (
          <Styled.Label key={data.id} color={data.color}>
            {data.id}
          </Styled.Label>
        ))}
      </Styled.LabelContainer>
      <Styled.GraphContainer>
        <ResponsiveLine
          {...commonProperties}
          {...axesProperties}
          {...lineProperties}
          curve="monotoneX"
          data={normalizedData}
          markers={markers}
          layers={['grid', thresholdLayer, 'lines', 'markers', 'axes', 'points', 'legends']}
        />
      </Styled.GraphContainer>
    </Styled.Root>
  );
};

/**
 * normalizeData makes sure that each time series has the same x-axis
 */
const normalizeData = (timeSerieses: TimeSeriesWithLabel[], colors: string[]): [GraphData[], Date[]] => {
  const sortedDates = timeSerieses
    .map((ts) => ts.timeSeries.map((d) => d.date))
    .reduce((agg, dates) => [...agg, ...dates], [])
    .filter((d, idx, self) => self.findIndex((d2) => d.getTime() === d2.getTime()) === idx)
    .sort((a, b) => compareAsc(a, b))
    .slice(-NUM_DATA_POINTS);

  const normalizedData = timeSerieses.map((ts, index) => ({
    id: ts.label,
    data: sortedDates
      .map((date, dateIndex) => ({
        x: dateIndex + 1,
        y: adjustValue(
          ts.timeSeries.find((d) => d.date.getTime() === date.getTime())?.value ?? null,
          -0.2 + 0.2 * index // offset in case of overlapping data points
        ),
      }))
      .filter((point) => point.y !== null),
    color: colors[index],
  }));
  return [normalizedData, sortedDates];
};

const adjustValue = (value: number | null, shift: number): number | null => {
  if (value === null) {
    return null;
  }
  return value + shift;
};
