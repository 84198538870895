import { math } from 'polished';
import styled from 'styled-components';

import { MarginChildren } from '../../../components';

export const MarginContainer = styled(MarginChildren)`
  width: 100%;
  padding: 0 ${({ theme }) => theme.deprecated_.sizeBasis};
  margin: ${({ theme }) => `${math(`${theme.deprecated_.sizeBasis} * 5`)} auto 0`};
  max-width: ${({ theme }) => theme.deprecated_.breakpoints.md};
`;
