import { useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { IntakeQAppointmentStatusEnum } from '../../../../graphql/__generated__/globalTypes';
import { type UpcomingAppointmentViewer } from '../../../../graphql/__generated__/UpcomingAppointmentViewer';
import { UPCOMING_APPOINTMENT_VIEWER } from '../../../../graphql/upcoming-appointment-viewer';
import { PROVIDER_QUERY } from '../../../PaymentDetails/WelcomeCallPaymentDetails/SessionDetails/hooks';
import { type ProviderQuery } from '../../../PaymentDetails/WelcomeCallPaymentDetails/SessionDetails/hooks/__generated__/ProviderQuery';
import { type UpcomingSession } from '../../types';
import { gqlSessionToUpcomingSession } from './mappers';

export type UseUpcomingSessionsReturn = {
  upcomingSessions: UpcomingSession[];
  loading: boolean;
};

export const useUpcomingSessions = (): UseUpcomingSessionsReturn => {
  const [providerId, setProviderId] = useState<string | undefined>(undefined);
  const { data, error, loading } = useQuery<UpcomingAppointmentViewer>(UPCOMING_APPOINTMENT_VIEWER);
  const {
    data: providerData,
    error: providerError,
    loading: providerLoading,
  } = useQuery<ProviderQuery>(PROVIDER_QUERY, { variables: { providerId }, skip: !providerId });
  useGraphQLErrorHandling(error, providerError);
  const upcomingSessions = (data?.viewer?.upcomingAppointments ?? []).map(gqlSessionToUpcomingSession);
  const rawOptimisticAppointmentDetails = Cookies.get('optimistic_appointment_details');
  const optimisticAppointmentDetails = JSON.parse(rawOptimisticAppointmentDetails ?? 'null');
  useEffect(() => {
    if (optimisticAppointmentDetails) {
      setProviderId(optimisticAppointmentDetails.providerId);
    }
  }, [optimisticAppointmentDetails, setProviderId]);
  const optimisticUpcomingSessions: UpcomingSession[] = useMemo(() => {
    // Check for matching appointment ID with opportunitistic upcoming session. If none,
    // then append optimistic upcoming session.
    if (
      !optimisticAppointmentDetails ||
      !providerData?.provider ||
      upcomingSessions.find((session) => session.id === optimisticAppointmentDetails.id)
    ) {
      return upcomingSessions;
    }
    const optimisticUpcomingSession: UpcomingSession = {
      id: optimisticAppointmentDetails.id,
      status: IntakeQAppointmentStatusEnum.WAITING_CONFIRMATION,
      startDatetime: parseISO(optimisticAppointmentDetails.startDatetime),
      endDatetime: parseISO(optimisticAppointmentDetails.endDatetime),
      service: {
        id: '',
        name: optimisticAppointmentDetails.serviceName,
      },
      provider: {
        id: providerData?.provider?.id,
        externalProviderId: optimisticAppointmentDetails.providerId,
        firstName: providerData?.provider?.firstName,
        lastName: providerData?.provider?.lastName,
        profilePicture: null,
      },
      zoomLink: null,
    };
    return [...upcomingSessions, optimisticUpcomingSession];
  }, [optimisticAppointmentDetails, providerData?.provider, upcomingSessions]);
  return {
    upcomingSessions: optimisticUpcomingSessions,
    loading: loading && providerLoading,
  };
};
