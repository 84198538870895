import { type FC, type PropsWithChildren } from 'react';

import { ButtonContainer, ButtonIcon, StyledButton } from './styled';

type NewConversationButtonProps = {
  onClick: () => void;
};

export const NewConversationButton: FC<PropsWithChildren<NewConversationButtonProps>> = ({ onClick }) => (
  <ButtonContainer>
    <StyledButton onClick={onClick}>
      <ButtonIcon /> New conversation
    </StyledButton>
  </ButtonContainer>
);
