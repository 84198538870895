import { type Route } from './types';

const isRoute = (obj: unknown): obj is Route => {
  const maybeRoute = obj as Route;
  return !!(maybeRoute?.path && maybeRoute?.test);
};

type FlattenedRoute = Omit<Route, 'url'>;

export const flattenRoutes = (obj: Record<string, unknown>): FlattenedRoute[] =>
  Object.keys(obj).reduce<FlattenedRoute[]>((prevRoutes, key) => {
    const value = obj[key];
    const currRoutes = isRoute(value) ? [value] : flattenRoutes(value as Record<string, unknown>);

    return [...prevRoutes, ...currRoutes];
  }, []);
