import { gql } from '@apollo/client';

export const SURVEY_RESPONSE_DETAILS_SURVEY_RESPONSE_FRAGMENT = gql`
  fragment SurveyResponseDetailsSurveyResponseFragment on SurveyResponseType {
    id
    createdAt
    riskCategorization
    child {
      id
      displayName
      gender
    }
    survey {
      id
      worryDomain {
        id
        name
      }
    }
    questionResponses {
      id
      question {
        id
        introText
        endText
      }
      answer {
        id
        label
        value
      }
    }
  }
`;
