import { gql } from '@apollo/client';

export const RESOURCE_FRAGMENT = gql`
  fragment ResourceFragment on ResourceType {
    id
    pk
    title
    content
    tags {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
    worryDomains {
      edges {
        node {
          id
          pk
          name
          shortName
        }
      }
    }
    contentFile
  }
`;
