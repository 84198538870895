import { math } from 'polished';
import styled, { css } from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.625`)};
  padding-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.625`)};
  cursor: pointer;

  border-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;

export const TitleSpan = styled.span`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  letter-spacing: 0.25px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const BodyColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: 8px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
`;

export const linkSpanStyle = css`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.deprecated_.colors.textBlue};
`;

export const LinkSpan = styled.span`
  ${linkSpanStyle}
`;

export const DurationSpan = styled.span`
  padding-left: 4px;
`;
