import { useCallback, useState, type FC, type PropsWithChildren } from 'react';

import { Button, Header, Input, Paginator, Select } from '@littleotter/legacy-components';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { MissingQueryDataError } from '../../../graphql/errors';
import { MyClientsTable } from '../components/MyClientsTable';
import { useMyClientsTableData } from './hooks';
import { MyClientsSection } from './styled';
import { calculatePages, calculateSkip, FAMILIES_PAGE_SIZE } from './utils';

export const MyClients: FC<PropsWithChildren> = () => {
  const [providerId, setProviderId] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [inputName, setInputName] = useState<string>('');
  const { tableData, totalCount, providerSelectOptions, viewer, myClientsLoading, staffUsersLoading } =
    useMyClientsTableData(providerId, name, FAMILIES_PAGE_SIZE, calculateSkip(page));

  const onProviderSelectChange = useCallback((_providerId: string) => {
    setProviderId(_providerId);
    setPage(1);
  }, []);

  const onInputName = useCallback((value: string | number) => {
    setInputName(String(value));
  }, []);

  if (myClientsLoading || staffUsersLoading) return <PageWideLoading />;
  if (!viewer) throw new MissingQueryDataError('StaffUsersQuery');

  return (
    <MyClientsSection>
      <Header as="h2">My Clients</Header>
      {viewer.isSuperuser && (
        <Select
          name="provider-select"
          label="Only show families for this provider:"
          options={providerSelectOptions}
          placeholder="All providers"
          value={providerId ?? undefined}
          onChange={onProviderSelectChange}
        />
      )}
      <Input type="text" label="Family name" name="name" value={inputName ?? undefined} onChange={onInputName} />
      <Button
        onClick={() => {
          setName(inputName);
          setPage(1);
        }}
      >
        Search
      </Button>
      <MyClientsTable tableData={tableData} providerId={providerId ?? undefined} />
      <Paginator selectedPage={page} totalPages={calculatePages(totalCount)} onClick={setPage} pagesToShow={7} />
    </MyClientsSection>
  );
};
