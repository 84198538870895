import { math } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 720px;
  width: 100%;
`;

type StyledIframeProps = {
  widgetHeight: number;
  booked?: boolean;
};

export const StyledIframe = styled.iframe<StyledIframeProps>`
  min-height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 20`)};
  height: ${({ widgetHeight }) => `${widgetHeight}px`};
  width: 100%;
`;
