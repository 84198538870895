export const ageTags = [
  {
    pk: 19,
    name: '0-2',
  },
  {
    pk: 4,
    name: '3-4',
  },
  {
    pk: 10,
    name: '5-6',
  },
  {
    pk: 13,
    name: '7-8',
  },
  {
    pk: 24,
    name: '9-10',
  },
  {
    pk: 15,
    name: '11-12',
  },
  {
    pk: 27,
    name: '13+',
  },
];
