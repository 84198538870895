import { math, rgba } from 'polished';
import styled from 'styled-components';

import { Button, Header } from '@littleotter/legacy-components';

export const CardContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  box-shadow: ${({ theme }) => `
    0px 0px 10px 1px ${rgba(theme.deprecated_.colors.japaneseIndigo, 0.05)},
    0px 20px 20px rgb(0, 0, 0, 0.07)
  `};
`;

export const Title = styled(Header).attrs({ as: 'h5' })`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.25`)};
  margin: ${(props) =>
    `${props.theme.deprecated_.sizeBasis} ${props.theme.deprecated_.sizeBasis} ${math(
      `${props.theme.deprecated_.sizeBasis} / 2`
    )} ${props.theme.deprecated_.sizeBasis}`};
`;

export const TitleIcon = styled.span.attrs({ role: 'img', 'aria-label': 'star' })`
  margin-right: ${(props) => math(`${props.theme.deprecated_.sizeBasis} / 2`)};
  float: left;
`;

export const TitleText = styled.span``;

export const Container = styled.p`
  margin: ${(props) => `0 ${props.theme.deprecated_.sizeBasis}`};
  color: ${({ theme }) => `${theme.deprecated_.colors.text}CC`};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
`;

export const ScheduleButton = styled(Button)`
  margin: ${({ theme }) => `${math(`${theme.deprecated_.sizeBasis} * 1.5`)} auto`};
  padding: ${({ theme }) => `${math(`${theme.deprecated_.sizeBasis} * 0.75`)}`};
`;

export const StyledSecondaryTitle = styled.h2`
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular}`)};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  margin: ${({ theme }) =>
    `0 ${theme.deprecated_.sizeBasis} ${math(`${theme.deprecated_.sizeBasis} / 2`)} ${theme.deprecated_.sizeBasis}`};
`;

export const IndentedText = styled.p`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  margin: ${({ theme }) =>
    `0 ${math(`${theme.deprecated_.sizeBasis} * 4`)} ${theme.deprecated_.sizeBasis} ${math(
      `${theme.deprecated_.sizeBasis} * 3`
    )}`};
`;

export const ChildrenContainer = styled.div`
  margin: ${({ theme }) =>
    `${math(`${theme.deprecated_.sizeBasis} * 0.6`)} 0 ${math(`${theme.deprecated_.sizeBasis} * 0.75`)}`};
  padding: 0 ${({ theme }) => theme.deprecated_.sizeBasis};
`;
