import { type DomainScore } from '../../../../../graphql/lo1/generated';

export type ImpactCollapseDomainProps = {
  title: string;
  subDomainScores: DomainScore[];
  childName: string;
  caregiverName: string;
  openCollapse: boolean;
};

export enum ScoreLabel {
  Caregiver = 'You',
  Child = '{{child_name}}',
}
