import { gql } from '@apollo/client';

export const PROVIDER_LIST_QUERY = gql`
  query ProviderListQuery {
    insurancePartners {
      id
      name
      slug
      coverageInfo
      codeLabel
      validateDateOfBirth
      requiredFamilyData
    }
  }
`;

export const PROVIDER_BY_SLUG_QUERY = gql`
  query ProviderBySlugQuery($slug: String!) {
    insurancePartner(slug: $slug) {
      id
      name
      slug
      coverageInfo
      codeLabel
      validateDateOfBirth
    }
  }
`;

export const CHILD_AUTHORIZATION_QUERY = gql`
  query ChildAuthorizationQuery($uuid: UUID!, $referralNumber: String!) {
    insuranceReferral(uuid: $uuid, referralNumber: $referralNumber) {
      insurancePartner {
        id
        name
        slug
        coverageInfo
        codeLabel
        validateDateOfBirth
      }
      referralNumber
      firstName
      lastName
      email
      typeOfService
      dateOfBirth
    }
  }
`;

export const ADD_CHILD_AUTHORIZATION_MUTATION = gql`
  mutation AddChildAuthorization($input: InsuranceReferralInput!) {
    addInsuranceAuthorization(addInsuranceAuthorizationInput: $input) {
      ... on ChildType {
        id
      }
      ... on AddInsuranceAuthorizationError {
        message
        fieldErrors {
          childId
          referralNumber
          firstName
          lastName
          dateOfBirth
          insurancePartner
        }
      }
    }
  }
`;
