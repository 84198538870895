import { differenceInMonths } from 'date-fns';
import { useMemo } from 'react';

import { useViewer } from '$shared/contexts/Viewer';
import { useGraphQLErrorHandling } from '$shared/hooks';

import { type ViewerQuery_viewer_family_children } from '../../../../../../graphql/__generated__/ViewerQuery';
import { SubjectType, useGetFmhcReportQuery, type FmhcReport } from '../../../../../../graphql/lo1/generated';
import { type Person } from '../FamilyOverview/types';

type SubjectName = { id: string; name: string; displayName?: string };
type SubjectTypeToSubjectNameMap = {
  [SubjectType.Family]: SubjectName;
  [SubjectType.Caregiver]: SubjectName;
  [SubjectType.Child]: { [key: string]: SubjectName };
};

export const useGetFmhcReport = (fmhcId: string): [Person[], boolean] => {
  const { viewer, loading: viewerLoading } = useViewer();
  const {
    data,
    loading: fmhcLoading,
    error,
  } = useGetFmhcReportQuery({
    variables: { request: { fmhcId } },
  });
  const loading = viewerLoading || fmhcLoading;
  useGraphQLErrorHandling(error);
  const subjectTypeToSubjectMap: SubjectTypeToSubjectNameMap | undefined = useMemo(() => {
    if (!viewer || !viewer.family) {
      return undefined;
    }
    return {
      [SubjectType.Family]: { id: 'family', name: 'Family' },
      [SubjectType.Caregiver]: { id: 'caregiver', name: viewer.firstName },
      [SubjectType.Child]: viewer.family.children.reduce(
        (agg, child) => ({
          ...agg,
          [child.id]: {
            id: child.id,
            name: child.firstName,
            displayName: `${child.firstName} · ${getChildAgeString(child.dateOfBirth)}`,
          },
        }),
        {} as { [key: string]: SubjectName }
      ),
    };
  }, [viewer]);
  const reports = useMemo(() => [...(data?.GetFmhcReport.reports ?? [])], [data]);
  if (!subjectTypeToSubjectMap) {
    return [[], false];
  }
  const people = viewer
    ? reports
        .filter(
          (report) =>
            report.subject.subjectType !== SubjectType.Child ||
            (report.subject.childSubject?.childId &&
              report.subject.childSubject.childId in subjectTypeToSubjectMap[SubjectType.Child])
        ) // only include children that still exist
        .sort(reportCmpFactory(viewer.family?.children ?? []))
        .map((report) => reportToPerson(report, subjectTypeToSubjectMap))
    : [];
  return [people, loading];
};

const reportToPerson = (report: FmhcReport, subjectTypeToSubjectNameMap: SubjectTypeToSubjectNameMap): Person => {
  const subjectName =
    report.subject.subjectType === SubjectType.Child
      ? subjectTypeToSubjectNameMap[report.subject.subjectType][report.subject.childSubject?.childId ?? '']
      : subjectTypeToSubjectNameMap[report.subject.subjectType];
  if (!subjectName) {
    throw new Error(`Could not find an existing child for the report subject: ${report.subject.childSubject?.childId}`);
  }
  const { id, name, displayName } = subjectName;
  const [concerningDomains, typicalDomains] = getDomains(report);
  return {
    id,
    name,
    displayName,
    concerningDomains,
    typicalDomains,
  };
};

const getDomains = (report: FmhcReport): [string[], string[]] => {
  const concerningDomains = report.domainReports
    .filter(
      (domainReport) =>
        domainReport.domainScore &&
        domainReport.domainScore.rawScore &&
        domainReport.domainScore.rawScore >= domainReport.domainScore.threshold
    )
    .map((domainReport) => domainReport.domainScore?.scoreName)
    .filter((domainName): domainName is string => !!domainName);
  const typicalDomains = report.domainReports
    .filter(
      (domainReport) =>
        domainReport.domainScore &&
        domainReport.domainScore.rawScore &&
        domainReport.domainScore.rawScore < domainReport.domainScore.threshold
    )
    .map((domainReport) => domainReport.domainScore?.scoreName)
    .filter((domainName): domainName is string => !!domainName);
  return [concerningDomains, typicalDomains];
};

const getChildAgeString = (dob: Date): string => {
  const months = differenceInMonths(new Date(), new Date(dob));
  const years = Math.floor(months / 12);
  return months > 23 ? `${years}yo` : `${months}mo`;
};

// TODO(PD-1103): unify business logic to order [[...children seeking care], [...other children], [...caregivers], family]
const reportCmpFactory = (
  children: ViewerQuery_viewer_family_children[]
): ((a: FmhcReport, b: FmhcReport) => number) => {
  const childrenIdMap = children.reduce(
    (agg, c) => ({ ...agg, [c.id]: c }),
    {} as { [key: string]: ViewerQuery_viewer_family_children }
  );
  const subjectTypeIndex = {
    [SubjectType.Child]: 0,
    [SubjectType.Caregiver]: 1,
    [SubjectType.Family]: 2,
  };
  return (a, b) => {
    if (a.subject.subjectType === SubjectType.Child && b.subject.subjectType === SubjectType.Child) {
      return compareViewerFamilyChildren(
        childrenIdMap[a.subject.childSubject?.childId ?? 0],
        childrenIdMap[b.subject.childSubject?.childId ?? 0]
      );
    }
    return subjectTypeIndex[a.subject.subjectType] - subjectTypeIndex[b.subject.subjectType];
  };
};

// seeking care comes before not seeking care. then alphabetical.
const compareViewerFamilyChildren = (
  a: ViewerQuery_viewer_family_children,
  b: ViewerQuery_viewer_family_children
): number => {
  if (a.isSeekingCare && b.isSeekingCare) {
    return a.firstName.localeCompare(b.firstName);
  }
  if (a.isSeekingCare) {
    return -1;
  }
  if (b.isSeekingCare) {
    return 1;
  }
  return a.firstName.localeCompare(b.firstName);
};
