import { type FC, type PropsWithChildren } from 'react';

import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../components/MetadataRoute';
import { RouteSwitch } from '../../components/RouteSwitch';
import { routes } from '../../routes';
import { ProviderClient } from './Client';
import { ClientSurveyResponseDetails } from './Client/ClientSurveyResponseDetails';
import { FamilyDetails } from './FamilyDetails';
import { ProviderHome } from './ProviderHome';
import { Reports } from './Reports';

export const Provider: FC<PropsWithChildren> = () => (
  <RouteSwitch>
    <MetadataRoute {...routeProps(routes.provider.home)}>
      <ProviderHome />
    </MetadataRoute>
    <MetadataRoute {...routeProps(routes.provider.client.surveyResponse)}>
      <ClientSurveyResponseDetails />
    </MetadataRoute>
    <MetadataRoute {...routeProps(routes.provider.client.details)}>
      <ProviderClient />
    </MetadataRoute>
    <MetadataRoute {...routeProps(routes.provider.family.details)}>
      <FamilyDetails />
    </MetadataRoute>
    <MetadataRoute {...routeProps(routes.provider.family.reports)}>
      <Reports />
    </MetadataRoute>
  </RouteSwitch>
);
