import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/legacy-components';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { MissingQueryDataError } from '../../../graphql/errors';
import { AssessmentsAndDocumentationTable } from './components';
import { useAssessmentsAndDocumentationTableData } from './hooks';
import { StyledSection } from './styled';

export const AssessmentsAndDocumentationSection: FC<PropsWithChildren> = () => {
  const { tableData, data, loading } = useAssessmentsAndDocumentationTableData();

  if (loading) return <PageWideLoading />;
  if (!data) throw new MissingQueryDataError('ProviderAssessmentsAndDocumentationQuery');

  return (
    <StyledSection>
      <Header as="h4">Assessments & Documentation</Header>
      <AssessmentsAndDocumentationTable tableData={tableData} />
    </StyledSection>
  );
};
