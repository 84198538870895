import { type FC, type PropsWithChildren } from 'react';

import { BaseTable } from '../../../components';
import { useAssessmentsAndDocumentationTable } from './hooks';
import { type AssessmentsAndDocumentationTableDataItem } from './types';

export type AssessmentsAndDocumentationTableProps = {
  tableData: AssessmentsAndDocumentationTableDataItem[];
};

/**
 * Table for the `Assessments & Documentation` section
 *
 * @param props.tableData array of `AssessmentsAndDocumentationTableDataItem`
 * objects needed for populating the table
 */
export const AssessmentsAndDocumentationTable: FC<PropsWithChildren<AssessmentsAndDocumentationTableProps>> = ({
  tableData,
}) => {
  const tableInstance = useAssessmentsAndDocumentationTable({ tableData });

  if (!tableInstance.rows.length) return <div>No data</div>;

  return <BaseTable tableInstance={tableInstance} />;
};
