import { differenceInYears } from 'date-fns';
import { useStateMachine } from 'little-state-machine';
import { type FC, type PropsWithChildren } from 'react';
import { Controller, useForm } from 'react-hook-form6';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Header, Input } from '@littleotter/legacy-components';

import { useEventTracking } from '$services/event-tracking';
import { capitalize } from '$shared/utils/formatString';

import { MarginChildren, SubText } from '../../../components';
import { routes } from '../../../routes';
import childInfo from './assets/childInfo.svg';
import { TantrumButton, updateState, type TantrumState } from './components/Tantrum';
import { Container } from './styled';

const Disclaimer = styled(SubText)`
  text-align: center;
  margin-top: calc(${({ theme }) => theme.deprecated_.sizeBasis} / 2);
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
`;

type ChildFormData = {
  firstName: string;
  dateOfBirth: string;
  email: string;
};

const defaultChildInfoFormValues = {
  firstName: '',
  dateOfBirth: '',
  email: '',
};

export const ChildInfo: FC<PropsWithChildren> = () => {
  const history = useHistory();
  const { action, state } = useStateMachine<TantrumState>(updateState);
  const { track, identify } = useEventTracking();

  const { control, handleSubmit, errors, formState } = useForm<ChildFormData>({
    defaultValues: state.tantrumData.child
      ? { ...state.tantrumData.child, email: state.tantrumData.email }
      : defaultChildInfoFormValues,
    mode: 'onChange',
  });

  const validateDateOfBirth = (dateOfBirth: string) => {
    const touched = formState.touched.dateOfBirth;

    const ageInYears = differenceInYears(new Date(), new Date(dateOfBirth));

    if (touched && !(ageInYears >= 2 && ageInYears <= 6)) {
      return 'This temper tantrum assessment is for children aged 2-6.';
    }
    return true;
  };

  const onSubmit = async (values: ChildFormData) => {
    const { email, firstName, dateOfBirth } = values;
    identify(email);
    track('STARTED_TEMPER_TANTRUM_ASSESSMENT');
    action({
      child: {
        firstName: capitalize(firstName),
        dateOfBirth,
      },
      email,
    });
    return history.push({ pathname: routes.tantrums.survey.url(), search: history.location.search });
  };

  return (
    <Container as="form" onSubmit={handleSubmit(onSubmit)}>
      <MarginChildren>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ maxWidth: '8rem', width: '50%' }}>
            <img src={childInfo} alt="page illustration" />
          </div>
        </div>
        <Header as="h3" style={{ textAlign: 'center' }}>
          First—tell us a bit about you and your child.
        </Header>
        <Controller
          as={Input}
          label="Child's First Name"
          name="firstName"
          control={control}
          rules={{ required: "Child's first name is required" }}
          errors={errors}
        />
        <Controller
          as={Input}
          label="Child's Birthday"
          name="dateOfBirth"
          type="date"
          placeholder="YYYY-MM-DD"
          control={control}
          rules={{
            required: "Child's birthday is required",
            pattern: {
              value: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
              message: 'Birthday must be in the format YYYY-MM-DD',
            },
            validate: validateDateOfBirth,
          }}
          errors={errors}
        />
        <Controller
          as={Input}
          label="Your Email"
          name="email"
          type="email"
          control={control}
          rules={{ required: 'Your email address is required' }}
          errors={errors}
        />
      </MarginChildren>
      <TantrumButton disabled={!formState.isValid} isLoading={formState.isSubmitting} variant="primary" type="submit">
        Next
      </TantrumButton>
      <Disclaimer>By clicking "Next", you agree to have your email address added to our mailing list.</Disclaimer>
    </Container>
  );
};
