import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  text-align: left;
`;

export const StyledLabel = styled.label`
  font-weight: ${(props) => props.theme.deprecated_.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  cursor: pointer;
  width: 100%;
  > div {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

export const IconLinkRow = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  user-select: none;
`;

export const IconLinkRowElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.6rem;
`;
