import { math } from 'polished';
import styled from 'styled-components';

export const OuterContainer = styled.div`
  padding-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  padding-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;

export const StepperContainer = styled.div`
  position: relative;
  height: ${({ theme }) => theme.deprecated_.sizeBasis};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Step = styled.div<{ $hidden: boolean; active: boolean }>`
  width: ${({ theme }) => math(`2 * ${theme.deprecated_.sizeBasis}`)};
  height: ${({ theme }) => math(`2 * ${theme.deprecated_.sizeBasis}`)};
  border: 2px solid ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
  z-index: 1;
  background-color: ${({ active, theme }) =>
    active ? theme.deprecated_.colors.yellow : theme.deprecated_.colors.lightestGray};
  color: ${({ theme, active }) => (active ? theme.deprecated_.colors.white : theme.deprecated_.colors.lightGray)};

  & > svg > path {
    fill: ${({ theme, active }) => (active ? theme.deprecated_.colors.white : theme.deprecated_.colors.lightGray)};
  }
`;

export const ProgressContainer = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.deprecated_.sizeBasis};
  top: 0;
  right: ${({ theme }) => theme.deprecated_.sizeBasis};
  bottom: 0;
  border-top-left-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  border-bottom-left-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  overflow: hidden;
  background-color: ${({ theme }) => theme.deprecated_.colors.lightestGray};
`;

export const Progress = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.deprecated_.colors.yellow};
`;
