import styled from 'styled-components';

export const StyledActionsWrapper = styled.div`
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 34px 24px;
  margin-bottom: 0;
  background: ${({ theme }) => theme.deprecated_.colors.white};

  a {
    display: block;
    padding-top: 10px;
  }

  @media (max-height: 670px) {
    position: relative;
  }

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    max-width: 300px;
    margin: 0 auto;
    position: relative;
    padding: 50px 24px;
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.deprecated_.colors.textBlue};
  display: block;
  margin-top: 24px;
  text-decoration: underline;
  cursor: pointer;
`;
