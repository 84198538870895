import { gql, useQuery, type WatchQueryFetchPolicy } from '@apollo/client';
import { type Fmhc } from 'src/graphql/lo1/generated';

import { type FmhcRetakeWindowQuery } from './__generated__/FmhcRetakeWindowQuery';
import { useFmhcsQuery } from './useFmhcsQuery';
import { useGetFmhcs, type FmhcScenario } from './useGetFmhcs';

export const FMHC_RETAKE_WINDOW_QUERY = gql`
  query FmhcRetakeWindowQuery {
    fmhcRetakeWindow {
      startDate
      endDate
    }
  }
`;

/**
 * If a breakglass FMHC window exists, then "freshness" for the `latestFreshSubmitted` is defined
 * by the window, and not the normal "freshness".
 * Otherwise, the same as `useGetFmhcs`.
 * @returns
 */
export const useGetFmhcsWithRetakeWindow = ({
  noCache = false,
  scenarios = [],
}: useGetFmhcsWithRetakeWindowProps = {}): useGetFmhcsWithRetakeWindowOutput => {
  const { loading: loadingFmhcs, latestFreshSubmitted, ...fmhcs } = useGetFmhcs({ noCache, scenarios });
  // Returns maybe a date range containing today for the current user to retake the FMHC, if one exists.
  const { data, loading: loadingWindow } = useQuery<FmhcRetakeWindowQuery>(FMHC_RETAKE_WINDOW_QUERY);
  const startDate = data?.fmhcRetakeWindow ? new Date(data.fmhcRetakeWindow.startDate) : undefined;

  // Look for a submitted FMHC in the window, if the window is defined.
  const fetchPolicy: WatchQueryFetchPolicy | undefined = noCache ? 'no-cache' : undefined;
  const [maybeFmhcsInRetakeWindow, loadingFmhcInRetakeWindow] = useFmhcsQuery({
    isSubmitted: true,
    dateThreshold: startDate,
    skipQuery: !data?.fmhcRetakeWindow,
    fetchPolicy,
  });
  const loading = loadingFmhcs || loadingWindow || loadingFmhcInRetakeWindow;

  const latestFreshSubmittedWithWindow = data?.fmhcRetakeWindow ? maybeFmhcsInRetakeWindow?.[0] : latestFreshSubmitted;

  return {
    ...fmhcs,
    latestFreshSubmitted: latestFreshSubmittedWithWindow,
    loading,
  };
};

type useGetFmhcsWithRetakeWindowProps = {
  noCache?: boolean;
  scenarios?: FmhcScenario[];
};

type useGetFmhcsWithRetakeWindowOutput = {
  [S in FmhcScenario]?: Fmhc;
} & {
  loading: boolean;
};
