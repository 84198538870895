import { endRoute, parentRoute } from '$shared/utils/routes';

export const libraryRoutes = {
  library: parentRoute('/library')({
    forYou: endRoute('/library/for-you'),
    explore: endRoute('/library/explore'),
    saved: endRoute('/library/saved'),
    resource: endRoute<{ id: string }>('/library/:id'),
  }),
};
