import { type FC, type PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export type HeaderProps = {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  recoleta?: boolean;
  style?: React.CSSProperties;
  useBodyFont?: boolean;
  id?: string;
};

export const headerSizeFromType = {
  h1: 'largest',
  h2: 'larger',
  h3: 'large',
  h4: 'h4',
  h5: 'h5',
} as const;

export const headerWeightFromType = {
  h1: 'bold',
  h2: 'bold',
  h3: 'bold',
  h4: 'bold',
  h5: 'bold',
} as const;

const larsseitStyles = css`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  letter-spacing: 0.25px;
`;

const recoletaStyles = css`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
`;

export const StyledHeader = styled.h1<HeaderProps>`
  font-size: ${({ theme, as }) => theme.deprecated_.fontSizes[headerSizeFromType[as]]};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  line-height: 1.2;

  ${({ as, useBodyFont, recoleta }) => {
    if (as === 'h1' || as === 'h2') {
      return recoletaStyles;
    }
    if (as === 'h5' && recoleta !== true) {
      return larsseitStyles;
    }
    if (useBodyFont) {
      return larsseitStyles;
    }
    return recoletaStyles;
  }}
`;

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ as, recoleta, children, ...props }) => (
  <StyledHeader as={as} recoleta={recoleta} {...props}>
    {children}
  </StyledHeader>
);
