import { math } from 'polished';
import styled, { createGlobalStyle, css } from 'styled-components';

import { Link } from '@littleotter/legacy-components';

import { CareDenSavedReportCard, SummaryCard } from './components';
import { FreeReportCard } from './components/FreeReportCard';

const CSSTitle = css`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  color: ${({ theme }) => theme.deprecated_.colors.text};
`;

export const StyledSecondaryTitle = styled.h2`
  ${CSSTitle};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.6`)};
  margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.625`)};
`;

export const StyledReportSubjectContainer = styled.div`
  margin: ${({ theme }) =>
    `${math(`${theme.deprecated_.sizeBasis} * 1.875`)} 0 ${math(`${theme.deprecated_.sizeBasis} * 2.18`)}`};
`;

export const StyledFreeReportCard = styled(FreeReportCard)`
  margin: ${({ theme }) =>
    `${math(`${theme.deprecated_.sizeBasis} * 1.25`)} 0 ${math(`${theme.deprecated_.sizeBasis} * 1.125`)} `};
`;

export const StyledCareDenSavedReportCard = styled(CareDenSavedReportCard)`
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.25`)};
`;

export const StyledHorizontalRule = styled.hr`
  border-color: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  opacity: 50%;
`;

export const SummaryProgressCardContainer = styled.div`
  margin: ${({ theme }) =>
    `${math(`${theme.deprecated_.sizeBasis} * 2.5`)} 0 ${math(`${theme.deprecated_.sizeBasis} * 3.75`)}`};
`;

export const StyledReportsContainer = styled.div`
  margin: 0 ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
`;

export const SummaryCardMargin = styled.div`
  &:first-of-type {
    margin-left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
  }
`;

export const DomainScoresContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 0.75`)};
`;

export const StyledSummaryCard = styled(SummaryCard)`
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 17.5`)};
  height: 100%;
  cursor: pointer;
  user-select: none;
`;

export const StyledMarginReport = styled.div`
  max-width: ${({ theme }) => theme.deprecated_.breakpoints.md};
  margin: 0 auto;
`;

export const FullLink = styled(Link)`
  width: 100%;
`;

export const ReportsPageStyles = createGlobalStyle`
  html, body {
    scroll-behavior: smooth;
    scroll-padding-top: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;
