import { type FC, type PropsWithChildren, type ReactNode } from 'react';

import { CollapseContent } from './CollapseContent';
import { type CollapseContentContainerVariant, type CollapseContentIconVariant } from './types';

export type ControlledCollapseProps = {
  label: ReactNode;
  maxHeight?: string;
  onClick?: () => void;
  isOpen?: boolean;
  iconVariant?: CollapseContentIconVariant;
  containerVariant?: CollapseContentContainerVariant;
  className?: string;
};

export const ControlledCollapse: FC<PropsWithChildren<ControlledCollapseProps>> = ({
  label,
  children,
  maxHeight = '1000px',
  onClick,
  isOpen = false,
  iconVariant,
  containerVariant,
  className,
}) => (
  <CollapseContent
    onClick={onClick}
    label={label}
    isOpen={isOpen}
    maxHeight={maxHeight}
    iconVariant={iconVariant}
    containerVariant={containerVariant}
    className={className}
  >
    {children}
  </CollapseContent>
);
