import { type FC, type PropsWithChildren } from 'react';

import { CheckRow, type CheckRowProps } from './CheckRow';
import { type PremadeCheckRowProps } from './interface';

export const HealthCheckUpCheckRow: FC<PropsWithChildren<PremadeCheckRowProps>> = ({ onClick, stepChip }) => {
  const props: CheckRowProps = {
    title: 'Take a child & family mental health checkup',
    stepChip: 'required',
    duration: '10m',
    linkText: "Let's go",
    image: 'kangaroos',
    onClick,
  };

  if (stepChip) {
    props.stepChip = stepChip;
  }
  return <CheckRow {...props} />;
};
