import { gql } from '@apollo/client';

import { SURVEY_RESPONSE_DETAILS_SURVEY_RESPONSE_FRAGMENT } from './survey-response-details-survey-response-fragment';

export const PROVIDER_CLIENT_QUERY = gql`
  query ProviderClientQuery($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      state
      profilePicture
      surveyResponses {
        edges {
          node {
            id
            pk
            createdAt
            score
            riskCategorization
            survey {
              id
              worryDomain {
                id
                name
              }
            }
          }
        }
      }
      family {
        children {
          id
          firstName
          lastName
          gender
          profilePicture
          worryDomains {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CLIENT_SURVEY_RESPONSE_DETAILS_QUERY = gql`
  query ClientSurveyResponseDetailsQuery($id: Int!) {
    surveyResponse(id: $id) {
      ...SurveyResponseDetailsSurveyResponseFragment
    }
  }
  ${SURVEY_RESPONSE_DETAILS_SURVEY_RESPONSE_FRAGMENT}
`;
