import { useQuery } from '@apollo/client';

import {
  type ConversationParticipantsQuery,
  type ConversationParticipantsQueryVariables,
} from './__generated__/ConversationParticipantsQuery';
import { CONVERSATION_PARTICIPANTS_QUERY } from './ConversationParticipantsQuery';
import { getParticipantName } from './getParticipantName';

type UseAuthorNameOptions = {
  conversationSid: string | undefined;
  authorId: string | null;
};

type UseAuthorName = (options: UseAuthorNameOptions) => string;

export const useAuthorName: UseAuthorName = ({ authorId, conversationSid }) => {
  const { data } = useQuery<ConversationParticipantsQuery, ConversationParticipantsQueryVariables>(
    CONVERSATION_PARTICIPANTS_QUERY,
    {
      variables: {
        conversationSid: String(conversationSid),
      },
      skip: !authorId || !conversationSid,
    }
  );

  if (!authorId || !data?.conversation.participants.length) {
    return '';
  }

  return getParticipantName({
    authorId,
    participants: data.conversation.participants,
  });
};
