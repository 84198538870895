import { hideVisually, lighten } from 'polished';
import { useCallback, useState, type FC } from 'react';
import styled from 'styled-components';

import { theme as _theme } from '../../styles';

const StyledRadioGroup = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing['04']};
  flex: 1 0 auto;
`;

const Icon = styled.div`
  width: ${(props) => props.theme.deprecated_.sizeBasis};
  height: ${(props) => props.theme.deprecated_.sizeBasis};
  border: ${(props) =>
    `${props.theme.deprecated_.border.size} ${props.theme.deprecated_.border.style} ${props.theme.deprecated_.border.color}`};
  display: inline-block;
  border-radius: 50%;
  margin-right: 0.5rem;
  transition: all 0.2s linear;
`;

const StyledRadio = styled.input.attrs({ type: 'radio' })`
  ${hideVisually()}
  &:checked + ${Icon} {
    background-color: ${(props) => props.theme.deprecated_.colors.text};
    border-color: transparent;
  }
`;

const StyledLabel = styled.label<{
  displayAsTags: boolean;
  isSelectedValue: boolean;
  tagsActivedBackground: string;
  tagsDisabledBackground: string;
}>`
  cursor: pointer;
  font-weight: ${(props) => props.theme.deprecated_.fontWeights.semiBold};
  flex: 1 1 auto;

  ${({ displayAsTags, isSelectedValue, theme, tagsActivedBackground, tagsDisabledBackground }) =>
    displayAsTags &&
    `
        font-family: ${theme.deprecated_.fontFamilies.body};
        transition: background-color 0.2s linear;
        border-radius: ${theme.deprecated_.border.radius};
        padding: 0.7rem;
        background-color: ${isSelectedValue ? tagsActivedBackground : tagsDisabledBackground};
        border: ${isSelectedValue ? '1px solid transparent' : `1px solid ${theme.deprecated_.colors.lightGray}`};
        > ${Icon} {
            display: none;
        }

    `}
`;

type Option = {
  label: string;
  value: string | number;
};

export type RadioGroupProps = {
  name: string;
  options: Option[];
  // TODO: make this prop required
  value?: string | number | null;
  onChange?: (value: string | number) => void;
  displayAsTags?: boolean;
  className?: string;
  tagsActivedBackground?: string;
  tagsDisabledBackground?: string;
};

export const RadioGroup: FC<RadioGroupProps> = ({
  className,
  name,
  options,
  value,
  onChange,
  displayAsTags = false,
  tagsActivedBackground = _theme.deprecated_.colors.brandPurple,
  tagsDisabledBackground = lighten(0.1, _theme.deprecated_.colors.lightGray),
}) => {
  const [selectedValue, setSelectedValue] = useState<string | number | null>(value ?? null);

  const handleOnChange = useCallback((v: string | number) => (onChange ? onChange(v) : undefined), [onChange]);

  const onValueChange = (_value: string | number) => {
    setSelectedValue(_value);
    return handleOnChange(_value);
  };

  /**
   * Allow user to select an option with the keyboard by pressing enter
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLLabelElement>, _value: string | number) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onValueChange(_value);
    }
  };

  return (
    <StyledRadioGroup role="radiogroup">
      {options.map(({ label, value: _value }) => (
        <StyledLabel
          className={className}
          key={_value}
          displayAsTags={displayAsTags}
          isSelectedValue={_value === selectedValue}
          tagsActivedBackground={tagsActivedBackground}
          tagsDisabledBackground={tagsDisabledBackground}
          tabIndex={0}
          onKeyDown={(e) => handleKeyDown(e, _value)}
        >
          <StyledRadio
            name={name}
            value={_value}
            onChange={() => onValueChange(_value)}
            checked={_value === selectedValue}
          />
          <Icon />
          <span style={{ verticalAlign: 'top' }}>{label}</span>
        </StyledLabel>
      ))}
    </StyledRadioGroup>
  );
};
