import {
  DomainReportContextType as GqlReportContextType,
  type DomainReport as GqlDomainReport,
  type DomainScore as GqlDomainScore,
  type DomainReportContext as GqlReportContext,
} from '../../../../../../graphql/lo1/generated';
import { isCaregiverSubject, isChildSubject, type Subject } from '../../../../../FmhcCheckup/types';
import {
  DEFAULT_CATEGORIZATION,
  IMPACT_DOMAIN,
  IMPACT_DOMAIN_CATEGORIZATION,
  type Categorization,
  type DomainReport,
  type ImpactReport,
  type Score,
} from '../../../types';

const gqlScoreToAppScore = (
  { scoreName, rawScore, threshold, maxScore }: GqlDomainScore,
  categorizations: Categorization
): Score => {
  return {
    label: scoreName,
    rawScore: rawScore ?? undefined,
    threshold,
    maxScore,
    categorizations,
  };
};

// TODO: Eventually, this logic will be removed from the frontend, and delivered to the backend.
// Make sure not to move this logic too far away from the backend source, in order to make the transition to
// favor the backend more simple
const isDomainDisabled = (positionInList: number, subject: Subject, isFreeAccount: boolean, domainName: string) => {
  return (
    isFreeAccount &&
    (isCaregiverSubject(subject) || isChildSubject(subject)) &&
    positionInList !== 0 &&
    domainName !== IMPACT_DOMAIN
  );
};

const gqlContextToContent = (contexts: GqlReportContext[]) => {
  const maybeExplanation = contexts.filter((c) => c.contextType === GqlReportContextType.Explanation)[0];
  const maybeCallToAction = contexts.filter((c) => c.contextType === GqlReportContextType.CallToAction)[0];
  return {
    explanation: maybeExplanation?.content ?? '',
    callToAction: maybeCallToAction?.content ?? '',
  };
};

const gqlDomainReportToDomainReport =
  (subject: Subject, isFreeAccount: boolean) =>
  ({ context, domainScore, domainName }: GqlDomainReport, gqlDomainReportIndex: number): DomainReport => {
    const isDisabled = isDomainDisabled(gqlDomainReportIndex, subject, isFreeAccount, domainName);
    const { explanation, callToAction } = gqlContextToContent(context);
    return {
      isDisabled,
      name: domainName ?? '',
      // TODO: Fix this when the gql is no longer set as optional. Should never be undefined

      score: gqlScoreToAppScore(domainScore!, DEFAULT_CATEGORIZATION),
      explanation,
      callToAction,
    };
  };

const gqlDomainReportToImpactReport = ({ context, domainScore, subDomainScores }: GqlDomainReport): ImpactReport => {
  const { explanation, callToAction } = gqlContextToContent(context);
  return {
    // TODO: Fix this when the gql is no longer set as optional. Should never be undefined

    score: gqlScoreToAppScore(domainScore!, IMPACT_DOMAIN_CATEGORIZATION),
    subScores: subDomainScores.map((score) =>
      gqlScoreToAppScore({ ...score, scoreName: score.label }, IMPACT_DOMAIN_CATEGORIZATION)
    ),
    explanation,
    callToAction,
  };
};

const isGqlImpactDomainReport = (di: GqlDomainReport): boolean => {
  return di.domainName === IMPACT_DOMAIN;
};

const isGqlDomainReport = (di: GqlDomainReport): boolean => {
  return di.domainName !== IMPACT_DOMAIN;
};

export const gqlDomainReportsToAppReports = (
  gqlDomainReports: GqlDomainReport[],
  subject: Subject,
  isFreeAccount: boolean
): { impact?: ImpactReport; domains: DomainReport[] } => {
  const impact = gqlDomainReports.filter(isGqlImpactDomainReport).map(gqlDomainReportToImpactReport)[0];
  const domains = gqlDomainReports.filter(isGqlDomainReport).map(gqlDomainReportToDomainReport(subject, isFreeAccount));
  return {
    impact,
    domains,
  };
};
