import { math } from 'polished';
import styled from 'styled-components';

import arrowRight from '../../assets/arrowRight.svg';

export const CardContainer = styled.div<{ inverseColors?: boolean; marginTop?: string }>`
  ${({ theme, marginTop }) => marginTop && `margin-top: ${math(`${theme.deprecated_.sizeBasis} * ${marginTop}`)};`}
  width: 100%;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
  overflow: hidden;
  display: flex;
  transition: all 0.2s linear;
  cursor: pointer;
  justify-content: space-between;
`;

export const StyledArrowRight = styled.img.attrs({
  src: arrowRight,
})`
  padding-right: 1rem;
  align-self: center;
`;
