import styled from 'styled-components';

import { GRAPH_HEIGHT } from '../../constants';

export const Root = styled.div`
  overflow: hidden;
  position: relative;
`;

export const LabelContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing['03']};
  margin: ${({ theme }) => theme.spacing['05']} 0;
`;

export const Label = styled.div<{ color: string }>`
  border-radius: ${({ theme }) => theme.borderRadius['02']};
  padding: ${({ theme }) => theme.spacing['03']};
  background: ${({ color }) => color};
  color: white;
`;

export const GraphContainer = styled.div`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  height: ${GRAPH_HEIGHT}px;
  margin-bottom: ${({ theme }) => theme.spacing['06']};
  position: relative;
  margin: ${({ theme }) => `${theme.spacing['03']} 0`};
`;
