import childIntroImage from '../../pages/assets/userImages/user_field.svg';
import childIntroImageTwo from '../../pages/assets/userImages/user_forest.svg';
import childIntroImageThree from '../../pages/assets/userImages/user_plants.svg';
import childIntroImageFour from '../../pages/assets/userImages/user_sunrise.svg';
import childIntroImageFive from '../../pages/assets/userImages/user_tree.svg';
import childIntroImageSix from '../../pages/assets/userImages/user_waves.svg';
import { hashString } from './hash';

export const CHILD_INTRO_IMAGE = {
  ONE: childIntroImage,
  TWO: childIntroImageTwo,
  THREE: childIntroImageThree,
  FOUR: childIntroImageFour,
  FIVE: childIntroImageFive,
  SIX: childIntroImageSix,
};

export const getChildIntroImage = (childName: string) => {
  const images = Object.entries(CHILD_INTRO_IMAGE);
  const value = hashString(childName) % images.length;
  return images[value][1];
};
