import { type FC, type PropsWithChildren } from 'react';

import { Background, FadeInContainer, HealthCheckUpCheckRow, NextSteps, OrderDetails } from './components';
import { OrderConfirmationContainer } from './styled';

export type OrderConfirmationContentProps = {
  appointmentDatetime: Date;
  practitionerFirstName: string;
  practitionerLastName: string;
  practitionerCredentials?: string;
  worryDomains: string;
  showHealthCheckupRow: boolean;
  onHealthCheckupRowClick: () => void;
};

export const OrderConfirmationContent: FC<PropsWithChildren<OrderConfirmationContentProps>> = ({
  appointmentDatetime,
  practitionerFirstName,
  practitionerLastName,
  practitionerCredentials,
  worryDomains,
  showHealthCheckupRow,
  onHealthCheckupRowClick,
}) => {
  const checkRowCandidates = [];

  if (showHealthCheckupRow) {
    checkRowCandidates.push(
      <HealthCheckUpCheckRow key="health-check-up-check-row" onClick={onHealthCheckupRowClick} />
    );
  }

  const handleHealthCheckupButtonClick = showHealthCheckupRow ? onHealthCheckupRowClick : undefined;

  return (
    <>
      <Background />
      <OrderConfirmationContainer>
        <OrderDetails
          appointmentDatetime={appointmentDatetime}
          practitionerFirstName={practitionerFirstName}
          practitionerLastName={practitionerLastName}
          practitionerCredentials={practitionerCredentials}
          worryDomains={worryDomains}
        />
        <FadeInContainer>
          <NextSteps
            title="Next steps"
            description={`We’re excited for you to meet ${practitionerFirstName}! Here's how you can maximize your session.`}
            emptyDescription="You’re all set! We’re looking forward to meeting your family."
            checkRows={checkRowCandidates}
            onHealthCheckupButtonClick={handleHealthCheckupButtonClick}
          />
        </FadeInContainer>
      </OrderConfirmationContainer>
    </>
  );
};
