import { type FmhcReport as GqlFmhcReport } from '../../../../../../graphql/lo1/generated';
import { SubjectType, type Subject } from '../../../../../FmhcCheckup/types';
import { type SubjectReportCollection } from '../../../types';
import { gqlDomainReportsToAppReports } from './domainReports';

export const gqlFamilyReportToAppReportForSubject =
  (familyWorryDomains: string[], isFreeAccount: boolean) =>
  ({ domainReports, numQuestionsAnswered }: GqlFmhcReport): SubjectReportCollection => {
    const title = "Your Family's Mental Health";

    const subject: Subject = {
      subjectType: SubjectType.Family,
    };
    const { impact, domains } = gqlDomainReportsToAppReports(domainReports, subject, isFreeAccount);
    return {
      title,
      worryDomains: familyWorryDomains,
      subject,
      numQuestionsAnswered,
      impactReport: impact,
      domainReports: domains,
    };
  };

export const gqlCaregiverReportToAppReportForSubject =
  (caregiverWorryDomains: string[], isFreeAccount: boolean) =>
  ({ domainReports, numQuestionsAnswered }: GqlFmhcReport): SubjectReportCollection => {
    const title = 'Your Mental Health';
    const subject: Subject = {
      subjectType: SubjectType.Caregiver,
    };

    const { impact, domains } = gqlDomainReportsToAppReports(domainReports, subject, isFreeAccount);

    return {
      title,
      worryDomains: caregiverWorryDomains,
      subject,
      numQuestionsAnswered,
      impactReport: impact,
      domainReports: domains,
    };
  };
