import { type FC, type PropsWithChildren } from 'react';

import { useGetFmhcs } from '$shared/scenarios/useGetFmhcs';

import { PageWideLoading } from '../../components/PageWideLoading';
import { Checkup } from './Checkup';
import { CheckupNew } from './CheckupNew';
import { type CheckupStartProps } from './props';

/**
 * CheckupStart continues a recent unsubmitted FMHC if it exists, or starts a new FMHC session
 */
export const CheckupStart: FC<PropsWithChildren<CheckupStartProps>> = (props) => {
  const { latestFreshUnsubmitted, loading } = useGetFmhcs({ scenarios: ['latestFreshUnsubmitted'] });
  if (loading) {
    return <PageWideLoading />;
  }
  return (
    <>
      {latestFreshUnsubmitted ? <Checkup {...props} fmhcId={latestFreshUnsubmitted.id} /> : <CheckupNew {...props} />}
    </>
  );
};
