import styled from 'styled-components';

export const StyledForm = styled.form`
  min-width: 100%;
  max-width: fit-content;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const SelectContainer = styled.div`
  height: 24rem;
`;
