import kebabCase from 'just-kebab-case';
import { type AllFlagsLDClient, type AsyncProviderConfig } from 'launchdarkly-react-client-sdk';

/**
 * Launch Darkly requires its provided default flag keys to be in kebab-case. This is not really convenient
 * for us for typescript typing purposes, so we pass our default flags as camelCase for typing, and then
 * process them with this function to convert our default `camelCase` flag keys to `kebab-case`.
 */
export const formatDefaultFlagKeys = (flags: AsyncProviderConfig['flags']): AllFlagsLDClient['flags'] => {
  if (!flags) {
    return {};
  }

  const formattedFlags: AllFlagsLDClient['flags'] = {};

  Object.keys(flags).forEach((key) => {
    formattedFlags[kebabCase(key)] = flags[key];
  });

  return formattedFlags;
};
