import { useMemo, useState } from 'react';

import { DJANGO_BACKEND_SERVICE } from '$configs/env';

export type AuthState = {
  loading: boolean;
  authorized: boolean;
  redirectTo?: string;
};

const inFlight: AuthState = {
  loading: true,
  authorized: false,
};

const authorized: AuthState = {
  loading: false,
  authorized: true,
};

const unauthorized: AuthState = {
  loading: false,
  authorized: false,
  redirectTo: 'https://little-renegades.myshopify.com/products/little-otter-holiday-checkup',
};

const storageKey = (slug: string): string => {
  return `lo:${slug}:auth`;
};

const getAuthFromStorage = (slug: string): string | null => {
  return window.sessionStorage.getItem(storageKey(slug));
};

export const persistAuth = (slug: string, value: string) => {
  window.sessionStorage.setItem(storageKey(slug), value);
};

export const useAuth = (slug: string): AuthState => {
  const [state, setState] = useState<AuthState>(inFlight);

  useMemo(() => {
    if (slug !== 'holiday' || getAuthFromStorage(slug) !== null) {
      setState(authorized);
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const orderId = params.get('o');
    const emailSha1 = params.get('s');
    if (!orderId || !emailSha1) {
      setState(unauthorized);
      return;
    }

    setState(inFlight);
    fetch(
      `${DJANGO_BACKEND_SERVICE.ROOT_URL}/magic-links/holiday-assessment/validate?o=${orderId}&s=${emailSha1}`
    ).then((response) => {
      if (response.ok) {
        persistAuth(slug, orderId);
        setState(authorized);
      } else {
        setState(unauthorized);
      }
    });
  }, [slug]);

  return state;
};
