import { gql } from '@apollo/client';

import { USER_FRAGMENT } from './user';

export const CREATE_CONVERSATION_USER_TOKEN = gql`
  mutation CreateConversationUserToken {
    createConversationUserToken {
      token
    }
  }
`;

export const CONVERSATION_INFO_FRAGMENT = gql`
  fragment ConversationInfoFragment on ConversationType {
    id
    conversationSid
    participants {
      id
      firstName
      lastName
      state
      profilePicture
      isStaff
      providerTitle
      outOfOfficeDates {
        startDate
        endDate
      }
      externalClientUuid # for IntakeQ client link
      family {
        id
      }
    }
    state
    lastMessageAt
  }
`;

export const CONVERSATION_INFO_QUERY = gql`
  query ConversationInfo($sid: String!) {
    viewer {
      id
      ...UserFragment
    }
    conversation(sid: $sid) {
      ...ConversationInfoFragment
    }
  }
  ${USER_FRAGMENT}
  ${CONVERSATION_INFO_FRAGMENT}
`;

export const CONVERSATIONS_INFO_QUERY = gql`
  query ConversationsInfo($sidIn: [String!]!) {
    conversations(sidIn: $sidIn) {
      ...ConversationInfoFragment
    }
  }
  ${CONVERSATION_INFO_FRAGMENT}
`;

export const CONVERSATIONS_INFO_BY_STATE_QUERY = gql`
  query ConversationsInfoByState($state: ConversationStateArgument!) {
    conversationsByState(state: $state) {
      id
      conversationSid
      state
      lastMessageAt
    }
  }
`;

export const HIDE_CHAT_MESSAGE = gql`
  mutation HideChatMessage($input: HideChatMessageInput!) {
    hideChatMessage(input: $input) {
      ok
    }
  }
`;

export const RESTORE_CHAT_MESSAGE = gql`
  mutation RestoreChatMessage($input: RestoreChatMessageInput!) {
    restoreChatMessage(input: $input) {
      ok
    }
  }
`;
