import { ApolloProvider } from '@apollo/client';
import { useAuth } from '@clerk/clerk-react';
import { math } from 'polished';
import { useMemo } from 'react';
import { Router } from 'react-router-dom';
import styled from 'styled-components';
import { ModalProvider } from 'styled-react-modal';

import { StyledModalBackground } from '@littleotter/legacy-components';

import { useFeatureFlagIdentityManager } from '$services/featureFlags';
import { ChildProvider } from '$shared/contexts/Child';
import { ConversationsProvider } from '$shared/contexts/Conversations';
import { ViewerProvider } from '$shared/contexts/Viewer';
import { createClient } from '$shared/utils';
import { history } from '$shared/utils/history';

import { AppErrorBoundary } from './components/AppErrorBoundary';
import { ErrorHandlerProvider, useErrorHandlerProviderValue } from './components/ErrorHandler';
import { InvalidSubscriptionModal } from './components/InvalidSubscriptionModal';
import { NavBar } from './components/Navbar';
import { ScrollToTop } from './components/ScrollToTop';
import { TermsAndConditionsModal } from './components/TermsAndConditionsModal';
import { ClerkProvider } from './infra/integrations/clerk/ClerkProvider';
import { Routes } from './RoutesComponent';

const StyledContainer = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Body = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  height: calc(100vh - (1.5 * ${(props) => props.theme.deprecated_.sizeBasis}));
  padding-top: max(
    calc(env(safe-area-inset-top) + ${(props) => math(`4 * ${props.theme.deprecated_.sizeBasis}`)}),
    ${(props) => math(`5 * ${props.theme.deprecated_.sizeBasis}`)}
  );
  padding-right: ${(props) => props.theme.deprecated_.sizeBasis};
  padding-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  padding-left: ${(props) => props.theme.deprecated_.sizeBasis};

  margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};

  @media (min-width: ${(props) => props.theme.deprecated_.breakpoints.md}) {
    height: 100%;
    max-width: ${(props) => props.theme.deprecated_.breakpoints.md};
    margin: 0 auto;
    width: 100%;
  }
`;

// TODO::AUTH - @Chris again don't necessarily like this inner component thing but it quickly unblocks me
const InnerAppDefaultMode = () => {
  const { getToken } = useAuth();
  const { errorHandler } = useErrorHandlerProviderValue();

  const apolloClient = useMemo(() => {
    return createClient({ errorHandler, getToken });
  }, [errorHandler, getToken]);

  const identityManager = useFeatureFlagIdentityManager();

  return (
    <ApolloProvider client={apolloClient}>
      <AppErrorBoundary>
        {/* TODO::AUTH - @Chris I had to bump router above clerk and clerk above apollo to be able to access bearer token - is this ok or do we need a different way to do this? */}
        {/* <Router history={history}> */}
        <ViewerProvider identityManager={identityManager}>
          <ConversationsProvider>
            <ScrollToTop />
            <StyledContainer>
              <NavBar />
              <Body>
                <ChildProvider>
                  <Routes />
                </ChildProvider>
                <TermsAndConditionsModal />
                <InvalidSubscriptionModal />
              </Body>
            </StyledContainer>
          </ConversationsProvider>
        </ViewerProvider>
        {/* </Router> */}
      </AppErrorBoundary>
    </ApolloProvider>
  );
};

export const AppDefaultMode = () => {
  const { errorObservable, errorHandler } = useErrorHandlerProviderValue();

  return (
    <ModalProvider backgroundComponent={StyledModalBackground}>
      <ErrorHandlerProvider errorObservable={errorObservable} errorHandler={errorHandler}>
        <Router history={history}>
          <ClerkProvider>
            <InnerAppDefaultMode />
          </ClerkProvider>
        </Router>
      </ErrorHandlerProvider>
    </ModalProvider>
  );
};
