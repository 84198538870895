import styled, { createGlobalStyle } from 'styled-components';

export const Background = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.deprecated_.colors.backgroundBlue};
    width: 100%;
    height: 100%;
  }
`;

export const Root = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  background: ${({ theme }) => theme.deprecated_.colors.brandPurple};
  padding: ${({ theme }) => theme.spacing['07']};
  margin: ${({ theme }) => theme.spacing['02']} ${({ theme }) => theme.spacing['04']};
  @media (min-width: ${(props) => props.theme.deprecated_.breakpoints.md}) {
    padding: ${({ theme }) => theme.spacing['11']};
    margin: ${({ theme }) => theme.spacing['02']} ${({ theme }) => theme.spacing['14']};
  }
`;

export const ClipboardContainer = styled.div`
  float: left;
  height: ${({ theme }) => theme.spacing['11']};
  width: ${({ theme }) => theme.spacing['11']};
`;

export const Message = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  @media (min-width: ${(props) => props.theme.deprecated_.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  margin: ${({ theme }) => theme.spacing['05']} ${({ theme }) => theme.spacing['03']};
`;

export const Code = styled.span`
  background: ${({ theme }) => theme.deprecated_.colors.darkGray};
  color: ${({ theme }) => theme.deprecated_.colors.white};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: ${({ theme }) => theme.spacing['05']} ${({ theme }) => theme.spacing['03']};
  padding: ${({ theme }) => theme.spacing['05']} ${({ theme }) => theme.spacing['04']};
  margin-bottom: 0;
  text-align: center;
`;

export const FullReportAnchor = styled.a`
  background-color: ${({ theme }) => theme.deprecated_.colors.darkGray};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  padding: ${({ theme }) => theme.spacing['03']} ${({ theme }) => theme.spacing['07']};
  margin-top: ${({ theme }) => theme.spacing['07']};
  border-radius: ${({ theme }) => theme.borderRadius['03']};
  @media (min-width: ${(props) => props.theme.deprecated_.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    padding: ${({ theme }) => theme.spacing['06']} ${({ theme }) => theme.spacing['10']};
    margin-top: ${({ theme }) => theme.spacing['10']};
  }
  color: ${({ theme }) => theme.deprecated_.colors.white};
`;
