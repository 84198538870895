import { capitalize } from 'lodash-es';
import { type FC, type PropsWithChildren } from 'react';
import { type DefaultTheme } from 'styled-components';

import { FlexContainer, Illustration, LeftSection, RightSection, RiskText, Title } from './styled';
import { type Risk } from './types';

export type CardContentProps = {
  outerTitle?: string;
  innerTitle?: string;
  risk: Risk;
  illustrationSrc: string;
};

const subscaleRiskColors = {
  very_high: 'red',
  high: 'red',
  medium: 'green',
  low: 'text',
};

const getColorFromRisk = (risk: Risk): keyof DefaultTheme['deprecated_']['colors'] => {
  if (risk && Object.keys(subscaleRiskColors).includes(risk)) {
    const subscaleKey = risk as keyof typeof subscaleRiskColors;
    const foundColor = subscaleRiskColors[subscaleKey];
    return foundColor as keyof DefaultTheme['deprecated_']['colors'];
  }
  if (risk === 'missing' || !risk) {
    return 'lightGray';
  }
  return 'text';
};

export const CardContent: FC<PropsWithChildren<CardContentProps>> = ({
  outerTitle,
  innerTitle,
  risk,
  illustrationSrc,
}) => (
  <>
    {outerTitle && <Title>{outerTitle}</Title>}
    <FlexContainer>
      <LeftSection>
        {innerTitle && <Title>{innerTitle}</Title>}
        <RiskText color={getColorFromRisk(risk)}>
          {risk && risk !== 'missing' ? risk.split('_').map((word) => `${capitalize(word)} `) : 'N/A'}
        </RiskText>
      </LeftSection>
      <RightSection>
        <Illustration
          src={illustrationSrc}
          alt={`${[outerTitle, innerTitle].filter((t) => t !== undefined).join(' ')} Illustration`}
          isGrayscale={!risk}
        />
      </RightSection>
    </FlexContainer>
  </>
);
