import { type FC, type PropsWithChildren } from 'react';

import { useViewer } from '$shared/contexts/Viewer';
import { WithPrerequisites } from '$shared/utils/rerouter/WithPrerequisites';

import { routes } from '../../../../routes';
import { OverTime } from './OverTime';

export const OverTimeWithPrerequisites: FC<PropsWithChildren> = () => {
  const { viewer, loading } = useViewer();

  // HACK: viewer.hasConfirmedAppointments is needed because family.carestatus.activelyincare does not include welcome calls...
  // but the problem is now anyone with a single confirmed appointment can access OverTime. We would only like people to
  // access OverTime if they are actively in care!
  // When this is fixed, we also need to fix the logic used by FMHC report paywall.
  const viewerCanAccessOverTime =
    (viewer?.hasConfirmedAppointments ||
      viewer?.family?.careStatus.activelyInCare ||
      viewer?.family?.careStatus.isMember) ??
    false;
  return (
    <WithPrerequisites
      prerequisites={[
        {
          predicate: !viewerCanAccessOverTime,
          route: routes.reports.fmhcs.home,
          noRedirect: true,
        },
      ]}
      loading={loading}
    >
      <OverTime />
    </WithPrerequisites>
  );
};
