import { useQuery } from '@apollo/client';
import { useEffect, useState, type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Header, Modal } from '@littleotter/legacy-components';

import { type BannerMessage } from '$shared/hooks';
import { useGoto } from '$shared/utils/rerouter';

import {
  type ProfileFamilyQuery,
  type ProfileFamilyQuery_viewer_family_caregivers,
} from '../../../graphql/__generated__/ProfileFamilyQuery';
import { PROFILE_FAMILY_QUERY } from '../../../graphql/profile';
import { routes } from '../../../routes';
import { ReactComponent as SlothChild } from '../ChildProfile/SlothChild.svg';
import { FamilyMemberCard, FamilyMemberLinkCard } from '../components';
import { AddNewCard } from '../components/AddNewCard';
import { AddNewCaregiverForm } from './AddNewCaregiverForm';

const StyledSection = styled.section`
  margin-bottom: 22px;

  & h4 {
    margin-bottom: 18px;
  }
`;

type FamilyPageProps = {
  setBannerMessage: (message: BannerMessage | null) => void;
};

export const FamilyPage: FC<PropsWithChildren<FamilyPageProps>> = ({ setBannerMessage }) => {
  const { data, refetch } = useQuery<ProfileFamilyQuery>(PROFILE_FAMILY_QUERY, { fetchPolicy: 'no-cache' });
  const [caregivers, setCaregivers] = useState<ProfileFamilyQuery_viewer_family_caregivers[]>([]);
  const [showAddNewCaregiverModal, setShowAddNewCaregiverModal] = useState(false);
  const addNewChild = useGoto({
    route: routes.child.home,
    redirect: routes.profile.family,
  });

  useEffect(() => {
    setCaregivers(data?.viewer?.family?.caregivers.filter((caregiver) => caregiver.id !== data.viewer?.id) ?? []);
  }, [data]);

  const onAddNewCaregiverSuccessfulSubmit = () => {
    setShowAddNewCaregiverModal(false);
    refetch();
  };

  return (
    <>
      <StyledSection>
        <Header as="h4">Caregivers</Header>
        {data?.viewer && (
          <FamilyMemberLinkCard
            href={routes.profile.me.url()}
            profilePictureSrc={data.viewer.profilePicture}
            text={`${data.viewer.firstName} ${data.viewer.lastName}`}
            subText="You"
          />
        )}
        {caregivers.map((caregiver) => (
          <FamilyMemberCard
            key={caregiver.id}
            profilePictureSrc={caregiver.profilePicture}
            text={`${caregiver.firstName} ${caregiver.lastName}`}
            subText="Caregiver"
          />
        ))}
        <AddNewCard onClick={() => setShowAddNewCaregiverModal(true)} labelText="Add New Caregiver" />
        <Modal
          isOpen={showAddNewCaregiverModal}
          onEscapeKeydown={() => setShowAddNewCaregiverModal(false)}
          onBackgroundClick={() => setShowAddNewCaregiverModal(false)}
          onCloseButtonClick={() => setShowAddNewCaregiverModal(false)}
        >
          <AddNewCaregiverForm
            setBannerMessage={setBannerMessage}
            onSuccessfulSubmit={onAddNewCaregiverSuccessfulSubmit}
          />
        </Modal>
      </StyledSection>
      <StyledSection>
        <Header as="h4">Children</Header>
        {data?.viewer?.family?.children.map((child) => (
          <FamilyMemberLinkCard
            key={child.id}
            href={routes.profile.child.url({ id: Number(child.id) })}
            profilePictureSrc={child.profilePicture}
            text={`${child.displayName} ${child.lastName}`}
            subText="Child"
            Placeholder={SlothChild}
          />
        ))}
        <AddNewCard onClick={addNewChild} labelText="Add New Child" />
      </StyledSection>
    </>
  );
};
