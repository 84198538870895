import { format } from 'date-fns';
import { type FC, type PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Link, theme } from '@littleotter/legacy-components';

import { type UpcomingProvider, type UpcomingSession } from '../../../types';
import {
  ProviderImage,
  UpcomingSessionItem,
  UpcomingSessionItemContainer,
  UpcomingSessionItemContent,
  UpcomingSessionsUnorderedList,
} from './styled';

export type UpcomingSessionListProps = {
  upcomingSessions: UpcomingSession[];
};

export const UpcomingSessionList: FC<PropsWithChildren<UpcomingSessionListProps>> = ({ upcomingSessions }) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.deprecated_.breakpoints.md})`,
  });

  const sortedUpcomingSessions = [...upcomingSessions].sort(
    (sessionA, sessionB) => new Date(sessionA.startDatetime).getTime() - new Date(sessionB.startDatetime).getTime()
  );

  const getContentText = (date: Date, provider: UpcomingProvider) => {
    if (isDesktop) {
      return (
        <>
          {format(date, "EEEE, MMM do 'at' h:mmaaa")} with {`${provider.firstName.charAt(0)}. ${provider.lastName}`}
        </>
      );
    }
    return (
      <>
        {format(date, "EEE, MMM do '@' h:mmaaa")} w/ {`${provider.firstName.charAt(0)}. ${provider.lastName}`}
      </>
    );
  };

  const getZoomLink = (session: UpcomingSession) => {
    if (session.zoomLink) {
      return (
        <Link variant="link" isAnchor href={session.zoomLink}>
          Join session
        </Link>
      );
    }
  };

  return (
    <UpcomingSessionsUnorderedList>
      {sortedUpcomingSessions.slice(0, 3).map((session) => {
        const startDatetime = new Date(session.startDatetime);
        return (
          <UpcomingSessionItem key={startDatetime.getTime()}>
            <UpcomingSessionItemContainer>
              {isDesktop && <ProviderImage src={session.provider.profilePicture} />}
              <UpcomingSessionItemContent>
                {getContentText(startDatetime, session.provider)}
                <br />
                {getZoomLink(session)}
              </UpcomingSessionItemContent>
            </UpcomingSessionItemContainer>
          </UpcomingSessionItem>
        );
      })}
    </UpcomingSessionsUnorderedList>
  );
};
