import { type FC } from 'react';

import { type CustomWidgetProps } from '../types';
import { ProgressBar } from './components';
import { Counter } from './components/Counter';

export type ProgressWidgetOptions = {
  current: number;
  total: number;
};

type ProgressWidgetProps = CustomWidgetProps<ProgressWidgetOptions>;

export const ProgressWidget: FC<ProgressWidgetProps> = ({ options }) => {
  const { current, total } = options as ProgressWidgetOptions;
  const progress = (current / total) * 100;

  return (
    <section>
      <Counter current={current} total={total} />
      <ProgressBar progress={progress} />
    </section>
  );
};
