import { type FC } from 'react';
import styled, { keyframes } from 'styled-components';

const svgAnimation = keyframes`
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg)
    }
`;

const circleAnimation = keyframes`
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }

    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }

    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
`;

export const StyledSvg = styled.svg`
  animation: 2s linear infinite ${svgAnimation};
  max-width: calc(12 * ${({ theme }) => theme.deprecated_.sizeBasis});
`;

export const StyledCircle = styled.circle`
  animation: 1.4s ease-in-out infinite both ${circleAnimation};
  display: block;
  fill: transparent;
  stroke: #2f3d4c;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
`;

export type StyledLoadingProps = {
  width?: string;
  className?: string;
};

export const Loading: FC<StyledLoadingProps> = ({ width = '24', className }) => (
  <StyledSvg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" width={width}>
    <StyledCircle cx="50" cy="50" r="45" />
  </StyledSvg>
);
