import { type FC, type PropsWithChildren } from 'react';

import { DomainHeader, StyledP } from '../styled';
import { WorryDomainTag, WorryDomainTagsContainer } from './styled';

export type WorryDomainItemProps = {
  message: string;
  worries: string[];
};

export const WorryDomainItem: FC<PropsWithChildren<WorryDomainItemProps>> = ({ message, worries }) => {
  return (
    <li>
      <DomainHeader>Worries</DomainHeader>
      <StyledP>{message}</StyledP>
      <WorryDomainTagsContainer>
        {worries.map((worryDomain) => (
          <WorryDomainTag key={worryDomain}>{worryDomain}</WorryDomainTag>
        ))}
      </WorryDomainTagsContainer>
    </li>
  );
};
