import { type FC, type PropsWithChildren } from 'react';

import {
  StyledBody,
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledProfilePicture,
  StyledSubtitle,
  StyledTitle,
  StyledTitleContainer,
} from './styled';

export type CardContentProps = {
  title: string;
  subtitle: string;
  avatar: string;
  onClick: () => void;
};

// TODO: Update this Card component to reuse the new ./Card' component
export const CardContent: FC<PropsWithChildren<CardContentProps>> = ({
  onClick,
  title,
  subtitle,
  avatar,
  children,
}) => (
  <StyledContainer onClick={onClick}>
    <StyledContent>
      <StyledHeader>
        <StyledProfilePicture src={avatar} />
        <StyledTitleContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        </StyledTitleContainer>
      </StyledHeader>
      <StyledBody>{children}</StyledBody>
    </StyledContent>
  </StyledContainer>
);
