import { math } from 'polished';
import styled from 'styled-components';

export const ReportGrayCardContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
  padding: ${(props) => {
    const padding1 = math(`${props.theme.deprecated_.sizeBasis} * 1`);
    const padding2 = math(`${props.theme.deprecated_.sizeBasis} * 1.5`);
    return `${padding1} ${padding2} ${padding1} ${padding1}`;
  }};
`;

export const ReportGrayCardIcon = styled.span.attrs({ role: 'img', 'aria-label': 'start' })`
  margin-right: ${(props) => math(`${props.theme.deprecated_.sizeBasis} / 2`)};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.5`)};
`;

export const ReportGrayCardBody = styled.div`
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular}`)};
`;

export const ReportGrayCardTitle = styled.strong`
  display: block;
  font-size: ${({ theme }) => `${theme.deprecated_.fontSizes.regular}`};
  margin-bottom: ${(props) => math(`${props.theme.deprecated_.sizeBasis} * 0.188`)};
`;
