import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Header, HTMLContainer } from '@littleotter/legacy-components';

import { type GetTermsAndPolicies } from '../../graphql/__generated__/GetTermsAndPolicies';
import { GET_TERMS_AND_POLICIES } from '../../graphql/terms';

const MarginChildren = styled.div`
  margin-top: ${(props) => props.theme.deprecated_.sizeBasis};
  > *:not(last-child) {
    margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  }
`;

type Params = {
  slug?: string;
};

export const Terms = () => {
  const { slug } = useParams<Params>();
  const { data } = useQuery<GetTermsAndPolicies>(GET_TERMS_AND_POLICIES, {
    variables: { slug },
  });
  if (!data) {
    return null;
  }
  const { termsAndPolicies } = data;
  const { name, dateActive, info, text } = termsAndPolicies;
  return (
    <MarginChildren>
      <Helmet>
        <title>{`Little Otter | ${name}`}</title>
      </Helmet>
      <Header as="h1">{name}</Header>
      {dateActive && <Header as="h3">Effective Date: {format(new Date(dateActive), 'MMMM d, yyyy')}</Header>}
      {info && <HTMLContainer content={info} />}
      {text && <HTMLContainer content={text} />}
    </MarginChildren>
  );
};
