import { gql } from '@apollo/client';

import { WORRY_DOMAIN_FRAGMENT } from './worry-domains';

export const CHILD_FRAGMENT = gql`
  fragment ChildFragment on ChildType {
    id
    firstName
    lastName
    preferredName
    displayName
    dateOfBirth
    gender
    profilePicture
    isSeekingCare
    worryDomains {
      edges {
        node {
          id
          ...WorryDomainFragment
        }
      }
    }
  }

  ${WORRY_DOMAIN_FRAGMENT}
`;
