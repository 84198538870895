import styled from 'styled-components';

export const Root = styled.div`
  margin: ${({ theme }) => theme.spacing['06']} 0;
  padding: ${({ theme }) => theme.spacing['02']};
`;

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing['06']};
  overflow-x: auto;

  /* Style scrollbar for chrome, safari*/
  ::-webkit-scrollbar {
    background: transparent;
    height: ${({ theme }) => theme.spacing['03']};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.deprecated_.colors.gray};
    border-radius: ${({ theme }) => theme.borderRadius['03']};
  }

  /* Style scrollbar for firefox */
  scrollbar-color: ${({ theme }) => theme.deprecated_.colors.gray} transparent;
`;

export const Subject = styled.button<{ selected: boolean }>`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'unset')};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h4};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.deprecated_.lineHeight};
  color: ${({ theme, selected }) => (selected ? theme.deprecated_.colors.text : theme.deprecated_.colors.gray)};
  letter-spacing: normal;
  border: unset;
  background: unset;
  cursor: pointer;
`;
