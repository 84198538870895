import { useCallback, useEffect, useMemo, useState, type FC, type PropsWithChildren } from 'react';

import { CollapseGroup, FullButton, Header, type CollapseItem } from '@littleotter/legacy-components';

import { useChildrenWorryDomains, useWorryDomains } from './hooks';
import { WorryDomainsContentContainer } from './styled';
import { type Child, type SelectedChildrenWorryDomainPksMap, type WorryDomain } from './types';
import { getCollapseItem } from './utils';

type OnSaveWorryDomainsArgs = {
  selectedChildrenWorryDomainPksMap: SelectedChildrenWorryDomainPksMap;
  selectedCaregiverWorryDomainPks: number[];
  selectedFamilyWorryDomainPks: number[];
};

export type WorryDomainsContentProps = {
  nextStepsText: string;
  userChildren: Child[];
  childWorryDomains: WorryDomain[];
  caregiverWorryDomains: WorryDomain[];
  familyWorryDomains: WorryDomain[];
  onSaveWorryDomains: (args: OnSaveWorryDomainsArgs) => void;
  isLoading: boolean;
  defaultChildrenWorryDomainPksMap: SelectedChildrenWorryDomainPksMap;
  defaultCaregiverWorryDomainPks: number[];
  defaultFamilyWorryDomainPks: number[];
};

/**
 * Main component for the worry domains selection page of the onboarding
 *
 * @param props.userChildren array of `Child` objects required for mapping
 * worry domain options for each child
 *
 * @param props.childWorryDomains array of all the available worry domain
 * options that can be selected for each child
 *
 * @param props.caregiverWorryDomains array of all the available worry domain
 * options that can be selected for the caregiver
 *
 * @param props.familyWorryDomains array of all the available worry domain
 * options that can be selected for the family
 *
 * @param props.onSaveWorryDomains function that will be called when the active
 * collapse item is the last one and the user clicks the `Next: Scheduling`
 * button
 *
 * @param props.isLoading whether the component is in a loading state or not.
 * Setting this to true disables user interaction with the component.
 *
 * @param props.defaultChildrenWorryDomainPksMap default prefill values for
 * children worry domains.
 *
 * @param props.defaultCaregiverWorryDomainPks default prefill values for
 * caregiver worry domains.
 *
 * @param props.defaultFamilyWorryDomainPks default prefill values for family
 * worry domains.
 *
 */
export const WorryDomainsContent: FC<PropsWithChildren<WorryDomainsContentProps>> = ({
  nextStepsText,
  userChildren,
  childWorryDomains,
  caregiverWorryDomains,
  familyWorryDomains,
  onSaveWorryDomains,
  isLoading,
  defaultChildrenWorryDomainPksMap,
  defaultCaregiverWorryDomainPks,
  defaultFamilyWorryDomainPks,
}) => {
  if (!userChildren.length) {
    throw new Error('At least one child is required for `WorryDomainsContent`');
  }

  const initialCollapseId = useMemo(() => `child-${userChildren[0].id}`, [userChildren]);
  const [activeCollapseId, setActiveCollapseId] = useState<string>(initialCollapseId);

  // Collapse when loading, expand on first element when loading ends
  useEffect(() => {
    if (isLoading) {
      setActiveCollapseId('');
    } else {
      setActiveCollapseId(initialCollapseId);
    }
  }, [initialCollapseId, isLoading]);

  // Children can each have individual worry domains, so they must all be accounted for on
  // a child-by-child basis
  const { selectedChildrenWorryDomainPksMap, onChangeChildWorryDomain } = useChildrenWorryDomains(
    userChildren,
    defaultChildrenWorryDomainPksMap
  );

  const { selectedWorryDomainPks: selectedCaregiverWorryDomainPks, onChangeWorryDomain: onChangeCaregiverWorryDomain } =
    useWorryDomains(defaultCaregiverWorryDomainPks);

  const { selectedWorryDomainPks: selectedFamilyWorryDomainPks, onChangeWorryDomain: onChangeFamilyWorryDomain } =
    useWorryDomains(defaultFamilyWorryDomainPks);

  // collapseItems is wrapped in a memo as it
  // then allows the referential integrity to persist
  const collapseItems = useMemo<CollapseItem[]>(
    () => [
      ...userChildren.map<CollapseItem>((child) =>
        getCollapseItem({
          id: `child-${child.id}`,
          label: `For ${child.firstName}`,
          selectedWorryDomainPks: selectedChildrenWorryDomainPksMap[child.id] ?? [],
          worryDomains: childWorryDomains,
          onChangeWorryDomain: (worryDomainPk, checked) =>
            onChangeChildWorryDomain({ childId: child.id, worryDomainPk, checked }),
        })
      ),
      getCollapseItem({
        id: 'caregiver',
        label: 'For You',
        selectedWorryDomainPks: selectedCaregiverWorryDomainPks,
        worryDomains: caregiverWorryDomains,
        onChangeWorryDomain: (worryDomainPk, checked) => onChangeCaregiverWorryDomain({ worryDomainPk, checked }),
      }),
      getCollapseItem({
        id: 'family',
        label: 'For Your Family',
        selectedWorryDomainPks: selectedFamilyWorryDomainPks,
        worryDomains: familyWorryDomains,
        onChangeWorryDomain: (worryDomainPk, checked) => onChangeFamilyWorryDomain({ worryDomainPk, checked }),
      }),
    ],
    [
      caregiverWorryDomains,
      childWorryDomains,
      familyWorryDomains,
      onChangeCaregiverWorryDomain,
      onChangeChildWorryDomain,
      onChangeFamilyWorryDomain,
      selectedCaregiverWorryDomainPks,
      selectedChildrenWorryDomainPksMap,
      selectedFamilyWorryDomainPks,
      userChildren,
    ]
  );

  // onNextButtonClick changes behavior based on which collapse
  // item is selected
  const onNextButtonClick = useCallback(() => {
    if (activeCollapseId !== collapseItems[collapseItems.length - 1].id) {
      // For all collapse items but the last it should open the next one
      const activeCollapseIndex = collapseItems.findIndex((collapseItem) => collapseItem.id === activeCollapseId);
      setActiveCollapseId(collapseItems[activeCollapseIndex + 1].id);
    } else {
      // For the last collapse it should submit the values to the provided
      // callback
      onSaveWorryDomains({
        selectedChildrenWorryDomainPksMap,
        selectedCaregiverWorryDomainPks,
        selectedFamilyWorryDomainPks,
      });
    }
  }, [
    activeCollapseId,
    collapseItems,
    onSaveWorryDomains,
    selectedCaregiverWorryDomainPks,
    selectedChildrenWorryDomainPksMap,
    selectedFamilyWorryDomainPks,
  ]);

  const showExtraText =
    (!activeCollapseId || activeCollapseId === collapseItems[collapseItems.length - 1].id) && !!nextStepsText;
  const buttonText = `Next${showExtraText ? `: ${nextStepsText}` : ''}`;

  return (
    <WorryDomainsContentContainer>
      <Header as="h3">Tell us your worries</Header>
      <CollapseGroup
        iconVariant="plus-minus"
        items={collapseItems}
        activeId={activeCollapseId}
        onCollapseClick={(collapseId) => {
          if (isLoading) return;
          setActiveCollapseId(collapseId);
        }}
      />
      <FullButton type="button" variant="textBlue" onClick={onNextButtonClick} isLoading={isLoading}>
        {buttonText}
      </FullButton>
    </WorryDomainsContentContainer>
  );
};
