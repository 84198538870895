import { Header, RadioGroup } from '@littleotter/legacy-components';

import { getPronounsFromGender } from '$shared/utils/getPronounsFromGender';

import { MarginChildren } from '..';
import { type GetIntakeSurveys_surveys_edges_node_questions } from '../../graphql/__generated__/GetIntakeSurveys';

type QuestionProps = {
  childName: string;
  childGender: string | null;
  question: GetIntakeSurveys_surveys_edges_node_questions;
  onQuestionResponse: (answerId: string) => void;
};

export const Question = ({ childName, childGender, question, onQuestionResponse }: QuestionProps) => {
  const genderTerms = getPronounsFromGender(childGender);
  const textReplacement = {
    '{childName}': childName,
    '{childPronoun}': genderTerms.possessive,
    '{childSubjectivePronoun}': genderTerms.subjective,
    '{childObjectivePronoun}': genderTerms.objective,
  };
  const textReplacementRegex = new RegExp(Object.keys(textReplacement).join('|'), 'gi');
  const introText = question.introText?.replace(
    textReplacementRegex,
    (matched) => textReplacement[matched as keyof typeof textReplacement]
  );
  const endText = question.endText?.replace(
    textReplacementRegex,
    (matched) => textReplacement[matched as keyof typeof textReplacement]
  );

  const options = question.answers.map((answer) => ({
    label: answer.label,
    value: answer.id,
  }));

  return (
    <MarginChildren>
      {introText && <Header as="h4">{introText}</Header>}
      <RadioGroup
        name="question"
        displayAsTags
        options={options}
        onChange={(value) => {
          if (typeof value === 'string') onQuestionResponse(value);
        }}
      />
      {endText && <Header as="h4">{endText}</Header>}
    </MarginChildren>
  );
};
