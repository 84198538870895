import { useLocation } from 'react-router-dom';

import { useGetFmhcsWithRetakeWindow } from '$shared/scenarios';

import { routes } from '../../../routes';
import { useViewer } from '../../../shared/contexts/Viewer';
import { Presenter, type ProgrammersModel } from './Presenter';

const FMHC_STALENESS_THRESHOLD_DAYS = 90;

export const useViewModel = () => {
  const {
    latestSubmitted,
    latestFreshSubmitted,
    latestFreshUnsubmitted,
    loading: fmchLoading,
  } = useGetFmhcsWithRetakeWindow();
  const { isActivelyInCare, isMember, hasConfirmedAppointments, loading: viewerLoading } = useViewer();
  const loading = fmchLoading || viewerLoading;
  const { pathname } = useLocation();
  const isOverTimeView = pathname === routes.reports.fmhcs.overTime.path;
  const canAccessCareOverTime = !isOverTimeView && (isActivelyInCare || isMember || hasConfirmedAppointments);

  const pm: ProgrammersModel = {
    retakeThreshold: FMHC_STALENESS_THRESHOLD_DAYS,
    hasSubmittedFirstFmhc: !!latestSubmitted,
    canContinueFmhc: !!latestFreshUnsubmitted,
    canRetakeFmhc: !latestFreshSubmitted,
    mostRecentFmhc: latestSubmitted,
    canAccessCareOverTime,
  };

  const presenter = new Presenter(pm);
  presenter.load();

  return {
    ...presenter.vm,
    loading,
  };
};
