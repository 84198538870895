import { sanitize } from 'dompurify';

export const getWordCountFromString = (text: string) => text.trim().replace(/\s+/g, ' ').split(' ').length;

export const getWordCountFromContent = (content: string) => {
  const safeHTML = sanitize(content);
  const element = document.createElement('div');
  element.innerHTML = safeHTML;

  const plainText = element.innerText || element.textContent || '';

  return getWordCountFromString(plainText);
};

export const getReadTime = (content: string) => {
  const avgWordsPerMinute = 200;

  return Math.ceil(getWordCountFromContent(content) / avgWordsPerMinute);
};
