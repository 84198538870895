import { type FC, type PropsWithChildren } from 'react';

import { AppointmentsSection } from './AppointmentsSection';
import { AssessmentsAndDocumentationSection } from './AssessmentsAndDocumentationSection';
import { FamilyDetailsTopSection } from './FamilyDetailsTopSection';
import { FamilyDetailsContainer } from './styled';

export const FamilyDetails: FC<PropsWithChildren> = () => {
  return (
    <FamilyDetailsContainer>
      <FamilyDetailsTopSection />
      <AssessmentsAndDocumentationSection />
      <AppointmentsSection />
    </FamilyDetailsContainer>
  );
};
