import { gql } from '@apollo/client';

import { RESOURCES_FRAGMENT } from './resources-fragment';

export const LIBRARY_HOME_QUERY = gql`
  query LibraryHomeQuery {
    worryDomains(isTopLevel: true) {
      edges {
        node {
          pk
          name
          shortName
        }
      }
    }
    viewer {
      id
      favorites {
        ...ResourcesFragment
      }
      family {
        children {
          id
          worryDomains {
            edges {
              node {
                id
                name
                resourcesByTag(first: 10) {
                  ...ResourcesFragment
                }
              }
            }
          }
        }
        manuallyCuratedResources(first: 10) {
          ...ResourcesFragment
        }
      }
    }
  }
  ${RESOURCES_FRAGMENT}
`;
