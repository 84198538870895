export const REDIRECT_KEY = 'redirectTo';
export type PARAMS = typeof REDIRECT_KEY;
export type REDIRECT_PARAMS = {
  [key in PARAMS]: string;
};
export class RedirectError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, RedirectError.prototype);

    this.name = typeof this;
  }
}

export type LocationState = Record<string, unknown>;
