import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
  type FamilyWorryDomainsQuery,
  type FamilyWorryDomainsQuery_family_caregivers_worryDomains,
  type FamilyWorryDomainsQuery_family_worryDomains,
} from './__generated__/FamilyWorryDomainsQuery';
import { FAMILY_WORRY_DOMAINS_QUERY } from './familyWorryDomainsQuery';
import { type ChildIdToWorryDomainsMap } from './types';

export const useFamilyWorryDomains = (familyId: string, caregiverId: string) => {
  const { data: fwdData, loading } = useQuery<FamilyWorryDomainsQuery>(FAMILY_WORRY_DOMAINS_QUERY, {
    variables: { familyId: Number(familyId) },
  });
  const caregiverWorryDomains = useMemo(
    () =>
      fwdData?.family.caregivers
        .filter((c) => c.id === caregiverId)
        .map((c) => extractWorryDomains(c.worryDomains))
        .reduce((a, wds) => [...a, ...wds], []) ?? [],
    [fwdData, caregiverId]
  );

  const familyWorryDomains = useMemo(() => extractWorryDomains(fwdData?.family.worryDomains), [fwdData]);

  const childrenWorryDomainsByChildId = useMemo(
    () =>
      (fwdData?.family.children ?? []).reduce(
        (acc, child) => ({
          ...acc,
          [child.id]: extractWorryDomains(child.worryDomains),
        }),
        {} as ChildIdToWorryDomainsMap
      ),
    [fwdData]
  );

  return { caregiverWorryDomains, familyWorryDomains, childrenWorryDomainsByChildId, loading };
};

const extractWorryDomains = (
  wds:
    | FamilyWorryDomainsQuery_family_caregivers_worryDomains
    | FamilyWorryDomainsQuery_family_worryDomains
    | FamilyWorryDomainsQuery_family_caregivers_worryDomains
    | undefined
    | null
): string[] => (wds?.edges ?? []).map((edge) => edge?.node?.name).filter((wd): wd is string => !!wd);
