import { type FC, type PropsWithChildren } from 'react';

import { parseInlineMarkdown } from '../../../../../FmhcCheckup/utils/markdownParser';
import {
  CardContainer,
  ChildrenContainer,
  Container,
  IndentedText,
  ScheduleButton,
  StyledSecondaryTitle,
  Title,
  TitleIcon,
  TitleText,
} from './styled';

export type FullReportCardProps = {
  titleTemplate: string;
  titleIcon: string;
  bodyTemplate: string;
  secondaryTitle?: string;
  secondaryText?: string;
  templateVariables?: {
    [key: string]: string;
  };
  buttonText: string;
  onClick: () => void;
};

export const FullReportCard: FC<PropsWithChildren<FullReportCardProps>> = ({
  titleTemplate,
  titleIcon,
  bodyTemplate,
  templateVariables,
  secondaryTitle,
  secondaryText,
  buttonText,
  onClick,
  children,
}) => {
  const titleParsed = parseInlineMarkdown({ template: titleTemplate, variables: templateVariables });
  const bodyParsed = parseInlineMarkdown({ template: bodyTemplate, variables: templateVariables });

  return (
    <CardContainer>
      <Title>
        <TitleIcon>{titleIcon}</TitleIcon>
        <TitleText dangerouslySetInnerHTML={{ __html: titleParsed }} />
      </Title>
      <Container dangerouslySetInnerHTML={{ __html: bodyParsed }} />
      {secondaryTitle && <StyledSecondaryTitle>{secondaryTitle}</StyledSecondaryTitle>}
      {secondaryText && <IndentedText>{secondaryText}</IndentedText>}
      {children && <ChildrenContainer>{children}</ChildrenContainer>}
      <ScheduleButton type="button" variant="outlineBold" onClick={onClick}>
        {buttonText}
      </ScheduleButton>
    </CardContainer>
  );
};
