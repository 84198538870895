import { type FC, type PropsWithChildren } from 'react';

import { getChildIntroImage } from '$shared/utils/childIntroImage';

import * as Styled from './styled';
import { SummaryCard } from './SummaryCard';
import { getBackground } from './SummaryCard/backgrounds';
import { DomainList } from './SummaryCard/DomainList';
import * as StyledDomainList from './SummaryCard/DomainList/styled';
import { type Person } from './types';

export const FamilyOverview: FC<PropsWithChildren<FamilyOverviewProps>> = ({ people }) => {
  return (
    <Styled.Root>
      {people.map((p) => (
        <SummaryCard
          key={p.id}
          title={p.displayName ?? p.name}
          background={getBackground(p.name)}
          icon={getChildIntroImage(p.name)}
        >
          <StyledDomainList.Container>
            <DomainList concerning domains={p.concerningDomains} />
            <DomainList domains={p.typicalDomains} />
          </StyledDomainList.Container>
        </SummaryCard>
      ))}
    </Styled.Root>
  );
};

export interface FamilyOverviewProps {
  people: Person[];
}
