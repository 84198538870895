import { type FC, type PropsWithChildren } from 'react';

import { KangaroosImage } from './KangaroosImage';
import { SlothsImage } from './SlothsImage';

export type ImageIconType = 'sloths' | 'kangaroos';

export const ImageIcon: FC<PropsWithChildren<{ type: ImageIconType }>> = ({ type }) =>
  ({
    sloths: <SlothsImage />,
    kangaroos: <KangaroosImage />,
  })[type] ?? null;
