import { math } from 'polished';
import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';

import { Header } from '../Header';

export type ItemProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  avatar?: string;
  actions?: ReactNode;
  onClick?: () => void;
};

const StyledContainer = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.deprecated_.sizeBasis};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};
  box-shadow: 0px 26px 60px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
`;

const StyledContent = styled.div`
  display: flex;
`;

const StyledActionsContent = styled.div`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  margin-top: 15px;

  @media (min-width: ${(props) => props.theme.deprecated_.breakpoints.md}) {
    margin-top: 0;
    margin-left: calc(5 * ${({ theme }) => theme.deprecated_.sizeBasis});
  }
`;

const StyledImageContainer = styled.div<{ avatar?: string }>`
  height: calc(4 * ${(props) => props.theme.deprecated_.sizeBasis});
  width: 100%;
  max-width: calc(4 * ${(props) => props.theme.deprecated_.sizeBasis});
  max-height: calc(4 * ${(props) => props.theme.deprecated_.sizeBasis});
  margin-right: ${(props) => props.theme.deprecated_.sizeBasis};
  overflow: hidden;
  border-radius: 100%;
  border: ${({ theme }) =>
    `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.colors.brandPurple}`};
  background-color: ${({ avatar, theme }) => (avatar ? 'none' : theme.deprecated_.colors.lightPurple)};
`;

const StyledImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > *:not(:last-child) {
    margin-bottom: calc(${(props) => props.theme.deprecated_.sizeBasis} / 3);
  }
`;

const StyledSubtitle = styled.div`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  > *:not(:last-child) {
    margin-bottom: calc(${(props) => props.theme.deprecated_.sizeBasis} / 3);
  }
`;

export const HorizontalCard: FC<ItemProps> = ({ title, subtitle, avatar, actions, onClick }) => (
  <StyledContainer onClick={onClick}>
    <StyledContent>
      <StyledImageContainer avatar={avatar}>{avatar && <StyledImage src={avatar} alt="" />}</StyledImageContainer>
      <StyledInfoContainer>
        <Header as="h5">{title}</Header>
        {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      </StyledInfoContainer>
    </StyledContent>
    {actions && <StyledActionsContent>{actions}</StyledActionsContent>}
  </StyledContainer>
);
