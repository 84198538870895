import { useEffect } from 'react';

import { useGetLatestSubmittedFmhcLazyQuery } from '../../../graphql/lo1/generated';

// TODO: delete this in favor of useFmhcsQuery with filtering for latest submitted FMHC
export const useLatestFmhcReportId = (familyId?: string) => {
  const [getLatestSubmittedFmhc, { loading, data: latestSubmittedFmhcResult }] = useGetLatestSubmittedFmhcLazyQuery();

  useEffect(() => {
    (async () => {
      if (familyId) {
        await getLatestSubmittedFmhc({ variables: { request: { familyId } } });
      }
    })();
  }, [getLatestSubmittedFmhc, familyId]);

  const latestFmhc = latestSubmittedFmhcResult?.GetLatestSubmittedFmhc.fmhc;
  return {
    latestFmhc,
    loading,
  };
};
