import { hashString } from '$shared/utils/hash';

import blue from '../../../../../../Reports/Fmhc/ReportsContent/components/SummaryContent/assets/backgrounds/bg_blue.svg';
import dkpurple from '../../../../../../Reports/Fmhc/ReportsContent/components/SummaryContent/assets/backgrounds/bg_dkpurple.svg';
import green from '../../../../../../Reports/Fmhc/ReportsContent/components/SummaryContent/assets/backgrounds/bg_green.svg';
import ltpurple from '../../../../../../Reports/Fmhc/ReportsContent/components/SummaryContent/assets/backgrounds/bg_ltpurple.svg';
import orange from '../../../../../../Reports/Fmhc/ReportsContent/components/SummaryContent/assets/backgrounds/bg_orange.svg';
import yellow from '../../../../../../Reports/Fmhc/ReportsContent/components/SummaryContent/assets/backgrounds/bg_yellow.svg';

export const BACKGROUNDS = {
  blue: [blue, '#63ABDF'],
  dkpurple: [dkpurple, '#D1B0EB'],
  green: [green, '#56BEBA'],
  orange: [orange, '#EE9462'],
  yellow: [yellow, '#EEBE62'],
  ltpurple: [ltpurple, '#E0CCEF'],
};

/**
 * Randomly and consistently match a child's name to a background.
 * @param childName
 * @returns the random and consistent background match based on the child's name
 */
export const getBackground = (childName: string): keyof typeof BACKGROUNDS => {
  const backgrounds = Object.keys(BACKGROUNDS) as (keyof typeof BACKGROUNDS)[];
  const length = backgrounds.length - 1;
  const value = hashString(childName) % length;
  return backgrounds[value];
};
