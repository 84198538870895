import * as styled from './styled';

export const BaseCta = ({ vm, actions }: BaseCtaProps) => (
  <styled.Root>
    <styled.Title>{vm.title}</styled.Title>
    <styled.Content>
      <ol style={{ marginBottom: 0, marginLeft: '20px', listStyleType: 'decimal' }}>
        {vm.steps.map((step) => (
          <li key={step}>{step}</li>
        ))}
      </ol>
    </styled.Content>
    <styled.StyledButton variant="textBlue" onClick={actions.onClick}>
      {vm.buttonText}
    </styled.StyledButton>
  </styled.Root>
);

export interface BaseCtaProps {
  vm: {
    title: string;
    steps: string[];
    buttonText: string;
  };
  actions: { onClick: () => void };
}
