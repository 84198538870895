import { type FC } from 'react';

import { type CustomWidgetProps } from '../types';
import { Image } from './components';

export type ImageWidgetOptions = {
  alt: string;
  src: string;
  maxWidth?: string;
  maxHeight?: string;
};

type ImageWidgetProps = CustomWidgetProps<ImageWidgetOptions>;

export const ImageWidget: FC<ImageWidgetProps> = ({ options }) => {
  const { alt, src, maxWidth, maxHeight } = options as ImageWidgetOptions;

  return <Image src={src} alt={alt} maxWidth={maxWidth} maxHeight={maxHeight} />;
};
