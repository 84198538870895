import { type GraphQLError as BaseGraphQLError } from 'graphql';

import { authenticatedRoutes } from '../../routes';
import { loginProxyUrl } from './proxy';
import { flattenRoutes } from './routes';

export type ErrorTypes = 'UNAUTHENTICATED' | 'TERMS_NOT_AGREED' | 'INVALID_SUBSCRIPTION' | 'ONBOARDING_INCOMPLETE';

// Key to mark GraphQL errors as handled in the extensions object
export const handledKey = 'lo-error-handled';

export interface GraphQLError extends BaseGraphQLError {
  extensions: {
    code: ErrorTypes;
    [handledKey]?: boolean;
  };
}

export const goToLogin = () => {
  const isAuthenticatedRoute = flattenRoutes(authenticatedRoutes).some((route) => route.test(window.location.pathname));

  if (!isAuthenticatedRoute) {
    return;
  }

  window.location.href = loginProxyUrl;
};
