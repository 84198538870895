import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { type SearchUsersQuery } from 'src/graphql/__generated__/SearchUsersQuery';

import { SEARCH_USER_QUERY } from '../../../../../../graphql/new-conversation-form';

export const useLiveSearchResults = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchUsersQuery>();
  const [searchUsers, { data: queryResults, loading }] = useLazyQuery<SearchUsersQuery>(SEARCH_USER_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (searchTerm !== '') {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        searchUsers({
          variables: { searchTerm },
        });
      }, 400);
    }

    return () => {
      // Clean up the timer if the component unmounts or if the search term changes
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [searchTerm, searchUsers]);

  useEffect(() => {
    setSearchResults(queryResults);
  }, [queryResults]);

  const search = useCallback((term: string) => {
    setSearchTerm(term);
  }, []);

  return {
    search,
    searchResults,
    loading,
  };
};
