import { useMutation } from '@apollo/client';
import { differenceInYears } from 'date-fns';
import { useStateMachine } from 'little-state-machine';
import { lighten, math } from 'polished';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form6';
import { AiOutlineClose } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Header, Input, Modal } from '@littleotter/legacy-components';

import { useEventTracking } from '$services/event-tracking';
import { capitalize } from '$shared/utils/formatString';
import { WithPrerequisites } from '$shared/utils/rerouter/WithPrerequisites';

import { MarginChildren, NextButton, NextLink } from '../../../components';
import { WorryDomainIllustration } from '../../../components/WorryDomainIllustration';
import {
  type SendTemperTanturmResultsEmailMutation,
  type SendTemperTanturmResultsEmailMutationVariables,
} from '../../../graphql/__generated__/SendTemperTanturmResultsEmailMutation';
import { SEND_TEMPER_TANTRUM_EMAIL } from '../../../graphql/temper-tantrum-email';
import { routes } from '../../../routes';
import { type TantrumState } from './components/Tantrum';

const ModalButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  & *:first-child {
    margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

const ModalCloseIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const StyledForm = styled.form`
  position: relative;
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.deprecated_.sizeBasis};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 ${({ theme }) => theme.deprecated_.sizeBasis};
  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    width: 75%;
    margin: 0 auto;
    padding: 0;
  }
`;

const Card = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => math(`2 * ${theme.deprecated_.sizeBasis}`)};
  padding: calc(2 * ${(props) => props.theme.deprecated_.sizeBasis}) ${(props) => props.theme.deprecated_.sizeBasis};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
`;

const Illustation = styled.div`
  width: 100%;
  height: calc(8 * ${({ theme }) => theme.deprecated_.sizeBasis});
  padding: calc(${({ theme }) => theme.deprecated_.sizeBasis} / 2);
  background-color: ${({ theme }) => lighten(0.17, theme.deprecated_.colors.lightBackgroundBlue)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
  flex: 1;
  > svg {
    width: 100%;
    height: 100%;
  }
`;

const categorizationToColorMap = {
  low: 'green',
  medium: 'yellow',
  high: 'red',
} as const;

type RiskCategorization = 'low' | 'medium' | 'high';

const RiskCategorizationTag = styled.p<{ riskCategorization: RiskCategorization }>`
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  padding: calc(${({ theme }) => theme.deprecated_.sizeBasis} / 4) ${({ theme }) => theme.deprecated_.sizeBasis};
  color: ${({ theme }) => theme.deprecated_.colors.white};
  background-color: ${({ theme, riskCategorization }) =>
    theme.deprecated_.colors[categorizationToColorMap[riskCategorization]]};
  text-align: center;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;

const RiskCategorizationTagWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: calc(${({ theme }) => theme.deprecated_.sizeBasis} * 0.75);
`;

const StyledList = styled.ul`
  list-style-type: disc;
  margin-left: ${({ theme }) => theme.deprecated_.sizeBasis};
  > li:not(:last-child) {
    margin-bottom: calc(${({ theme }) => theme.deprecated_.sizeBasis} / 2);
  }
`;

const ParagraphMoreLineHeight = styled.p`
  line-height: 1.4;
`;

const FeedbackButton = styled(NextButton)`
  margin-top: 0;
`;

const FeedbackLink = styled(NextLink)`
  margin-top: 0;
`;

const ResultsRow = styled.div`
  display: flex;
  width: 100%;
  > * {
    padding: ${({ theme }) => theme.deprecated_.sizeBasis};
    flex: 1;
    text-align: center;
    height: calc(5.75 * ${({ theme }) => theme.deprecated_.sizeBasis});
    &:not(:last-child) {
      border-right: ${({ theme }) =>
        `${theme.deprecated_.border.size} ${lighten(0.33, theme.deprecated_.colors.text)} ${
          theme.deprecated_.border.style
        }`};
    }
  }
`;

const ResultsHeader = styled.p`
  padding: calc(${({ theme }) => theme.deprecated_.sizeBasis} / 4) 0;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h5};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  margin-bottom: calc(${({ theme }) => theme.deprecated_.sizeBasis} * 0.75);
`;

const ResultsLabel = styled.p`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.smaller};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  text-transform: uppercase;
`;

type ShareProps = {
  modalOpen: boolean;
  closeModal: () => void;
  childName: string;
  tantrumScore: string;
  tantrumSummary: HTMLDivElement | null;
  email: string;
};

const SendEmailModal = ({ modalOpen, closeModal, childName, tantrumScore, tantrumSummary, email }: ShareProps) => {
  const { control, handleSubmit, formState, errors } = useForm({
    defaultValues: {
      email,
    },
    mode: 'all',
  });
  const [sendTemperTantrumResultsEmail] = useMutation<
    SendTemperTanturmResultsEmailMutation,
    SendTemperTanturmResultsEmailMutationVariables
  >(SEND_TEMPER_TANTRUM_EMAIL);

  if (!tantrumSummary) {
    return null;
  }

  const onSubmit: SubmitHandler<{ email: string }> = async (values) => {
    const input = {
      ...values,
      childName,
      tantrumScore,
      tantrumSummary: tantrumSummary.innerHTML,
    };
    await sendTemperTantrumResultsEmail({ variables: { input } });
    closeModal();
  };

  return (
    <Modal
      isOpen={modalOpen}
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
      onCloseButtonClick={closeModal}
    >
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <ModalCloseIcon onClick={closeModal}>
          <AiOutlineClose />
        </ModalCloseIcon>
        <Header as="h2">Send Results</Header>
        <Controller
          as={Input}
          control={control}
          name="email"
          type="email"
          label="Email Address"
          errors={errors}
          rules={{ required: 'Email address is required' }}
        />
        <ModalButtonRow>
          <Button variant="primary" type="submit" disabled={!formState.isValid} isLoading={formState.isSubmitting}>
            Send
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </ModalButtonRow>
      </StyledForm>
    </Modal>
  );
};

export const FeedbackWithPrerequisites = () => {
  const {
    state: { tantrumData },
  } = useStateMachine<TantrumState>();

  const childEntered = !!(tantrumData.child.firstName && tantrumData.child.dateOfBirth);
  const surveyStarted = tantrumData.surveyResponses.length > 0;

  return (
    <WithPrerequisites
      prerequisites={[
        {
          predicate: !childEntered || !surveyStarted,
          route: routes.tantrums.home,
          noRedirect: true,
        },
      ]}
      loading={false}
    >
      <Feedback />
    </WithPrerequisites>
  );
};

export const Feedback = () => {
  const { state } = useStateMachine<TantrumState>();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const { track } = useEventTracking();
  const riskCopyRef = useRef<HTMLDivElement>(null);

  const { child } = state.tantrumData;
  const { survey } = state.tantrumData;

  const { lowRiskThreshold } = survey;
  const { highRiskThreshold } = survey;
  let frequencyScore = 0;
  const score = state.tantrumData.surveyResponses.reduce((accum, currentResponse) => {
    if (currentResponse.questionPk === 7) {
      frequencyScore = Number(currentResponse.score);
    }
    return accum + Number(currentResponse.score);
  }, 0);

  let riskCategorization: RiskCategorization = 'low';
  let tantrumSummary =
    score === 0
      ? 'Hooray! Your child is not having tantrums now. They have a low risk for mental health challenges.'
      : `${child.firstName}’s tantrums are “typical” for a child their age. Kids with typical tantrums are not at higher risk for mental health challenges. However, even “typical” tantrums are upsetting–for you and for your child.`;
  let riskCopy = <ParagraphMoreLineHeight>{tantrumSummary}</ParagraphMoreLineHeight>;
  let tantrumListItems =
    score === 0 ? (
      <li>
        <strong>No tantrums</strong>
      </li>
    ) : (
      <>
        <li>
          <strong>Not every day</strong>
        </li>
        <li>
          <strong>Not aggressive</strong>
        </li>
      </>
    );
  let howWeHelpHeader = 'Tantrums can be upsetting no matter how frequent or aggressive.';

  if (score >= highRiskThreshold) {
    riskCategorization = 'high';
    tantrumSummary = `Tantrums that happen nearly every day and that are sometimes aggressive are concerning. They aren’t typical for children ${child.firstName}’s age. We call these concerning tantrums “mental health fevers.” Just like a fever, concerning tantrums tell us that something may be impacting your child’s health, in this case, your child’s mental health. You might want to talk with ${child.firstName}’s pediatrician or an early childhood mental health specialist.`;
    riskCopy = <ParagraphMoreLineHeight>{tantrumSummary}</ParagraphMoreLineHeight>;
    tantrumListItems = (
      <>
        <li>
          <strong>Nearly every day</strong>
        </li>
        <li>
          <strong>Aggressive</strong>
        </li>
      </>
    );
    howWeHelpHeader =
      'We know it is very upsetting when your little one is having an aggressive tantrum nearly every day!';
  } else if (score > lowRiskThreshold) {
    riskCategorization = 'medium';
    riskCopy = (
      <>
        <ParagraphMoreLineHeight>
          This type of tantrum is not uncommon but is a reason to consider exploring whether your child has an
          underlying mental health challenge.
        </ParagraphMoreLineHeight>
        <br />
        <ParagraphMoreLineHeight>
          <>
            {frequencyScore === 1 ? (
              <span>
                <strong>2 out of 10 children</strong> with daily, non-aggressive tantrums{' '}
              </span>
            ) : (
              <span>
                <strong>3 out of 10 kids</strong> with aggressive tantrums{' '}
              </span>
            )}
            have a mental health challenge, like anxiety or ADHD.
          </>
        </ParagraphMoreLineHeight>
      </>
    );
    if (frequencyScore === 1) {
      tantrumListItems = (
        <>
          <li>
            <strong>Nearly every day</strong>
          </li>
          <li>
            <strong>Not aggressive</strong>
          </li>
        </>
      );
      howWeHelpHeader = 'We know daily tantrums can wear you down.';
    } else {
      tantrumListItems = (
        <>
          <li>
            <strong>Not every day</strong>
          </li>
          <li>
            <strong>Aggressive</strong>
          </li>
        </>
      );
      howWeHelpHeader = 'We know it is very upsetting when your little one is aggressive during a tantrum!';
    }
  }

  useEffect(() => {
    track('FINISHED_TEMPER_TANTRUM_ASSESSMENT');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const childAge = differenceInYears(new Date(), new Date(child.dateOfBirth));

  return (
    <Container>
      <MarginChildren>
        <Header as="h3" style={{ textAlign: 'center' }}>
          Your Results
        </Header>
        <ResultsRow>
          <MarginChildren>
            <ResultsHeader>{child.firstName}</ResultsHeader>
            <ResultsLabel>Child</ResultsLabel>
          </MarginChildren>
          <MarginChildren>
            <ResultsHeader>{childAge} Years</ResultsHeader>
            <ResultsLabel>Age</ResultsLabel>
          </MarginChildren>
          <MarginChildren>
            <RiskCategorizationTagWrapper>
              <RiskCategorizationTag riskCategorization={riskCategorization}>
                {capitalize(riskCategorization)}
              </RiskCategorizationTag>
            </RiskCategorizationTagWrapper>

            <ResultsLabel>Risk</ResultsLabel>
          </MarginChildren>
        </ResultsRow>
        <Illustation>
          <WorryDomainIllustration worryDomainShortName="anger_aggression" />
        </Illustation>
        <Header as="h4">{child.firstName}'s Tantrums</Header>
        <StyledList>{tantrumListItems}</StyledList>
        <div ref={riskCopyRef}>{riskCopy}</div>
        <FeedbackButton
          variant="secondary"
          onClick={() => window.open(`https://littleotterhealth.com/blog/tantrums${location.search}`, '_blank')}
        >
          Learn More About Tantrums
        </FeedbackButton>
        <FeedbackLink isAnchor variant="primary" href={routes.unauthOnboarding.url()}>
          Sign Up for Little Otter
        </FeedbackLink>
        <Card>
          <MarginChildren>
            <Header as="h4">How we help</Header>
            <Header as="h5">{howWeHelpHeader}</Header>
            <ParagraphMoreLineHeight>
              Little Otter is here to help you understand {child.firstName}’s tantrums, provide you with support and
              strategies to manage them, and work with you to find out if your child is experiencing emotional,
              behavioral, or development challenges.
            </ParagraphMoreLineHeight>
            <ParagraphMoreLineHeight>We are here to help your child and family!</ParagraphMoreLineHeight>
            <FeedbackButton
              variant="secondary"
              onClick={() =>
                window.open(`https://www.littleotterhealth.com/ask-a-child-psychologist${location.search}`, '_blank')
              }
            >
              Ask a Little Otter Psychologist
            </FeedbackButton>
            <FeedbackLink isAnchor variant="primary" href={routes.unauthOnboarding.url()}>
              Sign Up for Little Otter
            </FeedbackLink>
          </MarginChildren>
        </Card>
        <Header as="h4" style={{ marginTop: '2rem' }}>
          What to do with these results?
        </Header>
        <StyledList>
          <li>
            Share them with your partner, pediatrician, or child’s teacher to start a conversation about your child’s
            mental health.
          </li>
          <li>
            Reach out to our team of professionals to learn more about your child’s mental health and learn skills that
            can help your entire family.
          </li>
        </StyledList>
        <FeedbackButton variant="secondary" onClick={() => setModalOpen(true)}>
          Share Results
        </FeedbackButton>
        <SendEmailModal
          modalOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
          tantrumScore={riskCategorization}
          tantrumSummary={riskCopyRef.current}
          childName={child.firstName}
          email={state.tantrumData.email}
        />
      </MarginChildren>
    </Container>
  );
};
