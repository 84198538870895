import { Conversation, type Conversation as ConversationT } from '@twilio/conversations';

import { type ClosedConversationInfo } from '$shared/contexts/Conversations/hooks/useClosedConversationsInfo';

import { type ConversationPreview } from '../../../shared/contexts/Conversations/conversationPreview';

export const getLastActiveAt = (c: ConversationT | ClosedConversationInfo | ConversationPreview) => {
  if (c instanceof Conversation) {
    if (c.lastMessage && c.lastMessage.dateCreated) {
      return new Date(c.lastMessage.dateCreated);
    }
    return new Date(c.dateCreated || '');
  }
  return new Date(c.lastActiveAt);
};
