import { retrieveTimezoneByAbbreviation } from '$shared/utils/timezoneLookup';

import { type ConversationInfoFragment } from '../../../graphql/__generated__/ConversationInfoFragment';
import { type UserState } from '../../../graphql/__generated__/globalTypes';

type ConversationParticipants = ConversationInfoFragment['participants'];

const getFormattedTimezone: (state: UserState) => string = (state) =>
  `(${state.valueOf()} ${retrieveTimezoneByAbbreviation(state.valueOf())})`;

export const getConversationTitle = (participants: ConversationParticipants) =>
  participants.map((p) => `${p.firstName}${p.lastName ? ` ${p.lastName}` : ''}`).join(' & ');

export const getConversationTitleWithTimezone = (participants: ConversationParticipants) =>
  participants
    .map(
      (p) => `${p.firstName}${p.lastName ? ` ${p.lastName}` : ''}${p.state ? ` ${getFormattedTimezone(p.state)}` : ''}`
    )
    .join(' & ');
