import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { math } from 'polished';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Banner, Header, Icon, Link, ProfilePicture } from '@littleotter/legacy-components';

import { useBannerMessage, useGraphQLErrorHandling } from '$shared/hooks';
import { appointmentsProxyUrl } from '$shared/utils/proxy';

import { MarginChildren } from '../../../components';
import { PageWideLoading } from '../../../components/PageWideLoading';
import { UpcomingAppointment } from '../../../components/UpcomingAppointment';
import {
  type CarePlanQuery,
  type CarePlanQuery_treatmentNotes,
  type CarePlanQueryVariables,
} from '../../../graphql/__generated__/CarePlanQuery';
import { CARE_PLAN_QUERY } from '../../../graphql/care-plan';
import { MissingQueryDataError } from '../../../graphql/errors';
import { routes } from '../../../routes';
import { useLatestFmhcReportId } from '../hooks/useLatestFmhcReportId';
import { MarginContainer } from './styled';
import { removeVersionTagFromNoteName } from './utils/removeVersionTagFromNoteName';

const Container = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
  }
`;

const Wrapper = styled.div`
  box-shadow: 0px 26px 60px rgba(0, 0, 0, 0.05);
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
`;

const SessionCard = styled.div<{ shouldShowPointer?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: ${({ theme }) => `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} transparent`};
  border-radius: ${({ theme }) => math(`${theme.deprecated_.border.radius} * 2`)};
  transition: all 0.2s linear;
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  ${({ shouldShowPointer }) => shouldShowPointer && 'cursor: pointer;'}

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.deprecated_.border.color};
    box-shadow: ${({ theme }) => `0 4px 8px ${theme.deprecated_.colors.lightGray}`};
  }
`;
export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
`;

const BigText = styled.div`
  margin: ${({ theme }) => `0 ${theme.deprecated_.sizeBasis}`};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.large};
  color: ${({ theme }) => theme.deprecated_.colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProviderName = styled.p`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  color: ${({ theme }) => theme.deprecated_.colors.text};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
`;

export const SessionDate = styled.p`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;

const FloatRightLink = styled(Link)`
  float: right;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
`;

export const CareJourney = () => {
  // uses next-gen schedule appointments page
  const scheduleHref = appointmentsProxyUrl;
  const {
    data,
    loading: carePlanLoading,
    error: carePlanError,
  } = useQuery<CarePlanQuery, CarePlanQueryVariables>(CARE_PLAN_QUERY, {
    variables: { includeNoteSummary: false },
  });

  useGraphQLErrorHandling(carePlanError);

  const { bannerMessage, bannerIsShowing, setBannerMessage } = useBannerMessage();
  const history = useHistory();
  const { latestFmhc, loading: latestFmhcIdLoading } = useLatestFmhcReportId(data?.viewer?.family?.id);
  const latestFmhcId = latestFmhc?.id;

  const loading = carePlanLoading || latestFmhcIdLoading;

  if (loading) return <PageWideLoading />;
  if (!data?.viewer?.family) throw new MissingQueryDataError('CarePlanQuery');

  const sessionNotes: CarePlanQuery_treatmentNotes[] = data.treatmentNotes.map((note) => ({
    ...note,
    noteName: removeVersionTagFromNoteName(note.noteName),
  }));

  const {
    viewer: {
      hasConfirmedAppointments,
      family: { nextAppointment },
    },
  } = data;

  return (
    <MarginContainer>
      <Banner
        message={bannerMessage.message}
        isShowing={bannerIsShowing}
        variant={bannerMessage.type}
        onTimeout={() => setBannerMessage(null)}
      />
      <Container>
        <MarginChildren>
          <Header as="h4">Upcoming Appointments</Header>
          <UpcomingAppointment
            nextAppointment={nextAppointment}
            setBannerMessage={setBannerMessage}
            hasConfirmedAppointments={hasConfirmedAppointments}
          />
        </MarginChildren>
        {latestFmhcId && (
          <MarginChildren>
            <Header as="h4">Reports</Header>
            <Wrapper>
              <SessionCard
                shouldShowPointer
                onClick={() => {
                  const latestFmhcIdRoute = routes.reports.fmhcs.fmhc.url({ fmhcId: latestFmhcId });
                  history.push(latestFmhcIdRoute);
                }}
              >
                <ProfilePicture size="smaller" />
                <Content>
                  <Header as="h5">Initial Check-up Results</Header>
                </Content>
                <BigText>
                  <Icon name="ChevronRight" />
                </BigText>
              </SessionCard>
            </Wrapper>
          </MarginChildren>
        )}
        <MarginChildren>
          <Header as="h4">
            <span>Sessions</span>
            <FloatRightLink href={scheduleHref}>Schedule New</FloatRightLink>
          </Header>
          {sessionNotes.map((note) => (
            <Wrapper key={note.pk}>
              <SessionCard
                shouldShowPointer={note.status === 'locked'}
                onClick={() =>
                  note.status === 'locked' && history.push(routes.care.journey.sessionNote.url({ id: note.pk }))
                }
              >
                <ProfilePicture src={note.provider?.profilePicture} size="smaller" />
                <Content>
                  <ProviderName>
                    {note.provider ? `${note.provider.firstName} ${note.provider.lastName}` : 'Little Otter Provider'}
                  </ProviderName>
                  <Header as="h5">{removeVersionTagFromNoteName(note.noteName)}</Header>
                  <SessionDate>{format(new Date(note.datetime), 'EEEE, MMMM do yyyy')}</SessionDate>
                </Content>
                {note.status === 'locked' && (
                  <BigText>
                    <Icon name="ChevronRight" />
                  </BigText>
                )}
              </SessionCard>
            </Wrapper>
          ))}
        </MarginChildren>
      </Container>
    </MarginContainer>
  );
};
