import { gql } from '@apollo/client';

import { SURVEY_FRAGMENT } from './survey';

export const TANTRUM_SURVEY = gql`
  query TantrumSurvey {
    survey(id: 2) {
      ...SurveyFragment
    }
  }

  ${SURVEY_FRAGMENT}
`;
