import styled from 'styled-components';

import { Header, Link } from '@littleotter/legacy-components';

export const CardContainer = styled.article`
  display: flex;
  height: 9rem;
  background-color: ${({ theme }) => theme.deprecated_.colors.text};
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.deprecated_.colors.text};
  border-radius: 1rem;
`;

export const CardDate = styled.div`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  line-height: 1.5;
`;

export const CardTime = styled.div`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.header};
  line-height: 1.5;
`;

export const CardTitle = styled(Header).attrs({ as: 'h3' })`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.h4};
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
`;

export const CardLeftSection = styled.div`
  flex-basis: 16rem;
  align-self: center;
  padding: 1rem;
  color: ${({ theme }) => theme.deprecated_.colors.cream};
`;

export const CardLink = styled(Link).attrs({ variant: 'clearStyles' })`
  flex-grow: 1;
  display: flex;
  padding-left: 2rem;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  transition: background-color 0.2s linear;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  }

  &:active {
    background-color: ${({ theme }) => theme.deprecated_.colors.lightGray};
  }
`;

export const CardLinkContent = styled.div`
  flex-grow: 1;
  align-self: center;
`;

export const CardDisabledLink = styled.div`
  flex-grow: 1;
  display: flex;
  padding-left: 2rem;
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;

export const CardDisabledLinkContent = styled.p`
  flex-grow: 1;
  align-self: center;
  line-height: inherit;
`;

export const CardRightSection = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};

  & > ${CardLink}:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.deprecated_.colors.lightBackground};
  }
`;
