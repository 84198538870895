import { math } from 'polished';
import { type FC } from 'react';
import styled from 'styled-components';

import { ProfilePicture } from '../ProfilePicture';

const gridTemplate = '[i1-start] 8fr [i2-start] 16fr [b-start] 12fr [i1-end] 8fr [i2-end] 4fr [b-end]';

const GridContainer = styled.div<{ sizeMultiplier: number }>`
  display: grid;
  grid-template-columns: ${gridTemplate};
  grid-template-rows: ${gridTemplate};
  width: ${({ theme, sizeMultiplier }) => math(`${theme.deprecated_.sizeBasis} * ${sizeMultiplier} * 1.33`)};
  height: ${({ theme, sizeMultiplier }) => math(`${theme.deprecated_.sizeBasis} * ${sizeMultiplier} * 1.33`)};
  margin-right: ${({ theme }) => theme.deprecated_.sizeBasis};
`;

const ImageContainer = styled.div<{ sizeMultiplier: number }>`
  width: ${({ theme, sizeMultiplier }) => math(`${theme.deprecated_.sizeBasis} * ${sizeMultiplier}`)};
  height: ${({ theme, sizeMultiplier }) => math(`${theme.deprecated_.sizeBasis} * ${sizeMultiplier}`)};
`;

const BackImageContainer = styled(ImageContainer)`
  grid-column: i1-start / i1-end;
  grid-row: i1-start / i1-end;
`;

const FrontImageContainer = styled(ImageContainer)`
  grid-column: i2-start / i2-end;
  grid-row: i2-start / i2-end;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: b-start / b-end;
  grid-row: b-start / b-end;
  background-color: ${({ theme }) => theme.deprecated_.colors.backgroundBlue};
  color: ${({ theme }) => theme.deprecated_.colors.white};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.smaller};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
  border: ${({ theme }) =>
    `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.colors.white}`};
  border-radius: 50%;
`;

export type MultiProfilePictureProps = {
  srcList: string[];
  sizeMultiplier?: number;
};

export const orderProfilePictureSourceList = (srcList: string[]): string[] => {
  const isEmptySrc = (src: string | undefined) => !src;
  const existingProfilePics = srcList.filter((src) => !isEmptySrc(src));
  const emptyProfilePics = srcList.filter(isEmptySrc);

  return existingProfilePics.concat(emptyProfilePics);
};

/**
 * Allows a user to contain multiple profile images for display.
 * Shows up as 2 overlapping picture bubbles, with an extra badge when more than two
 * profile pictures are given. Front bubble is first element of array, back bubble ise second.
 * Will favor existing photos over non-existent ones.
 * @param srcList
 * @param sizeMultiplier
 * @constructor
 */
export const MultiProfilePicture: FC<MultiProfilePictureProps> = ({
  srcList: unorderedSrcList,
  sizeMultiplier = 2.25,
}) => {
  const srcList = orderProfilePictureSourceList(unorderedSrcList);
  if (srcList.length <= 1) return <ProfilePicture size="smallest" src={srcList[0]} />;

  return (
    <GridContainer sizeMultiplier={sizeMultiplier}>
      <BackImageContainer sizeMultiplier={sizeMultiplier}>
        <ProfilePicture src={srcList[1]} customSizeMultiplier={sizeMultiplier} />
      </BackImageContainer>
      <FrontImageContainer sizeMultiplier={sizeMultiplier}>
        <ProfilePicture src={srcList[0]} customSizeMultiplier={sizeMultiplier} />
      </FrontImageContainer>
      {srcList.length > 2 && <Badge>+{srcList.length - 2}</Badge>}
    </GridContainer>
  );
};
