import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState, type FC, type PropsWithChildren } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { Banner } from '@littleotter/legacy-components';

import { logger } from '$services/logging';
import { useBannerMessage } from '$shared/hooks';
import { useGetFmhcs } from '$shared/scenarios';

import { PageWideLoading } from '../../components/PageWideLoading';
import { type ViewerQuery } from '../../graphql/__generated__/ViewerQuery';
import { VIEWER_QUERY } from '../../graphql/user';
import { CheckupContent } from './CheckupContent';
import {
  useGetCaregiverQuestions,
  useGetChildQuestions,
  useGetFamilyQuestions,
  useGetVersion,
  useMarkFmhcAsSubmitted,
  useSubmitAssessment,
} from './hooks/gql';
import { useRefreshDetector } from './hooks/useRefreshDetector';
import { gqlChildToChildSubject } from './mappers/gqlChildToChildSubject';
import { type CheckupStartProps } from './props';
import { type SubjectAnswers, type SubjectQuestions } from './types';

/**
 * Checkup is a container which uses hooks to manage filling out and submitting
 * FMHC assessments for a given FMHC ID.
 */
export const Checkup: FC<PropsWithChildren<CheckupProps>> = ({ baseRoute, getFmhcReportUrl, fmhcId }) => {
  const history = useHistory();
  const location = useLocation();
  const { data: viewerData, loading: viewerDataLoading } = useQuery<ViewerQuery>(VIEWER_QUERY);

  const [getCaregiverQuestions, caregiverQuestionsIsLoading] = useGetCaregiverQuestions();
  const [getChildQuestions, getChildQuestionsIsLoading] = useGetChildQuestions();
  const [getFamilyQuestions, getFamilyQuestionsIsLoading] = useGetFamilyQuestions();

  const [submitAssessment, submitAssessmentLoading] = useSubmitAssessment();
  const { markFmhcAsSubmitted, markFmhcAsSubmittedLoading } = useMarkFmhcAsSubmitted();

  const [subjectQuestions, setSubjectQuestions] = useState<SubjectQuestions[]>([]);
  const [submissionRetrying, setSubmissionRetrying] = useState(false);

  const { version, getVersionLoading } = useGetVersion(fmhcId);

  useRefreshDetector(() => {
    if (!matchPath(location.pathname, { path: baseRoute, exact: true })) {
      history.push(baseRoute);
    }
  });

  const isLoading =
    getFamilyQuestionsIsLoading ||
    caregiverQuestionsIsLoading ||
    getChildQuestionsIsLoading ||
    viewerDataLoading ||
    getVersionLoading ||
    submitAssessmentLoading ||
    markFmhcAsSubmittedLoading ||
    submissionRetrying;

  const { id: viewerId, firstName: viewerFirstName, lastName: viewerLastName } = viewerData?.viewer ?? {};
  const viewerLastNameCommaFirstName = useMemo(
    () => (viewerLastName && viewerFirstName ? `${viewerLastName}, ${viewerFirstName}` : undefined),
    [viewerLastName, viewerFirstName]
  );
  const { bannerMessage, bannerIsShowing, setBannerMessage } = useBannerMessage();

  const retryTimeMs = 500;

  useEffect(() => {
    // Async functions are used here because `getAssessmentQuestionData` is async
    (async () => {
      const children = viewerData?.viewer?.family?.children ?? [];

      const childrenAssessments = await Promise.all(children.map(gqlChildToChildSubject).map(getChildQuestions));
      const caregiverAssessment = await getCaregiverQuestions();
      const familyAssessment = await getFamilyQuestions();
      setSubjectQuestions([...childrenAssessments, caregiverAssessment, familyAssessment]);
    })();
  }, [getCaregiverQuestions, getChildQuestions, getFamilyQuestions, viewerData]);

  const onAssessmentCompletion = useCallback(
    async (answers: SubjectAnswers): Promise<{ success: boolean }> => {
      if (!viewerId || !viewerLastNameCommaFirstName || !version) {
        return { success: false };
      }
      try {
        await submitAssessment(fmhcId, viewerId, viewerLastNameCommaFirstName, answers, version);
        return { success: true };
      } catch (e) {
        setBannerMessage({
          type: 'error',
          message: 'Failed to submit assessment answers. Please try again later.',
        });
        logger.error(new Error('Error submitting FMHC', { cause: e }));
        return { success: false };
      }
    },
    [viewerId, viewerLastNameCommaFirstName, version, submitAssessment, fmhcId, setBannerMessage]
  );

  // ! HACK: useGetFmhcs query just to get the `refetch`, so it can be used in the `onFmhcCompletion` callback. The query responses aren't actually used in this FC.
  const { refetch } = useGetFmhcs();

  const onFmhcCompletion = useCallback(async () => {
    if (fmhcId && viewerId && viewerLastNameCommaFirstName && version) {
      // Retry submitting FMHC
      let retries = 0;
      while (retries < 5) {
        const { errors } = await markFmhcAsSubmitted(fmhcId, viewerId, viewerLastNameCommaFirstName);
        if (!errors) {
          refetch();
          // breaks out of loop
          setSubmissionRetrying(false);
          history.push(getFmhcReportUrl(fmhcId));
          return;
        }
        setSubmissionRetrying(true);
        const timeout = retries * retryTimeMs;

        await new Promise((r) => setTimeout(r, timeout));
        retries += 1;
      }
      // Could not successfully submit FMHC
      setSubmissionRetrying(false);
      setBannerMessage({
        type: 'error',
        message: 'Failed to submit Family Mental Health Checkup. Please try again later.',
      });
    }
  }, [
    fmhcId,
    viewerId,
    viewerLastNameCommaFirstName,
    version,
    setBannerMessage,
    markFmhcAsSubmitted,
    refetch,
    history,
    getFmhcReportUrl,
  ]);

  if (isLoading) {
    return <PageWideLoading />;
  }

  return (
    <>
      <Banner
        message={bannerMessage.message}
        isShowing={bannerIsShowing}
        variant={bannerMessage.type}
        onTimeout={() => setBannerMessage(null)}
      />
      <CheckupContent
        baseRoute={baseRoute}
        subjectsQuestions={subjectQuestions}
        onAssessmentCompletion={onAssessmentCompletion}
        getFmhcId={() => fmhcId}
        onFmhcCompletion={onFmhcCompletion}
      />
    </>
  );
};

export type CheckupProps = CheckupStartProps & {
  fmhcId: string;
};
