import { rgba } from 'polished';
import styled from 'styled-components';

import { Button, Header } from '@littleotter/legacy-components';

export const CardContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius['02']};
  box-shadow: ${({ theme }) => `
    0px 0px 10px 1px ${rgba(theme.deprecated_.colors.japaneseIndigo, 0.05)},
    0px 20px 20px rgb(0, 0, 0, 0.07)
  `};
`;

export const Title = styled(Header).attrs({ as: 'h5' })`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  margin: ${({ theme }) => theme.spacing['05']};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing['04']};
`;

export const IconContainer = styled.div`
  height: ${({ theme }) => theme.spacing['07']};
  width: ${({ theme }) => theme.spacing['07']};
`;

export const Container = styled.p`
  margin: ${({ theme }) => `${theme.spacing['04']} ${theme.spacing['05']}`};
  color: ${({ theme }) => `${theme.deprecated_.colors.text}CC`};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const ScheduleButton = styled(Button)`
  margin: ${({ theme }) => `${theme.spacing['04']} ${theme.spacing['07']} 0`};
  padding: ${({ theme }) => theme.spacing['05']};
  background-color: ${({ theme }) => theme.deprecated_.colors.text};
  color: ${({ theme }) => theme.deprecated_.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const PurchaseButton = styled(Button)`
  margin: ${({ theme }) => `${theme.spacing['04']} ${theme.spacing['07']} 0`};
  padding: ${({ theme }) => theme.spacing['05']};
  background-color: ${({ theme }) => theme.deprecated_.colors.backgroundBlue};
  color: ${({ theme }) => theme.deprecated_.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing['07']};
`;
