import { type FC, type PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form6';
import { type SearchUsersQuery_accountsNamespace_searchUsers_users } from 'src/graphql/__generated__/SearchUsersQuery';

import { Banner, FullButton, Header, MultiSelect } from '@littleotter/legacy-components';

import { useBannerMessage } from '$shared/hooks';

import { useNewConversationForm } from './hooks';
import { useLiveSearchResults } from './hooks/useLiveSearchResults';
import { SelectContainer, StyledForm } from './styled';
import { type NewConversationFormData } from './types';

const presentOptions: (
  users: SearchUsersQuery_accountsNamespace_searchUsers_users[] | undefined
) => { value: string; label: string }[] = (users) => {
  const options = users ?? [];
  return options.map((user) => {
    const name = user.lastName ? `${user.firstName} ${user.lastName}` : `${user.firstName}`;
    const label = `${user.intakeqClientId}: ${name} (${user.email})`;
    return {
      value: user.id,
      label,
    };
  });
};

export const NewConversationForm: FC<PropsWithChildren> = () => {
  const { bannerMessage, bannerIsShowing, setBannerMessage } = useBannerMessage();
  const { searchResults, search, loading } = useLiveSearchResults();

  const { control, errors, formState, handleSubmit, mutationLoading, onSubmit } =
    useNewConversationForm(setBannerMessage);

  return (
    <>
      <Banner
        message={bannerMessage.message}
        isShowing={bannerIsShowing}
        variant={bannerMessage.type}
        onTimeout={() => setBannerMessage(null)}
      />
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Header as="h3">Add New Conversation</Header>
        <SelectContainer>
          <Controller
            render={({ name, value, onChange }) => (
              <MultiSelect
                name={name}
                label="People you want to start a conversation with (you are already included)"
                placeholder="Select the participants..."
                options={presentOptions(searchResults?.accountsNamespace.searchUsers.users)}
                selectedValues={value}
                onChange={onChange}
                onInputChange={search}
                loading={loading}
                errors={errors}
              />
            )}
            name="participants"
            control={control}
            rules={{
              validate: (value: NewConversationFormData['participants']) => value.length > 0,
            }}
          />
        </SelectContainer>
        <FullButton type="submit" disabled={!formState.isValid} isLoading={mutationLoading}>
          Add conversation
        </FullButton>
      </StyledForm>
    </>
  );
};
