import { App, type URLOpenListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { usePageViewTracking } from '$shared/hooks';
import { usePushNotifications } from '$shared/hooks/usePushNotifications';
import { routeProps } from '$shared/utils/routes';

import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import { MetadataRoute } from './components/MetadataRoute';
import { RouteSwitch } from './components/RouteSwitch';
import { BackToSchoolReport, HolidayReport } from './pages/AssessmentReports';
import { Assessments } from './pages/Assessments';
import { Care } from './pages/Care';
import { Child } from './pages/Child';
import { CheckupWithPrerequisites } from './pages/FmhcCheckup';
import HolidayCheckupGate from './pages/HolidayCheckupGate';
import { LegacyLogout } from './pages/LegacyLogout';
import { Library } from './pages/Library';
import { MatchListLanding } from './pages/MatchListLanding';
import { MessagesWithPrerequisites } from './pages/Messages';
import { AuthOnboarding } from './pages/Onboarding';
import { PaymentDetailsWithPrerequisites } from './pages/PaymentDetails';
import { Profile } from './pages/Profile';
import { Provider } from './pages/Provider';
import { Referral } from './pages/Referral';
import { Reports } from './pages/Reports/Fmhc';
import { PublicTantrumScreen } from './pages/Survey/PublicTantrum';
import { Terms } from './pages/Terms';
import { routes } from './routes';

const StyledRoutesWrapper = styled.section<{ removeExtraPadding: boolean }>`
  position: relative;

  ${({ removeExtraPadding }) =>
    removeExtraPadding &&
    `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  `}
`;

// ! be aware that route matching is not exact and "base" routes need to be rendered after its
// ! subroutes so that <Switch /> matches them and not the "base" ones
// ! e.g.: <Route path="/route/subroute" ... /> needs to be rendered before <Route path="/route" ... />
// ! this is why the Route that renders <Home /> (`/`) needs to be the last

// routes that everyone can access
const publicRoutesMap = {
  [routes.assessmentReport.backToSchoolReport.path]: <BackToSchoolReport />,
  [routes.assessmentReport.holidayReport.path]: <HolidayReport />,
  [routes.assessments.base.path]: <Assessments />,
  [routes.tantrums.base.path]: <PublicTantrumScreen />,
  [routes.terms.path]: <Terms />,
  [routes.legacyLogout.path]: <LegacyLogout />,
  '/checkup/holiday/2022': <HolidayCheckupGate />,
};

// routes that only authenticated users can access
const authenticatedRoutesMap = {
  [routes.care.base.path]: <Care />,
  [routes.library.base.path]: <Library />,
  [routes.profile.base.path]: <Profile />,
  [routes.provider.base.path]: <Provider />,
  [routes.messages.base.path]: <MessagesWithPrerequisites />,
  [routes.authOnboarding.base.path]: <AuthOnboarding />,
  [routes.checkupOnboarding.path]: <Redirect to={routes.checkup.home.url()} />,
  [routes.checkup.base.path]: (
    <CheckupWithPrerequisites
      baseRoute={routes.checkup.base.path}
      getFmhcReportUrl={(fmhcId: string) => routes.reports.fmhcs.fmhc.url({ fmhcId })}
    />
  ),
  [routes.reports.base.path]: <Reports />,
  [routes.paymentDetails.base.path]: <PaymentDetailsWithPrerequisites />,
  [routes.child.base.path]: <Child />,
  [routes.referral.base.path]: <Referral />,
  [routes.matchList.path]: <MatchListLanding />,
};

export const Routes = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  usePageViewTracking();

  useEffect(() => {
    App.addListener('appUrlOpen', (eventData: URLOpenListenerEvent) => {
      const slug = eventData.url.split('.com').pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, [history]);

  usePushNotifications();

  const routesWithoutExtraPadding = [
    routes.checkup.base,
    routes.reports.fmhcs.fmhc,
    routes.care.base,
    routes.provider.family.base,
    routes.matchList,
  ];

  const subroutesWithExtraPadding = [
    routes.care.schedule.path,
    routes.profile.care.home.path,
    routes.profile.care.bio.path.replace('/:id', ''),
    routes.referral.home.path,
  ];

  const removeExtraPadding =
    routesWithoutExtraPadding.some((route) => route.test(pathname)) &&
    !subroutesWithExtraPadding.some((path) => pathname.includes(path));

  return (
    <StyledRoutesWrapper removeExtraPadding={removeExtraPadding}>
      <RouteSwitch>
        {Object.keys(publicRoutesMap).map((path) => (
          <MetadataRoute key={path} path={path}>
            {publicRoutesMap[path]}
          </MetadataRoute>
        ))}

        {Object.keys(authenticatedRoutesMap).map((path) => (
          <AuthenticatedRoute key={path} path={path}>
            {authenticatedRoutesMap[path]}
          </AuthenticatedRoute>
        ))}

        <AuthenticatedRoute {...routeProps(routes.home)}>
          <Redirect to={routes.care.home.url()} />
        </AuthenticatedRoute>
      </RouteSwitch>
    </StyledRoutesWrapper>
  );
};
