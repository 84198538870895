import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
  return {
    windowHeight,
    windowWidth,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const listener = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return windowDimensions;
};
