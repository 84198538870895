import { math } from 'polished';
import styled, { type DefaultTheme } from 'styled-components';

export const Card = styled.div`
  width: 10rem;
  height: 7rem;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.deprecated_.colors.lightestGray};
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
  display: flex;
  flex-direction: column;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

export const LeftSection = styled.div`
  flex-grow: 1;
`;

export const RightSection = styled.div`
  align-self: flex-end;
`;

export const Title = styled.div``;

export const RiskText = styled.div<{ color: keyof DefaultTheme['deprecated_']['colors'] }>`
  margin-top: 0.5rem;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  color: ${({ theme, color }) => theme.deprecated_.colors[color]};
`;

export const Illustration = styled.img<{ isGrayscale: boolean }>`
  max-width: 5.75rem;
  ${({ isGrayscale }) => (isGrayscale ? 'filter: grayscale(1);' : '')}
`;
