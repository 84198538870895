import { gql } from '@apollo/client';

import { RESOURCES_FRAGMENT } from './resources-fragment';

const LIBRARY_WORRY_DOMAINS_FRAGMENT = gql`
  fragment LibraryWorryDomainsFragment on WorryDomainTypeConnection {
    edges {
      node {
        pk
        name
        shortName
      }
    }
  }
`;

export const GET_ALL_WORRY_DOMAINS = gql`
  query LibraryWorryDomains {
    worryDomains(isTopLevel: true) {
      ...LibraryWorryDomainsFragment
    }
  }
  ${LIBRARY_WORRY_DOMAINS_FRAGMENT}
`;

export const GET_ALL_WORRY_DOMAINS_AND_TAGS = gql`
  query LibraryWorryDomainsAndTags {
    worryDomains {
      ...LibraryWorryDomainsFragment
    }
    tags {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
  ${LIBRARY_WORRY_DOMAINS_FRAGMENT}
`;

export const SEARCH_RESOURCES = gql`
  query SearchResources($search: String, $worryDomains: [ID], $tags: [ID], $ageRanges: [ID]) {
    resources(search: $search, worryDomains: $worryDomains, tags: $tags, ageRanges: $ageRanges) {
      ...ResourcesFragment
    }
  }
  ${RESOURCES_FRAGMENT}
`;
