import styled, { css } from 'styled-components';

export const tableStyles = css`
  th {
    padding: ${({ theme }) => theme.deprecated_.sizeBasis};
    border: ${({ theme }) =>
      `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.border.color}`};
    text-align: center;
    font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  }

  td {
    padding: ${({ theme }) => theme.deprecated_.sizeBasis};
    border: ${({ theme }) =>
      `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.border.color}`};
  }
`;

export const Table = styled.table`
  ${tableStyles}
`;
