import styled from 'styled-components';

export const AlertContainer = styled.div`
  padding: ${({ theme }) => theme.spacing['05']};
  color: ${({ theme }) => theme.deprecated_.colors.white};
  background-color: ${({ theme }) => theme.deprecated_.colors.text};
  border-top: 1px solid ${({ theme }) => theme.deprecated_.border.color};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.small};
`;

export const AlertHeading = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-bottom: ${({ theme }) => theme.spacing['05']};
`;

export const AlertDescription = styled.p`
  margin: ${({ theme }) => theme.spacing['04']} 0;
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 35px;
  cursor: pointer;
  color: ${({ theme }) => theme.deprecated_.colors.gray50};
`;
