import { filter } from 'lodash-es';
import { useEffect, useState } from 'react';

import { useSearchParams } from '@littleotter/kit/react';

export const useFilteredData = <T>(
  items: T[],
  filterFunction: (item: T, query: string | undefined) => boolean
): { visibleItems: T[] } => {
  const [visibleItems, setVisibleItems] = useState<T[]>([]);
  const [{ q: query }] = useSearchParams<{ q?: string }>();

  useEffect(() => {
    setVisibleItems(filter(items, (item) => filterFunction(item, query)));
  }, [query, items, filterFunction]);

  return {
    visibleItems,
  };
};
