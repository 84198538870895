import { endRoute, parentRoute } from '$shared/utils/routes';

export type FmhcUrlParams = {
  fmhcId: string;
};

// Routing for onboarding exists inside the component itself
export const reportsRoutes = {
  reports: parentRoute('/reports')({
    fmhcs: parentRoute('/reports/fmhc')({
      overTime: endRoute('/reports/fmhc/over-time'),
      fmhc: endRoute<FmhcUrlParams>('/reports/fmhc/:fmhcId'),
    }),
  }),
};
